import {
  ADD_ELEMENTS_DOMAINPAGE,
  API_DOMAIN_ADD,
  API_DOMAIN_DELETE,
  API_DOMAIN_EDIT,
  API_DOMAIN_GET,
  API_DOMAIN_SEARCH,
  CLEAR_DOMAIN_TABLE,
  NO_ACTION,
  SET_SCREEN_DOMAINPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tdomain = {
  domains: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblDomain = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_DOMAIN_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const domains = action.payload;
      const domainsOptions = domains.map((domain) => ({ seq: domain.seqDomainId, value: domain.tblDomain.valueCode, label: domain.valueDes }));
      return { ...state, [domains[0]?.tblDomain.domainCode]: domainsOptions };
    },
    [API_DOMAIN_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tdomains: {
          domains: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_DOMAIN_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.domains;
      state.tdomains.totalElements += 1;

      return { ...state, tdomains: { ...state.tdomains, domains: [action.payload].concat(state.tdomains?.domains || []) } };
    },
    [API_DOMAIN_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const domainPayload = action.payload;
      const { seqDomainId } = domainPayload;
      const domains = state.tdomains?.domains || [];
      const newDomainuages = domains.map((domain) => {
        if (domain.seqDomainId === seqDomainId) {
          return domainPayload;
        } else {
          return domain;
        }
      });
      delete state.domains;
      return { ...state, tdomains: { ...state.tdomains, domains: newDomainuages } };
    },
    [API_DOMAIN_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.domains;
      state.tdomains.totalElements -= 1;
      return { ...state, tdomains: { ...state.tdomains, domains: state.tdomains.domains.filter((c) => c.seqDomainId !== action.payload) } };
    },
    [ADD_ELEMENTS_DOMAINPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tdomains: {
          domains: [...state.tdomains.domains, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.tdomains?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_DOMAINPAGE]: (state, action) => ({ ...state, tdomains: { ...state.tdomains, screenPage: action.payload } }),
    [CLEAR_DOMAIN_TABLE]: (state) => ({ ...state, tdomains: initial_tdomain }),
  },
  { tdomains: initial_tdomain }
);

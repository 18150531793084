import React, { useCallback, useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ImageList from "@material-ui/core/ImageList";
import PropTypes from "prop-types";
import TblCircularProgress from "../TblCircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const customStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    item: {
      cursor: "pointer",
    },
    gridList: {
      maxWidth: (cols) => cols * 32 + cols * 300,
    },
  };
});

const TblGridBands = ({ gridList, cols, loading }) => {
  const cClasses = customStyles(cols);

  const labels = useSelector((state) => state.tblLabel.labels);
  const history = useHistory();

  const [itemWidth, setItemWidth] = useState(Math.round(window.innerWidth / cols) - 16);

  const handleResize = useCallback(() => {
    setItemWidth(Math.round(window.innerWidth / cols) - 16);
  }, [cols]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const Image = ({ item }) => {
    return <Avatar variant="square" src={item.photoGroup} alt={item.name} style={{ width: itemWidth > 300 ? 300 : itemWidth, height: itemWidth > 300 ? 300 : itemWidth }} />;
  };

  return (
    <div className={cClasses.root}>
      {!loading ? (
        <ImageList rowHeight="auto" cols={cols} className={cClasses.gridList} gap={16} style={{ paddingLeft: 10 }}>
          {gridList.map((item, i) => (
            <GridListTile key={item.seqGroup} className={cClasses.item} onClick={() => history.push(`/groups/${item.codGroup}`)}>
              <Image key={i} item={item} />
              <GridListTileBar
                style={{ width: itemWidth > 300 ? 300 : itemWidth }}
                title={item.name}
                subtitle={
                  (item.total || item.total === 0) && (
                    <span>
                      {labels["TBL_COMMON_LABEL_TOTAL"]}: {item.total}
                      {" - "}
                      {labels["TBL_COMMON_LABEL_VOTES"]}: {item.votes}
                    </span>
                  )
                }
              />
            </GridListTile>
          ))}
        </ImageList>
      ) : (
        <TblCircularProgress />
      )}
    </div>
  );
};

TblGridBands.propTypes = {
  gridList: PropTypes.array.isRequired,
  cols: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default TblGridBands;

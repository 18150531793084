import { useMemo, useState } from "react";

import DomainSearchForm from "./DomainSearchForm";
import DomainTable from "./DomainTable";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblDomain from "../../../constants/TblDomain.json";

const extraData = {
  headCells: TblDomain.headCells,
  initialValues: {
    seqDomainId: null,
    domainCode: "",
    valueCode: "",
    valueDes: "",
    codLang: "",
  },
};

const DomainTab = ({ data, actions }) => {
  const [selected, setSelected] = useState({});
  data = useMemo(() => ({ ...data, ...extraData, selected }), [data, selected]);
  actions = useMemo(() => ({ ...actions, setSelected }), [actions, setSelected]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <DomainSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <DomainTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

DomainTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default DomainTab;

export class TblImagesPath {
  constructor(seqImagesPath, imageName, imagePath, imageGroup, url, active, sort, dateCre, dateMod, userCre, userMod) {
    this.seqImagesPath = seqImagesPath;
    this.imageName = imageName;
    this.imagePath = imagePath;
    this.imageGroup = imageGroup;
    this.url = url;
    this.active = active;
    this.sort = sort;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

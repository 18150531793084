import { ROLE_ADMIN, ROLE_NOACTIVE, ROLE_PUBLIC } from "../../../constants";
import { alpha, makeStyles } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import TblLinearProgress from "../../TblLinearProgress";
import TblMenu from "../../TblMenu";
import TblMobileMenu from "../TblMobileMenu";
import TblSearchBox from "../TblSearchBox";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const customClasses = makeStyles((theme) => ({
  grow: {
    width: "100%",
    flexGrow: 1,
    zIndex: theme.zIndex.modal + 1,
  },
  linkAppBar: {
    textDecoration: "none",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  linkMenu: {
    textDecoration: "none",
  },
  menuButton: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginRight: theme.spacing(0),
    },
  },
  menuLink: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      fontWeight: 600,
      fontSize: "1.05rem",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      display: "block",
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
}));

const TblMenuHeader = ({ data, actions, setScroll }) => {
  const classes = useStyles();
  const cClasses = customClasses();
  const labels = useSelector((state) => state.tblLabel.labels);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const { location, suggestBandsInputText, anchorEl, openMenu, loadingBands, hiddenLogo } = data;
  const { setHiddenLogo, handleProfileMenuOpen, handleMenuClose, handleLogout, toggleMenu, handleSearch } = actions;

  return (
    <AppBar color="inherit" position="sticky" className={cClasses.grow}>
      <Toolbar>
        <IconButton edge="start" className={cClasses.menuButton} color="inherit" aria-label="menu" onClick={toggleMenu(!openMenu)}>
          <MenuIcon />
        </IconButton>
        {!hiddenLogo && (
          <Link to="/" onClick={() => setScroll("home")}>
            <img src={"/images/application/tblimagelogoname.png"} alt="logo-name" />
          </Link>
        )}
        <Link className={cClasses.linkAppBar} to="/">
          <Button onClick={() => setScroll("home")}>
            <Typography className={cClasses.menuLink} variant="h6" noWrap>
              {labels["TBL_TBLFRAME_APPBAR_LINK_HOME"]}
            </Typography>
          </Button>
        </Link>
        <Link className={cClasses.linkAppBar} to="/">
          <Button onClick={() => setScroll("services")}>
            <Typography className={cClasses.menuLink} variant="h6" noWrap>
              {labels["TBL_TBLFRAME_APPBAR_LINK_WHATISTOURBAND"]}
            </Typography>
          </Button>
        </Link>
        <Link className={cClasses.linkAppBar} to="/groups">
          <Button>
            <Typography className={cClasses.menuLink} variant="h6" noWrap>
              {labels["TBL_COMMON_LABEL_GROUPS"]}
            </Typography>
          </Button>
        </Link>
        <Link className={cClasses.linkAppBar} to="/contests">
          <Button>
            <Typography className={cClasses.menuLink} variant="h6" noWrap>
              {labels["TBL_COMMON_LABEL_CONTEST"]}
            </Typography>
          </Button>
        </Link>
        <Link className={cClasses.linkAppBar} to="/">
          <Button onClick={() => setScroll("contact")}>
            <Typography className={cClasses.menuLink} variant="h6" noWrap>
              {labels["TBL_TBLFRAME_APPBAR_LINK_CONTACT"]}
            </Typography>
          </Button>
        </Link>
        <Link className={cClasses.linkAppBar} to="">
          <Button onClick={() => window.open(labels["TBL_TBLFRAME_APPBAR_URL_DOWNLOAD"], "_blank")}>
            <Typography className={cClasses.menuLink} variant="h6" noWrap>
              {labels["TBL_TBLFRAME_APPBAR_LINK_DOWNLOAD"]}
            </Typography>
          </Button>
        </Link>
        {!hiddenLogo && <div className={cClasses.grow} />}
        <div className={cClasses.search}>
          <div className={cClasses.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={labels["TBL_TBLFRAME_APPBAR_INPUTBASE_SEARCH"]}
            classes={{
              root: cClasses.inputRoot,
              input: cClasses.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            disabled={loadingBands}
            inputRef={suggestBandsInputText}
            onChange={handleSearch}
            onFocus={() => {
              if (isMobileOnly) {
                setHiddenLogo(true);
              }
            }}
            onBlur={(event) => {
              if (event && isMobileOnly) {
                setHiddenLogo(false);
              }
            }}
          />
        </div>
        {!userSession.applicationRole.includes(ROLE_PUBLIC) && !userSession.applicationRole.includes(ROLE_NOACTIVE) && (
          <Link to={location.pathname !== "/login" ? `/login?pathname=${location.pathname}` : `/login?pathname=/`}>
            <IconButton edge="end" aria-label="login button" aria-controls="bar-login" aria-haspopup="true" color="inherit">
              <Avatar alt="TourBandLive-Avatar" />
            </IconButton>
          </Link>
        )}
        {(userSession.applicationRole.includes(ROLE_PUBLIC) || userSession.applicationRole.includes(ROLE_NOACTIVE)) && (
          <React.Fragment>
            <IconButton edge="end" aria-label="account of current user" aria-controls="bar-search" aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
              <div className={classes.hideMobile}>
                <Typography className={classes.pr1}>{userSession.user}</Typography>
              </div>
              <Avatar alt="TourBandLive-Avatar" src={userSession.photoProfile} />
            </IconButton>
            <TblMenu anchorEl={anchorEl} onClose={handleMenuClose}>
              {userSession && userSession.applicationRole.includes(ROLE_ADMIN) && (
                <MenuItem onClick={handleMenuClose}>
                  <Link className={cClasses.linkMenu} to="/maintenance">
                    <Button startIcon={<SettingsIcon />}>{labels["TBL_TBLFRAME_TBLMENU_LINK_MAINTENANCE"]}</Button>
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleMenuClose}>
                <Link className={cClasses.linkMenu} to="/profile">
                  <Button startIcon={<PersonIcon />}>{labels["TBL_TBLFRAME_TBLMENU_LINK_PROFILE"]}</Button>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Link className={cClasses.linkMenu} to="/">
                  <Button startIcon={<ExitToAppIcon />}>{labels["TBL_TBLFRAME_TBLMENU_LINK_LOGOUT"]}</Button>
                </Link>
              </MenuItem>
            </TblMenu>
          </React.Fragment>
        )}
      </Toolbar>
      {loadingBands && <TblLinearProgress />}
      <TblMobileMenu data={data} actions={actions} setScroll={setScroll} />
      <TblSearchBox data={data} actions={actions} />
    </AppBar>
  );
};

TblMenuHeader.propTypes = {
  setScroll: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblMenuHeader;

import { faUpload, faVideo } from "@fortawesome/free-solid-svg-icons";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import TblTextField from "../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import YouTube from "react-youtube";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblAddBandVideo = ({ data, actions, component: UpdateForm }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { headCells, videos, selectedIndex, videoId } = data;
  const { handleListItemClick, handleRemoveVideo, handleUploadVideo } = actions;

  const generate = () => {
    return videos.map((video) => (
      <ListItem key={video.videoId} button selected={selectedIndex === video.videoId} onClick={(event) => handleListItemClick(event, video.videoId)}>
        <ListItemAvatar>
          <Avatar>
            <FontAwesomeIcon icon={faVideo} size="lg" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={video.videoId} />
        <div className={classes.ml1}></div>
        <ListItemSecondaryAction>
          <IconButton onClick={() => handleRemoveVideo(video.videoId)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };

  return (
    <Grid container direction="column" justifyContent="center" alignContent="center" alignItems="center">
      <Grid item xs={10} md={8} lg={4} container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={10} md={5}>
          <TblTextField id={headCells[16].id} name={headCells[16].column} label={labels[headCells[16].label]} properties={headCells[16].properties} />
          <UpdateForm />
        </Grid>
        <Grid item xs={2} md={1}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={handleUploadVideo}>
              <FontAwesomeIcon icon={faUpload} color="#f50057" size="lg" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item xs={10} md={8} lg={4}>
        <List>{generate()}</List>
      </Grid>
      <div className={classes.pt1}></div>
      {videoId && (
        <Grid item xs={10} md={8} lg={4}>
          <Typography variant="subtitle1">{labels[headCells[17].label]}:</Typography>
          <YouTube videoId={videoId} opts={{ width: isMobileOnly ? 300 : 640, height: 150 }} />
        </Grid>
      )}
    </Grid>
  );
};

TblAddBandVideo.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  component: PropTypes.node.isRequired,
};

export default TblAddBandVideo;

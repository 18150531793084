import { ADD_ELEMENTS_LANGPAGE, API_LANG_ADD, API_LANG_DELETE, API_LANG_EDIT, API_LANG_GET, API_LANG_SEARCH, CLEAR_LANG_TABLE, SET_SCREEN_LANGPAGE } from "../constants";
import { apiAddLang, apiDeleteLang, apiGetLangs, apiSearchLangs, apiUpdateLang } from "../api/langAPI";
import { urlAddLang, urlDeleteLang, urlGetLangs, urlSearchLangs, urlUpdateLang } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_LANGPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_LANGPAGE, (lang) => apiSearchLangs(urlSearchLangs, lang)());
export const actionGetLangs = createAction(API_LANG_GET, apiGetLangs(urlGetLangs));
export const actionSearchLangs = createAction(API_LANG_SEARCH, (lang) => apiSearchLangs(urlSearchLangs, lang)());
export const actionAddLang = createAction(API_LANG_ADD, (lang) => apiAddLang(urlAddLang, lang)());
export const actionUpdateLang = createAction(API_LANG_EDIT, (lang) => apiUpdateLang(urlUpdateLang, lang)());
export const actionDeleteLang = createAction(API_LANG_DELETE, ({ seqLanguage }) => apiDeleteLang(urlDeleteLang, seqLanguage)());
export const actionClearLangTable = createAction(CLEAR_LANG_TABLE);

import {
  ADD_ELEMENTS_CONTEST_BANDPAGE,
  API_CONTEST_BANDS_GET,
  API_CONTEST_BAND_SEARCH,
  API_CONTEST_BAND_VOTES_STATS,
  CLEAR_CONTEST_BAND_TABLE,
  SET_SCREEN_CONTEST_BANDPAGE,
} from "../constants";
import { apiGetContestBands, apiSearchContestBands } from "../api/contestBandAPI";
import { urlGetContestBandVotesStats, urlGetContestBands, urlSearchContestBands } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_CONTEST_BANDPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_CONTEST_BANDPAGE, (contest) => apiSearchContestBands(urlSearchContestBands, contest)());
export const actionSearchContestBands = createAction(API_CONTEST_BAND_SEARCH, (contest) => apiSearchContestBands(urlSearchContestBands, contest)());
export const actionGetContestBandVotesStats = createAction(API_CONTEST_BAND_VOTES_STATS, (contest) => apiGetContestBands(urlGetContestBandVotesStats, contest)());
export const actionGetContestBands = createAction(API_CONTEST_BANDS_GET, (contest) => apiGetContestBands(urlGetContestBands, contest)());
export const actionClearContestBandTable = createAction(CLEAR_CONTEST_BAND_TABLE);

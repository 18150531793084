import React, { useEffect } from "react";

import PropTypes from "prop-types";
import TblChangePass from "../components/TblProfile/TblChangePass/TblChangePass";
import TblFrame from "../components/TblFrame";

const ChangePassPage = ({ setScroll }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TblFrame setScroll={setScroll} size={6}>
      <TblChangePass />
    </TblFrame>
  );
};

ChangePassPage.propTypes = {
  setScroll: PropTypes.func.isRequired,
};

export default ChangePassPage;

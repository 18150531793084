import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblStyle } from "../model/TblStyle";

export const apiGetStyles = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchStyles = (url, obj) => async () => {
  const { codStyle, desStyle } = obj;
  url = `${url}?codStyle=${codStyle}&desStyle=${desStyle}`;
  return searchData(url, obj);
};

export const apiAddStyle = (url, obj) => async () => {
  const { seqStyle, codStyle, desStyle } = obj;
  const request = new TblStyle(seqStyle, codStyle, desStyle);
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateStyle = (url, obj) => async () => {
  const { seqStyle, codStyle, desStyle, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqStyle}`;
  const request = new TblStyle(seqStyle, codStyle, desStyle, dateCre, dateMod, userCre, userMod);
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteStyle = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

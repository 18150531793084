import { BrowserView, MobileOnlyView, TabletView, isMobileOnly } from "react-device-detect";

import Grid from "@material-ui/core/Grid";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStyles } from "../../../theme/useStyles";

const TblGridSkeleton = () => {
  const classes = useStyles();
  const width = isMobileOnly ? 300 : 600;
  const height = isMobileOnly ? 200 : 400;
  return (
    <Grid item xs={12} container direction={isMobileOnly ? "column" : "row"} justifyContent="space-evenly" alignItems="center" spacing={2} className={classes.mt1}>
      <BrowserView>
        <Grid item>
          <Skeleton animation="wave" variant="circle" width={30} height={30} />
        </Grid>
      </BrowserView>
      <TabletView>
        <Grid item>
          <Skeleton animation="wave" variant="circle" width={30} height={30} />
        </Grid>
      </TabletView>
      <Skeleton animation="wave" variant="rect" width={width} height={height} />
      <MobileOnlyView>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <Skeleton animation="wave" variant="circle" width={30} height={30} />
          </Grid>
          <Grid item>
            <Skeleton animation="wave" variant="circle" width={30} height={30} />
          </Grid>
        </Grid>
      </MobileOnlyView>
      <BrowserView>
        <Grid item>
          <Skeleton animation="wave" variant="circle" width={30} height={30} />
        </Grid>
      </BrowserView>
      <TabletView>
        <Grid item>
          <Skeleton animation="wave" variant="circle" width={30} height={30} />
        </Grid>
      </TabletView>
    </Grid>
  );
};

export default TblGridSkeleton;

import {
  ADD_ELEMENTS_ERROR_MESSAGEPAGE,
  API_ERROR_MESSAGE_ADD,
  API_ERROR_MESSAGE_DELETE,
  API_ERROR_MESSAGE_EDIT,
  API_ERROR_MESSAGE_GET,
  API_ERROR_MESSAGE_SEARCH,
  CLEAR_ERROR_MESSAGE_TABLE,
  SET_SCREEN_ERROR_MESSAGEPAGE,
} from "../constants";
import { apiAddErrorMessage, apiDeleteErrorMessage, apiGetErrorMessages, apiSearchErrorMessages, apiUpdateErrorMessage } from "../api/errorMessageAPI";
import { urlAddErrorMessage, urlDeleteErrorMessage, urlGetErrorMessages, urlSearchErrorMessages, urlUpdateErrorMessage } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_ERROR_MESSAGEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_ERROR_MESSAGEPAGE, (errorMessage) => apiSearchErrorMessages(urlSearchErrorMessages, errorMessage)());
export const actionGetErrorMessages = createAction(API_ERROR_MESSAGE_GET, apiGetErrorMessages(urlGetErrorMessages));
export const actionSearchErrorMessages = createAction(API_ERROR_MESSAGE_SEARCH, (errorMessage) => apiSearchErrorMessages(urlSearchErrorMessages, errorMessage)());
export const actionAddErrorMessage = createAction(API_ERROR_MESSAGE_ADD, (errorMessage) => apiAddErrorMessage(urlAddErrorMessage, errorMessage)());
export const actionUpdateErrorMessage = createAction(API_ERROR_MESSAGE_EDIT, (errorMessage) => apiUpdateErrorMessage(urlUpdateErrorMessage, errorMessage)());
export const actionDeleteErrorMessage = createAction(API_ERROR_MESSAGE_DELETE, ({ seqErrorMessageId }) => apiDeleteErrorMessage(urlDeleteErrorMessage, seqErrorMessageId)());
export const actionClearErrorMessageTable = createAction(CLEAR_ERROR_MESSAGE_TABLE);

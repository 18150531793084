import { Form, Formik } from "formik";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblDialogTitle from "../TblDialogTitle";
import TblTextField from "../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const TblContestGenerateWinnersPopup = ({ open, setOpen, onAction }) => {
  const labels = useSelector((state) => state.tblLabel.labels);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    onAction(values.numWinners);
  };

  return (
    <Dialog onClose={handleClosePopup} aria-labelledby="tbl-contest-groups-results-popup" open={open}>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={handleClosePopup} style={{ color: "#fff", backgroundColor: "#666" }}>
        {labels["TBL_CONTEST_ACTION_LABEL_GENERATE_WINNER"]}
      </TblDialogTitle>
      <Formik enableReinitialize initialValues={{ numWinners: 0 }} onSubmit={handleSubmit}>
        <Form>
          <DialogContent dividers style={{ overflow: "hidden" }}>
            <Grid item container direction="column">
              <Grid item xs={12} container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <TblTextField id="input-numwinners" name="numWinners" label={labels["TBL_CONTEST_GENERATE_WINNERS_LABEL_NUM_WINNERS"]} properties={{ type: "number" }} />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="outlined">
              {labels["TBL_CONTEST_BUTTON_LABEL_GENERATE_WINNER"]}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

TblContestGenerateWinnersPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
};

export default TblContestGenerateWinnersPopup;

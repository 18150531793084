import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblUploadFile from "./TblUploadFile";
import Typography from "@material-ui/core/Typography";

const TblAvatar = ({ id, name, label, properties, src, setImageSrc }) => {
  return (
    <React.Fragment>
      <Grid item>
        <Grid item container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Avatar alt="TourBandLive-Avatar" variant="square" src={src} style={{ width: 300, height: 300 }} />
          </Grid>
          <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">{label}:</Typography>
            </Grid>
            <Grid item>
              <TblUploadFile id={id} name={name} setImageSrc={setImageSrc} properties={properties} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

TblAvatar.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  properties: PropTypes.object,
  src: PropTypes.string,
  setImageSrc: PropTypes.func.isRequired,
};

export default TblAvatar;

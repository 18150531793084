import { Form, Formik, useFormikContext } from "formik";
import { OPERATION_ADD, OPERATION_EDIT, VALUE_S } from "../../../constants";
import React, { useEffect, useState } from "react";
import { convert2Date, getByPath, showNotifier } from "../../../utils/dataUtils";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import TblLinearProgress from "../../TblLinearProgress";
import { TblOption } from "../../../model/TblOption";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";

const TblMaintainPopup = ({ children, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();
  const [toClose, setToClose] = useState(false);
  const tblDomain = useSelector((state) => state.tblDomain);

  const {
    initialValues,
    headCells,
    title,
    numRows,
    selected,
    masterSelected,
    rows,
    search,
    operation,
    isTouched,
    tableLabel,
    open,
    fullScreen,
    serverPage,
    childrens,
    isLoading,
  } = data;
  const {
    actionAdd,
    actionUpdate,
    actionAddElementsPage,
    setOperation,
    setSelected,
    setIsTouched,
    setTitle,
    setOpen,
    setFullScreen,
    onValidation,
    beforeClose,
    beforeSubmit,
    beforeCreate,
    beforeUndo,
    beforeNext,
    beforePrevious,
    afterSubmit,
    setIsLoading,
  } = actions;

  const sequence = headCells[0].column;

  const UpdateForm = () => {
    const { initialValues, setFieldValue, touched } = useFormikContext();
    useEffect(() => {
      if (_.isEmpty(touched)) {
        Object.entries(initialValues).forEach(([key, value]) => {
          const field = headCells.find((field) => field.column === key);
          if (field) {
            const selectedValue = getByPath(selected, field.path);
            const selectOptions = getByPath(selected, field.select);
            let fieldValue = "";
            if (field.select && selectOptions) {
              const attrs = Object.values(selectOptions);
              fieldValue = new TblOption(attrs[0], attrs[1], attrs[2]);
            } else if (field.master) {
              fieldValue = getByPath(masterSelected, field.path);
            } else if (field.domain && field.properties?.type !== "checkbox" && tblDomain[field.domain] && selectedValue) {
              const domains = tblDomain[field.domain];
              const option = domains.find((domain) => String(domain.value) === String(selectedValue));
              fieldValue = new TblOption(option.seq, option.value, option.label);
            } else if (field.properties?.type === "checkbox" && selectedValue) {
              fieldValue = selectedValue === VALUE_S ? true : false;
            } else if (field.children) {
              const children = getByPath(childrens, field.children);
              if (children instanceof Array) {
                const values = children.reduce((acc, element) => {
                  return [...acc, element.value];
                }, []);
                fieldValue = values;
              } else {
                fieldValue = children;
              }
            } else if (field.date) {
              fieldValue = convert2Date(selectedValue, field.properties.format);
            } else if (!field.image) {
              fieldValue = selectedValue;
            }

            if (fieldValue || String(fieldValue) === String(0) || String(fieldValue) === String(false)) {
              setFieldValue(key, fieldValue);
            } else {
              setFieldValue(key, value);
            }
          }
        });
      } else {
        let otherTouched = true;
        Object.entries(touched).forEach(([tkey, value]) => {
          Object.entries(initialValues).forEach(([vkey, value]) => {
            if (tkey === vkey) {
              otherTouched = false;
            }
          });
          if (otherTouched) {
            delete touched[tkey];
          }
        });
        setIsTouched(true);
      }
    }, [initialValues, setFieldValue, touched]);
    return null;
  };

  /**
   * Method to submit form
   * @param {*} values
   * @param { setSubmitting, setFieldError, setErrors } actions
   */
  const handleSubmit = (values, { setSubmitting, setTouched }) => {
    if (beforeSubmit) {
      beforeSubmit(values);
    }
    const currentRow = { ...selected, ...values };
    if (operation === OPERATION_ADD) {
      callSaveRecord(actionAdd, currentRow, setSubmitting, setTouched);
    } else {
      callSaveRecord(actionUpdate, currentRow, setSubmitting, setTouched);
    }
  };

  /**
   * Method to call action to save a record
   * @param {*} action
   * @param {*} setSubmitting
   * @param {*} currentRow
   * @param {*} setErrors
   */
  const callSaveRecord = (action, request, setSubmitting, setTouched) => {
    callService(dispatch, action, request)
      .then((response) => {
        showNotifier(dispatch, labels["TBL_COMMON_ACTION_SUCCESSFULLY"], "success");
        setTouched({});
        setIsTouched(false);
        setSelected(response);
        toClose && handleClose();
        setOperation(OPERATION_EDIT);
        setTitle(`${labels["TBL_COMMON_TITLE_EDITIONPOPUP"]} ${tableLabel}`);

        if (afterSubmit) {
          afterSubmit(response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  /**
   * Method to create new row
   */
  const handleCreate = () => {
    if (beforeCreate) {
      beforeCreate();
    }

    setOperation(OPERATION_ADD);
    setIsTouched(true);
    setSelected({});

    setTitle(`${labels["TBL_COMMON_TITLE_CREATIONPOPUP"]} ${tableLabel}`);
  };

  /**
   * Method to undo changes
   */
  const handleUndo = () => {
    if (beforeUndo) {
      beforeUndo();
    }

    if (operation === OPERATION_ADD) {
      if (numRows > 0) {
        setOperation(OPERATION_EDIT);
        setSelected(rows[0]);

        setIsTouched(false);
        setTitle(`${labels["TBL_COMMON_TITLE_EDITIONPOPUP"]} ${tableLabel}`);
      } else {
        setOpen(false);
      }
    } else {
      setIsTouched(false);
    }
  };

  /**
   * Method to go to the previous row
   */
  const handleSkipPrevious = () => {
    setIsTouched(false);
    const index = rows.findIndex((row) => selected[sequence] === row[sequence]);
    setSelected(rows[index - 1]);

    if (beforePrevious) {
      beforePrevious();
    }
  };

  /**
   * Method to go to the next row
   */
  const handleSkipNext = () => {
    setIsTouched(false);
    const index = rows.findIndex((row) => selected[sequence] === row[sequence]);
    if (index === rows.length - 1) {
      setIsLoading(true);
      search.page = serverPage + 1;
      callService(dispatch, actionAddElementsPage, search).then((response) => {
        setSelected(response[0]);

        setIsLoading(false);
      });
    } else {
      setSelected(rows[index + 1]);
    }

    if (beforeNext) {
      beforeNext();
    }
  };

  /**
   * Method to close popup
   */
  const handleClose = () => {
    if (beforeClose) {
      beforeClose();
    }

    setOpen(false);
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={handleClose} aria-labelledby="tbl-popup-maintain" open={open} maxWidth="md" fullWidth fullScreen={fullScreen}>
      <Formik initialValues={initialValues} validate={onValidation} onSubmit={handleSubmit}>
        {({ submitForm, isSubmitting, resetForm }) => (
          <Form>
            <DialogTitle id="tbl-dialog-title">{title}</DialogTitle>
            <DialogContent style={{ overflowX: "hidden" }} dividers>
              {children}
              <UpdateForm />
            </DialogContent>

            <DialogActions>
              <IconButton
                color="secondary"
                variant="contained"
                onClick={() => {
                  setToClose(true);
                  submitForm();
                }}
                disabled={isSubmitting || isLoading || !isTouched}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                color="primary"
                variant="contained"
                onClick={() => {
                  setToClose(false);
                  submitForm();
                }}
                disabled={isSubmitting || isLoading || !isTouched}
              >
                <SaveIcon />
              </IconButton>
              <IconButton onClick={handleClose} disabled={isSubmitting || isLoading}>
                <CancelIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  resetForm();
                  handleCreate();
                }}
                disabled={isSubmitting || isLoading || isTouched}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  resetForm();
                  handleUndo();
                }}
                disabled={isSubmitting || isLoading || !isTouched}
              >
                <RotateLeftIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  resetForm();
                  handleSkipPrevious();
                }}
                disabled={isSubmitting || isLoading || (selected[sequence] && selected[sequence] === rows[0][sequence]) || isTouched}
              >
                <SkipPreviousIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  resetForm();
                  handleSkipNext();
                }}
                disabled={isSubmitting || isLoading || (rows[numRows - 1] && selected[sequence] && selected[sequence] === rows[numRows - 1][sequence]) || isTouched}
              >
                <SkipNextIcon />
              </IconButton>
              {fullScreen ? (
                <IconButton onClick={() => setFullScreen(false)} disabled={isSubmitting || isLoading}>
                  <FullscreenExitIcon />
                </IconButton>
              ) : (
                fullScreen === false && (
                  <IconButton onClick={() => setFullScreen(true)} disabled={isSubmitting || isLoading}>
                    <FullscreenIcon />
                  </IconButton>
                )
              )}
            </DialogActions>
            <div>{(isSubmitting || isLoading || isLoading) && <TblLinearProgress />}</div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

TblMaintainPopup.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblMaintainPopup;

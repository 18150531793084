import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblLanguage } from "../model/TblLanguage";
import { TblText } from "../model/TblText";
import { TblTextId } from "../model/TblTextId";

export const apiGetTemplates = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchTemplates = (url, obj) => async () => {
  const { codText, desText, codLang } = obj;
  url = `${url}?codText=${codText}&desText=${desText}&codLang=${codLang?.value ?? ""}`;
  return searchData(url, obj);
};

export const apiAddTemplate = (url, obj) => async () => {
  const { seqTextId, codSearch, codText, desText, codLang } = obj;
  const request = new TblTextId(seqTextId, new TblText(undefined, codText, codSearch), desText, new TblLanguage(codLang.seq, codLang.value, codLang.label));
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateTemplate = (url, obj) => async () => {
  const { seqTextId, codText, codSearch, desText, codLang, tblText, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqTextId}`;
  const request = new TblTextId(
    seqTextId,
    new TblText(tblText.seqText, codText, codSearch, dateCre, dateMod, userCre, userMod),
    desText,
    new TblLanguage(codLang.seq, codLang.value, codLang.label),
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteTemplate = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

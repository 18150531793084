import { faBandcamp, faFacebookF, faInstagram, faSoundcloud, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const TblBandLinks = ({ data }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const { links } = data;

  const IconRRSS = ({ tooltip, urlPage, icon }) => (
    <Tooltip title={tooltip}>
      <IconButton onClick={() => window.open(urlPage, "_blank")}>
        <FontAwesomeIcon icon={icon} color="#ef343b" size="xs" />
      </IconButton>
    </Tooltip>
  );

  return (
    <Grid item xs={12} container direction="row" alignItems="center">
      <Grid item>
        <Typography variant="subtitle1">{labels["TBL_BANDDETAILS_LABEL_RRSS"]}:</Typography>
      </Grid>
      <Grid item>
        {links["web"] && <IconRRSS tooltip="Web" urlPage={links["web"]} icon={faGlobeEurope} />}
        {links["facebook"] && <IconRRSS tooltip="Facebook" urlPage={links["facebook"]} icon={faFacebookF} />}
        {links["instagram"] && <IconRRSS tooltip="Instagram" urlPage={links["instagram"]} icon={faInstagram} />}
        {links["youtube"] && <IconRRSS tooltip="Youtube" urlPage={links["youtube"]} icon={faYoutube} />}
        {links["bandcamp"] && <IconRRSS tooltip="Bandcamp" urlPage={links["bandcamp"]} icon={faBandcamp} />}
        {links["soundcloud"] && <IconRRSS tooltip="Soundcloud" urlPage={links["soundcloud"]} icon={faSoundcloud} />}
      </Grid>
    </Grid>
  );
};

TblBandLinks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TblBandLinks;

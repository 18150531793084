import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblHandsVote from "../../../../TblHandsVote/TblHandsVote";
import TblMsgPopup from "../../../../TblPopups/TblMsgPopup";
import TblTBPointVote from "../../../../TblTBPointVote/TblTBPointVote";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../../theme/useStyles";

const TblBandVote = ({ data, actions }) => {
  const classes = useStyles();
  const band = useSelector((state) => state.tblBand.band);
  const labels = useSelector((state) => state.tblLabel.labels);

  const { vote, tbpoint, openMsgPopup } = data;
  const { voteBand, voteTbpoint, setOpenMsgPopup, handleCloseMsgPopup } = actions;

  return (
    <React.Fragment>
      {!_.isEmpty(band) && (
        <Grid item xs={12} container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
          <Grid item xs="auto" lg={7} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                {labels["TBL_COMMON_LABEL_MYVOTE"]}
              </Typography>
            </Grid>
            <TblHandsVote voteRange={5} codGroup={band.tblGroup.codGroup} vote={vote} onVote={voteBand} />
          </Grid>
          <Grid item xs="auto" lg={5} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h4" align="center">
                  {labels["TBL_COMMON_LABEL_MYTBPOINT"]}
                </Typography>
                <img className={classes.pl1} src="/images/application/tblimagegrouptitletbpoint.png" alt="tbpoint" variant="square" />
              </Box>
            </Grid>
            <TblTBPointVote vote={tbpoint} onVote={voteTbpoint} />
          </Grid>
        </Grid>
      )}
      <TblMsgPopup open={openMsgPopup} setOpen={setOpenMsgPopup} tKey="TBL_TBPOINTPOPUPTEMPLATE_TBPOINTPOPUP_TEXT_INFO" onClosePopup={handleCloseMsgPopup} />
    </React.Fragment>
  );
};

TblBandVote.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblBandVote;

import { OPERATION_ADD, OPERATION_EDIT } from "../../../constants";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddBoxIcon from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import TblConfirmPopup from "../../TblPopups/TblConfirmPopup";
import TblMenu from "../../TblMenu";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";
import { showNotifier } from "../../../utils/dataUtils";

const Cards = ({ children, data, actions, setOpenAudit, hideActionsMenu, menuActions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const { selected, cardtitle, masterSelected, tableLabel, isNoUpdatable, isLoading, isReadOnly } = data;
  const { actionDelete, setSelected, setOperation, setIsTouched, setTitle, setOpen, beforeOpen, beforeDelete } = actions;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Method to open creation/edition popup
   * @param {*} operation
   */
  const handleOpen = (operation) => {
    if (beforeOpen) {
      beforeOpen(operation);
    }

    setOperation(operation);
    if (operation === OPERATION_ADD) {
      setIsTouched(true);
      setSelected({});

      setTitle(`${labels["TBL_COMMON_TITLE_CREATIONPOPUP"]} ${tableLabel}`);
    } else {
      setIsTouched(false);
      setTitle(`${labels["TBL_COMMON_TITLE_EDITIONPOPUP"]} ${tableLabel}`);
    }
    setOpen(true);
  };

  /**
   * Method to open audit popup
   */
  const handleOpenAudit = () => {
    handleClose();
    if (!_.isEmpty(selected)) {
      setOpenAudit(true);
    }
  };

  /**
   * Method to delete a language
   * @param {*} selected
   */
  const handleDelete = () => {
    setOpenConfirm(true);
  };

  const confirmDelete = () => {
    if (beforeDelete) {
      beforeDelete();
    }
    callService(dispatch, actionDelete, selected).then(() => {
      showNotifier(dispatch, labels["TBL_COMMON_ACTION_SUCCESSFULLY"], "success");
      setSelected({});

      setOpenConfirm(false);
    });
  };

  return (
    <Card>
      <CardHeader className="padding-left-15" title={cardtitle} />
      <CardActions>
        <IconButton className="padding-left-15" onClick={() => handleOpen(OPERATION_ADD)} disabled={(!!masterSelected && _.isEmpty(masterSelected)) || isLoading || !!isReadOnly}>
          <AddBoxIcon />
        </IconButton>
        <IconButton
          onClick={() => handleOpen(OPERATION_EDIT)}
          disabled={_.isEmpty(selected) || (!!masterSelected && _.isEmpty(masterSelected)) || !!isNoUpdatable || isLoading || !!isReadOnly}
        >
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete} disabled={_.isEmpty(selected) || (!!masterSelected && _.isEmpty(masterSelected)) || isLoading || !!isReadOnly}>
          <DeleteIcon />
        </IconButton>
        {!hideActionsMenu ? (
          <Button endIcon={<MoreVertIcon />} aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
            {labels["TBL_COMMON_MENU_ACTIONS"]}
          </Button>
        ) : null}
        <TblMenu anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem onClick={handleOpenAudit}>{labels["TBL_COMMON_TITLE_AUDIT"]}</MenuItem>
          {menuActions}
        </TblMenu>
      </CardActions>
      <CardContent>{children}</CardContent>
      <TblConfirmPopup open={openConfirm} setOpen={setOpenConfirm} onConfirm={confirmDelete} text="TBL_COMMON_TEXT_DELETECONFIRMTEXT" />
    </Card>
  );
};

Cards.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  setOpenAudit: PropTypes.func,
  hideActionsMenu: PropTypes.bool,
  menuActions: PropTypes.node,
};

export default Cards;

import Button from "@material-ui/core/Button";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

const TblUploadFile = ({ id, name, setImageSrc, properties }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const { errors, touched, setTouched, isSubmitting } = useFormikContext();
  const { type } = properties;
  const valid = !touched[name] || !errors[name];

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      if (imageDataUrl.split(",")[0].includes("data:image")) {
        setImageSrc(imageDataUrl);
      }
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  return (
    <FormControl fullWidth>
      <TextField
        style={{ display: "none" }}
        id={id}
        name={name}
        type={type}
        variant="outlined"
        onChange={onFileChange}
        onBlur={() => setTouched({ file: true }, true)}
        error={!valid}
        helperText={errors[name]}
        disabled={isSubmitting}
      />
      <label htmlFor={id}>
        <Button variant="contained" color="primary" startIcon={<FindInPageIcon />} component="span">
          {labels["TBL_COMMON_BUTTON_EXAMINE"]}
        </Button>
      </label>
    </FormControl>
  );
};

TblUploadFile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  properties: PropTypes.object,
};

export default TblUploadFile;

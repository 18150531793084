import {
  ADD_ELEMENTS_COUNTRYPAGE,
  API_COUNTRY_ADD,
  API_COUNTRY_DELETE,
  API_COUNTRY_EDIT,
  API_COUNTRY_GET,
  API_COUNTRY_SEARCH,
  CLEAR_COUNTRY_TABLE,
  NO_ACTION,
  SET_SCREEN_COUNTRYPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tcountry = {
  countries: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblCountry = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_COUNTRY_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const countries = action.payload;
      const countriesOptions = countries.map((country) => ({
        seq: country.seqCountryId,
        value: country.tblCountry.codCountry,
        label: country.desCountry,
      }));
      return { ...state, countries: countriesOptions };
    },
    [API_COUNTRY_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcountries: {
          countries: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_COUNTRY_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.countries;
      state.tcountries.totalElements += 1;

      return { ...state, tcountries: { ...state.tcountries, countries: [action.payload].concat(state.tcountries?.countries || []) } };
    },
    [API_COUNTRY_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const countryPayload = action.payload;
      const { seqCountryId } = countryPayload;
      const countries = state.tcountries?.countries || [];
      const newCountryuages = countries.map((country) => {
        if (country.seqCountryId === seqCountryId) {
          return countryPayload;
        } else {
          return country;
        }
      });
      delete state.countries;
      return { ...state, tcountries: { ...state.tcountries, countries: newCountryuages } };
    },
    [API_COUNTRY_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.countries;
      state.tcountries.totalElements -= 1;
      return { ...state, tcountries: { ...state.tcountries, countries: state.tcountries.countries.filter((c) => c.seqCountryId !== action.payload) } };
    },
    [ADD_ELEMENTS_COUNTRYPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcountries: {
          countries: [...state.tcountries.countries, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.tcountries?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_COUNTRYPAGE]: (state, action) => ({ ...state, tcountries: { ...state.tcountries, screenPage: action.payload } }),
    [CLEAR_COUNTRY_TABLE]: (state) => ({ ...state, tcountries: initial_tcountry }),
  },
  { tcountries: initial_tcountry, countries: [] }
);

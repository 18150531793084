import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import TblCheckBoxLabel from "../../TblForms/TblCheckboxLabel";
import TblLinearProgress from "../../TblLinearProgress";
import TblTextField from "../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import { actionLogin } from "../../../actions/userActions";
import { callService } from "../../../utils/serviceUtils";
import { useStyles } from "../../../theme/useStyles";

const TblLoginForm = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { isLoading, screens, topRef } = data;
  const { setIsLoading, setShowScreen, setInitialValues, handleReCaptchaVerify } = actions;

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { user, password } = values;
      if (!user) {
        errors.user = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!password) {
        errors.password = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setIsLoading(true);
      try {
        const result = await handleReCaptchaVerify();
        if (result) {
          await callService(dispatch, actionLogin, values);
          history.push(params.get("pathname"));
        }
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
    [dispatch, handleReCaptchaVerify, history, params, setIsLoading]
  );

  const handleShowSignupForm = () => {
    !isLoading && setShowScreen(screens.signupForm);
    setInitialValues({ id: "", user: "", email: "", password: "", confpass: "", birthDate: null, codProvince: "", photoProfile: "", socialName: "" });
  };

  const handleShowLoginOptions = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.loginOptions);
  };

  const handleShowRememberPassword = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.rememberPassword);
  };

  return (
    <Grid item xs={12}>
      <Formik initialValues={{ user: "", password: "", remember: false }} validate={handleValidate} onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
        {({ isSubmitting }) => (
          <Form>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                <TblTextField id="user-input" name="user" label={labels["TBL_ENTITY_TBL_USER"]} properties={{ type: "text", maxLength: 30 }} />
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                <TblTextField id="password-input" name="password" label={labels["TBL_ENTITY_TBL_USER_PASSWORD"]} properties={{ type: "password", maxLength: 15 }} />
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Grid item container direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                <Grid item xs={6}>
                  <TblCheckBoxLabel id="remember-input" name="remember" label={labels["TBL_TBLLOGIN_FORMIK_CHECKBOX_REMEMBER"]} properties={{ type: "checkbox" }} />
                </Grid>
                <Grid item xs={6}>
                  <Link onClick={handleShowRememberPassword} variant="body2">
                    {labels["TBL_LOGIN_BUTTON_REMEMBERPASS"]}
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Grid item xs={6} md={5}>
                <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                  {labels["TBL_COMMON_BUTTON_LOGIN"]}
                </Button>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                {isLoading && <TblLinearProgress />}
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      <div className={classes.mt2}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">{labels["TBL_LOGIN_MSG_NOTREGISTERED"]}</Typography>
        <div className={classes.mrxs}></div>
        <Link onClick={handleShowSignupForm} component="button" variant="body2">
          {labels["TBL_LOGIN_BUTTON_SIGNUP"]}
        </Link>
      </Box>
      <div className={classes.myxs}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">{labels["TBL_LOGIN_MSG_LOGINBACK"]}</Typography>
        <div className={classes.mrxs}></div>
        <Link onClick={handleShowLoginOptions} component="button" variant="body2">
          {labels["TBL_LOGIN_BUTTON_LOGINBACK"]}
        </Link>
      </Box>
    </Grid>
  );
};

TblLoginForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblLoginForm;

import React, { useEffect, useState } from "react";
import { actionClearBandReviewTable, actionSearchUserReviews } from "../../../actions/bandReviewActions";
import { actionClearBandUserVoteTable, actionSearchUserVotes } from "../../../actions/bandUserVoteActions";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblTabPanel from "../../TblTabPanel";
import UserReviewTable from "./UserReviewTable";
import UserTable from "./UserTable";
import UserVoteTable from "./UserVoteTable";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";
import { useStyles } from "../../../theme/useStyles";

const UserContainer = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const [value, setValue] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { headCellsUsers, headCellsUserVotes, headCellsUserReviews, initialValuesUser, selecUser, childrens } = data;
  const { setSelecUser, setChildrens } = actions;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!_.isEmpty(selecUser) || isUpdated) {
      setIsUpdated(false);
      const userVoteRQ = {
        user: selecUser.user,
        orderBy: headCellsUserVotes[1].path,
      };
      const userReviewRQ = {
        user: selecUser.user,
        orderBy: headCellsUserReviews[1].path,
      };
      callService(dispatch, actionSearchUserVotes, userVoteRQ);
      callService(dispatch, actionSearchUserReviews, userReviewRQ);
    } else if (_.isEmpty(selecUser)) {
      Promise.all([dispatch(actionClearBandUserVoteTable()), dispatch(actionClearBandReviewTable())]);
    }
  }, [dispatch, selecUser, headCellsUserVotes, headCellsUserReviews, childrens, setChildrens, isUpdated]);

  return (
    <div>
      <UserTable headCells={headCellsUsers} initialValues={initialValuesUser} selected={selecUser} setSelected={setSelecUser} data={data} actions={actions} />
      <div className={classes.pt2}></div>
      <Paper>
        <Tabs value={value} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="band tabs">
          <Tab label={labels["TBL_COMMON_LABEL_VOTES"]} />
          <Tab label={labels["TBL_COMMON_LABEL_BANDREVIEWS"]} />
        </Tabs>
      </Paper>
      <TblTabPanel value={value} index={0}>
        <UserVoteTable headCells={headCellsUserVotes} masterSelected={selecUser} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={1}>
        <UserReviewTable headCells={headCellsUserReviews} masterSelected={selecUser} data={data} actions={actions} />
      </TblTabPanel>
    </div>
  );
};

UserContainer.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserContainer;

import Grid from "@material-ui/core/Grid";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStyles } from "../../../../../theme/useStyles";

const TblBandFileSk = () => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
      <Grid item container direction="column" alignItems="center" xs={12} lg={3} spacing={2}>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" width={300} height={300} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" width={250} height={64} />
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="center" spacing={3}>
          <Grid item>
            <Skeleton animation="wave" variant="rect" width={128} height={70} />
          </Grid>
          <Grid item>
            <Skeleton animation="wave" variant="rect" width={128} height={70} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column" alignItems="flex-start" xs={12} lg={4} spacing={2}>
        <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Skeleton animation="wave" heigth={41} />
          </Grid>
          <Grid item xs={1}>
            <Skeleton animation="wave" variant="rect" width={64} height={26} />
          </Grid>
        </Grid>
        <hr className={classes.my1} />
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" width={602} height={150} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" width={602} height={20} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" width={602} height={20} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton animation="wave" variant="rect" width={602} height={20} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TblBandFileSk;

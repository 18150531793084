export class TblContestant {
  constructor(seqContestant, tblContest, tblUser, codContestant, valid, winner, dateCre, dateMod, userCre, userMod) {
    this.seqContestant = seqContestant;
    this.tblContest = tblContest;
    this.tblUser = tblUser;
    this.codContestant = codContestant;
    this.valid = valid;
    this.winner = winner;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import React, { useEffect } from "react";
import { actionClearProvinceTable, actionSearchProvinces } from "../../../../actions/provinceActions";
import { useDispatch, useSelector } from "react-redux";

import CountryTable from "./CountryTable";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import ProvinceTable from "./ProvinceTable/ProvinceTable";
import Typography from "@material-ui/core/Typography";
import { callService } from "../../../../utils/serviceUtils";
import { useStyles } from "../../../../theme/useStyles";

const PlaceContainer = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { headCellsCountries, headCellsProvinces, initialValuesCountries, initialValuesProvinces, selecCountry } = data;
  const { setSelecCountry, setIsLoading } = actions;

  const dispatch = useDispatch();

  useEffect(() => {
    if (selecCountry?.tblCountry?.codCountry) {
      setIsLoading(true);
      const request = {
        codCountry: selecCountry?.tblCountry?.codCountry,
        orderBy: headCellsProvinces[1].path,
      };
      callService(dispatch, actionSearchProvinces, request).finally(() => setIsLoading(false));
    } else {
      dispatch(actionClearProvinceTable());
    }
  }, [selecCountry, dispatch, headCellsProvinces, setIsLoading]);

  return (
    <div>
      <CountryTable headCells={headCellsCountries} initialValues={initialValuesCountries} selected={selecCountry} setSelected={setSelecCountry} data={data} actions={actions} />
      <div className={classes.pt2}></div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">
            {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_PROVINCES"]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProvinceTable headCells={headCellsProvinces} initialValues={initialValuesProvinces} masterSelected={selecCountry} data={data} actions={actions} />
        </Grid>
      </Grid>
    </div>
  );
};

PlaceContainer.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default PlaceContainer;

import { useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import ImagesPathSearchForm from "./ImagesPathSearchForm";
import ImagesPathTable from "./ImagesPathTable";
import PropTypes from "prop-types";
import TblImagesPath from "../../../constants/TblImagesPath.json";

const extraData = {
  headCells: TblImagesPath.headCells,
  initialValues: {
    seqImagesPath: null,
    file: null,
    imageName: "",
    imagePath: "",
    imageGroup: "",
    url: "",
    sort: "",
    active: true,
  },
};

const ImagesPathTab = ({ data, actions }) => {
  const [selected, setSelected] = useState({});
  data = useMemo(() => ({ ...data, ...extraData, selected }), [data, selected]);
  actions = useMemo(() => ({ ...actions, setSelected }), [actions, setSelected]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <ImagesPathSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <ImagesPathTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

ImagesPathTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ImagesPathTab;

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const TblBandProvinces = ({ data }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const { provinces } = data;

  return (
    <Grid item xs={12}>
      <Typography variant="subtitle1" display="inline">
        {labels["TBL_BANDDETAILS_LABEL_PROVINCES"]}:
      </Typography>
      <Typography variant="body1" display="inline" paragraph={true} align="justify" color="secondary">
        {provinces.map((province, i) => {
          if (i === provinces.length - 1) {
            return <span key={i}> {province}</span>;
          } else {
            return (
              <span key={i}>
                {" " + province}
                <span style={{ color: "black" }}> |</span>
              </span>
            );
          }
        })}
      </Typography>
    </Grid>
  );
};

TblBandProvinces.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TblBandProvinces;

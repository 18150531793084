import React, { useCallback, useMemo, useState } from "react";
import { actionAddBandStyle, actionAddElementsPage, actionDeleteBandStyle, actionSearchBandStyles, actionSetCurrentPage } from "../../../../actions/bandStyleActions";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupStyle } from "../../../../model/TblGroupStyle";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import { TblStyle } from "../../../../model/TblStyle";
import TblTable from "../../TblTable";
import { actionGetStyles } from "../../../../actions/styleActions";
import { callService } from "../../../../utils/serviceUtils";

const BandStyleTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const styles = useSelector((state) => state.tblStyle.styles);
  const tbandStyles = useSelector((state) => state.tblBandStyle.tbandStyles);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const isNoUpdatable = true;

  const { search, serverPage, screenPage, totalElements } = tbandStyles;
  const { selecStyle, openStyleAudit } = data;
  const { setSelecStyle, setOpenStyleAudit } = actions;

  const rows = useMemo(
    () =>
      tbandStyles.bandStyles.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupStyle(
            row.seqStyle,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            new TblStyle(row.tblStyle.seqStyle, row.tblStyle.codStyle, row.tblStyle.desStyle),
            row.dateCre,
            row.userCre
          ),
        ];
      }, []),
    [tbandStyles.bandStyles]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_GROUP_STYLE"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      if (!values.codStyle) {
        errors.codStyle = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  const handleOpenMaintain = useCallback(() => {
    if (masterSelected?.tblGroup?.tblCountry) {
      callService(dispatch, actionGetStyles);
    }
  }, [dispatch, masterSelected]);

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BANDSTYLES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecStyle,
      isNoUpdatable,
    }),
    [data, open, headCells, initialValues, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecStyle, isNoUpdatable]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandStyles,
      actionAdd: actionAddBandStyle,
      actionDelete: actionDeleteBandStyle,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setOpen,
      onValidation: handleValidation,
      setSelected: setSelecStyle,
      beforeOpen: handleOpenMaintain,
    }),
    [actions, handleValidation, setSelecStyle, handleOpenMaintain]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenStyleAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <TblSelect
              id={headCells[1].id}
              name={headCells[1].column}
              label={labels[headCells[1].label]}
              options={styles}
              defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_STYLE"] }}
            />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openStyleAudit} setOpen={setOpenStyleAudit} selected={selecStyle} />
    </div>
  );
};

BandStyleTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandStyleTable;

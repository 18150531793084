export class TblGroupId {
  constructor(seqGroupId, tblGroup, tblLanguage, description, listProvinces, listStyles, listLinks, listVideos, dateCre, dateMod, userCre, userMod) {
    this.seqGroupId = seqGroupId;
    this.tblGroup = tblGroup;
    this.tblLanguage = tblLanguage;
    this.description = description;
    this.provinces = listProvinces;
    this.styles = listStyles;
    this.links = listLinks;
    this.videos = listVideos;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

export class TblGroupReviewRevision {
  constructor(seqReviewRevision, reviewTitle, review, dateCre, dateMod, userCre, userMod) {
    this.seqReviewRevision = seqReviewRevision;
    this.reviewTitle = reviewTitle;
    this.review = review;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import {
  ADD_ELEMENTS_PROVINCEPAGE,
  API_PROVINCE_ADD,
  API_PROVINCE_DELETE,
  API_PROVINCE_EDIT,
  API_PROVINCE_GET,
  API_PROVINCE_SEARCH,
  CLEAR_PROVINCE_TABLE,
  SET_SCREEN_PROVINCEPAGE,
} from "../constants";
import { apiAddProvince, apiDeleteProvince, apiGetProvinces, apiSearchProvinces, apiUpdateProvince } from "../api/provinceAPI";
import { urlAddProvince, urlDeleteProvince, urlGetProvinces, urlSearchProvinces, urlUpdateProvince } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_PROVINCEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_PROVINCEPAGE, (province) => apiSearchProvinces(urlSearchProvinces, province)());
export const actionGetProvinces = createAction(API_PROVINCE_GET, (country) => apiGetProvinces(urlGetProvinces, country)());
export const actionSearchProvinces = createAction(API_PROVINCE_SEARCH, (country) => apiSearchProvinces(urlSearchProvinces, country)());
export const actionAddProvince = createAction(API_PROVINCE_ADD, (province) => apiAddProvince(urlAddProvince, province)());
export const actionUpdateProvince = createAction(API_PROVINCE_EDIT, (province) => apiUpdateProvince(urlUpdateProvince, province)());
export const actionDeleteProvince = createAction(API_PROVINCE_DELETE, ({ seqProvince }) => apiDeleteProvince(urlDeleteProvince, seqProvince)());
export const actionClearProvinceTable = createAction(CLEAR_PROVINCE_TABLE);

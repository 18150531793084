export class TblProvince {
  constructor(seqProvince, tblCountry, codProvince, desProvince, dateCre, dateMod, userCre, userMod) {
    this.seqProvince = seqProvince;
    this.tblCountry = tblCountry;
    this.codProvince = codProvince;
    this.desProvince = desProvince;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

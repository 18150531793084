export class TblGroupReview {
  constructor(seqReview, codReview, tblGroup, tblUser, reviewTitle, review, reviewed, reviewedDate, tblGroupReviewRevision, dateCre, dateMod, userCre, userMod) {
    this.seqReview = seqReview;
    this.codReview = codReview;
    this.tblGroup = tblGroup;
    this.tblUser = tblUser;
    this.reviewTitle = reviewTitle;
    this.review = review;
    this.reviewed = reviewed;
    this.reviewedDate = reviewedDate;
    this.tblGroupReviewRevision = tblGroupReviewRevision;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblDomain } from "../model/TblDomain";
import { TblDomainId } from "../model/TblDomainId";
import { TblLanguage } from "../model/TblLanguage";

export const apiGetDomains = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiGetDomain = (url, obj) => async () => {
  const { domainCode, userLang } = obj;
  url = `${url}/${domainCode}?lang=${userLang}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchDomains = (url, obj) => async () => {
  const { domainCode, valueCode, valueDes, codLang } = obj;
  url = `${url}?domainCode=${domainCode}&valueCode=${valueCode}&valueDes=${valueDes}&codLang=${codLang?.value ?? ""}`;
  return searchData(url, obj);
};

export const apiAddDomain = (url, obj) => async () => {
  const { seqDomainId, domainCode, valueCode, valueDes, sort, codLang } = obj;
  const request = new TblDomainId(seqDomainId, new TblDomain(undefined, domainCode, valueCode), valueDes, sort, new TblLanguage(codLang.seq, codLang.value, codLang.label));
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateDomain = (url, obj) => async () => {
  const { seqDomainId, domainCode, valueCode, valueDes, sort, codLang, tblDomain, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqDomainId}`;
  const request = new TblDomainId(
    seqDomainId,
    new TblDomain(tblDomain.seqDomain, domainCode, valueCode),
    valueDes,
    sort,
    new TblLanguage(codLang.seq, codLang.value, codLang.label),
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteDomain = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

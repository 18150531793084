import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { actionSignUp, actionValidUserEmail, actionValidUserName } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import TblDatePicker from "../../TblForms/TblDatePicker";
import TblLinearProgress from "../../TblLinearProgress";
import TblSelect from "../../TblForms/TblSelect";
import TblTextField from "../../TblForms/TblTextField";
import TblUser from "../../../constants/TblUser.json";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";
import { useStyles } from "../../../theme/useStyles";

const headCells = TblUser.headCells;

const TblSignupSocialForm = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const errorMessages = useSelector((state) => state.tblErrorMessage.errorMessages);
  const dispatch = useDispatch();

  const [timeoutSearch, setTimeoutSearch] = useState(0);
  const [userNameError, setUserNameError] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { isLoading, initialValues, screens, provinces, topRef } = data;
  const { setIsLoading, setShowScreen, handleReCaptchaVerify } = actions;

  useEffect(() => {
    if (initialValues.user || initialValues.email) {
      callService(dispatch, actionValidUserName, initialValues.user)
        .then(() => setUserNameError(false))
        .catch(() => setUserNameError(true));
      callService(dispatch, actionValidUserEmail, initialValues.email).catch(() => setShowScreen(screens.loginOptions));
    }
  }, [dispatch, initialValues.email, initialValues.user, screens.loginOptions, setShowScreen]);

  const UpdateForm = () => {
    const { setFieldValue, touched } = useFormikContext();
    useEffect(() => {
      if (_.isEmpty(touched)) {
        Object.entries(initialValues).forEach(([key, value]) => {
          setFieldValue(key, value);
        });
      }
    }, [setFieldValue, touched]);
    return null;
  };

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { user, email, birthDate, codProvince } = values;
      if (!user) {
        errors.user = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (userNameError) {
        errors.user = errorMessages["TBL_0011"];
      }
      if (!email) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_EMAIL"];
      }
      if (!birthDate) {
        errors.birthDate = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!codProvince) {
        errors.codProvince = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [errorMessages, labels, userNameError]
  );

  const handleValidUserName = useCallback(
    (event) => {
      const name = event.target.value;
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
      if (name.length > 0) {
        setTimeoutSearch(
          setTimeout(() => {
            callService(dispatch, actionValidUserName, name)
              .then(() => {
                setUserNameError(false);
              })
              .catch(() => setUserNameError(true));
          }, 500)
        );
      }
    },
    [dispatch, timeoutSearch]
  );

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setIsLoading(true);
      try {
        const result = await handleReCaptchaVerify();
        if (result) {
          await callService(dispatch, actionSignUp, values);
          history.push(params.get("pathname"));
        }
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
    [dispatch, handleReCaptchaVerify, history, params, setIsLoading]
  );

  const handleShowLoginForm = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.loginForm);
  };

  const handleShowLoginOptions = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.loginOptions);
  };

  return (
    <Grid item xs={12}>
      <Formik initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                <TblTextField
                  id={headCells[1].id}
                  name={headCells[1].column}
                  label={labels[headCells[1].label]}
                  onChange={handleValidUserName}
                  properties={headCells[1].properties}
                />
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                <TblDatePicker id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} />
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                <TblSelect
                  id={headCells[10].id}
                  name={headCells[10].column}
                  label={labels[headCells[10].label]}
                  options={provinces}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_PROVINCE"] }}
                />
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Grid item xs={5}>
                <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                  {labels["TBL_COMMON_BUTTON_CONTINUE_LABEL"]}
                </Button>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                {isLoading && <TblLinearProgress />}
              </Grid>
            </Box>
            <UpdateForm />
          </Form>
        )}
      </Formik>
      <div className={classes.mt2}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">{labels["TBL_LOGIN_MSG_LOGIN"]}</Typography>
        <div className={classes.mrxs}></div>
        <Link onClick={handleShowLoginForm} component="button" variant="body2">
          {labels["TBL_LOGIN_BUTTON_LOGIN"]}
        </Link>
      </Box>
      <div className={classes.myxs}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">{labels["TBL_LOGIN_MSG_LOGINBACK"]}</Typography>
        <div className={classes.mrxs}></div>
        <Link onClick={handleShowLoginOptions} component="button" variant="body2">
          {labels["TBL_LOGIN_BUTTON_LOGINBACK"]}
        </Link>
      </Box>
      <div className={classes.mt1}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Link onClick={() => window.open("/politicadeprivacidad.pdf", "_blank")} component="button" variant="body2">
          {labels["TBL_COMMON_LINK_PRIVACYPOLICY"]}
        </Link>
      </Box>
    </Grid>
  );
};

TblSignupSocialForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblSignupSocialForm;

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblDatePicker from "../../../TblForms/TblDatePicker";
import TblSearchForm from "../../TblSearchForm";
import TblSelect from "../../../TblForms/TblSelect";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetCountries } from "../../../../actions/countryActions";
import { actionGetDomain } from "../../../../actions/domainActions";
import { actionGetLangs } from "../../../../actions/langActions";
import { actionGetProvinces } from "../../../../actions/provinceActions";
import { actionSearchUsers } from "../../../../actions/userActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const initialValues = {
  user: "",
  email: "",
  name: "",
  lastName: "",
  birthDate: null,
  userLang: "",
  codCountry: "",
  codProvince: "",
  isLinkedFacebook: "",
  isLinkedGoogle: "",
  active: "",
  applicationRole: "",
  notifications: "",
  dateCreFrom: null,
  dateCreTo: null,
};

const UserSearchForm = ({ data, actions }) => {
  const userSession = useSelector((state) => state.tblUser.userSession);
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const countries = useSelector((state) => state.tblCountry.countries);
  const provinces = useSelector((state) => state.tblProvince.provinces);
  const yesNoOptions = useSelector((state) => state.tblDomain.IND_YES_NO);
  const appRoleOptions = useSelector((state) => state.tblDomain.APPLICATION_ROL);
  const dispatch = useDispatch();

  const { headCellsUsers, disabledProv } = data;
  const { setDisabledProv, setSelecUser } = actions;

  if (langs.length === 0) {
    callService(dispatch, actionGetLangs);
  }

  if (countries.length === 0) {
    callService(dispatch, actionGetCountries);
  }

  if (!yesNoOptions || yesNoOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "IND_YES_NO", userLang: userSession.userLang });
  }

  if (!appRoleOptions || appRoleOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "APPLICATION_ROL", userLang: userSession.userLang });
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.orderBy = headCellsUsers[1].path;
      callService(dispatch, actionSearchUsers, values).finally(() => {
        setSelecUser({});
        setSubmitting(false);
      });
    },
    [dispatch, headCellsUsers, setSelecUser]
  );

  const handleSelectCountry = useCallback(
    (e) => {
      const country = e.target?.value?.value;
      callService(dispatch, actionGetProvinces, country).then((response) => {
        if (response.length > 0) {
          setDisabledProv(false);
        } else {
          setDisabledProv(true);
        }
      });
    },
    [dispatch, setDisabledProv]
  );

  return (
    <TblSearchForm initialValues={initialValues} onSubmit={handleSubmit}>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblTextField id={headCellsUsers[1].id} name={headCellsUsers[1].column} label={labels[headCellsUsers[1].label]} properties={headCellsUsers[1].properties} />
        </Grid>
        <Grid item xs={5}>
          <TblTextField id={headCellsUsers[4].id} name={headCellsUsers[4].column} label={labels[headCellsUsers[4].label]} properties={headCellsUsers[4].properties} />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblTextField id={headCellsUsers[5].id} name={headCellsUsers[5].column} label={labels[headCellsUsers[5].label]} properties={headCellsUsers[5].properties} />
        </Grid>
        <Grid item xs={5}>
          <TblTextField id={headCellsUsers[6].id} name={headCellsUsers[6].column} label={labels[headCellsUsers[6].label]} properties={headCellsUsers[6].properties} />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblDatePicker id={headCellsUsers[7].id} name={headCellsUsers[7].column} label={labels[headCellsUsers[7].label]} properties={headCellsUsers[7].properties} />
        </Grid>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[8].id}
            name={headCellsUsers[8].column}
            label={labels[headCellsUsers[8].label]}
            options={langs}
            defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[9].id}
            name={headCellsUsers[9].column}
            label={labels[headCellsUsers[9].label]}
            options={countries}
            defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_COUNTRY"] }}
            onSelect={handleSelectCountry}
          />
        </Grid>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[10].id}
            name={headCellsUsers[10].column}
            disabled={disabledProv}
            label={labels[headCellsUsers[10].label]}
            options={provinces}
            defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_PROVINCE"] }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[11].id}
            name={headCellsUsers[11].column}
            label={labels[headCellsUsers[11].label]}
            options={yesNoOptions}
            defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
          />
        </Grid>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[12].id}
            name={headCellsUsers[12].column}
            label={labels[headCellsUsers[12].label]}
            options={yesNoOptions}
            defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[13].id}
            name={headCellsUsers[13].column}
            label={labels[headCellsUsers[13].label]}
            options={yesNoOptions}
            defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
          />
        </Grid>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[14].id}
            name={headCellsUsers[14].column}
            label={labels[headCellsUsers[14].label]}
            options={appRoleOptions}
            defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblSelect
            id={headCellsUsers[15].id}
            name={headCellsUsers[15].column}
            label={labels[headCellsUsers[15].label]}
            options={yesNoOptions}
            defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
          />
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-around" item>
        <Grid item xs={5}>
          <TblDatePicker id={headCellsUsers[16].id} name={headCellsUsers[16].column} label={labels[headCellsUsers[16].label]} properties={headCellsUsers[16].properties} />
        </Grid>
        <Grid item xs={5}>
          <TblDatePicker id={headCellsUsers[17].id} name={headCellsUsers[17].column} label={labels[headCellsUsers[17].label]} properties={headCellsUsers[17].properties} />
        </Grid>
      </Grid>
    </TblSearchForm>
  );
};

UserSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserSearchForm;

import React, { useCallback, useMemo, useState } from "react";
import {
  actionAddContestant,
  actionAddElementsPage,
  actionDeleteContestant,
  actionSearchContestants,
  actionSetCurrentPage,
  actionUpdateContestant,
} from "../../../../actions/contestantActions";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import { TblContestant } from "../../../../model/TblContestant";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { TblUser } from "../../../../model/TblUser";
import { actionGetUsers } from "../../../../actions/userActions";
import { callService } from "../../../../utils/serviceUtils";

const ContestantTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tcontestants = useSelector((state) => state.tblContestant.tcontestants);
  const users = useSelector((state) => state.tblUser.users);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { search, serverPage, screenPage, totalElements } = tcontestants;
  const { selecContestant, openContestantAudit } = data;
  const { setSelecContestant, setOpenContestantAudit } = actions;

  if (users.length === 0) {
    callService(dispatch, actionGetUsers);
  }

  const rows = useMemo(
    () =>
      tcontestants.contestants.reduce((acc, row) => {
        return [
          ...acc,
          new TblContestant(
            row.seqContestant,
            row.tblContest,
            new TblUser(row.tblUser.seqUser, row.tblUser.user, row.tblUser.user),
            row.codContestant,
            row.valid,
            row.winner,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tcontestants.contestants]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_CONTESTANT"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { user, codContestant } = values;
      if (!user) {
        errors.user = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!codContestant) {
        errors.codContestant = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_ENTITY_TBL_CONTEST"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecContestant,
    }),
    [data, open, headCells, initialValues, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecContestant]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchContestants,
      actionAdd: actionAddContestant,
      actionUpdate: actionUpdateContestant,
      actionDelete: actionDeleteContestant,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setSelected: setSelecContestant,
      onValidation: handleValidation,
      setOpen,
    }),
    [actions, handleValidation, setSelecContestant]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenContestantAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid container direction="row" justifyContent="space-between" item>
            <Grid item xs={5}>
              <TblAutocomplete id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} options={users} />
            </Grid>
            <Grid item xs={5}>
              <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" item>
            <Grid item xs={5}>
              <TblCheckBoxLabel id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
            </Grid>
            <Grid item xs={5}>
              <TblCheckBoxLabel id={headCells[4].id} name={headCells[4].column} label={labels[headCells[4].label]} properties={headCells[4].properties} />
            </Grid>
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openContestantAudit} setOpen={setOpenContestantAudit} selected={selecContestant} />
    </div>
  );
};

ContestantTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContestantTable;

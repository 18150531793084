export class TblCountryId {
  constructor(seqCountryId, tblCountry, desCountry, tblLanguage, dateCre, dateMod, userCre, userMod) {
    this.seqCountryId = seqCountryId;
    this.tblCountry = tblCountry;
    this.desCountry = desCountry;
    this.tblLanguage = tblLanguage;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

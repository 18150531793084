import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TblDatePicker = ({ id, name, label, properties, disabled }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const { setFieldValue, isSubmitting, values } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, values[name]);
  }, [name, setFieldValue, values]);

  properties = {
    ...properties,
    clearLabel: labels["TBL_COMMON_BUTTON_RESET"],
    cancelLabel: labels["TBL_COMMON_BUTTON_CLOSE"],
    okLabel: labels["TBL_COMMON_BUTTON_OK"],
  };

  return (
    <FormControl fullWidth>
      <Field component={KeyboardDatePicker} id={id} name={name} label={label} inputVariant="outlined" {...properties} disabled={isSubmitting || disabled} />
    </FormControl>
  );
};

TblDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  properties: PropTypes.object,
  disabled: PropTypes.bool,
};

export default TblDatePicker;

import { VALUE_N, VALUE_S } from "../constants";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblCountry } from "../model/TblCountry";
import { TblGroup } from "../model/TblGroup";
import { TblGroupId } from "../model/TblGroupId";
import { TblGroupLink } from "../model/TblGroupLink";
import { TblGroupProvince } from "../model/TblGroupProvince";
import { TblGroupStyle } from "../model/TblGroupStyle";
import { TblGroupVideo } from "../model/TblGroupVideo";
import { TblLanguage } from "../model/TblLanguage";
import { TblProvince } from "../model/TblProvince";
import { TblStyle } from "../model/TblStyle";
import _ from "lodash";
import { latinise } from "../utils/dataUtils";

const varToString = (varObj) => Object.keys(varObj)[0];

export const apiGetBands = (url, obj) => async () => {
  const { name } = obj;
  url = `${url}?name=${name}`;
  return searchData(url, obj);
};

export const apiGetBand = (url, obj) => async () => {
  url = `${url}/${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiGetRankingBands = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchBands = (url, obj) => async () => {
  const { name, codCountry, provinces, codLang, reviewed } = obj;
  url = `${url}?name=${name || ""}&codCountry=${codCountry?.value || ""}&provinces=${provinces.reduce((acc, province) => [...acc, province.value], [])}&codLang=${
    codLang?.value || ""
  }&reviewed=${reviewed?.value || ""}`;
  return searchData(url, obj);
};

export const apiFilterBands = (url, obj) => async () => {
  const { name, provinces, styles } = obj;
  url = `${url}?name=${name || ""}&provinces=${provinces.reduce((acc, province) => [...acc, province.value], [])}&styles=${styles.reduce(
    (acc, style) => [...acc, style.value],
    []
  )}`;
  return searchData(url, obj);
};

export const apiAddBand = (url, obj) => async () => {
  const {
    seqGroupId,
    codGroup,
    name,
    codCountry,
    provinces,
    styles,
    codLang,
    reviewed,
    description,
    web,
    facebook,
    instagram,
    youtube,
    bandcamp,
    soundcloud,
    videos,
    croppedImage,
  } = obj;

  const country = new TblCountry(codCountry.seq, codCountry.value);
  const group = new TblGroup(undefined, codGroup, name, country, undefined, reviewed ? VALUE_S : VALUE_N);
  const language = new TblLanguage(codLang.seq, codLang.value, codLang.label);

  const tblLinks = [{ web }, { facebook }, { instagram }, { youtube }, { bandcamp }, { soundcloud }].reduce((acc, link) => {
    return [...acc, new TblGroupLink(undefined, group, varToString(link), link[varToString(link)])];
  }, []);

  const tblProvinces = provinces.reduce((acc, province) => {
    return [...acc, new TblGroupProvince(undefined, group, country, new TblProvince(province.seq, country, province.value, province.label))];
  }, []);

  const tblStyles = styles.reduce((acc, style) => {
    return [...acc, new TblGroupStyle(undefined, group, new TblStyle(style.seq, style.value, style.label))];
  }, []);

  const request = new TblGroupId(seqGroupId, group, language, description, tblProvinces, tblStyles, tblLinks, videos);
  request.image = croppedImage;
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateBand = (url, obj) => async () => {
  const {
    seqGroupId,
    codGroup,
    tblGroup,
    name,
    codCountry,
    provinces,
    styles,
    codLang,
    reviewed,
    description,
    web,
    facebook,
    instagram,
    youtube,
    bandcamp,
    soundcloud,
    videos,
    dateCre,
    dateMod,
    userCre,
    userMod,
    croppedImage,
    childrens,
  } = obj;

  url = `${url}/${seqGroupId}`;
  const country = new TblCountry(codCountry.seq, codCountry.value, dateCre, dateMod, userCre, userMod);
  const group = new TblGroup(tblGroup.seqGroup, codGroup, name, country, tblGroup.photoGroup, reviewed ? VALUE_S : VALUE_N, dateCre, dateMod, userCre, userMod);
  const language = new TblLanguage(codLang.seq, codLang.value, codLang.label, dateCre, dateMod, userCre, userMod);

  const tblLinks = [{ web }, { facebook }, { instagram }, { youtube }, { bandcamp }, { soundcloud }].reduce((acc, link) => {
    const groupLink = childrens.links[varToString(link)];
    return [...acc, new TblGroupLink(groupLink?.seq, group, varToString(link), link[varToString(link)], dateCre, dateMod, userCre, userMod)];
  }, []);

  let rmProvinces = [];
  const tblProvinces = provinces.reduce((acc, province) => {
    const groupProvince = childrens.provinces.find((x) => x.value.seq === province.seq);
    if (groupProvince) {
      rmProvinces.push(groupProvince);
    }
    return [
      ...acc,
      new TblGroupProvince(
        groupProvince?.seq,
        group,
        country,
        new TblProvince(province.seq, country, province.value, province.label, dateCre, dateMod, userCre, userMod),
        dateCre,
        userCre
      ),
    ];
  }, []);
  rmProvinces = childrens.provinces.filter((el) => !rmProvinces.includes(el));
  rmProvinces.forEach((el) => {
    tblProvinces.push(new TblGroupProvince(el.seq));
  });

  let rmStyles = [];
  const tblStyles = styles.reduce((acc, style) => {
    const groupStyle = childrens.styles.find((x) => x.value.seq === style.seq);
    if (groupStyle) {
      rmStyles.push(groupStyle);
    }
    return [...acc, new TblGroupStyle(groupStyle?.seq, group, new TblStyle(style.seq, style.value, style.label, dateCre, dateMod, userCre, userMod), dateCre, userCre)];
  }, []);
  rmStyles = childrens.styles.filter((el) => !rmStyles.includes(el));
  rmStyles.forEach((el) => {
    tblStyles.push(new TblGroupStyle(el.seq));
  });

  const tblVideos = videos.map((video) => new TblGroupVideo(video.seqVideo, group, video.videoId, video.urlVideo, video.reviewed, dateCre, dateMod, userCre, userMod));
  const rmVideos = childrens.videos.filter((el) => !_.some(videos, el.value));
  rmVideos.forEach((el) => {
    tblVideos.push(new TblGroupVideo(el.seq));
  });

  const request = new TblGroupId(seqGroupId, group, language, description, tblProvinces, tblStyles, tblLinks, tblVideos, dateCre, dateMod, userCre, userMod);
  request.image = croppedImage;
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteBand = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

export const apiAddPublicBand = (url, obj) => async () => {
  const { name, provinces, styles, description, web, facebook, instagram, youtube, bandcamp, soundcloud, videos, croppedImage, country, language } = obj;

  const tblCountry = new TblCountry(country.seq, country.value);
  const group = new TblGroup(undefined, latinise(name.toUpperCase()), name, tblCountry, undefined, VALUE_N);
  const tblLanguage = new TblLanguage(language.seq, language.value, language.label);

  const tblLinks = [{ web }, { facebook }, { instagram }, { youtube }, { bandcamp }, { soundcloud }].reduce((acc, link) => {
    return [...acc, new TblGroupLink(undefined, group, varToString(link), link[varToString(link)])];
  }, []);

  const tblProvinces = provinces.reduce((acc, province) => {
    return [...acc, new TblGroupProvince(undefined, group, tblCountry, new TblProvince(province.seq, tblCountry, province.value, province.label))];
  }, []);

  const tblStyles = styles.reduce((acc, style) => {
    return [...acc, new TblGroupStyle(undefined, group, new TblStyle(style.seq, style.value, style.label))];
  }, []);

  const request = new TblGroupId(undefined, group, tblLanguage, description, tblProvinces, tblStyles, tblLinks, videos);
  request.image = croppedImage;
  const { data } = await doPost(url, request);
  return data;
};

export const apiVoteBand = (url, obj) => async () => {
  const { codGroup, vote, tourBandPoint } = obj;
  url = `${url}/${codGroup}`;
  const { data } = await doPost(url, { vote, tourBandPoint });
  return data;
};

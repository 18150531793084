import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import TblCircularProgress from "../../TblCircularProgress";
import TblDialogTitle from "../TblDialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const customStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TblContestWinnersPopup = ({ open, onClosePopup, loading }) => {
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const winners = useSelector((state) => state.tblContestant.winners) || [];

  return (
    <Dialog onClose={onClosePopup} aria-labelledby="tbl-contest-groups-results-popup" open={open} fullWidth maxWidth={"xs"}>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={onClosePopup} style={{ color: "#fff", backgroundColor: "#17a2b8" }}>
        {labels["TBL_CONTEST_DIALOG_LABEL_WINNER"]}
      </TblDialogTitle>
      <DialogContent dividers>
        {!loading ? (
          <List dense className={cClasses.root}>
            {winners.map((value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem key={value} button>
                  <ListItemAvatar>
                    <Avatar alt={value.tblUser.user} src={value.tblUser.photoProfile} />
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={value.tblUser.user} />
                  <ListItemSecondaryAction>{value.codContestant}</ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <TblCircularProgress />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosePopup} variant="outlined">
          {labels["TBL_COMMON_BUTTON_CLOSE"]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TblContestWinnersPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TblContestWinnersPopup;

import { ADD_ELEMENTS_BAND_USER_VOTEPAGE, API_BAND_USER_VOTE_SEARCH, CLEAR_BAND_USER_VOTE_TABLE, NO_ACTION, SET_SCREEN_BAND_USER_VOTEPAGE } from "../constants";

import { handleActions } from "redux-actions";

export const initial_tbandUserVotes = {
  bandUserVotes: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBandUserVote = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_USER_VOTE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandUserVotes: {
          bandUserVotes: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [ADD_ELEMENTS_BAND_USER_VOTEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandUserVotes: {
          bandUserVotes: [...state.tbandUserVotes.bandUserVotes, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbandUserVotes?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BAND_USER_VOTEPAGE]: (state, action) => ({ ...state, tbandUserVotes: { ...state.tbandUserVotes, screenPage: action.payload } }),
    [CLEAR_BAND_USER_VOTE_TABLE]: (state) => ({ ...state, tbandUserVotes: initial_tbandUserVotes }),
  },
  { tbandUserVotes: initial_tbandUserVotes }
);

import { isMobileOnly, isTablet } from "react-device-detect";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { ROLE_PUBLIC } from "../../constants";
import React from "react";
import TblBandFilters from "./TblBandFilters/TblBandFilters";
import TblGridBands from "../TblGridBands/TblGridBands";
import Typography from "@material-ui/core/Typography";
import { useBands } from "../../hooks/TblBand/useBands";
import { useSelector } from "react-redux";
import { useStyles } from "../../theme/useStyles";

const TblBand = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const tbands = useSelector((state) => state.tblBand.tbands);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const { bandData, actions } = useBands();
  const { topRef, filters, showFilters, paginBands, isLoading, page } = bandData;
  const { setShowFilters, handleChangePage } = actions;

  const cols = isMobileOnly ? 1 : isTablet ? 2 : paginBands.length < 4 ? paginBands.length : 4;

  return (
    <React.Fragment>
      <div className={classes.pTopPage} ref={topRef}></div>
      <Grid container direction="column" alignContent="center" alignItems="center" className={classes.pt1}>
        <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
          <Grid item className={classes.pr1}>
            <Typography variant="h3" align="center">
              {labels["TBL_BANDPAGE_LABEL_TITLE"]}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant={filters > 0 ? "contained" : "outlined"} color={filters > 0 ? "secondary" : "default"} onClick={() => setShowFilters(!showFilters)}>
              <Badge badgeContent={filters} color="secondary">
                <FilterListIcon />
              </Badge>
            </Button>
          </Grid>
        </Grid>
        {userSession.applicationRole.includes(ROLE_PUBLIC) && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Link to={"/groups/add"}>
              <Button endIcon={<AddCircleIcon />}>{labels["TBL_BAND_BUTTON_LABEL_ADDBAND"]}</Button>
            </Link>
          </Box>
        )}

        <hr className={classes.mb1} />
        {showFilters && <TblBandFilters data={bandData} actions={actions} />}
        <div className={classes.mt1}></div>
        <TblGridBands gridList={paginBands} cols={cols} loading={isLoading} />
        <div className={classes.py1}></div>
        <Pagination
          count={Math.ceil(tbands.totalElements / (isMobileOnly ? 5 : isTablet ? 10 : 20))}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <div className={classes.py1}></div>
    </React.Fragment>
  );
};

export default TblBand;

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblSearchForm from "../../TblSearchForm";
import TblSelect from "../../../TblForms/TblSelect";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetCountries } from "../../../../actions/countryActions";
import { actionGetDomain } from "../../../../actions/domainActions";
import { actionGetLangs } from "../../../../actions/langActions";
import { actionGetProvinces } from "../../../../actions/provinceActions";
import { actionSearchBands } from "../../../../actions/bandActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const initialValues = {
  name: "",
  codCountry: "",
  provinces: [],
  codLang: "",
  reviewed: "",
};

const BandSearchForm = ({ data, actions }) => {
  const userSession = useSelector((state) => state.tblUser.userSession);
  const labels = useSelector((state) => state.tblLabel.labels);
  const countries = useSelector((state) => state.tblCountry.countries) || [];
  const provinces = useSelector((state) => state.tblProvince.provinces) || [];
  const langs = useSelector((state) => state.tblLang.langs) || [];
  const yesNoOptions = useSelector((state) => state.tblDomain.IND_YES_NO) || [];
  const dispatch = useDispatch();

  const { headCellsBands, disabledProv } = data;
  const { setDisabledProv, setSelecBand } = actions;

  if (langs.length === 0) {
    callService(dispatch, actionGetLangs);
  }

  if (countries.length === 0) {
    callService(dispatch, actionGetCountries);
  }

  if (yesNoOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "IND_YES_NO", userLang: userSession.userLang });
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.page = 0;
      values.orderBy = headCellsBands[2].path;
      callService(dispatch, actionSearchBands, values).finally(() => {
        setSelecBand({});
        setSubmitting(false);
      });
    },
    [dispatch, headCellsBands, setSelecBand]
  );

  const handleSelectCountry = useCallback(
    (e) => {
      const country = e.target?.value?.value;
      callService(dispatch, actionGetProvinces, country).then((response) => {
        if (response.length > 0) {
          setDisabledProv(false);
        } else {
          setDisabledProv(true);
        }
      });
    },
    [dispatch, setDisabledProv]
  );

  return (
    <TblSearchForm initialValues={initialValues} onSubmit={handleSubmit}>
      <Grid container item xs={8}>
        <TblTextField id={headCellsBands[2].id} name={headCellsBands[2].column} label={labels[headCellsBands[2].label]} properties={headCellsBands[2].properties} />
      </Grid>
      <Grid container item xs={8}>
        <TblSelect
          id={headCellsBands[3].id}
          name={headCellsBands[3].column}
          label={labels[headCellsBands[3].label]}
          options={countries}
          defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_COUNTRY"] }}
          onSelect={handleSelectCountry}
        />
      </Grid>
      <Grid container item xs={8}>
        <TblAutocomplete
          id={headCellsBands[4].id}
          name={headCellsBands[4].column}
          label={labels[headCellsBands[4].label]}
          disabled={disabledProv}
          properties={headCellsBands[4].properties}
          options={provinces}
        />
      </Grid>
      <Grid container item xs={8}>
        <TblSelect
          id={headCellsBands[5].id}
          name={headCellsBands[5].column}
          label={labels[headCellsBands[5].label]}
          options={langs}
          defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
        />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCellsBands[6].id}
          name={headCellsBands[6].column}
          label={labels[headCellsBands[6].label]}
          options={yesNoOptions}
          defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
        />
      </Grid>
    </TblSearchForm>
  );
};

BandSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BandSearchForm;

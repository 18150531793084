import { ADD_ELEMENTS_STYLEPAGE, API_STYLE_ADD, API_STYLE_DELETE, API_STYLE_EDIT, API_STYLE_GET, API_STYLE_SEARCH, CLEAR_STYLE_TABLE, SET_SCREEN_STYLEPAGE } from "../constants";
import { apiAddStyle, apiDeleteStyle, apiGetStyles, apiSearchStyles, apiUpdateStyle } from "../api/styleAPI";
import { urlAddStyle, urlDeleteStyle, urlGetStyles, urlSearchStyles, urlUpdateStyle } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_STYLEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_STYLEPAGE, (style) => apiSearchStyles(urlSearchStyles, style)());
export const actionGetStyles = createAction(API_STYLE_GET, apiGetStyles(urlGetStyles));
export const actionSearchStyles = createAction(API_STYLE_SEARCH, (style) => apiSearchStyles(urlSearchStyles, style)());
export const actionAddStyle = createAction(API_STYLE_ADD, (style) => apiAddStyle(urlAddStyle, style)());
export const actionUpdateStyle = createAction(API_STYLE_EDIT, (style) => apiUpdateStyle(urlUpdateStyle, style)());
export const actionDeleteStyle = createAction(API_STYLE_DELETE, ({ seqStyleuage }) => apiDeleteStyle(urlDeleteStyle, seqStyleuage)());
export const actionClearStyleTable = createAction(CLEAR_STYLE_TABLE);

export class TblContestGroup {
  constructor(seqContestGroup, tblContest, tblGroup, codContestGroup, avgGroup, countVotes, dateCre, dateMod, userCre, userMod) {
    this.seqContestGroup = seqContestGroup;
    this.tblContest = tblContest;
    this.tblGroup = tblGroup;
    this.codContestGroup = codContestGroup;
    this.avgGroup = avgGroup;
    this.countVotes = countVotes;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

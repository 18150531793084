export class TblGroupUserVote {
  constructor(seqVote, tblGroup, tblUser, vote, tourBandPoint, dateCre, dateMod, userCre, userMod) {
    this.seqVote = seqVote;
    this.tblGroup = tblGroup;
    this.tblUser = tblUser;
    this.vote = vote;
    this.tourBandPoint = tourBandPoint;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { faBandcamp, faFacebook, faInstagram, faSoundcloud, faYoutube } from "@fortawesome/free-brands-svg-icons";

import Box from "@material-ui/core/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import TblTextField from "../../../TblForms/TblTextField";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const BandLinks = ({ data }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const { headCells, isLoading } = data;

  return (
    <Grid item xs={12} container direction="column">
      <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={1} style={{ paddingTop: 18 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FontAwesomeIcon icon={faGlobeEurope} size="lg" />
          </Box>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item xs={10}>
          {!isLoading ? (
            <TblTextField id={headCells[10].id} name={headCells[10].column} label={labels[headCells[10].label]} properties={headCells[10].properties} />
          ) : (
            <Skeleton variant="rect" className={classes.skeletonInput} />
          )}
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={1} style={{ paddingTop: 18 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </Box>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item xs={10}>
          {!isLoading ? (
            <TblTextField id={headCells[11].id} name={headCells[11].column} label={labels[headCells[11].label]} properties={headCells[11].properties} />
          ) : (
            <Skeleton variant="rect" className={classes.skeletonInput} />
          )}
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={1} style={{ paddingTop: 18 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </Box>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item xs={10}>
          {!isLoading ? (
            <TblTextField id={headCells[12].id} name={headCells[12].column} label={labels[headCells[12].label]} properties={headCells[12].properties} />
          ) : (
            <Skeleton variant="rect" className={classes.skeletonInput} />
          )}
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={1} style={{ paddingTop: 18 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </Box>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item xs={10}>
          {!isLoading ? (
            <TblTextField id={headCells[13].id} name={headCells[13].column} label={labels[headCells[13].label]} properties={headCells[13].properties} />
          ) : (
            <Skeleton variant="rect" className={classes.skeletonInput} />
          )}
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={1} style={{ paddingTop: 18 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FontAwesomeIcon icon={faBandcamp} size="lg" />
          </Box>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item xs={10}>
          {!isLoading ? (
            <TblTextField id={headCells[14].id} name={headCells[14].column} label={labels[headCells[14].label]} properties={headCells[14].properties} />
          ) : (
            <Skeleton variant="rect" className={classes.skeletonInput} />
          )}
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={1} style={{ paddingTop: 18 }}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <FontAwesomeIcon icon={faSoundcloud} size="lg" />
          </Box>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item xs={10}>
          {!isLoading ? (
            <TblTextField id={headCells[15].id} name={headCells[15].column} label={labels[headCells[15].label]} properties={headCells[15].properties} />
          ) : (
            <Skeleton variant="rect" className={classes.skeletonInput} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

BandLinks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BandLinks;

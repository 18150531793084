import React, { useCallback, useMemo } from "react";
import {
  actionAddElementsPage,
  actionAddErrorMessage,
  actionDeleteErrorMessage,
  actionSearchErrorMessages,
  actionSetCurrentPage,
  actionUpdateErrorMessage,
} from "../../../../actions/errorMessageActions";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import { TblErrorMessage } from "../../../../model/TblErrorMessage";
import { TblErrorMessageId } from "../../../../model/TblErrorMessageId";
import { TblLanguage } from "../../../../model/TblLanguage";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const ErrorMessageTable = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const terrorMessages = useSelector((state) => state.tblErrorMessage.terrorMessages);

  const { search, serverPage, screenPage, totalElements } = terrorMessages;
  const { headCells, openAudit, selected } = data;
  const { setOpenAudit } = actions;

  const rows = useMemo(
    () =>
      terrorMessages.errorMessages.reduce((acc, row) => {
        return [
          ...acc,
          new TblErrorMessageId(
            row.seqErrorMessageId,
            new TblErrorMessage(row.tblErrorMessage.seqErrorMessage, row.tblErrorMessage.codMessage),
            row.text,
            new TblLanguage(row.tblLanguage.seqLanguage, row.tblLanguage.codLang, row.tblLanguage.desLang),
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [terrorMessages.errorMessages]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_ERROR_MESSAGE"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { codMessage, text, codLang } = values;
      if (!codMessage) {
        errors.codMessage = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!text) {
        errors.text = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!codLang?.value) {
        errors.codLang = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_ERRORMSG"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
    }),
    [data, labels, rows, tableLabel, search, serverPage, screenPage, totalElements]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchErrorMessages,
      actionAdd: actionAddErrorMessage,
      actionUpdate: actionUpdateErrorMessage,
      actionDelete: actionDeleteErrorMessage,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
    }),
    [actions, handleValidation]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
          <Grid container item xs={6}>
            <TblSelect
              id={headCells[3].id}
              name={headCells[3].column}
              label={labels[headCells[3].label]}
              options={langs}
              defaultOption={{ seq: 0, value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
            />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

ErrorMessageTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ErrorMessageTable;

import { OPERATION_ADD, OPERATION_EDIT } from "../../../../constants";
import React, { useCallback, useState } from "react";
import { convert2DateFormat, getByPath } from "../../../../utils/dataUtils";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TblCircularProgress from "../../../TblCircularProgress";
import Typography from "@material-ui/core/Typography";
import YouTube from "react-youtube";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const customStyles = makeStyles((theme) => ({
  photoMaxHeight: {
    maxHeight: 150,
  },
  head: {
    backgroundColor: "#5F5A59",
    color: theme.palette.common.white,
    lineHeight: 3,
  },
}));

const TblTableRow = ({ data, actions, row, index, page, rowsPerPage }) => {
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const tblDomain = useSelector((state) => state.tblDomain);

  const [openDetailRow, setOpenDetailRow] = useState(false);

  const { headCells, selected, tableLabel, isNoUpdatable, isReadOnly, isLoading, detailRow } = data;
  const { setSelected, setOperation, setIsTouched, setTitle, beforeOpen, setOpen } = actions;

  const sequence = headCells[0].column;

  const isSelected = useCallback(
    (row) => {
      return selected[sequence] === row[sequence];
    },
    [selected, sequence]
  );

  const isItemSelected = isSelected(row);
  const labelId = `tbl-table-checkbox-${index}`;

  const handleClick = (event, row) => {
    if ((event.target.cellIndex && event.target.cellIndex !== 0) || event.target.type === "checkbox") {
      if (!isSelected(row)) {
        setSelected(row);
      } else {
        setSelected({});
      }
    }
  };

  /**
   * Method to open creation/edition popup
   * @param {*} operation
   */
  const handleOpen = (operation) => {
    if (beforeOpen) {
      beforeOpen(operation);
    }
    setOperation(operation);
    if (operation === OPERATION_ADD) {
      setIsTouched(true);
      setSelected({});

      setTitle(`${labels["TBL_COMMON_TITLE_CREATIONPOPUP"]} ${tableLabel}`);
    } else {
      setIsTouched(false);
      setTitle(`${labels["TBL_COMMON_TITLE_EDITIONPOPUP"]} ${tableLabel}`);
    }
    setOpen(true);
  };

  const handleOpenDetailRow = useCallback(() => {
    const headCellDetailRow = headCells.find((headCell) => headCell.detailRow);
    if (headCellDetailRow && getByPath(row, headCellDetailRow.path)) {
      setOpenDetailRow(!openDetailRow);
    }
  }, [headCells, openDetailRow, row]);

  return (
    <React.Fragment>
      <TableRow hover onClick={(event) => handleClick(event, row)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} selected={isItemSelected}>
        {detailRow && (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={handleOpenDetailRow}>
              {openDetailRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>
          {!isLoading || !isItemSelected ? (
            <Button
              id="input-edit"
              endIcon={<EditIcon id="input-edit-icon" />}
              onClick={() => handleOpen(OPERATION_EDIT)}
              disabled={!isSelected(row) || !!isNoUpdatable || !!isReadOnly}
            >
              <Typography id="input-edit-text">{index + 1 + page * rowsPerPage}</Typography>
            </Button>
          ) : (
            <TblCircularProgress />
          )}
        </TableCell>

        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
        </TableCell>

        {headCells.map((column) => {
          if (!column.noShow && !column.detailRow) {
            if (column.image) {
              return (
                <TableCell key={`${column.column}-${row[sequence]}`} align="left">
                  <Avatar className={cClasses.photoMaxHeight} variant="square" alt="tbl-img" src={getByPath(row, column.path)} />
                </TableCell>
              );
            } else if (column.video) {
              return (
                <TableCell key={`${column.column}-${row[sequence]}`} align="left">
                  <YouTube videoId={getByPath(row, column.path)} opts={{ height: 150 }} />
                </TableCell>
              );
            } else if (column.domain) {
              const domains = tblDomain[column.domain];
              const option = domains.find((domain) => domain.value === getByPath(row, column.path));
              return (
                <TableCell key={`${column.column}-${row[sequence]}`} align="left">
                  {option && option.label}
                </TableCell>
              );
            } else if (column.date) {
              const value = getByPath(row, column.path);
              return (
                <TableCell key={`${column.column}-${row[sequence]}`} align="left">
                  {convert2DateFormat(value, column.properties.format)}
                </TableCell>
              );
            } else {
              return (
                <TableCell key={`${column.column}-${row[sequence]}`} align="left">
                  {getByPath(row, column.path)}
                </TableCell>
              );
            }
          } else {
            return null;
          }
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDetailRow} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => {
                    if (headCell.detailRow) {
                      return (
                        <TableCell
                          className={cClasses.head}
                          key={headCell.column}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "default"}
                        >
                          {labels[headCell.label]}
                        </TableCell>
                      );
                    }
                    return null;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {headCells.map((column) => {
                  if (column.detailRow) {
                    return (
                      <TableCell key={`${column.column}-${row[sequence]}`} align="left">
                        {getByPath(row, column.path)}
                      </TableCell>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

TblTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TblTableRow;

import { VALUE_N, VALUE_S } from "../constants";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblGroup } from "../model/TblGroup";
import { TblGroupReview } from "../model/TblGroupReview";
import { TblGroupReviewRevision } from "../model/TblGroupReviewRevision";
import { TblUser } from "../model/TblUser";

export const apiGetBandReviews = (url, obj) => async () => {
  url = `${url}?codGroup=${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchBandReviews = (url, obj) => async () => {
  const { codGroup } = obj;
  url = `${url}?codGroup=${codGroup}`;
  return searchData(url, obj);
};

export const apiAddBandReview = (url, obj) => async () => {
  const { seqReview, tblGroup, user, reviewTitle, review, reviewed, revisionReviewTitle, revisionReview } = obj;
  let tblGroupReviewRevision = undefined;
  if (revisionReview || revisionReviewTitle) {
    tblGroupReviewRevision = new TblGroupReviewRevision(undefined, revisionReviewTitle, revisionReview);
  }
  const request = new TblGroupReview(
    seqReview,
    `${tblGroup.codGroup}_${user.value}`,
    new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name),
    new TblUser(user.seq, user.value),
    reviewTitle,
    review,
    reviewed ? VALUE_S : VALUE_N,
    undefined,
    tblGroupReviewRevision
  );
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateBandReview = (url, obj) => async () => {
  const { seqReview, tblGroup, user, reviewTitle, review, reviewed, tblGroupReviewRevision, revisionReviewTitle, revisionReview, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqReview}`;
  let newRevision = undefined;
  if (revisionReview || revisionReviewTitle) {
    newRevision = new TblGroupReviewRevision(
      tblGroupReviewRevision.seqReviewRevision,
      revisionReviewTitle,
      revisionReview,
      tblGroupReviewRevision.dateCre,
      tblGroupReviewRevision.dateMod,
      tblGroupReviewRevision.userCre,
      tblGroupReviewRevision.userMod
    );
  }
  const request = new TblGroupReview(
    seqReview,
    `${tblGroup.codGroup}_${user.value}`,
    new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name),
    new TblUser(user.seq, user.value),
    reviewTitle,
    review,
    reviewed ? VALUE_S : VALUE_N,
    undefined,
    newRevision,
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteBandReview = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

export const apiCreateBandReview = (url, obj) => async () => {
  const { tblGroup, tblUser, title, review } = obj;
  const request = new TblGroupReview(undefined, undefined, tblGroup, tblUser, title, review);
  const { data } = await doPost(url, request);
  return data;
};

export const apiAddBandReviewAssess = (url, obj) => async () => {
  url = `${url}/${obj}`;
  const { data } = await doPost(url);
  return data;
};

export const apiSearchUserReviews = (url, obj) => async () => {
  const { user } = obj;
  url = `${url}?user=${user}`;
  return searchData(url, obj);
};

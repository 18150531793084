import { CONTEST_BANDS, ROLE_NOACTIVE, ROLE_PUBLIC, VALUE_S } from "../../../constants";
import React, { useCallback, useState } from "react";
import { callService, doGet } from "../../../utils/serviceUtils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Properties } from "../../../constants/properties";
import TblCardNoContest from "../TblCardNoContest";
import { TblContestGroup } from "../../../model/TblContestGroup";
import { TblContestGroupVote } from "../../../model/TblContestGroupVote";
import TblContestVoteGroupPopup from "../../TblPopups/TblContestVoteGroupPopup/TblContestVoteGroupPopup";
import { TblContestant } from "../../../model/TblContestant";
import TblGridContests from "../TblGridContests";
import TblGridSkeleton from "../TblGridContests/TblGridSkeleton";
import { TblGroup } from "../../../model/TblGroup";
import TblInsertPinPopup from "../../TblPopups/TblInsertPinPopup/TblInsertPinPopup";
import TblParticipateConfirm from "../../TblPopups/TblParticipateConfirmPopup/TblParticipateConfirmPopup";
import Typography from "@material-ui/core/Typography";
import { actionClearContestBandTable } from "../../../actions/contestBandActions";
import { actionGetMyContests } from "../../../actions/contestActions";
import { actionParticipate } from "../../../actions/contestantActions";
import { showNotifier } from "../../../utils/dataUtils";
import { urlValidContestCode } from "../../../api/urls";

const TblActives = ({ topRef, loading, setLoading }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const templates = useSelector((state) => state.tblTemplate.templates);
  const activeContests = useSelector((state) => state.tblContest.activeContests);
  const myContests = useSelector((state) => state.tblContest.myContests);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const [loadingP, setLoadingP] = useState(false);
  const [openContestVoteGroupPopup, setOpenContestVoteGroupPopup] = useState(false);
  const [openInsertPinPopup, setOpenInsertPinPopup] = useState(false);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [codContestant, setCodContestant] = useState("");
  const [tblContest, setTblContest] = useState(null);
  const [error, setError] = useState(false);

  const postService = useCallback(
    (response) => {
      setCodContestant(response[0].tblContestant.codContestant);
      setOpenConfirmPopup(true);
      callService(dispatch, actionGetMyContests, VALUE_S);
    },
    [dispatch]
  );

  const callParticipateService = useCallback(
    (request) => {
      setLoadingP(true);
      callService(dispatch, actionParticipate, request).then((response) => {
        postService(response);
        setLoadingP(false);
      });
    },
    [dispatch, postService]
  );

  const participateStep3 = useCallback(
    (contest, groupVotes) => {
      setError(false);
      const contestGroupsVote = Object.keys(groupVotes).map(
        (key) =>
          new TblContestGroupVote(
            undefined,
            new TblContestGroup(undefined, undefined, new TblGroup(undefined, key)),
            new TblContestant(undefined, contest),
            undefined,
            groupVotes[key]
          )
      );
      if (contestGroupsVote.length > 0) {
        setOpenContestVoteGroupPopup(false);
        callParticipateService(contestGroupsVote);
      } else {
        setError(true);
      }
    },
    [callParticipateService]
  );

  const participateStep2 = useCallback(
    (contest) => {
      setError(false);
      if (contest.contestType === CONTEST_BANDS) {
        const url = `${urlValidContestCode}?codContest=${contest.codContest}&contestCode=${contest.contestCode}`;
        doGet(url).then(
          () => {
            setOpenInsertPinPopup(false);
            setOpenContestVoteGroupPopup(true);
          },
          (error) => {
            setError(true);
            const errorMsg = error?.response?.data?.apierror?.message || error?.response?.data?.message;
            if (errorMsg?.includes("TBL_")) {
              showNotifier(dispatch, Properties.getERRORS()[errorMsg], "error");
            } else {
              showNotifier(dispatch, Properties.getCOMMON_ERROR(), "error");
            }
          }
        );
      } else {
        setOpenInsertPinPopup(false);
        callParticipateService([new TblContestGroupVote(undefined, undefined, new TblContestant(undefined, contest))]);
      }
    },
    [callParticipateService, dispatch]
  );

  const participateStep1 = useCallback(
    (contest) => {
      setTblContest(contest);
      if (userSession.applicationRole.includes(ROLE_PUBLIC)) {
        if (contest.contestCodeRequired === VALUE_S) {
          setOpenInsertPinPopup(true);
        } else if (contest.contestType === CONTEST_BANDS) {
          setOpenContestVoteGroupPopup(true);
        } else {
          callParticipateService([new TblContestGroupVote(undefined, undefined, new TblContestant(undefined, contest))]);
        }
      } else if (!userSession.applicationRole.includes(ROLE_NOACTIVE)) {
        history.push(`/login?pathname=${location.pathname}`);
      }
    },
    [callParticipateService, history, location.pathname, userSession.applicationRole]
  );

  const handleCloseContestVoteGroupPopup = useCallback(() => {
    setOpenContestVoteGroupPopup(false);
    setTblContest(null);
    dispatch(actionClearContestBandTable());
  }, [dispatch]);

  const handleCloseInsertPinPopup = useCallback(() => {
    setOpenInsertPinPopup(false);
    dispatch(actionClearContestBandTable());
    setTblContest(null);
  }, [dispatch]);

  const handleCloseConfirmPopup = useCallback(() => {
    setOpenConfirmPopup(false);
    dispatch(actionClearContestBandTable());
    setTblContest(null);
  }, [dispatch]);

  return (
    <React.Fragment>
      <Grid container direction="column" alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {labels["TBL_CONTEST_TABPANEL_TITLE_ACTIVE"]}
          </Typography>
          <hr />
        </Grid>
        {!loading ? (
          activeContests.length > 0 ? (
            <TblGridContests topRef={topRef} gridList={activeContests || []} myContests={myContests} onAction={participateStep1} loading={loading || loadingP} />
          ) : (
            <TblCardNoContest image={"/images/application/tblimagecontestnoactives.jpg"} text={templates["TBL_CONTEST_NO_ACTIVE_CARD"]} topRef={topRef} />
          )
        ) : (
          <TblGridSkeleton />
        )}
      </Grid>
      {!loading && (
        <React.Fragment>
          {tblContest && (
            <React.Fragment>
              <TblContestVoteGroupPopup
                open={openContestVoteGroupPopup}
                onClosePopup={handleCloseContestVoteGroupPopup}
                contest={tblContest}
                onSubmit={participateStep3}
                error={error}
              />
              <TblInsertPinPopup
                open={openInsertPinPopup}
                onClosePopup={handleCloseInsertPinPopup}
                contest={tblContest}
                setContest={setTblContest}
                onSubmit={participateStep2}
                error={error}
              />
              <TblParticipateConfirm open={openConfirmPopup} onClosePopup={handleCloseConfirmPopup} codContestant={codContestant} />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

PropTypes.TblBandReview = {
  topRef: PropTypes.object.isRequired,
};

export default TblActives;

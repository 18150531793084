import { Form, Formik } from "formik";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";
import TblLinearProgress from "../../TblLinearProgress";
import { useSelector } from "react-redux";

const TblSearchForm = ({ children, initialValues, onValidate, onSubmit }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  return (
    <Formik initialValues={initialValues} validate={onValidate} onSubmit={onSubmit}>
      {({ isSubmitting, resetForm }) => (
        <Form>
          <Grid container direction="column" spacing={3}>
            {children}
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" spacing={3}>
            <Grid item>
              <Button type="submit" variant="contained" color="secondary" startIcon={<SearchIcon />} disabled={isSubmitting}>
                {labels["TBL_COMMON_BUTTON_SEARCH"]}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" startIcon={<RotateLeftIcon />} onClick={resetForm} disabled={isSubmitting}>
                {labels["TBL_COMMON_BUTTON_RESET"]}
              </Button>
            </Grid>
          </Grid>
          {isSubmitting && <TblLinearProgress />}
        </Form>
      )}
    </Formik>
  );
};

TblSearchForm.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.object.isRequired,
  onValidate: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TblSearchForm;

import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const TblHtmlView = ({ data }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  return (
    <div>
      <Typography variant="h6">{labels["TBL_COMMON_TITLE_PREVIEW"]}</Typography>
      <Paper style={{ padding: 15 }}>{ReactHtmlParser(data)}</Paper>
    </div>
  );
};

TblHtmlView.propTypes = {
  data: PropTypes.object,
};

export default TblHtmlView;

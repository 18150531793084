import { useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import StyleSearchForm from "./StyleSearchForm";
import StyleTable from "./StyleTable";
import TblStyle from "../../../constants/TblStyle.json";

const extraData = {
  headCells: TblStyle.headCells,
  initialValues: {
    seqStyle: null,
    codStyle: "",
    desStyle: "",
  },
};

const StyleTab = ({ data, actions }) => {
  const [selected, setSelected] = useState({});
  data = useMemo(() => ({ ...data, ...extraData, selected }), [data, selected]);
  actions = useMemo(() => ({ ...actions, setSelected }), [actions, setSelected]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <StyleSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <StyleTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

StyleTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default StyleTab;

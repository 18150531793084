import React, { useEffect, useRef, useState } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import TblCircularProgress from "../../TblCircularProgress";
import TblGridBands from "../../TblGridBands/TblGridBands";
import Typography from "@material-ui/core/Typography";
import { actionGetRankingBands } from "../../../actions/bandActions";
import { callService } from "../../../utils/serviceUtils";
import { useStyles } from "../../../theme/useStyles";

const cols = isMobileOnly ? 1 : isTablet ? 2 : 5;

const TblRanking = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const rankingBands = useSelector((state) => state.tblBand.rankingBands);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  useEffect(() => {
    callService(dispatch, actionGetRankingBands).finally(() => {
      if (isMounted.current) {
        setLoading(false);
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, [dispatch]);

  return (
    <Grid container direction="column" alignContent="center" alignItems="center" className={classes.py3}>
      <Grid item xs={10}>
        <Typography variant="h3" align="center">
          {labels["TBL_HOME_RANKING_TITLE"]}
        </Typography>
        <hr className={classes.my1} />
      </Grid>
      {!loading ? <TblGridBands gridList={rankingBands} cols={cols} /> : <TblCircularProgress />}
    </Grid>
  );
};

export default TblRanking;

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import PinInput from "react-pin-input";
import PropTypes from "prop-types";
import React from "react";
import TblDialogTitle from "../TblDialogTitle";
import { useSelector } from "react-redux";

const TblInsertPinPopup = ({ open, onClosePopup, contest, setContest, onSubmit, error }) => {
  const labels = useSelector((state) => state.tblLabel.labels);

  const handleComplete = (value) => {
    contest.contestCode = value;
    setContest(contest);
  };

  return (
    <Dialog onClose={onClosePopup} aria-labelledby="tbl-popup-message" open={open} fullWidth>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={onClosePopup} style={{ backgroundColor: "#ffc107" }}>
        {labels["TBL_ENTITY_TBL_CONTEST_CODCONTEST"]}
      </TblDialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" alignContent="center" alignItems="center">
          <PinInput
            length={4}
            initialValue=""
            type="custom"
            style={{ padding: "10px" }}
            inputStyle={{ borderRadius: 5, borderColor: error ? "red" : "black" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={handleComplete}
            autoSelect={true}
            regexCriteria={/^[A-Za-z0-9]*$/}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(contest)} color="secondary" variant="contained">
          {labels["TBL_COMMON_BUTTON_CONTINUE_LABEL"]}
        </Button>
        <Button onClick={onClosePopup} variant="outlined">
          {labels["TBL_COMMON_BUTTON_CANCEL"]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TblInsertPinPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  contest: PropTypes.object.isRequired,
  setContest: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default TblInsertPinPopup;

export class TblText {
  constructor(seqText, codText, codSearch, dateCre, dateMod, userCre, userMod) {
    this.seqText = seqText;
    this.codText = codText;
    this.codSearch = codSearch;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

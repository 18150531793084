export class TblGroup {
  constructor(seqGroup, codGroup, name, tblCountry, photoGroup, reviewed, dateCre, dateMod, userCre, userMod) {
    this.seqGroup = seqGroup;
    this.codGroup = codGroup;
    this.name = name;
    this.tblCountry = tblCountry;
    this.photoGroup = photoGroup;
    this.reviewed = reviewed;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

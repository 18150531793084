import {
  actionDesVinculateFacebook,
  actionDesVinculateGoogle,
  actionGetUser,
  actionUnsubscribeUser,
  actionUpdatePublicUser,
  actionVinculateAccount,
} from "../../actions/userActions";
import { callService, doPost } from "../../utils/serviceUtils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TblUser from "../../constants/TblUser.json";
import { VALUE_ESP } from "../../constants";
import { actionGetProvinces } from "../../actions/provinceActions";
import { showNotifier } from "../../utils/dataUtils";
import { urlVerifyReCaptcha } from "../../api/urls";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory } from "react-router-dom";

export const useProfile = () => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const user = useSelector((state) => state.tblUser.user);
  const provinces = useSelector((state) => state.tblProvince.provinces);

  const topRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [tab, setTab] = useState(0);
  const [votes, setVotes] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [contests, setContests] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUnsub, setIsLoadingUnsub] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const headCells = TblUser.headCells;

  const initialValues = useMemo(
    () => ({
      seqUser: user.seqUser,
      user: user.user,
      password: "",
      confpass: "",
      email: user.email,
      name: user.name,
      lastName: user.lastName,
      birthDate: user.birthDate,
      userLang: user.userLang,
      codCountry: user.tblCountry,
      codProvince: provinces.find((x) => x.value === user.tblProvince?.codProvince),
      isLinkedFacebook: user.facebookId,
      isLinkedGoogle: user.googleId,
      notifications: user.notifications === "S" ? true : false,
      file: null,
    }),
    [provinces, user]
  );

  //reCAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return false;
    }
    const token = await executeRecaptcha();
    const request = {
      token: token,
    };
    const result = await doPost(urlVerifyReCaptcha, request);
    return result?.data?.success;
  }, [executeRecaptcha]);

  const responseFacebook = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        const result = await handleReCaptchaVerify();
        if (result) {
          const request = {
            seqUser: userSession.seqUser,
            id: response?.userID,
            name: response?.name,
            email: response?.email,
            photoProfile: response?.picture?.data?.url,
            socialName: "FACEBOOK",
            token: response?.signedRequest,
          };
          await callService(dispatch, actionVinculateAccount, request);
          showNotifier(dispatch, labels["TBL_MYPROFILE_CONFIRMATION_VINCULATE_MSG_OK"], "success");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, handleReCaptchaVerify, labels, userSession.seqUser]
  );

  const responseGoogle = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        const result = await handleReCaptchaVerify();
        if (result && !response.error) {
          const request = {
            seqUser: userSession.seqUser,
            id: response?.profileObj?.googleId,
            name: response?.profileObj?.givenName,
            email: response?.profileObj?.email,
            photoProfile: response?.profileObj.imageUrl,
            socialName: "GOOGLE",
            token: response?.tokenId,
          };
          await callService(dispatch, actionVinculateAccount, request);
          showNotifier(dispatch, labels["TBL_MYPROFILE_CONFIRMATION_VINCULATE_MSG_OK"], "success");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, handleReCaptchaVerify, labels, userSession.seqUser]
  );

  const handleDesVinculateFacebook = useCallback(async () => {
    setIsLoading(true);
    try {
      await callService(dispatch, actionDesVinculateFacebook, userSession);
      showNotifier(dispatch, labels["TBL_MYPROFILE_CONFIRMATION_DESVINCULATE_MSG_OK"], "success");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, labels, userSession]);

  const handleDesVinculateGoogle = useCallback(async () => {
    setIsLoading(true);
    try {
      await callService(dispatch, actionDesVinculateGoogle, userSession);
      showNotifier(dispatch, labels["TBL_MYPROFILE_CONFIRMATION_DESVINCULATE_MSG_OK"], "success");
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, labels, userSession]);

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { password, confpass, birthDate, codProvince } = values;
      if (!codProvince?.value) {
        errors.codProvince = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!birthDate) {
        errors.birthDate = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (password !== "" && password.length < 6) {
        errors.password = labels["TBL_COMMON_INVALIDINPUT_MINLENGTH"].replace("<number>", "6");
      } else if (password !== confpass) {
        errors.password = labels["TBL_COMMON_PASSWORD_NOMATCH"];
        errors.confpass = labels["TBL_COMMON_PASSWORD_NOMATCH"];
      }
      return errors;
    },
    [labels]
  );
  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setIsLoading(true);
      values.croppedImage = croppedImage;
      try {
        await callService(dispatch, actionUpdatePublicUser, values);
        showNotifier(dispatch, labels["TBL_MYPROFILE_CONFIRMATION_MSG_OK"], "success");
      } finally {
        setSubmitting(false);
        setIsLoading(false);
        setShowChangePass(false);
        setEditMode(false);
      }
    },
    [croppedImage, dispatch, labels]
  );

  const handleChange = (event, newValue) => {
    setTab(newValue);
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleUnsubscribe = useCallback(async () => {
    setIsLoadingUnsub(true);
    try {
      await callService(dispatch, actionUnsubscribeUser, userSession);
    } finally {
      setIsLoadingUnsub(false);
      history.push("/");
    }
  }, [dispatch, history, userSession]);

  const loadData = useCallback(async () => {
    await Promise.all([callService(dispatch, actionGetUser, userSession.user), callService(dispatch, actionGetProvinces, VALUE_ESP)]);
  }, [dispatch, userSession.user]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const data = useMemo(
    () => ({
      topRef,
      tab,
      votes,
      reviews,
      contests,
      isLoading,
      showChangePass,
      imageSrc,
      croppedImage,
      headCells,
      initialValues,
      openConfirm,
      isLoadingUnsub,
      editMode,
    }),
    [contests, croppedImage, editMode, headCells, imageSrc, initialValues, isLoading, isLoadingUnsub, openConfirm, reviews, showChangePass, tab, votes]
  );

  const actions = useMemo(
    () => ({
      setTab,
      setVotes,
      setReviews,
      setContests,
      setIsLoading,
      handleChange,
      setShowChangePass,
      setImageSrc,
      setCroppedImage,
      executeRecaptcha,
      responseFacebook,
      responseGoogle,
      handleValidate,
      handleSubmit,
      handleDesVinculateFacebook,
      handleDesVinculateGoogle,
      handleUnsubscribe,
      setOpenConfirm,
      setIsLoadingUnsub,
      setEditMode,
    }),
    [executeRecaptcha, handleDesVinculateFacebook, handleDesVinculateGoogle, handleSubmit, handleUnsubscribe, handleValidate, responseFacebook, responseGoogle]
  );

  return { data, actions };
};

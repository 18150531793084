export class TblGroupLink {
  constructor(seqLink, tblGroup, namePage, urlPage, dateCre, dateMod, userCre, userMod) {
    this.seqLink = seqLink;
    this.tblGroup = tblGroup;
    this.namePage = namePage;
    this.rrss = { seq: 0, value: namePage, label: namePage.charAt(0).toUpperCase() + namePage.slice(1) };
    this.urlPage = urlPage;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { ROLE_NOACTIVE, ROLE_PUBLIC } from "../../constants";
import { actionGetBand, actionVoteBand } from "../../actions/bandActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import _ from "lodash";
import { callService } from "../../utils/serviceUtils";
import { showNotifier } from "../../utils/dataUtils";

export const useBandFile = () => {
  const band = useSelector((state) => state.tblBand.band);
  const labels = useSelector((state) => state.tblLabel.labels);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [vote, setVote] = useState({});
  const [tbpoint, setTbpoint] = useState(0);
  const [openMsgPopup, setOpenMsgPopup] = useState(false);
  const [showStats, setShowStats] = useState(null);
  const [showVote, setShowVote] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [showMore, setShowMore] = useState(null);

  const [height, setHeight] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleResize = useCallback(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (dimensions.width < 1280) {
      if (band.myVote) {
        setShowVote(false);
        setShowStats(true);
      } else {
        setShowVote(true);
        setShowStats(false);
      }
    } else {
      setShowStats(true);
      setShowVote(true);
    }

    if (height > 150) {
      setShowMore(false);
    }
  }, [height, dimensions.width, band]);

  useEffect(() => {
    if (!_.isEmpty(band)) {
      setVote({ [band.tblGroup.codGroup]: band.myVote || 0 });
      setTbpoint(band.myTourBandPoint || 0);
    }
  }, [band]);

  const processResponse = useCallback(() => {
    callService(dispatch, actionGetBand, band.tblGroup.codGroup).then(() => showNotifier(dispatch, labels["TBL_COMMON_ACTION_SUCCESSFULLY"], "success"));
  }, [band, dispatch, labels]);

  const handleCloseMsgPopup = () => {
    setOpenMsgPopup(false);
  };

  const voteBand = useCallback(
    (value) => {
      if (userSession.applicationRole.includes(ROLE_PUBLIC)) {
        callService(dispatch, actionVoteBand, { codGroup: band.tblGroup.codGroup, vote: value, tourBandPoint: value === 5 ? band.myTourBandPoint || 0 : 0 }).then(() => {
          processResponse();
        });
      } else if (!userSession.applicationRole.includes(ROLE_NOACTIVE)) {
        setVote({ [band.tblGroup.codGroup]: 0 });
        history.push(`/login?pathname=${location.pathname}`);
      }
    },
    [band, dispatch, history, location, processResponse, userSession]
  );

  const voteTbpoint = useCallback(
    (value) => {
      if (userSession.applicationRole.includes(ROLE_PUBLIC)) {
        if (band.myVote === 5) {
          callService(dispatch, actionVoteBand, { codGroup: band.tblGroup.codGroup, vote: band.myVote, tourBandPoint: value }).then(() => {
            processResponse();
          });
        } else {
          setOpenMsgPopup(true);
        }
      } else if (!userSession.applicationRole.includes(ROLE_NOACTIVE)) {
        setTbpoint(0);
        history.push(`/login?pathname=${location.pathname}`);
      }
    },
    [band, dispatch, history, location, processResponse, userSession]
  );

  const stats = useMemo(() => [1, 2, 3, 4, 5].reduce((acc, index) => [...acc, { name: index, votes: band?.["votes" + index] }], []), [band]);
  const links = useMemo(() => band.links?.reduce((acc, link) => ({ ...acc, [link.namePage]: link.urlPage }), {}), [band.links]);
  const provinces = useMemo(() => band.provinces?.reduce((acc, province) => [...acc, province.tblProvince.desProvince], []), [band.provinces]);
  const styles = useMemo(() => band.styles?.reduce((acc, style) => [...acc, style.tblStyle.desStyle], []), [band.styles]);

  const data = useMemo(
    () => ({
      showStats,
      showVote,
      showShare,
      showMore,
      stats,
      links,
      provinces,
      styles,
      measuredRef,
      dimensions,
      vote,
      tbpoint,
      openMsgPopup,
    }),
    [showStats, showVote, showShare, showMore, stats, links, provinces, styles, measuredRef, dimensions, vote, tbpoint, openMsgPopup]
  );

  const actions = useMemo(
    () => ({
      setShowStats,
      setShowVote,
      setShowShare,
      setShowMore,
      setDimensions,
      setVote,
      setTbpoint,
      setOpenMsgPopup,
      handleCloseMsgPopup,
      voteBand,
      voteTbpoint,
    }),
    [voteBand, voteTbpoint]
  );

  return { data, actions };
};

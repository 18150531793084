import React, { useCallback, useMemo, useState } from "react";
import { actionAddElementsPage, actionAddUser, actionDeleteUser, actionSearchUsers, actionSetCurrentPage, actionUpdateUser } from "../../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { OPERATION_ADD } from "../../../../constants";
import PropTypes from "prop-types";
import { Properties } from "../../../../constants/properties";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblAvatar from "../../../TblForms/TblAvatar";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import TblCropper from "../../../TblCropper/TblCropper";
import TblDatePicker from "../../../TblForms/TblDatePicker";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { TblUser } from "../../../../model/TblUser";
import { actionGetProvinces } from "../../../../actions/provinceActions";
import { callService } from "../../../../utils/serviceUtils";
import { getByPath } from "../../../../utils/dataUtils";

const UserTable = ({ headCells, initialValues, selected, setSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tusers = useSelector((state) => state.tblUser.tusers);
  const langs = useSelector((state) => state.tblLang.langs);
  const countries = useSelector((state) => state.tblCountry.countries);
  const provinces = useSelector((state) => state.tblProvince.provinces);
  const appRoleOptions = useSelector((state) => state.tblDomain.APPLICATION_ROL);
  const dispatch = useDispatch();

  const { search, serverPage, screenPage, totalElements } = tusers;
  const { openAudit, disabledProv } = data;
  const { setOpenAudit, setDisabledProv } = actions;

  const rows = useMemo(
    () =>
      tusers.users.reduce((acc, row) => {
        return [
          ...acc,
          new TblUser(
            row.seqUser,
            row.user,
            undefined,
            row.facebookId,
            row.vinculateFacebook,
            row.googleId,
            row.vinculateGoogle,
            row.name,
            row.lastName,
            row.email,
            row.birthDate,
            row.userLang,
            row.tblCountry,
            row.tblProvince,
            row.photoProfile,
            row.applicationRole,
            row.active,
            row.activeKey,
            row.notifications,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tusers.users]
  );

  // Cropper
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const tableLabel = labels["TBL_ENTITY_TBL_USER"];

  const handleSelectCountry = useCallback(
    (e) => {
      const country = e.target;
      callService(dispatch, actionGetProvinces, country?.value?.value).then((response) => {
        if (response.length > 0) {
          setDisabledProv(false);
        } else {
          setDisabledProv(true);
        }
      });
    },
    [dispatch, setDisabledProv]
  );

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { user, password, confpass, email, birthDate, userLang, codCountry, codProvince, applicationRole, file } = values;
      if (!user) {
        errors.user = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (password !== "" && password.length < 6) {
        errors.password = labels["TBL_COMMON_INVALIDINPUT_MINLENGTH"].replace("<number>", "6");
      } else if (password !== confpass) {
        errors.password = labels["TBL_COMMON_PASSWORD_NOMATCH"];
        errors.confpass = labels["TBL_COMMON_PASSWORD_NOMATCH"];
      }
      if (!email) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_EMAIL"];
      }
      if (!birthDate) {
        errors.birthDate = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!userLang) {
        errors.userLang = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!codCountry) {
        errors.codCountry = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!codProvince) {
        errors.codProvince = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!applicationRole) {
        errors.applicationRole = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (file && !Properties.SUPPORTED_FORMATS.includes(file.type)) {
        errors.file = labels["TBL_COMMON_INVALIDINPUT_FILEFORMAT"];
      }
      return errors;
    },
    [labels]
  );

  const handleBeforeSubmit = useCallback(
    (values) => {
      values.croppedImage = croppedImage;
    },
    [croppedImage]
  );

  const handleBeforeOpen = useCallback(
    (operation) => {
      setCroppedImage(null);
      setImageSrc(null);
      if (operation === OPERATION_ADD) {
        setDisabledProv(true);
      } else {
        setDisabledProv(false);
      }
      if (selected?.tblCountry) {
        callService(dispatch, actionGetProvinces, selected.tblCountry.codCountry);
      }
    },
    [selected, dispatch, setDisabledProv]
  );

  data = useMemo(
    () => ({
      ...data,
      headCells,
      initialValues,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_USERS"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected,
      imageSrc,
    }),
    [data, headCells, initialValues, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selected, imageSrc]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchUsers,
      actionAdd: actionAddUser,
      actionUpdate: actionUpdateUser,
      actionDelete: actionDeleteUser,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
      setSelected,
      setCroppedImage,
      setImageSrc,
      beforeSubmit: handleBeforeSubmit,
      beforeOpen: handleBeforeOpen,
    }),
    [actions, handleValidation, handleBeforeSubmit, handleBeforeOpen, setSelected]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        {imageSrc ? (
          <TblCropper data={data} actions={actions} />
        ) : (
          <Grid container direction="column" spacing={3}>
            <TblAvatar
              id={headCells[18].id}
              name={headCells[18].column}
              label={labels[headCells[18].label]}
              properties={headCells[18].properties}
              src={croppedImage || getByPath(selected, headCells[18].path)}
              setImageSrc={setImageSrc}
            />
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
              </Grid>
              <Grid item xs={5}>
                <TblTextField id={headCells[6].id} name={headCells[6].column} label={labels[headCells[6].label]} properties={headCells[6].properties} />
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
              </Grid>
              <Grid item xs={5}>
                <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblTextField id={headCells[4].id} name={headCells[4].column} label={labels[headCells[4].label]} properties={headCells[4].properties} />
              </Grid>
              <Grid item xs={5}>
                <TblTextField id={headCells[5].id} name={headCells[5].column} label={labels[headCells[5].label]} properties={headCells[5].properties} />
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblDatePicker id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} />
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" item>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[9].id}
                  name={headCells[9].column}
                  label={labels[headCells[9].label]}
                  options={countries}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_COUNTRY"] }}
                  onSelect={handleSelectCountry}
                />
              </Grid>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[10].id}
                  name={headCells[10].column}
                  disabled={disabledProv}
                  label={labels[headCells[10].label]}
                  options={provinces}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_PROVINCE"] }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" item>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[8].id}
                  name={headCells[8].column}
                  label={labels[headCells[8].label]}
                  options={langs}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
                />
              </Grid>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[14].id}
                  name={headCells[14].column}
                  label={labels[headCells[14].label]}
                  options={appRoleOptions}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblCheckBoxLabel id={headCells[13].id} name={headCells[13].column} label={labels[headCells[13].label]} properties={headCells[13].properties} />
              </Grid>
              <Grid item xs={5}>
                <TblCheckBoxLabel id={headCells[15].id} name={headCells[15].column} label={labels[headCells[15].label]} properties={headCells[15].properties} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

UserTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserTable;

import React, { useCallback, useMemo, useState } from "react";
import {
  actionAddElementsPage,
  actionAddProvince,
  actionDeleteProvince,
  actionSearchProvinces,
  actionSetCurrentPage,
  actionUpdateProvince,
} from "../../../../../actions/provinceActions";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../../TblPopups/TblAuditPopup";
import TblCards from "../../../TblCards";
import { TblCountry } from "../../../../../model/TblCountry";
import TblMaintainPopup from "../../../TblMaintainPopup";
import { TblProvince } from "../../../../../model/TblProvince";
import TblTable from "../../../TblTable";
import TblTextField from "../../../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const ProvinceTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tprovinces = useSelector((state) => state.tblProvince.tprovinces);

  const [open, setOpen] = useState(false);

  const { search, serverPage, screenPage, totalElements } = tprovinces;
  const { selecProvince, openProvinceAudit } = data;
  const { setSelecProvince, setOpenProvinceAudit } = actions;

  const rows = useMemo(
    () =>
      tprovinces.provinces.reduce((acc, row) => {
        return [
          ...acc,
          new TblProvince(
            row.seqProvince,
            new TblCountry(row.tblCountry.seqCountry, row.tblCountry.codCountry),
            row.codProvince,
            row.desProvince,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tprovinces.provinces]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_PROVINCE"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { codProvince, desProvince } = values;
      if (!codProvince) {
        errors.codProvince = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!desProvince) {
        errors.desProvince = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_PROVINCES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecProvince,
    }),
    [data, open, headCells, initialValues, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecProvince]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchProvinces,
      actionAdd: actionAddProvince,
      actionUpdate: actionUpdateProvince,
      actionDelete: actionDeleteProvince,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setOpen,
      onValidation: handleValidation,
      setSelected: setSelecProvince,
    }),
    [actions, handleValidation, setSelecProvince]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenProvinceAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openProvinceAudit} setOpen={setOpenProvinceAudit} selected={selecProvince} />
    </div>
  );
};

ProvinceTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ProvinceTable;

import { MobileOnlyView, isMobileOnly } from "react-device-detect";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TblCircularProgress from "../../TblCircularProgress";
import TblDialogTitle from "../TblDialogTitle";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TblContestGroupsResultPopup = ({ open, onClosePopup, loading }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tcontestBandVotesStats = useSelector((state) => state.tblContestBand.tcontestBandVotesStats);

  return (
    <Dialog onClose={onClosePopup} aria-labelledby="tbl-contest-groups-results-popup" open={open} fullWidth fullScreen={isMobileOnly}>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={onClosePopup} style={{ color: "#fff", backgroundColor: "#17a2b8" }}>
        {labels["TBL_CONTEST_GROUPS_RESULTS_LABEL"]}
      </TblDialogTitle>
      <DialogContent dividers>
        <MobileOnlyView>
          <Toolbar>
            <Typography variant="h6" style={{ flex: "1 1 100%" }} component="div">
              {labels["TBL_CONTEST_GROUPS_RESULTS_LABEL"]}
            </Typography>
          </Toolbar>
        </MobileOnlyView>
        {!loading ? (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{labels["TBL_ENTITY_TBL_BAND"]}</StyledTableCell>
                  <StyledTableCell align="right">{labels["TBL_ENTITY_TBL_CONTEST_GROUP_AVGGROUP"]}</StyledTableCell>
                  <StyledTableCell align="right">{labels["TBL_COMMON_LABEL_VOTES"]}</StyledTableCell>
                  <StyledTableCell align="right">{labels["TBL_COMMON_LABEL_SCORING"]}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tcontestBandVotesStats?.stats.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.code}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.avgColumn}</StyledTableCell>
                    <StyledTableCell align="right">{row.countColumn}</StyledTableCell>
                    <StyledTableCell align="right">{row.score}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <TblCircularProgress />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosePopup} variant="outlined">
          {labels["TBL_COMMON_BUTTON_CLOSE"]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TblContestGroupsResultPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TblContestGroupsResultPopup;

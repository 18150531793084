import React, { useEffect, useState } from "react";
import { VALUE_N, VALUE_S } from "../../../../constants";
import { faUpload, faVideo } from "@fortawesome/free-solid-svg-icons";

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { TblGroupVideo } from "../../../../model/TblGroupVideo";
import TblTextField from "../../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import YouTube from "react-youtube";
import { getYoutubeId } from "../../../../utils/tblUtils";
import { isMobileOnly } from "react-device-detect";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const BandVideos = ({ data }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { headCells, childrens } = data;

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [videoId, setVideoId] = useState("");
  const [removeId, setRemoveId] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [videos, setVideos] = useState([]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setVideoId(event.target.textContent);
    setRemoveId("");
  };

  const handleUploadVideo = () => {
    setIsUpload(true);
  };

  const handleRemoveVideo = (pVideoId) => {
    setRemoveId(pVideoId);
    setVideoId("");
  };

  useEffect(() => {
    const listVideos = childrens.videos?.map((video) => video.value);
    if (listVideos?.length > 0) {
      setVideos(listVideos);
      setVideoId(listVideos[0].videoId);
      setSelectedIndex(listVideos[0].videoId);
    }
  }, [childrens.videos]);

  const generate = () => {
    return videos.map((video) => (
      <ListItem key={video.videoId} button selected={selectedIndex === video.videoId} onClick={(event) => handleListItemClick(event, video.videoId)}>
        <ListItemAvatar>
          <Avatar>
            <FontAwesomeIcon icon={faVideo} size="lg" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={video.videoId} />
        <div className={classes.ml1}></div>
        <ListItemSecondaryAction>
          <IconButton onClick={() => handleRemoveVideo(video.videoId)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };

  const UpdateForm = () => {
    const { values, setFieldValue, errors, setErrors, touched, setTouched } = useFormikContext();
    useEffect(() => {
      setVideos(values.videos);
      if (isUpload) {
        const id = getYoutubeId(values.urlVideo);
        if (id) {
          if (!values.videos.find((video) => video.videoId === id)) {
            setFieldValue("videos", [...values.videos, new TblGroupVideo(null, undefined, id, values.urlVideo, values.reviewed ? VALUE_S : VALUE_N)]);
            setFieldValue("urlVideo", "");
          } else {
            setErrors({ ...errors, urlVideo: labels["TBL_COMMON_INVALIDINPUT_URLVIDEO_ALREADYEXIST"] });
          }
        } else {
          setErrors({ ...errors, urlVideo: labels["TBL_COMMON_INVALIDINPUT_WRONGYOUTUBEURL"] });
        }

        setIsUpload(false);
        setVideoId(id);
        setSelectedIndex(id);
      }
      if (removeId) {
        setFieldValue(
          "videos",
          values.videos.filter((video) => video.videoId !== removeId)
        );
        setTouched({ ...touched, videos: true });
        setRemoveId("");
      }
    }, [setFieldValue, values, errors, setErrors, touched, setTouched]);

    return null;
  };

  return (
    <Grid container direction="column" justifyContent="center" alignContent="center" alignItems="center">
      <Grid item xs={10} md={8} lg={4} container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={10} md={5}>
          <TblTextField id={headCells[16].id} name={headCells[16].column} label={labels[headCells[16].label]} properties={headCells[16].properties} />
          <UpdateForm />
        </Grid>
        <Grid item xs={2} md={1}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={handleUploadVideo}>
              <FontAwesomeIcon icon={faUpload} color="#f50057" size="lg" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <div className={classes.pt1}></div>
      <Grid item xs={10} md={8} lg={4}>
        <List>{generate()}</List>
      </Grid>
      <div className={classes.pt1}></div>
      {videoId && (
        <Grid item xs={10} md={8} lg={4}>
          <Typography variant="subtitle1">{labels[headCells[17].label]}:</Typography>
          <YouTube videoId={videoId} opts={{ width: isMobileOnly ? 300 : 640, height: 150 }} />
        </Grid>
      )}
    </Grid>
  );
};

BandVideos.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandVideos;

import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { Properties } from "../../../../../constants/properties";
import React from "react";
import { useSelector } from "react-redux";

const TblBandShare = () => {
  const band = useSelector((state) => state.tblBand.band);

  return (
    <React.Fragment>
      <WhatsappShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/groups/${band?.tblGroup?.codGroup}`} title={`${band.tblGroup.name} en @tourbandlive`}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <TelegramShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/groups/${band?.tblGroup?.codGroup}`} title={`${band.tblGroup.name} en @tourbandlive`}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
      <LineShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/groups/${band?.tblGroup?.codGroup}`} title={`${band.tblGroup.name} en @tourbandlive`}>
        <LineIcon size={32} round={true} />
      </LineShareButton>
      <FacebookShareButton
        url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/groups/${band?.tblGroup?.codGroup}`}
        quote={`${band.tblGroup.name} en @tourbandlive`}
        hashtag={"#sordoelquenovote"}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton
        url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/groups/${band?.tblGroup?.codGroup}`}
        title={`${band.tblGroup.name} en @tourbandlive`}
        hashtags={["sordoelquenovote"]}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </React.Fragment>
  );
};

export default TblBandShare;

import React, { useMemo } from "react";
import { VALUE_N, VALUE_S } from "../../../../constants";
import { actionAddElementsPage, actionSearchBandUserVotes, actionSetCurrentPage } from "../../../../actions/bandUserVoteActions";

import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupUserVote } from "../../../../model/TblGroupUserVote";
import TblTable from "../../TblTable";
import { TblUser } from "../../../../model/TblUser";
import { useSelector } from "react-redux";

const BandUserVoteTable = ({ headCells, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tbandUserVotes = useSelector((state) => state.tblBandUserVote.tbandUserVotes);

  const isReadOnly = true;

  const { search, serverPage, screenPage, totalElements } = tbandUserVotes;
  const { selecUserVote, openUserVoteAudit } = data;
  const { setSelecUserVote, setOpenUserVoteAudit } = actions;

  const rows = useMemo(
    () =>
      tbandUserVotes.bandUserVotes.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupUserVote(
            row.seqVote,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            new TblUser(row.tblUser.seqUser, row.tblUser.user),
            row.vote,
            row.tourBandPoint ? VALUE_S : VALUE_N,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tbandUserVotes.bandUserVotes]
  );

  const tableLabel = labels["TBL_COMMON_LABEL_VOTES"];

  data = useMemo(
    () => ({
      ...data,
      headCells,
      masterSelected,
      cardtitle: labels["TBL_COMMON_LABEL_VOTES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecUserVote,
      isReadOnly,
    }),
    [data, headCells, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecUserVote, isReadOnly]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandUserVotes,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setSelected: setSelecUserVote,
    }),
    [actions, setSelecUserVote]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenUserVoteAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblAuditPopup open={openUserVoteAudit} setOpen={setOpenUserVoteAudit} selected={selecUserVote} />
    </div>
  );
};

BandUserVoteTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandUserVoteTable;

export const OPERATION_ADD = "ADD";
export const OPERATION_EDIT = "EDIT";
export const ROLE_ADMIN = "ROLE_ADM";
export const ROLE_PUBLIC = "ROLE_PUB";
export const ROLE_NOACTIVE = "ROLE_NAC";
export const ROLE_ANONYMOUS = "ROLE_ANO";
export const VALUE_S = "S";
export const VALUE_N = "N";
export const VALUE_ES = "ES";
export const VALUE_ESP = "ESP";

// CONTEST TYPES
export const CONTEST_SIMPLE = "H";
export const CONTEST_BANDS = "G";

// CALL STATUS
export const DONE = "DONE";
export const IN_PROGRESS = "IN_PROGRESS";

// REDUCE COMMON
export const NO_ACTION = "NO_ACTION";

// NOTIFIER_REDUCER_ACTIONS
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

// BAND_REDUCER_ACTIONS
export const API_BAND_GET = "BAND_GET";
export const API_BAND_GET_BAND = "BAND_GET_BAND";
export const API_BAND_SEARCH = "BAND_SEARCH";
export const API_BAND_ADD = "BAND_ADD";
export const API_BAND_EDIT = "BAND_EDIT";
export const API_BAND_DELETE = "BAND_DELETE";
export const SET_SCREEN_BANDPAGE = "SET_SCREEN_BANDPAGE";
export const ADD_ELEMENTS_BANDPAGE = "ADD_ELEMENTS_BANDPAGE";
export const CLEAR_BAND_TABLE = "CLEAR_BAND_TABLE";
export const CLEAR_BAND = "CLEAR_BAND";
export const API_BAND_GET_RANKING = "BAND_GET_RANKING";
export const API_BAND_VOTEBAND = "BAND_VOTEBAND";

// BAND_PROVINCE_REDUCER_ACTIONS
export const API_BAND_PROVINCE_SEARCH = "BAND_PROVINCE_SEARCH";
export const API_BAND_PROVINCE_ADD = "BAND_PROVINCE_ADD";
export const API_BAND_PROVINCE_DELETE = "BAND_PROVINCE_DELETE";
export const SET_SCREEN_BAND_PROVINCEPAGE = "SET_SCREEN_BAND_PROVINCEPAGE";
export const ADD_ELEMENTS_BAND_PROVINCEPAGE = "ADD_ELEMENTS_BAND_PROVINCEPAGE";
export const CLEAR_BAND_PROVINCE_TABLE = "CLEAR_BAND_PROVINCE_TABLE";

// BAND_STYLE_REDUCER_ACTIONS
export const API_BAND_STYLE_SEARCH = "BAND_STYLE_SEARCH";
export const API_BAND_STYLE_ADD = "BAND_STYLE_ADD";
export const API_BAND_STYLE_DELETE = "BAND_STYLE_DELETE";
export const SET_SCREEN_BAND_STYLEPAGE = "SET_SCREEN_BAND_STYLEPAGE";
export const ADD_ELEMENTS_BAND_STYLEPAGE = "ADD_ELEMENTS_BAND_STYLEPAGE";
export const CLEAR_BAND_STYLE_TABLE = "CLEAR_BAND_STYLE_TABLE";

// BAND_LINK_REDUCER_ACTIONS
export const API_BAND_LINK_SEARCH = "BAND_LINK_SEARCH";
export const API_BAND_LINK_ADD = "BAND_LINK_ADD";
export const API_BAND_LINK_EDIT = "BAND_LINK_EDIT";
export const API_BAND_LINK_DELETE = "BAND_LINK_DELETE";
export const SET_SCREEN_BAND_LINKPAGE = "SET_SCREEN_BAND_LINKPAGE";
export const ADD_ELEMENTS_BAND_LINKPAGE = "ADD_ELEMENTS_BAND_LINKPAGE";
export const CLEAR_BAND_LINK_TABLE = "CLEAR_BAND_LINK_TABLE";

// BAND_VIDEO_REDUCER_ACTIONS
export const API_BAND_VIDEO = "BAND_VIDEO_GET";
export const API_BAND_VIDEO_SEARCH = "BAND_VIDEO_SEARCH";
export const API_BAND_VIDEO_ADD = "BAND_VIDEO_ADD";
export const API_BAND_VIDEO_EDIT = "BAND_VIDEO_EDIT";
export const API_BAND_VIDEO_DELETE = "BAND_VIDEO_DELETE";
export const SET_SCREEN_BAND_VIDEOPAGE = "SET_SCREEN_BAND_VIDEOPAGE";
export const ADD_ELEMENTS_BAND_VIDEOPAGE = "ADD_ELEMENTS_BAND_VIDEOPAGE";
export const CLEAR_BAND_VIDEO_TABLE = "CLEAR_BAND_VIDEO_TABLE";
export const CLEAR_BAND_VIDEO = "CLEAR_BAND_VIDEO";

// BAND_USER_VOTE_REDUCER_ACTIONS
export const API_BAND_USER_VOTE_SEARCH = "BAND_USER_VOTE_SEARCH";
export const SET_SCREEN_BAND_USER_VOTEPAGE = "SET_SCREEN_BAND_USER_VOTEPAGE";
export const ADD_ELEMENTS_BAND_USER_VOTEPAGE = "ADD_ELEMENTS_BAND_USER_VOTEPAGE";
export const CLEAR_BAND_USER_VOTE_TABLE = "CLEAR_BAND_USER_VOTE_TABLE";

// BAND_USER_REVIEW_REDUCER_ACTIONS
export const API_BAND_REVIEW = "BAND_REVIEW_GET";
export const API_BAND_REVIEW_SEARCH = "BAND_REVIEW_SEARCH";
export const API_BAND_REVIEW_ADD = "BAND_REVIEW_ADD";
export const API_BAND_REVIEW_EDIT = "BAND_REVIEW_EDIT";
export const API_BAND_REVIEW_DELETE = "BAND_REVIEW_DELETE";
export const API_BAND_REVIEW_CREATE = "BAND_REVIEW_CREATE";
export const API_BAND_REVIEW_ASSESS_ADD = "BAND_REVIEW_ASSESS_ADD";
export const SET_SCREEN_BAND_REVIEWPAGE = "SET_SCREEN_BAND_REVIEWPAGE";
export const ADD_ELEMENTS_BAND_REVIEWPAGE = "ADD_ELEMENTS_BAND_REVIEWPAGE";
export const CLEAR_BAND_REVIEW_TABLE = "CLEAR_BAND_REVIEW_TABLE";

// USER_REDUCER_ACTIONS
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const API_USER_GET = "USER_GET";
export const API_USERS_GET = "USERS_GET";
export const API_USER_SEARCH = "USER_SEARCH";
export const API_USER_ADD = "USER_ADD";
export const API_USER_EDIT = "USER_EDIT";
export const API_USER_DELETE = "USER_DELETE";
export const SET_SCREEN_USERPAGE = "SET_SCREEN_USERPAGE";
export const ADD_ELEMENTS_USERPAGE = "ADD_ELEMENTS_USERPAGE";
export const CLEAR_USER_TABLE = "CLEAR_USER_TABLE";

// CONTEST_REDUCER_ACTIONS
export const API_CONTEST_GET = "CONTEST_GET";
export const API_CONTEST_SEARCH = "CONTEST_SEARCH";
export const API_ACTIVE_CONTEST = "GET_ACTIVE_CONTEST";
export const API_UPCOMING_CONTEST = "GET_FUTURE_CONTEST";
export const API_FINISHED_CONTEST = "GET_FINISHED_CONTEST";
export const API_MY_CONTEST = "GET_MY_CONTEST";
export const API_CONTEST_ADD = "CONTEST_ADD";
export const API_CONTEST_EDIT = "CONTEST_EDIT";
export const API_CONTEST_DELETE = "CONTEST_DELETE";
export const SET_SCREEN_CONTESTPAGE = "SET_SCREEN_CONTESTPAGE";
export const ADD_ELEMENTS_CONTESTPAGE = "ADD_ELEMENTS_CONTESTPAGE";
export const CLEAR_CONTEST_TABLE = "CLEAR_CONTEST_TABLE";

// CONTESTANT_REDUCER_ACTIONS
export const API_CONTESTANT_SEARCH = "CONTESTANT_SEARCH";
export const API_CONTESTANT_WINNER = "CONTESTANT_WINNER";
export const API_CONTESTANT_NEW_WINNER = "CONTESTANT_NEW_WINNER";
export const API_CONTESTANT_ADD = "CONTESTANT_ADD";
export const API_CONTESTANT_EDIT = "CONTESTANT_EDIT";
export const API_CONTESTANT_DELETE = "CONTESTANT_DELETE";
export const API_PARTICIPATE = "PARTICIPATE";
export const SET_SCREEN_CONTESTANTPAGE = "SET_SCREEN_CONTESTANTPAGE";
export const ADD_ELEMENTS_CONTESTANTPAGE = "ADD_ELEMENTS_CONTESTANTPAGE";
export const CLEAR_CONTESTANT_TABLE = "CLEAR_CONTESTANT_TABLE";

// CONTEST_BAND_REDUCER_ACTIONS
export const API_CONTEST_BAND_SEARCH = "CONTEST_BAND_SEARCH";
export const API_CONTEST_BAND_VOTES_STATS = "CONTEST_BAND_VOTES_STATS";
export const API_CONTEST_BANDS_GET = "CONTEST_BANDS_GET";
export const SET_SCREEN_CONTEST_BANDPAGE = "SET_SCREEN_CONTEST_BANDPAGE";
export const ADD_ELEMENTS_CONTEST_BANDPAGE = "ADD_ELEMENTS_CONTEST_BANDPAGE";
export const CLEAR_CONTEST_BAND_TABLE = "CLEAR_CONTEST_BAND_TABLE";

// LANG_REDUCER_ACTIONS
export const API_LANG_GET = "LANG_GET";
export const API_LANG_SEARCH = "LANG_SEARCH";
export const API_LANG_ADD = "LANG_ADD";
export const API_LANG_EDIT = "LANG_EDIT";
export const API_LANG_DELETE = "LANG_DELETE";
export const SET_SCREEN_LANGPAGE = "SET_SCREEN_LANGPAGE";
export const ADD_ELEMENTS_LANGPAGE = "ADD_ELEMENTS_LANGPAGE";
export const CLEAR_LANG_TABLE = "CLEAR_LANG_TABLE";

// LABEL_REDUCER_ACTIONS
export const API_LABEL_GET = "LABEL_GET";
export const API_LABEL_SEARCH = "LABEL_SEARCH";
export const API_LABEL_ADD = "LABEL_ADD";
export const API_LABEL_EDIT = "LABEL_EDIT";
export const API_LABEL_DELETE = "LABEL_DELETE";
export const SET_SCREEN_LABELPAGE = "SET_SCREEN_LABELPAGE";
export const ADD_ELEMENTS_LABELPAGE = "ADD_ELEMENTS_LABELPAGE";
export const CLEAR_LABEL_TABLE = "CLEAR_LABEL_TABLE";

// ERROR_MESSAGE_REDUCER_ACTIONS
export const API_ERROR_MESSAGE_GET = "ERROR_MESSAGE_GET";
export const API_ERROR_MESSAGE_SEARCH = "ERROR_MESSAGE_SEARCH";
export const API_ERROR_MESSAGE_ADD = "ERROR_MESSAGE_ADD";
export const API_ERROR_MESSAGE_EDIT = "ERROR_MESSAGE_EDIT";
export const API_ERROR_MESSAGE_DELETE = "ERROR_MESSAGE_DELETE";
export const SET_SCREEN_ERROR_MESSAGEPAGE = "SET_SCREEN_ERROR_MESSAGEPAGE";
export const ADD_ELEMENTS_ERROR_MESSAGEPAGE = "ADD_ELEMENTS_ERROR_MESSAGEPAGE";
export const CLEAR_ERROR_MESSAGE_TABLE = "CLEAR_ERROR_MESSAGE_TABLE";

// TEMPLATE_REDUCER_ACTIONS
export const API_TEMPLATE_GET = "TEMPLATE_GET";
export const API_TEMPLATE_SEARCH = "TEMPLATE_SEARCH";
export const API_TEMPLATE_ADD = "TEMPLATE_ADD";
export const API_TEMPLATE_EDIT = "TEMPLATE_EDIT";
export const API_TEMPLATE_DELETE = "TEMPLATE_DELETE";
export const SET_SCREEN_TEMPLATEPAGE = "SET_SCREEN_TEMPLATEPAGE";
export const ADD_ELEMENTS_TEMPLATEPAGE = "ADD_ELEMENTS_TEMPLATEPAGE";
export const CLEAR_TEMPLATE_TABLE = "CLEAR_TEMPLATE_TABLE";

// COUNTRY_REDUCER_ACTIONS
export const API_COUNTRY_GET = "COUNTRY_GET";
export const API_COUNTRY_SEARCH = "COUNTRY_SEARCH";
export const API_COUNTRY_ADD = "COUNTRY_ADD";
export const API_COUNTRY_EDIT = "COUNTRY_EDIT";
export const API_COUNTRY_DELETE = "COUNTRY_DELETE";
export const SET_SCREEN_COUNTRYPAGE = "SET_SCREEN_COUNTRYPAGE";
export const ADD_ELEMENTS_COUNTRYPAGE = "ADD_ELEMENTS_COUNTRYPAGE";
export const CLEAR_COUNTRY_TABLE = "CLEAR_COUNTRY_TABLE";

// PROVINCE_REDUCER_ACTIONS
export const API_PROVINCE_GET = "PROVINCE_GET";
export const API_PROVINCE_SEARCH = "PROVINCE_SEARCH";
export const API_PROVINCE_ADD = "PROVINCE_ADD";
export const API_PROVINCE_EDIT = "PROVINCE_EDIT";
export const API_PROVINCE_DELETE = "PROVINCE_DELETE";
export const SET_SCREEN_PROVINCEPAGE = "SET_SCREEN_PROVINCEPAGE";
export const ADD_ELEMENTS_PROVINCEPAGE = "ADD_ELEMENTS_PROVINCEPAGE";
export const CLEAR_PROVINCE_TABLE = "CLEAR_PROVINCE_TABLE";

// DOMAIN_REDUCER_ACTIONS
export const API_DOMAIN_GET = "DOMAIN_GET";
export const API_DOMAIN_SEARCH = "DOMAIN_SEARCH";
export const API_DOMAIN_ADD = "DOMAIN_ADD";
export const API_DOMAIN_EDIT = "DOMAIN_EDIT";
export const API_DOMAIN_DELETE = "DOMAIN_DELETE";
export const SET_SCREEN_DOMAINPAGE = "SET_SCREEN_DOMAINPAGE";
export const ADD_ELEMENTS_DOMAINPAGE = "ADD_ELEMENTS_DOMAINPAGE";
export const CLEAR_DOMAIN_TABLE = "CLEAR_DOMAIN_TABLE";

// IMAGES_PATH_REDUCER_ACTIONS
export const API_IMAGES_PATH_GET = "IMAGES_PATH_GET";
export const API_IMAGES_PATH_SEARCH = "IMAGES_PATH_SEARCH";
export const API_IMAGES_PATH_ADD = "IMAGES_PATH_ADD";
export const API_IMAGES_PATH_EDIT = "IMAGES_PATH_EDIT";
export const API_IMAGES_PATH_DELETE = "IMAGES_PATH_DELETE";
export const SET_SCREEN_IMAGES_PATHPAGE = "SET_SCREEN_IMAGES_PATHPAGE";
export const ADD_ELEMENTS_IMAGES_PATHPAGE = "ADD_ELEMENTS_IMAGES_PATHPAGE";
export const CLEAR_IMAGES_PATH_TABLE = "CLEAR_IMAGES_PATH_TABLE";

// STYLE_REDUCER_ACTIONS
export const API_STYLE_GET = "STYLE_GET";
export const API_STYLE_SEARCH = "STYLE_SEARCH";
export const API_STYLE_ADD = "STYLE_ADD";
export const API_STYLE_EDIT = "STYLE_EDIT";
export const API_STYLE_DELETE = "STYLE_DELETE";
export const SET_SCREEN_STYLEPAGE = "SET_SCREEN_STYLEPAGE";
export const ADD_ELEMENTS_STYLEPAGE = "ADD_ELEMENTS_STYLEPAGE";
export const CLEAR_STYLE_TABLE = "CLEAR_STYLE_TABLE";

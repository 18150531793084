import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { CONTEST_BANDS } from "../../../constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import TblCircularProgress from "../../TblCircularProgress";
import TblDialogTitle from "../TblDialogTitle";
import TblHandsVote from "../../TblHandsVote/TblHandsVote";
import TblSwitchTransition from "../../TblSwitchTransition/TblSwitchTransition";
import Typography from "@material-ui/core/Typography";
import { actionGetContestBands } from "../../../actions/contestBandActions";
import { callService } from "../../../utils/serviceUtils";
import { isMobileOnly } from "react-device-detect";

const TblContestVoteGroupPopup = ({ open, onClosePopup, contest, onSubmit, error }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const contestBands = useSelector((state) => state.tblContestBand.contestBands);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [contestBandIndex, setContestBandIndex] = useState(0);
  const [vote, setVote] = useState({});
  const [transition, setTransition] = useState("alpha-right");

  const style = useMemo(() => ({ width: isMobileOnly ? 150 : 300, height: isMobileOnly ? 150 : 300 }), []);

  if (!loading && contest.contestType === CONTEST_BANDS && contestBands.length === 0) {
    setLoading(true);
    callService(dispatch, actionGetContestBands, contest).finally(() => setLoading(false));
  }

  const nextBand = useCallback(() => {
    setTransition("alpha-right");
    if (contestBandIndex !== contestBands.length - 1) {
      setContestBandIndex(contestBandIndex + 1);
    } else {
      setContestBandIndex(0);
    }
  }, [contestBandIndex, contestBands.length]);
  const previousBand = useCallback(() => {
    setTransition("alpha-left");
    if (contestBandIndex !== 0) {
      setContestBandIndex(contestBandIndex - 1);
    } else {
      setContestBandIndex(contestBands.length - 1);
    }
  }, [contestBandIndex, contestBands.length]);

  const handleVote = useCallback(
    (myVote, codGroup) => {
      setVote({ ...vote, [codGroup]: myVote });
    },
    [vote]
  );

  return (
    <Dialog onClose={onClosePopup} aria-labelledby="tbl-contest-group-vote-popup" open={open} fullWidth>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={onClosePopup} style={{ color: "#fff", backgroundColor: "#17a2b8" }}>
        {labels["TBL_CONTEST_GROUP_VOTE_LABEL"]}
      </TblDialogTitle>
      <DialogContent dividers style={{ overflow: "hidden" }}>
        {!loading && contestBands.length > 0 ? (
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <TblSwitchTransition mode="out-in" keyTransition={contestBandIndex} effectClass={transition}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {labels["TBL_ENTITY_TBL_BAND"]}: {contestBands[contestBandIndex].name}
                </Typography>
              </Grid>
            </TblSwitchTransition>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <IconButton color="secondary" aria-label="upload picture" component="span" onClick={previousBand}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <TblSwitchTransition mode="out-in" keyTransition={contestBandIndex} effectClass={transition}>
                <Grid item>
                  <Avatar src={contestBands[contestBandIndex].photoGroup} alt={contestBands[contestBandIndex].name} variant="square" style={style} />
                </Grid>
              </TblSwitchTransition>
              <Grid item>
                <IconButton color="secondary" aria-label="upload picture" component="span" onClick={nextBand}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>
            <TblSwitchTransition mode="out-in" keyTransition={contestBandIndex} effectClass={transition}>
              <Grid item xs={12}>
                <TblHandsVote voteRange={contest.voteRange} codGroup={contestBands[contestBandIndex].codGroup} vote={vote} loading={loading} onVote={handleVote} />
              </Grid>
            </TblSwitchTransition>
            {error && (
              <Typography variant="body1" color="error">
                {labels["TBL_CONTEST_VOTE_GROUP_POPUP_ERROR"]}
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <TblCircularProgress />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(contest, vote)} color="secondary" variant="contained">
          {labels["TBL_COMMON_BUTTON_CONTINUE_LABEL"]}
        </Button>
        <Button onClick={onClosePopup} variant="outlined">
          {labels["TBL_COMMON_BUTTON_CANCEL"]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TblContestVoteGroupPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  contest: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TblContestVoteGroupPopup;

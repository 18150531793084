export class TblDomainId {
  constructor(seqDomainId, tblDomain, valueDes, sort, tblLanguage, dateCre, dateMod, userCre, userMod) {
    this.seqDomainId = seqDomainId;
    this.tblDomain = tblDomain;
    this.valueDes = valueDes;
    this.sort = sort;
    this.tblLanguage = tblLanguage;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { VALUE_N, VALUE_S } from "../constants";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblGroup } from "../model/TblGroup";
import { TblGroupId } from "../model/TblGroupId";
import { TblGroupVideo } from "../model/TblGroupVideo";

export const apiGetBandVideos = (url, obj) => async () => {
  url = `${url}?codGroup=${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchBandVideos = (url, obj) => async () => {
  const { codGroup } = obj;
  url = `${url}?codGroup=${codGroup}`;
  return searchData(url, obj);
};

export const apiAddPublicBandVideos = (url, obj) => async () => {
  const { videos, tblGroup } = obj;
  const request = new TblGroupId(undefined, tblGroup, undefined, undefined, undefined, undefined, undefined, videos);
  const { data } = await doPost(url, request);
  return data;
};

export const apiAddBandVideo = (url, obj) => async () => {
  const { seqVideo, tblGroup, videoId, urlVideo, reviewed } = obj;
  const request = new TblGroupVideo(seqVideo, new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name), videoId, urlVideo, reviewed ? VALUE_S : VALUE_N);
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateBandVideo = (url, obj) => async () => {
  const { seqVideo, tblGroup, videoId, urlVideo, reviewed, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqVideo}`;
  const request = new TblGroupVideo(
    seqVideo,
    new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name),
    videoId,
    urlVideo,
    reviewed ? VALUE_S : VALUE_N,
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteBandVideo = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

import { actionClearBand, actionGetBands } from "../../actions/bandActions";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { actionClearBandReviewTable } from "../../actions/bandReviewActions";
import { actionClearBandVideoTable } from "../../actions/bandVideoActions";
import { actionGetImagesPaths } from "../../actions/imagesPathActions";
import { actionLogout } from "../../actions/userActions";
import { callService } from "../../utils/serviceUtils";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

export const useTblFrame = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const suggestBandsInputText = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [timeoutSearch, setTimeoutSearch] = useState(0);
  const [loadingBands, setLoadingBands] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [hiddenLogo, setHiddenLogo] = useState(false);
  const [visibleHeader, setVisibleHeader] = useState(true);

  const cols = isMobile ? 2 : 5;
  const width = isMobile ? 150 : 300;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    callService(dispatch, actionLogout).then(() => dispatch({ type: "LOGIN_ACTION" }));

    handleMenuClose();
    history.push("/");
  }, [dispatch, handleMenuClose, history]);

  const toggleMenu = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenMenu(open);
  };

  const toggleBox = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    suggestBandsInputText.current.value = "";
    setOpenSearch(open);
  };

  const handleSearch = useCallback(
    (event) => {
      const name = event.target.value;
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
      if (name.length > 0) {
        setTimeoutSearch(
          setTimeout(() => {
            setLoadingBands(true);
            const searchBandRQ = {
              name: name,
              orderBy: "tblGroup.name",
            };
            callService(dispatch, actionGetBands, searchBandRQ)
              .then((response) => {
                if (response.content.length > 0) {
                  setOpenSearch(true);
                } else {
                  setOpenSearch(false);
                }
              })
              .finally(() => {
                setLoadingBands(false);
                suggestBandsInputText.current.focus();
              });
          }, 500)
        );
      } else {
        setOpenSearch(false);
      }
    },
    [dispatch, timeoutSearch]
  );

  const handleScroll = (event) => {
    if (window.scrollY >= 40) {
      setVisibleHeader(false);
    } else {
      setVisibleHeader(true);
    }
  };

  const handleNavigate = useCallback(() => {
    setOpenSearch(false);
    suggestBandsInputText.current.value = "";
    dispatch(actionClearBand());
    dispatch(actionClearBandVideoTable());
    dispatch(actionClearBandReviewTable());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    callService(dispatch, actionGetImagesPaths, { imageGroup: "BAM" });
    callService(dispatch, actionGetImagesPaths, { imageGroup: "BAF" });
  }, [dispatch]);

  const data = useMemo(
    () => ({
      location,
      history,
      suggestBandsInputText,
      anchorEl,
      openMenu,
      timeoutSearch,
      loadingBands,
      openSearch,
      hiddenLogo,
      visibleHeader,
      cols,
      width,
    }),
    [anchorEl, cols, hiddenLogo, history, loadingBands, location, openMenu, openSearch, timeoutSearch, visibleHeader, width]
  );

  const actions = useMemo(
    () => ({
      setAnchorEl,
      setOpenMenu,
      setTimeoutSearch,
      setLoadingBands,
      setOpenSearch,
      setHiddenLogo,
      handleProfileMenuOpen,
      handleMenuClose,
      handleLogout,
      toggleMenu,
      toggleBox,
      handleSearch,
      setVisibleHeader,
      handleNavigate,
    }),
    [handleLogout, handleMenuClose, handleSearch, setVisibleHeader, handleNavigate]
  );

  return { data, actions };
};

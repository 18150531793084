import { ROLE_ANONYMOUS, VALUE_ES } from "../constants";
import { getCookie, parseJwt } from "../utils/dataUtils";

import { TblUser } from "../model/TblUser";

export const createUserSession = () => {
  let token = getCookie("OAUTH_TOKEN");
  if (token) {
    const decodeToken = parseJwt(token);
    return new TblUser(
      decodeToken.seqUser,
      decodeToken.user,
      undefined,
      decodeToken.facebookId,
      undefined,
      decodeToken.googleId,
      undefined,
      decodeToken.name,
      decodeToken.lastName,
      decodeToken.email,
      decodeToken.birthDate,
      decodeToken.userLang,
      decodeToken.country,
      decodeToken.province,
      decodeToken.photoProfile,
      decodeToken.CLAIM_TOKEN,
      undefined,
      undefined,
      decodeToken.notifications
    );
  } else {
    return {
      userLang: VALUE_ES,
      applicationRole: ROLE_ANONYMOUS,
    };
  }
};

export const getYoutubeId = (url) => {
  var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  } else {
    return null;
  }
};

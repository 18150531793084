import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const customStyles = makeStyles(() => ({
  TblTabPanel: {
    padding: 15,
    overflow: "hidden",
  },
}));

const TblTabPanel = ({ children, value, index, fullWidth }) => {
  const cClasses = customStyles();
  return (
    <div className={cClasses.TblTabPanel} style={fullWidth ? { width: "100%" } : {}} hidden={value !== index} id={`tbl-TblTabPanel-${index}`} aria-labelledby={`tbl-tab-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

TblTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  fullWidth: PropTypes.bool,
};

export default TblTabPanel;

import { ROLE_NOACTIVE, ROLE_PUBLIC } from "../../../constants";
import { faGift, faUserCircle, faUsers } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblServices = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const history = useHistory();

  return (
    <Grid container direction="column" alignContent="center" alignItems="center" className={classes.py3}>
      <Grid item xs={10}>
        <Typography variant="h3" align="center">
          {labels["TBL_HOME_SERVICES_TITLE"]}
        </Typography>
        <hr className={classes.my1} />
      </Grid>
      <Grid item xs={12} container direction="row" justifyContent="center" spacing={3}>
        <Grid item container xs={12} lg={3} direction="column" alignContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton
              aria-label="my-profile"
              onClick={() => {
                if (!userSession.applicationRole.includes(ROLE_PUBLIC) && !userSession.applicationRole.includes(ROLE_NOACTIVE)) {
                  history.push("/login?pathname=/");
                } else {
                  history.push("/profile");
                }
              }}
            >
              <FontAwesomeIcon icon={faUserCircle} color="#dc3545" size="5x" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h4" align="center">
              {labels["TBL_HOME_SERVICES_USERS_TITLE"]}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              {labels["TBL_HOME_SERVICES_USERS_DESCRIPTION"]}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={3} direction="column" alignContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton
              aria-label="my-band"
              onClick={() => {
                if (!userSession.applicationRole.includes(ROLE_PUBLIC) && !userSession.applicationRole.includes(ROLE_NOACTIVE)) {
                  history.push("/login?pathname=/");
                } else {
                  history.push("/groups");
                }
              }}
            >
              <FontAwesomeIcon icon={faUsers} color="#dc3545" size="5x" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h4" align="center">
              {labels["TBL_HOME_SERVICES_BANDS_TITLE"]}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              {labels["TBL_HOME_SERVICES_BANDS_DESCRIPTION"]}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} lg={3} direction="column" alignContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton
              aria-label="my-conteste"
              onClick={() => {
                if (!userSession.applicationRole.includes(ROLE_PUBLIC) && !userSession.applicationRole.includes(ROLE_NOACTIVE)) {
                  history.push("/login?pathname=/");
                } else {
                  history.push("/contests");
                }
              }}
            >
              <FontAwesomeIcon icon={faGift} color="#dc3545" size="5x" />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h4" align="center">
              {labels["TBL_HOME_SERVICES_CONTESTS_TITLE"]}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" align="center">
              {labels["TBL_HOME_SERVICES_CONTESTS_DESCRIPTION"]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TblServices;

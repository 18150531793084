import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React from "react";
import ShareIcon from "@material-ui/icons/Share";
import TblBandChart from "./TblBandChart";
import TblBandFileSk from "./TblBandFileSk";
import TblBandLinks from "./TblBandLinks";
import TblBandProvinces from "./TblBandProvinces";
import TblBandShare from "./TblBandShare";
import TblBandStyles from "./TblBandStyles";
import TblBandVote from "./TblBandVote";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { useBandFile } from "../../../../hooks/TblBand/useBandFile";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const TblBandFile = ({ isLoading }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const band = useSelector((state) => state.tblBand.band);

  const { data, actions } = useBandFile();
  const { showShare, showStats, showVote, showMore, measuredRef } = data;
  const { setShowShare, setShowStats, setShowVote, setShowMore } = actions;

  const HandAvgVoteImg = ({ index }) => {
    const intAvg = Math.trunc(band.avgGroup);
    if (index <= intAvg) {
      return <img src="/images/application/tblimagegrouphandvoted.png" alt="average-vote" variant="square" />;
    } else if (band.avgGroup % 1 > 0 && index === Math.ceil(band.avgGroup)) {
      return <img src="/images/application/tblimagegrouphandhalfvoted.png" alt="average-vote" variant="square" />;
    } else {
      return <img src="/images/application/tblimagegrouphandvote.png" alt="average-vote" variant="square" />;
    }
  };

  return (
    <React.Fragment>
      {!isLoading && !_.isEmpty(band) ? (
        <Grid container>
          <Grid item xs={12} container direction="row" justifyContent="center" alignItems="flex-start">
            <Grid item xs={12} lg={4} xl={3} container direction="column" alignItems="center">
              <div className={classes.hideBrowser} style={{ width: "100%" }}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h4">{band.tblGroup.name}</Typography>
                  </Box>
                  <Box>
                    {!showShare && (
                      <IconButton aria-label="share" onClick={() => setShowShare(!showShare)}>
                        <ShareIcon />
                      </IconButton>
                    )}
                    {showShare && <TblBandShare />}
                  </Box>
                </Box>
                <div className={classes.mt1}></div>
              </div>
              <Grid item xs={12}>
                <Avatar src={band.tblGroup.photoGroup} alt={band.tblGroup.name} variant="square" style={{ width: 300, height: 300 }} />
              </Grid>
              <div className={classes.hideBrowser} style={{ width: "100%" }}>
                <Grid item xs={12} className={classes.mt1}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Button
                      className={classes.mr1}
                      variant={showStats ? "contained" : "outlined"}
                      color={showStats ? "secondary" : "default"}
                      onClick={() => {
                        setShowStats(!showStats);
                        setShowVote(false);
                      }}
                    >
                      {labels["TBL_BANDFILE_BUTTON_SCORE"]}
                    </Button>
                    <Button
                      variant={showVote ? "contained" : "outlined"}
                      color={showVote ? "secondary" : "default"}
                      onClick={() => {
                        setShowVote(!showVote);
                        setShowStats(false);
                      }}
                    >
                      {labels["TBL_BANDFILE_BUTTON_VOTE"]}
                    </Button>
                  </Box>
                </Grid>
              </div>
              {showStats && (
                <div id="stats" style={{ width: "100%" }}>
                  <div className={classes.mt1}></div>
                  <Grid item xs={12} container direction="row" justifyContent="center" spacing={1}>
                    {[1, 2, 3, 4, 5].map((index) => (
                      <Grid item key={index}>
                        <HandAvgVoteImg index={index} />
                      </Grid>
                    ))}
                  </Grid>
                  <div className={classes.mt1}></div>
                  <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item xs={4} sm={5} container direction="column">
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{ backgroundColor: "#ef343b", height: 72 }}>
                          <Typography variant="h3" style={{ color: "#fff" }}>
                            {band.total}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            border: "1px solid",
                            borderColor: "#ef343b",
                            height: 70,
                          }}
                        >
                          <Typography variant="h6" align="center" style={{ color: "#ef343b" }}>
                            {band.votes}
                          </Typography>
                          <Typography variant="h6" align="center" style={{ color: "#ef343b" }}>
                            {labels["TBL_COMMON_LABEL_VOTES"]}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={8} sm={7}>
                      <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                        <TblBandChart data={data} />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className={classes.hideBrowser} style={{ width: "100%" }}>
                {showVote && (
                  <div id="vote-browser">
                    <div className={classes.mt1}></div>
                    <TblBandVote data={data} actions={actions} />
                  </div>
                )}
                <div className={classes.mx1}></div>
                <div className={classes.my1}></div>
              </div>
            </Grid>
            <Grid item xs={12} lg={5} xl={4} container direction="column" alignItems="flex-start">
              <div className={classes.hideMobile} style={{ width: "100%" }}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography variant="h4">{band.tblGroup.name}</Typography>
                  </Box>
                  <Box>
                    {!showShare && (
                      <IconButton aria-label="share" onClick={() => setShowShare(!showShare)}>
                        <ShareIcon />
                      </IconButton>
                    )}
                    {showShare && <TblBandShare />}
                  </Box>
                </Box>
              </div>
              <hr className={classes.my1} />
              <Grid item xs={12} container direction="column" alignItems="flex-end">
                <Grid item>
                  <Collapse in={showMore} collapsedSize={150}>
                    <Typography ref={measuredRef} variant="body1" align="justify">
                      {band.description}
                    </Typography>
                  </Collapse>
                </Grid>
                {showMore !== null && (
                  <Grid item>
                    <Button variant="text" color="primary" onClick={() => setShowMore(!showMore)}>
                      {showMore ? labels["TBL_COMMON_BUTTON_LABEL_SHOW_LESS"] : labels["TBL_COMMON_BUTTON_LABEL_SHOW_MORE"]}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <div className={classes.mt1}></div>
              <Grid item xs={12}>
                <TblBandProvinces data={data} />
              </Grid>
              <Grid item xs={12}>
                <TblBandStyles data={data} />
              </Grid>
              <Grid item xs={12}>
                <TblBandLinks data={data} />
              </Grid>
              <div className={classes.mt1}></div>
              <div id="vote-browser" className={classes.hideMobile}>
                <TblBandVote data={data} actions={actions} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <TblBandFileSk />
      )}
      <div className={classes.mb2}></div>
    </React.Fragment>
  );
};

TblBandFile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default TblBandFile;

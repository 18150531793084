import {
  ADD_ELEMENTS_BANDPAGE,
  API_BAND_ADD,
  API_BAND_DELETE,
  API_BAND_EDIT,
  API_BAND_GET,
  API_BAND_GET_BAND,
  API_BAND_GET_RANKING,
  API_BAND_SEARCH,
  API_BAND_VOTEBAND,
  CLEAR_BAND,
  CLEAR_BAND_TABLE,
  NO_ACTION,
  SET_SCREEN_BANDPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tband = {
  bands: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBand = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const bandsPayload = action.payload;
      const bands = bandsPayload.content.map((band) => ({
        seq: band.seqGroupId,
        value: band.tblGroup.codGroup,
        label: band.tblGroup.name,
        photoGroup: band.tblGroup.photoGroup,
      }));
      return { ...state, bands: bands };
    },
    [API_BAND_GET_BAND]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, band: action.payload };
    },
    [API_BAND_VOTEBAND]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const { vote, tourBandPoint } = action.payload;
      const band = state.band;
      band.myVote = vote;
      band.myTourBandPoint = tourBandPoint ? 1 : 0;
      return { ...state, band: band };
    },
    [API_BAND_GET_RANKING]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const bandsPayload = action.payload;
      const bands = bandsPayload.map((band) => ({
        seqGroup: band.seqGroup,
        codGroup: band.codGroup,
        name: band.name,
        photoGroup: band.photoGroup,
        dateHistoric: band.dateHis,
        votes: band.votes,
        scoring: band.scoring,
        avgGroup: band.avgGroup,
      }));
      return { ...state, rankingBands: bands };
    },
    [API_BAND_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbands: {
          ...state.tbands,
          bands: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_BAND_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbands.totalElements += 1;

      return { ...state, tbands: { ...state.tbands, bands: [action.payload].concat(state.tbands?.bands || []) } };
    },
    [API_BAND_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const bandPayload = action.payload;
      const { seqGroupId } = bandPayload;
      const bands = state.tbands?.bands || [];
      const initialValue = [];
      const newBands = bands.map((band) => {
        if (band.seqGroupId === seqGroupId) {
          return bandPayload;
        } else {
          return band;
        }
      }, initialValue);
      return { ...state, tbands: { ...state.tbands, bands: newBands } };
    },
    [API_BAND_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbands.totalElements -= 1;
      return { ...state, tbands: { ...state.tbands, bands: state.tbands.bands.filter((c) => c.seqGroupId !== action.payload) } };
    },
    [ADD_ELEMENTS_BANDPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbands: {
          bands: [...state.tbands.bands, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbands?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BANDPAGE]: (state, action) => ({ ...state, tbands: { ...state.tbands, screenPage: action.payload } }),
    [CLEAR_BAND_TABLE]: (state) => ({ ...state, tbands: initial_tband }),
    [CLEAR_BAND]: (state) => ({ ...state, band: {} }),
  },
  { tbands: initial_tband, bands: [], band: {}, rankingBands: [] }
);

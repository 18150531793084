import React, { useCallback, useMemo, useState } from "react";
import { actionAddBandProvince, actionAddElementsPage, actionDeleteBandProvince, actionSearchBandProvinces, actionSetCurrentPage } from "../../../../actions/bandProvinceActions";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import { TblCountry } from "../../../../model/TblCountry";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupProvince } from "../../../../model/TblGroupProvince";
import TblMaintainPopup from "../../TblMaintainPopup";
import { TblProvince } from "../../../../model/TblProvince";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import { actionGetProvinces } from "../../../../actions/provinceActions";
import { callService } from "../../../../utils/serviceUtils";

const BandProvinceTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const provinces = useSelector((state) => state.tblProvince.provinces);
  const tbandProvinces = useSelector((state) => state.tblBandProvince.tbandProvinces);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const isNoUpdatable = true;

  const { search, serverPage, screenPage, totalElements } = tbandProvinces;
  const { selecProvince, openProvinceAudit } = data;
  const { setSelecProvince, setOpenProvinceAudit } = actions;

  const rows = useMemo(
    () =>
      tbandProvinces.bandProvinces.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupProvince(
            row.seqProvince,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            new TblCountry(row.tblCountry.seqCountry, row.tblCountry.codCountry),
            new TblProvince(row.tblProvince.seqProvince, undefined, row.tblProvince.codProvince, row.tblProvince.desProvince),
            row.dateCre,
            row.userCre
          ),
        ];
      }, []),
    [tbandProvinces.bandProvinces]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_GROUP_PROVINCE"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      if (!values.codProvince) {
        errors.codProvince = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  const handleOpenMaintain = useCallback(() => {
    if (masterSelected?.tblGroup?.tblCountry) {
      callService(dispatch, actionGetProvinces, masterSelected.tblGroup.tblCountry.codCountry);
    }
  }, [dispatch, masterSelected]);

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BANDPROVINCES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecProvince,
      isNoUpdatable,
    }),
    [data, open, headCells, initialValues, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecProvince, isNoUpdatable]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandProvinces,
      actionAdd: actionAddBandProvince,
      actionDelete: actionDeleteBandProvince,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setOpen,
      onValidation: handleValidation,
      setSelected: setSelecProvince,
      beforeOpen: handleOpenMaintain,
    }),
    [actions, handleValidation, setSelecProvince, handleOpenMaintain]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenProvinceAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <TblSelect
              id={headCells[1].id}
              name={headCells[1].column}
              label={labels[headCells[1].label]}
              options={provinces}
              defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_PROVINCE"] }}
            />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openProvinceAudit} setOpen={setOpenProvinceAudit} selected={selecProvince} />
    </div>
  );
};

BandProvinceTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandProvinceTable;

import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const customStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    "&:hover": {
      color: "white",
    },
    "&$active": {
      color: "white",
    },
  },
  active: {},
  icon: {
    color: "inherit !important",
  },
  tableBody: {
    fontSize: 14,
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    lineHeight: 3,
  },
  rowColumn: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    lineHeight: 3,
    width: "5%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TblTableHead = ({ headCells, onSelectAllClick, onRequestSort, order, orderBy, numSelected, rowCount, detailRow }) => {
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {detailRow && <TableCell className={cClasses.rowColumn}></TableCell>}
        <TableCell className={cClasses.rowColumn}>
          <Typography align="center">#</Typography>
        </TableCell>
        <TableCell className={cClasses.head} padding="checkbox">
          {onSelectAllClick ? (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select-all" }}
            />
          ) : (
            ""
          )}
        </TableCell>
        {headCells.map((headCell) => {
          if (!headCell.noShow && !headCell.detailRow) {
            return (
              <TableCell
                className={cClasses.head}
                key={headCell.column}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.column ? order : false}
              >
                <TableSortLabel
                  classes={{ root: cClasses.root, active: cClasses.active, icon: cClasses.icon }}
                  active={orderBy === headCell.path}
                  direction={orderBy === headCell.path ? order : "asc"}
                  onClick={createSortHandler(headCell.path)}
                >
                  {labels[headCell.label]}
                  {orderBy === headCell.path ? <span className={cClasses.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null}
                </TableSortLabel>
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
};

TblTableHead.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      seq: PropTypes.string,
      column: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      label: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
    })
  ).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  detailRow: PropTypes.bool,
};

export default TblTableHead;

import React, { useEffect, useState } from "react";
import { actionClearContestBandTable, actionGetContestBandVotesStats, actionSearchContestBands } from "../../../actions/contestBandActions";
import { actionClearContestantTable, actionSearchContestants } from "../../../actions/contestantActions";
import { useDispatch, useSelector } from "react-redux";

import ContestBandTable from "./ContestBandTable";
import ContestTable from "./ContestTable";
import ContestantTable from "./ContestantTable";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblTabPanel from "../../TblTabPanel";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";
import { useStyles } from "../../../theme/useStyles";

const ContestContainer = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const [value, setValue] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { headCellsContests, headCellsContestants, headCellsContestBands, initialValuesContest, initialValuesContestant, selecContest, childrens } = data;
  const { setSelecContest, setIsLoading, setChildrens } = actions;

  const dispatch = useDispatch();
  useEffect(() => {
    if ((!_.isEmpty(selecContest) && selecContest?.seqContestId !== childrens?.seqContestId) || isUpdated) {
      setIsUpdated(false);
      setIsLoading(true);
      const contestantRQ = {
        codContest: selecContest.tblContest.codContest,
        orderBy: headCellsContestants[1].path,
      };
      const contestBandRQ = {
        codContest: selecContest.tblContest.codContest,
        orderBy: headCellsContestBands[1].path,
      };
      const promises = [callService(dispatch, actionSearchContestants, contestantRQ), callService(dispatch, actionSearchContestBands, contestBandRQ)];
      if (selecContest.tblContest.contestType === "G") {
        const contestBandVotesStatsRQ = {
          codContest: selecContest.tblContest.codContest,
        };
        promises.push(callService(dispatch, actionGetContestBandVotesStats, contestBandVotesStatsRQ));
      } else {
        dispatch(actionClearContestBandTable());
      }
      Promise.all(promises).then((responses) => {
        setChildrens({
          seqContestId: selecContest.seqContestId,
          bands:
            responses[1]?.length > 0
              ? responses[1].map((contestGroup) => ({
                  seq: contestGroup.tblGroup.seqGroup,
                  value: { seq: contestGroup.tblGroup.seqGroup, value: contestGroup.tblGroup.codGroup, label: contestGroup.tblGroup.name },
                }))
              : [],
        });
        setIsLoading(false);
      });
    } else if (_.isEmpty(selecContest) && !_.isEmpty(childrens)) {
      setChildrens({});
      Promise.all([dispatch(actionClearContestantTable()), dispatch(actionClearContestBandTable())]);
    }
  }, [dispatch, selecContest, headCellsContestants, headCellsContestBands, childrens, setChildrens, isUpdated, setIsLoading]);

  return (
    <div>
      <ContestTable headCells={headCellsContests} initialValues={initialValuesContest} selected={selecContest} setSelected={setSelecContest} data={data} actions={actions} />
      <div className={classes.pt2}></div>
      <Paper>
        <Tabs value={value} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="band tabs">
          <Tab label={labels["TBL_BANDCONTAINER_APPBAR_TABPANEL_CONTESTANTS"]} />
          <Tab label={labels["TBL_BANDCONTAINER_APPBAR_TABPANEL_BANDVOTESSTATS"]} />
        </Tabs>
      </Paper>
      <TblTabPanel value={value} index={0}>
        <ContestantTable headCells={headCellsContestants} initialValues={initialValuesContestant} masterSelected={selecContest} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={1}>
        <ContestBandTable headCells={headCellsContestBands} masterSelected={selecContest} data={data} actions={actions} />
      </TblTabPanel>
    </div>
  );
};

ContestContainer.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContestContainer;

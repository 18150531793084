import { useMemo, useState } from "react";

import AdminBandTabs from "./Bands/AdminBandTabs";
import AdminTextsTabs from "./AdminTextTabs";
import ContestTab from "./Contests/ContestTab";
import DomainTab from "./Domains/DomainTab";
import Grid from "@material-ui/core/Grid";
import ImagesPathTab from "./ImagesPaths/ImagesPathTab";
import LangTab from "./Languages/LangTab";
import PlaceTab from "./Places/PlaceTab";
import StyleTab from "./Styles/StyleTab";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblTabPanel from "../TblTabPanel";
import Typography from "@material-ui/core/Typography";
import UserTab from "./Users/UserTab";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const a11yProps = (index) => {
  return {
    id: `tbl-tab-${index}`,
    "aria-controls": `tbl-TblTabPanel-${index}`,
  };
};

const customStyles = makeStyles((theme) => ({
  rootTabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
}));

const MaintainTabs = () => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const cClasses = customStyles();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openAudit, setOpenAudit] = useState(false);
  const [operation, setOperation] = useState("");
  const [title, setTitle] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [previewData, setPreviewData] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const [showPreviewData, setShowPreviewData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [childrens, setChildrens] = useState({});

  const data = useMemo(
    () => ({
      open,
      openAudit,
      operation,
      title,
      isTouched,
      previewData,
      fullScreen,
      showPreviewData,
      isLoading,
      childrens,
    }),
    [open, openAudit, operation, title, isTouched, previewData, fullScreen, showPreviewData, isLoading, childrens]
  );

  const actions = useMemo(
    () => ({
      setOpen,
      setOpenAudit,
      setOperation,
      setTitle,
      setIsTouched,
      setPreviewData,
      setFullScreen,
      setShowPreviewData,
      setIsLoading,
      setChildrens,
    }),
    [setOpen, setOpenAudit, setOperation, setTitle, setIsTouched, setPreviewData, setFullScreen, setShowPreviewData, setIsLoading, setChildrens]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={cClasses.rootTabs} style={{ overflow: "visible" }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="TourBandLive Maintain Tabs"
        className={cClasses.tabs}
      >
        <Tab label={labels["TBL_COMMON_LABEL_GROUPS"]} {...a11yProps(0)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_USER"]} {...a11yProps(1)} />
        <Tab label={labels["TBL_COMMON_LABEL_CONTEST"]} {...a11yProps(2)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_STYLE"]} {...a11yProps(3)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_LOCALE"]} {...a11yProps(4)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_LANGUAGE"]} {...a11yProps(5)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_TEXTS"]} {...a11yProps(6)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_IMAGESPATH"]} {...a11yProps(7)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_DOMAINS"]} {...a11yProps(8)} />
        <Tab label={labels["TBL_MAINTAINTABS_TAB_LABEL_STATS"]} {...a11yProps(9)} />
      </Tabs>
      <TblTabPanel value={value} index={0} fullWidth={true}>
        <AdminBandTabs data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={1} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_USERS"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UserTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={2} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_CONTESTS"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ContestTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={3} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_STYLES"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyleTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={4} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_COUNTRIES"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PlaceTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={5} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_LANGUAGES"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LangTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={6} fullWidth={true}>
        <AdminTextsTabs data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={7} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_IMAGESPATH"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImagesPathTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={8} fullWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_DOMAINS"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DomainTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={9} fullWidth={true}>
        Item Ten
      </TblTabPanel>
    </div>
  );
};

export default MaintainTabs;

import {
  ADD_ELEMENTS_TEMPLATEPAGE,
  API_TEMPLATE_ADD,
  API_TEMPLATE_DELETE,
  API_TEMPLATE_EDIT,
  API_TEMPLATE_GET,
  API_TEMPLATE_SEARCH,
  CLEAR_TEMPLATE_TABLE,
  SET_SCREEN_TEMPLATEPAGE,
} from "../constants";
import { apiAddTemplate, apiDeleteTemplate, apiGetTemplates, apiSearchTemplates, apiUpdateTemplate } from "../api/templateAPI";
import { urlAddTemplate, urlDeleteTemplate, urlGetTemplates, urlSearchTemplates, urlUpdateTemplate } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_TEMPLATEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_TEMPLATEPAGE, (template) => apiSearchTemplates(urlSearchTemplates, template)());
export const actionGetTemplates = createAction(API_TEMPLATE_GET, apiGetTemplates(urlGetTemplates));
export const actionSearchTemplates = createAction(API_TEMPLATE_SEARCH, (template) => apiSearchTemplates(urlSearchTemplates, template)());
export const actionAddTemplate = createAction(API_TEMPLATE_ADD, (template) => apiAddTemplate(urlAddTemplate, template)());
export const actionUpdateTemplate = createAction(API_TEMPLATE_EDIT, (template) => apiUpdateTemplate(urlUpdateTemplate, template)());
export const actionDeleteTemplate = createAction(API_TEMPLATE_DELETE, ({ seqTextId }) => apiDeleteTemplate(urlDeleteTemplate, seqTextId)());
export const actionClearTemplateTable = createAction(CLEAR_TEMPLATE_TABLE);

export class TblLanguage {
  constructor(seqLanguage, codLang, desLang, dateCre, dateMod, userCre, userMod) {
    this.seqLanguage = seqLanguage;
    this.codLang = codLang;
    this.desLang = desLang;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { doGet, searchData } from "../utils/serviceUtils";

export const apiSearchContestBands = (url, obj) => async () => {
  const { codContest } = obj;
  url = `${url}?codContest=${codContest}`;
  return searchData(url, obj);
};

export const apiGetContestBands = (url, obj) => async () => {
  const { codContest } = obj;
  url = `${url}?codContest=${codContest}`;
  const { data } = await doGet(url);
  return data;
};

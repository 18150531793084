import {
  ADD_ELEMENTS_CONTESTPAGE,
  API_ACTIVE_CONTEST,
  API_CONTEST_ADD,
  API_CONTEST_DELETE,
  API_CONTEST_EDIT,
  API_CONTEST_GET,
  API_CONTEST_SEARCH,
  API_FINISHED_CONTEST,
  API_MY_CONTEST,
  API_UPCOMING_CONTEST,
  CLEAR_CONTEST_TABLE,
  SET_SCREEN_CONTESTPAGE,
} from "../constants";
import { apiAddContest, apiDeleteContest, apiGetContests, apiGetMyContests, apiSearchContests, apiUpdateContest } from "../api/contestAPI";
import {
  urlAddContest,
  urlDeleteContest,
  urlGetActiveContests,
  urlGetContests,
  urlGetFinishedContests,
  urlGetMyContests,
  urlGetUpcomingContests,
  urlSearchContests,
  urlUpdateContest,
} from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_CONTESTPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_CONTESTPAGE, (contest) => apiSearchContests(urlSearchContests, contest)());
export const actionGetContests = createAction(API_CONTEST_GET, apiGetContests(urlGetContests));
export const actionSearchContests = createAction(API_CONTEST_SEARCH, (contest) => apiSearchContests(urlSearchContests, contest)());
export const actionGetActiveContests = createAction(API_ACTIVE_CONTEST, apiGetContests(urlGetActiveContests));
export const actionGetUpcomingContests = createAction(API_UPCOMING_CONTEST, apiGetContests(urlGetUpcomingContests));
export const actionGetFinishedContests = createAction(API_FINISHED_CONTEST, apiGetContests(urlGetFinishedContests));
export const actionGetMyContests = createAction(API_MY_CONTEST, (active) => apiGetMyContests(urlGetMyContests, active)());
export const actionAddContest = createAction(API_CONTEST_ADD, (contest) => apiAddContest(urlAddContest, contest)());
export const actionUpdateContest = createAction(API_CONTEST_EDIT, (contest) => apiUpdateContest(urlUpdateContest, contest)());
export const actionDeleteContest = createAction(API_CONTEST_DELETE, ({ seqContestId }) => apiDeleteContest(urlDeleteContest, seqContestId)());
export const actionClearContestTable = createAction(CLEAR_CONTEST_TABLE);

import { Field, useFormikContext } from "formik";

import { CheckboxWithLabel } from "formik-material-ui";
import PropTypes from "prop-types";

const TblCheckBoxLabel = ({ id, name, label, properties, disabled }) => {
  const { isSubmitting } = useFormikContext();
  const { type } = properties;

  return <Field component={CheckboxWithLabel} id={id} name={name} Label={{ label: label }} type={type} disabled={isSubmitting || disabled} />;
};

TblCheckBoxLabel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  properties: PropTypes.object,
  disabled: PropTypes.bool,
};

export default TblCheckBoxLabel;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { actionAddContest, actionAddElementsPage, actionDeleteContest, actionSearchContests, actionSetCurrentPage, actionUpdateContest } from "../../../../actions/contestActions";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { Properties } from "../../../../constants/properties";
import Skeleton from "@material-ui/lab/Skeleton";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import TblCircularProgress from "../../../TblCircularProgress";
import TblContestGenerateWinnersPopup from "../../../TblPopups/TblContestGenerateWinnersPopup/TblContestGenerateWinnersPopup";
import { TblContestId } from "../../../../model/TblContestId";
import TblDateTimePicker from "../../../TblForms/TblDateTimePicker";
import TblHtmlView from "../../../TblForms/TblHtmlView";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import TblUploadFile from "../../../TblForms/TblUploadFile";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import _ from "lodash";
import { actionGenerateContestWinners } from "../../../../actions/contestantActions";
import { actionGetBands } from "../../../../actions/bandActions";
import { actionGetDomain } from "../../../../actions/domainActions";
import { callService } from "../../../../utils/serviceUtils";
import { getByPath } from "../../../../utils/dataUtils";
import { useStyles } from "../../../../theme/useStyles";

const ContestTable = ({ headCells, initialValues, selected, setSelected, data, actions }) => {
  const classes = useStyles();
  const userSession = useSelector((state) => state.tblUser.userSession);
  const labels = useSelector((state) => state.tblLabel.labels);
  const tcontests = useSelector((state) => state.tblContest.tcontests);
  const contestTypeOptions = useSelector((state) => state.tblDomain.CONTEST_TYPE);
  const indYesNoOptions = useSelector((state) => state.tblDomain.IND_YES_NO);
  const voteRangeOptions = useSelector((state) => state.tblDomain.CONTEST_VOTERANGE);
  const langs = useSelector((state) => state.tblLang.langs);
  const bands = useSelector((state) => state.tblBand.bands);
  const dispatch = useDispatch();

  const { search, serverPage, screenPage, totalElements } = tcontests;
  const { openAudit, previewData, showPreviewData, isLoading } = data;
  const { setOpenAudit, setPreviewData, setShowPreviewData } = actions;
  const suggestBandsInputText = useRef(null);
  const [openGWPopup, setOpenGWPopup] = useState(false);

  const rows = useMemo(
    () =>
      tcontests.contests.reduce((acc, row) => {
        return [
          ...acc,
          new TblContestId(row.seqContestId, row.tblContest, row.tblLanguage, row.summary, row.description, undefined, row.dateCre, row.dateMod, row.userCre, row.userMod),
        ];
      }, []),
    [tcontests.contests]
  );

  const [cType, setCType] = useState("");
  const [loadingBands, setLoadingBands] = useState(false);
  const [timeoutSearch, setTimeoutSearch] = useState(0);
  // Cropper
  const [imageSrc, setImageSrc] = useState(null);

  const tableLabel = labels["TBL_ENTITY_TBL_CONTEST"];

  if (!voteRangeOptions || voteRangeOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "CONTEST_VOTERANGE", userLang: userSession.userLang });
  }

  useEffect(() => {
    if (!_.isEmpty(selected)) {
      setCType(selected.tblContest.contestType);
    }
  }, [selected]);

  const searchBands = useCallback(
    (name) => {
      return new Promise((resolve) => {
        const searchBandRQ = {
          name: name,
          orderBy: "tblGroup.name",
        };
        callService(dispatch, actionGetBands, searchBandRQ).finally(() => resolve());
      });
    },
    [dispatch]
  );

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { contestType, contestCodeRequired, name, description, startDate, endDate, codLang, voteRange, bands, file } = values;
      if (!contestType) {
        errors.contestType = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!contestCodeRequired) {
        errors.contestCodeRequired = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!name) {
        errors.name = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!description) {
        errors.description = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!startDate) {
        errors.startDate = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!endDate) {
        errors.endDate = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!codLang) {
        errors.codLang = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (cType === "G") {
        if (!voteRange) {
          errors.voteRange = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
        }
        if (bands.length < 1) {
          errors.bands = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
        }
      }
      if (file && !Properties.SUPPORTED_FORMATS.includes(file.type)) {
        errors.file = labels["TBL_COMMON_INVALIDINPUT_FILEFORMAT"];
      }
      return errors;
    },
    [labels, cType]
  );

  const handleBeforeSubmit = useCallback(
    (values) => {
      values.croppedImage = imageSrc;
    },
    [imageSrc]
  );

  const handleBeforeOpen = useCallback(() => {
    setImageSrc(null);
    searchBands("");
  }, [searchBands]);

  const handleChangeCType = useCallback((event) => {
    setCType(event.target.value.value);
  }, []);

  const handleChangeBands = useCallback(
    (event) => {
      const name = event.target.value;
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
      setTimeoutSearch(
        setTimeout(() => {
          setLoadingBands(true);
          searchBands(name).finally(() => {
            setLoadingBands(false);
            suggestBandsInputText.current.focus();
          });
        }, 500)
      );
    },
    [searchBands, timeoutSearch]
  );

  const handleSelectBands = useCallback(() => {
    setLoadingBands(true);
    searchBands("").finally(() => {
      setLoadingBands(false);
      suggestBandsInputText.current.focus();
    });
  }, [searchBands]);

  const handleGenerateWinners = useCallback(
    (numWinners) => {
      callService(dispatch, actionGenerateContestWinners, { codContest: selected.tblContest.codContest, numWinners: numWinners }).finally(() => setOpenGWPopup(false));
    },
    [dispatch, selected]
  );

  const handleOpenGenerateWinnersPopup = useCallback(() => {
    if (!_.isEmpty(selected)) {
      setOpenGWPopup(true);
    }
  }, [selected]);

  const menuActions = <MenuItem onClick={handleOpenGenerateWinnersPopup}>{labels["TBL_CONTEST_ACTION_LABEL_GENERATE_WINNER"]}</MenuItem>;

  data = useMemo(
    () => ({
      ...data,
      headCells,
      initialValues,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_CONTESTS"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected,
      imageSrc,
    }),
    [data, headCells, initialValues, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selected, imageSrc]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchContests,
      actionAdd: actionAddContest,
      actionUpdate: actionUpdateContest,
      actionDelete: actionDeleteContest,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
      setSelected,
      setImageSrc,
      beforeSubmit: handleBeforeSubmit,
      beforeOpen: handleBeforeOpen,
    }),
    [actions, handleValidation, handleBeforeSubmit, handleBeforeOpen, setSelected]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit} menuActions={menuActions}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{labels[headCells[15].label]}:</Typography>
            {imageSrc || getByPath(selected, headCells[15].path) ? (
              <img alt="contest-img" src={imageSrc || getByPath(selected, headCells[15].path)} style={{ maxHeight: 300 }} />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TblUploadFile id={headCells[15].id} name={headCells[15].column} setImageSrc={setImageSrc} properties={headCells[15].properties} />
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item xs={5}>
              <TblSelect
                id={headCells[3].id}
                name={headCells[3].column}
                label={labels[headCells[3].label]}
                options={contestTypeOptions}
                onSelect={handleChangeCType}
                defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
              />
            </Grid>
            <Grid item xs={5}>
              <TblSelect
                id={headCells[4].id}
                name={headCells[4].column}
                label={labels[headCells[4].label]}
                options={indYesNoOptions}
                defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[18].id} name={headCells[18].column} label={labels[headCells[18].label]} properties={headCells[18].properties} />
          </Grid>
          <Grid item container spacing={3} xs={12}>
            <Grid item container direction="column" alignItems="flex-end" xs={showPreviewData ? 6 : 12}>
              {showPreviewData ? (
                <IconButton onClick={() => setShowPreviewData(false)}>
                  <VisibilityOffIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setShowPreviewData(true)}>
                  <VisibilityIcon />
                </IconButton>
              )}
              <TblTextField
                id={headCells[16].id}
                name={headCells[16].column}
                label={labels[headCells[16].label]}
                setPreviewData={setPreviewData}
                properties={headCells[16].properties}
              />
            </Grid>
            {showPreviewData && (
              <Grid item xs={6}>
                <TblHtmlView data={previewData} />
              </Grid>
            )}
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item xs={5}>
              <TblDateTimePicker id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} />
            </Grid>
            <Grid item xs={5}>
              <TblDateTimePicker id={headCells[8].id} name={headCells[8].column} label={labels[headCells[8].label]} properties={headCells[8].properties} />
            </Grid>
          </Grid>
          {cType === "G" && (
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                {!isLoading ? (
                  <div>
                    <TblAutocomplete
                      id={headCells[17].id}
                      name={headCells[17].column}
                      disabled={loadingBands}
                      inputRef={suggestBandsInputText}
                      isPaginated={true}
                      label={labels[headCells[17].label]}
                      onChange={handleChangeBands}
                      onSelect={handleSelectBands}
                      properties={headCells[17].properties}
                      options={bands}
                    />
                  </div>
                ) : (
                  <Skeleton variant="rect" className={classes.skeletonInput} />
                )}
              </Grid>
              <Grid item xs={1}>
                <span>{loadingBands && <TblCircularProgress />}</span>
              </Grid>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[5].id}
                  name={headCells[5].column}
                  label={labels[headCells[5].label]}
                  options={voteRangeOptions}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <TblTextField id={headCells[10].id} name={headCells[10].column} label={labels[headCells[10].label]} properties={headCells[10].properties} />
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <Grid item xs={5}>
              <TblSelect
                id={headCells[14].id}
                name={headCells[14].column}
                label={labels[headCells[14].label]}
                options={langs}
                defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
              />
            </Grid>
            <Grid item xs={5}>
              <TblCheckBoxLabel id={headCells[9].id} name={headCells[9].column} label={labels[headCells[9].label]} properties={headCells[9].properties} />
            </Grid>
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
      <TblContestGenerateWinnersPopup open={openGWPopup} setOpen={setOpenGWPopup} onAction={handleGenerateWinners} />
    </div>
  );
};

ContestTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContestTable;

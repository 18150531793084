import React, { useMemo } from "react";
import { actionAddElementsPage2, actionSearchBandUserVotes, actionSetCurrentPage } from "../../../../actions/bandUserVoteActions";

import PropTypes from "prop-types";
import TblCards from "../../TblCards";
import { TblContestGroup } from "../../../../model/TblContestGroup";
import { TblGroup } from "../../../../model/TblGroup";
import TblTable from "../../TblTable";
import { useSelector } from "react-redux";

const ContestBandTable = ({ headCells, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tcontestBandVotesStats = useSelector((state) => state.tblContestBand.tcontestBandVotesStats);

  const isReadOnly = true;

  const { search, serverPage, screenPage, totalElements } = tcontestBandVotesStats;
  const { selecContestBand } = data;
  const { setSelecContestBand } = actions;

  const rows = useMemo(
    () =>
      tcontestBandVotesStats.stats.reduce((acc, row) => {
        return [...acc, new TblContestGroup(row.id, undefined, new TblGroup(undefined, undefined, row.code), undefined, row.avgColumn, row.countColumn)];
      }, []),
    [tcontestBandVotesStats.stats]
  );

  data = useMemo(
    () => ({
      ...data,
      headCells,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BANDUSER_VOTES"],
      rows,
      tableLabel: "",
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecContestBand,
      isReadOnly,
    }),
    [data, headCells, masterSelected, labels, rows, search, serverPage, screenPage, totalElements, selecContestBand, isReadOnly]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandUserVotes,
      actionAddElementsPage: actionAddElementsPage2,
      setScreenPage: actionSetCurrentPage,
      setSelected: setSelecContestBand,
    }),
    [actions, setSelecContestBand]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} hideActionsMenu={true}>
        <TblTable data={data} actions={actions} />
      </TblCards>
    </div>
  );
};

ContestBandTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContestBandTable;

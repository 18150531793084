import {
  ADD_ELEMENTS_BANDPAGE,
  API_BAND_ADD,
  API_BAND_DELETE,
  API_BAND_EDIT,
  API_BAND_GET,
  API_BAND_GET_BAND,
  API_BAND_GET_RANKING,
  API_BAND_SEARCH,
  API_BAND_VOTEBAND,
  CLEAR_BAND,
  CLEAR_BAND_TABLE,
  NO_ACTION,
  SET_SCREEN_BANDPAGE,
} from "../constants";
import {
  apiAddBand,
  apiAddPublicBand,
  apiDeleteBand,
  apiFilterBands,
  apiGetBand,
  apiGetBands,
  apiGetRankingBands,
  apiSearchBands,
  apiUpdateBand,
  apiVoteBand,
} from "../api/bandAPI";
import { urlAddBand, urlAddPublicBand, urlDeleteBand, urlFilterBands, urlGetBands, urlGetRankingBands, urlSearchBands, urlUpdateBand, urlVoteBand } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BANDPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BANDPAGE, (band) => apiSearchBands(urlSearchBands, band)());
export const actionGetBands = createAction(API_BAND_GET, (name) => apiGetBands(urlGetBands, name)());
export const actionGetBand = createAction(API_BAND_GET_BAND, (band) => apiGetBand(urlGetBands, band)());
export const actionSearchBands = createAction(API_BAND_SEARCH, (band) => apiSearchBands(urlSearchBands, band)());
export const actionFilterBands = createAction(API_BAND_SEARCH, (band) => apiFilterBands(urlFilterBands, band)());
export const actionAddBand = createAction(API_BAND_ADD, (band) => apiAddBand(urlAddBand, band)());
export const actionUpdateBand = createAction(API_BAND_EDIT, (band) => apiUpdateBand(urlUpdateBand, band)());
export const actionDeleteBand = createAction(API_BAND_DELETE, ({ seqGroupId }) => apiDeleteBand(urlDeleteBand, seqGroupId)());
export const actionAddPublicBand = createAction(NO_ACTION, (band) => apiAddPublicBand(urlAddPublicBand, band)());
export const actionClearBandTable = createAction(CLEAR_BAND_TABLE);
export const actionClearBand = createAction(CLEAR_BAND);
export const actionGetRankingBands = createAction(API_BAND_GET_RANKING, apiGetRankingBands(urlGetRankingBands));
export const actionVoteBand = createAction(API_BAND_VOTEBAND, (myVote) => apiVoteBand(urlVoteBand, myVote)());

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";

import _ from "lodash";
import { actionGetBand } from "../../actions/bandActions";
import { actionGetBandReviews } from "../../actions/bandReviewActions";
import { actionGetBandVideos } from "../../actions/bandVideoActions";
import { callService } from "../../utils/serviceUtils";
import { useParams } from "react-router-dom";

export const useBandDetails = () => {
  const band = useSelector((state) => state.tblBand.band);
  const bandVideos = useSelector((state) => state.tblBandVideo.bandVideos);
  const bandReviews = useSelector((state) => state.tblBandReview.bandReviews);
  const dispatch = useDispatch();
  const topRef = useRef(null);

  const { codGroup } = useParams();

  const [tab, setTab] = useState(0);
  const [videos, setVideos] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!isLoading && _.isEmpty(band)) {
      setIsLoading(true);
      Promise.all([
        callService(dispatch, actionGetBand, codGroup),
        callService(dispatch, actionGetBandVideos, codGroup),
        callService(dispatch, actionGetBandReviews, codGroup),
      ]).then((responses) => {
        setIsLoading(false);
        if (responses[1]) {
          setVideos(responses[1].length);
        }
        if (responses[2]) {
          setReviews(responses[2].length);
        }
      });
    }
  }, [band, codGroup, dispatch, bandReviews, bandVideos, isLoading]);

  const data = useMemo(
    () => ({
      topRef,
      tab,
      videos,
      reviews,
      isLoading,
    }),
    [isLoading, reviews, tab, videos]
  );

  const actions = useMemo(
    () => ({
      setTab,
      setVideos,
      setReviews,
      setIsLoading,
      handleChange,
    }),
    []
  );

  return { data, actions };
};

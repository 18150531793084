import ErrorMessageTab from "./ErrorMessages/ErrorMessageTab";
import Grid from "@material-ui/core/Grid";
import LabelTab from "./Labels/LabelTab";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblTabPanel from "../TblTabPanel";
import TemplateTab from "./Templates/TemplateTab";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { useState } from "react";

const AdminTextsTabs = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Paper>
        <Tabs value={value} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="texts tabs">
          <Tab label={labels["TBL_TEXTSPAGE_APPBAR_TABPANEL_TEMPLATES"]} />
          <Tab label={labels["TBL_TEXTSPAGE_APPBAR_TABPANEL_LABELS"]} />
          <Tab label={labels["TBL_TEXTSPAGE_APPBAR_TABPANEL_ERRORMSG"]} />
        </Tabs>
      </Paper>
      <TblTabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_TEMPLATE"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TemplateTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_LABEL"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LabelTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_ERRORMSG"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ErrorMessageTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
    </div>
  );
};

AdminTextsTabs.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default AdminTextsTabs;

import { Form, Formik } from "formik";

import ActionButtons from "../ActionButtons";
import Grid from "@material-ui/core/Grid";
import React from "react";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblTextField from "../../../TblForms/TblTextField";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const Step2 = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const provinceOptions = useSelector((state) => state.tblProvince.provinces);
  const styleOptions = useSelector((state) => state.tblStyle.styles);

  const { initialValues, headCells, activeStep, steps } = data;
  const { handleValidationStep2, handleSubmit, handleBack } = actions;

  return (
    <Formik initialValues={initialValues} validate={handleValidationStep2} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid container item xs={10} md={8} lg={4}>
              <TblTextField id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} />
            </Grid>
            <div className={classes.pt1}></div>
            <Grid container item xs={10} md={8} lg={4}>
              <TblAutocomplete id={headCells[4].id} name={headCells[4].column} label={labels[headCells[4].label]} properties={headCells[4].properties} options={provinceOptions} />
            </Grid>
            <div className={classes.pt1}></div>
            <Grid container item xs={10} md={8} lg={4}>
              <TblAutocomplete id={headCells[9].id} name={headCells[9].column} label={labels[headCells[9].label]} properties={headCells[9].properties} options={styleOptions} />
            </Grid>
          </Grid>
          <ActionButtons isSubmitting={isSubmitting} activeStep={activeStep} steps={steps} handleBack={handleBack} />
        </Form>
      )}
    </Formik>
  );
};

export default Step2;

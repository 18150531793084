import { doDelete, doPost, searchData } from "../utils/serviceUtils";

import { TblGroup } from "../model/TblGroup";
import { TblGroupStyle } from "../model/TblGroupStyle";
import { TblStyle } from "../model/TblStyle";

export const apiSearchBandStyles = (url, obj) => async () => {
  const { codGroup } = obj;
  url = `${url}?codGroup=${codGroup}`;
  return searchData(url, obj);
};

export const apiAddBandStyle = (url, obj) => async () => {
  const { seqStyle, tblGroup, codStyle } = obj;
  const request = new TblGroupStyle(seqStyle, new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name), new TblStyle(codStyle.seq, codStyle.value, codStyle.label));
  const { data } = await doPost(url, request);
  return data;
};

export const apiDeleteBandStyle = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

export class TblGroupVideo {
  constructor(seqVideo, tblGroup, videoId, urlVideo, reviewed, dateCre, dateMod, userCre, userMod) {
    this.seqVideo = seqVideo;
    this.tblGroup = tblGroup;
    this.videoId = videoId;
    this.urlVideo = urlVideo;
    this.reviewed = reviewed;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import {
  ADD_ELEMENTS_TEMPLATEPAGE,
  API_TEMPLATE_ADD,
  API_TEMPLATE_DELETE,
  API_TEMPLATE_EDIT,
  API_TEMPLATE_GET,
  API_TEMPLATE_SEARCH,
  CLEAR_TEMPLATE_TABLE,
  NO_ACTION,
  SET_SCREEN_TEMPLATEPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_ttemplate = {
  templates: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblTemplate = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_TEMPLATE_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const templatesPayload = action.payload;
      const initialValue = {};
      const templates = templatesPayload.reduce((acc, template) => {
        return { ...acc, [template.tblText.codText]: template.desText };
      }, initialValue);
      return { ...state, templates: templates };
    },
    [API_TEMPLATE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        ttemplates: {
          templates: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_TEMPLATE_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.ttemplates.totalElements += 1;

      return { ...state, ttemplates: { ...state.ttemplates, templates: [action.payload].concat(state.ttemplates?.templates || []) } };
    },
    [API_TEMPLATE_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const templatePayload = action.payload;
      const { seqTextId } = templatePayload;
      const templates = state.ttemplates?.templates || [];
      const initialValue = [];
      const newTemplates = templates.map((template) => {
        if (template.seqTextId === seqTextId) {
          return templatePayload;
        } else {
          return template;
        }
      }, initialValue);
      return { ...state, ttemplates: { ...state.ttemplates, templates: newTemplates } };
    },
    [API_TEMPLATE_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.ttemplates.totalElements -= 1;
      return { ...state, ttemplates: { ...state.ttemplates, templates: state.ttemplates.templates.filter((c) => c.seqTextId !== action.payload) } };
    },
    [ADD_ELEMENTS_TEMPLATEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        ttemplates: {
          templates: [...state.ttemplates.templates, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.ttemplates?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_TEMPLATEPAGE]: (state, action) => ({ ...state, ttemplates: { ...state.ttemplates, screenPage: action.payload } }),
    [CLEAR_TEMPLATE_TABLE]: (state) => ({ ...state, ttemplates: initial_ttemplate }),
  },
  { ttemplates: initial_ttemplate, templates: [] }
);

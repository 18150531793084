export class TblTextId {
  constructor(seqTextId, tblText, desText, tblLanguage, dateCre, dateMod, userCre, userMod) {
    this.seqTextId = seqTextId;
    this.tblText = tblText;
    this.desText = desText;
    this.tblLanguage = tblLanguage;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

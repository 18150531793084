import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from "../constants";

import { createAction } from "redux-actions";

export const actionEnqueueSnackbar = createAction(ENQUEUE_SNACKBAR, (notification) => {
  const key = notification.options && notification.options.key;
  switch (notification.message) {
    case "Access Denied":
      notification.message = "No tienes acceso al recurso solicidato, por favor inicia sesión";
      break;
    case "Forbidden":
      notification.message = "No tienes permisos para realizar esta acción, contacte con el Administrador";
      break;
    case "Authentication failed: bad credentials":
      notification.message = "El usuario o la contraseña son incorrectos";
      break;
    case "Unexpected error":
      notification.message = "Servicio no disponible, intentelo más tarde";
      break;
    default:
      break;
  }

  return {
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
});

export const actionCloseSnackbar = createAction(CLOSE_SNACKBAR, (key) => ({
  dismissAll: !key,
  key,
}));

export const actionRemoveSnackbar = createAction(REMOVE_SNACKBAR, (key) => key);

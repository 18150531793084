import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblLabel } from "../model/TblLabel";
import { TblLabelId } from "../model/TblLabelId";
import { TblLanguage } from "../model/TblLanguage";

export const apiGetLabels = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchLabels = (url, obj) => async () => {
  const { codLabel, desLabel, codLang } = obj;
  url = `${url}?codLabel=${codLabel}&desLabel=${desLabel}&codLang=${codLang?.value ?? ""}`;
  return searchData(url, obj);
};

export const apiAddLabel = (url, obj) => async () => {
  const { seqLabelId, codLabel, desLabel, codLang } = obj;
  const request = new TblLabelId(seqLabelId, new TblLabel(undefined, codLabel), desLabel, new TblLanguage(codLang.seq, codLang.value, codLang.label));
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateLabel = (url, obj) => async () => {
  const { seqLabelId, codLabel, desLabel, codLang, tblLabel, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqLabelId}`;
  const request = new TblLabelId(
    seqLabelId,
    new TblLabel(tblLabel.seqLabel, codLabel, dateCre, dateMod, userCre, userMod),
    desLabel,
    new TblLanguage(codLang.seq, codLang.value, codLang.label),
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteLabel = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

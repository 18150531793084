import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblCardNoContest from "../TblCardNoContest";
import TblGridContests from "../TblGridContests";
import TblGridSkeleton from "../TblGridContests/TblGridSkeleton";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const TblUpcomings = ({ topRef, loading }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const templates = useSelector((state) => state.tblTemplate.templates);
  const upcomingContests = useSelector((state) => state.tblContest.upcomingContests);

  return (
    <React.Fragment>
      <Grid container direction="column" alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {labels["TBL_CONTEST_TABPANEL_TITLE_UPCOMING"]}
          </Typography>
          <hr />
        </Grid>
        {!loading ? (
          upcomingContests.length > 0 ? (
            <TblGridContests topRef={topRef} gridList={upcomingContests || []} loading={loading} />
          ) : (
            <TblCardNoContest image={"/images/application/tblimagecontestnoupcoming.jpg"} text={templates["TBL_CONTEST_NO_UPCOMING_CARD"]} topRef={topRef} />
          )
        ) : (
          <TblGridSkeleton />
        )}
      </Grid>
    </React.Fragment>
  );
};

PropTypes.TblBandReview = {
  topRef: PropTypes.object.isRequired,
};

export default TblUpcomings;

import { isMobileOnly } from "react-device-detect";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  /* Common */
  pTopPage: {
    paddingTop: "64px",
  },
  pb1: {
    paddingBottom: "1.5rem",
  },
  pt1: {
    paddingTop: "1.5rem",
  },
  pr1: {
    paddingRight: "1.5rem",
  },
  pl1: {
    paddingLeft: "1.5rem",
  },
  py1: {
    paddingBottom: "1.5rem",
    paddingTop: "1.5rem",
  },
  px1: {
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
  },
  pb2: {
    paddingBottom: "3rem",
  },
  pt2: {
    paddingTop: "3rem",
  },
  pr2: {
    paddingRight: "3rem",
  },
  pl2: {
    paddingLeft: "3rem",
  },
  py2: {
    paddingBottom: "3rem",
    paddingTop: "3rem",
  },
  py3: {
    paddingBottom: "6rem",
    paddingTop: "6rem",
  },
  px2: {
    paddingRight: "3rem",
    paddingLeft: "3rem",
  },
  mbxs: {
    marginBottom: "0.5rem",
  },
  mtxs: {
    marginTop: "0.5rem",
  },
  mrxs: {
    marginRight: "0.5rem",
  },
  mlxs: {
    marginLeft: "0.5rem",
  },
  myxs: {
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
  },
  mxxs: {
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
  mb1: {
    marginBottom: "1.5rem",
  },
  mt1: {
    marginTop: "1.5rem",
  },
  mr1: {
    marginRight: "1.5rem",
  },
  ml1: {
    marginLeft: "1.5rem",
  },
  my1: {
    marginBottom: "1.5rem",
    marginTop: "1.5rem",
  },
  mx1: {
    marginRight: "1.5rem",
    marginLeft: "1.5rem",
  },
  mb2: {
    marginBottom: "3rem",
  },
  mt2: {
    marginTop: "3rem",
  },
  mr2: {
    marginRight: "3rem",
  },
  ml2: {
    marginLeft: "3rem",
  },
  my2: {
    marginBottom: "3rem",
    marginTop: "3rem",
  },
  mx2: {
    marginRight: "3rem",
    marginLeft: "3rem",
  },
  textWhite: {
    color: "#fff",
  },
  textPrimary: {
    color: "#ef343b",
  },
  borderWhite: {
    borderColor: "#fff",
  },
  borderPrimary: {
    borderPrimary: "#ef343b",
  },
  typographyAlignIcon: {
    verticalAlign: "middle",
  },
  displayInline: {
    display: "inline !important",
  },
  bgPrimary: {
    backgroundColor: "#ef343b !important",
  },
  bgTexture: {
    backgroundImage: `url("/images/application/tblimagebgheader.jpg")`,
    paddingBottom: isMobileOnly ? 0 : 20,
  },
  bgDark: {
    backgroundColor: "#212529 !important",
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  divider: {
    width: "90% !important",
  },
  displayNone: {
    display: "none",
  },
  fullWidth: {
    width: "100%",
  },
  hideMobile: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  hideBrowser: {
    display: "block",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  /* Thumb */
  photoMaintainMaxHeight: {
    maxHeight: 300,
  },

  /* Skeleton sizes */
  skeletonInput: {
    height: "56px !important",
  },

  /* TblForms */
  textarea: {
    resize: "both",
    overflow: "scroll",
  },

  /* TblConfirmPopup */
  confirmHeader: {
    backgroundColor: "#ffc107",
  },

  /* Info dialog title */
  infoDialogTitle: {
    color: "#fff",
    backgroundColor: "#17a2b8",
  },
}));

import { Form, Formik } from "formik";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";
import ImageList from "@material-ui/core/ImageList";
import Pagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import { ROLE_PUBLIC } from "../../../../constants";
import React from "react";
import TblAddBandVideo from "../../TblAddBandVideo";
import TblCircularProgress from "../../../TblCircularProgress";
import TblLinearProgress from "../../../TblLinearProgress";
import Typography from "@material-ui/core/Typography";
import YouTube from "react-youtube";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core";
import useBandVideo from "../../../../hooks/TblBand/useBandVideo";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const customStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    cursor: "pointer",
  },
}));

const TblBandVideo = ({ topRef, setVideos, isLoading }) => {
  const classes = useStyles();
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const band = useSelector((state) => state.tblBand.band);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const bandVideos = useSelector((state) => state.tblBandVideo.bandVideos);

  const { data, actions, UpdateForm } = useBandVideo(topRef);
  const { editMode, paginVideos, cols, page, initialValues } = data;
  const { handleAddBandVideo, handleChange, handleValidation, handleSubmit } = actions;

  const height = isMobile ? 180 : 360;
  const width = isMobile ? 320 : 640;

  const Video = ({ item }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <YouTube videoId={item.videoId} opts={{ width: width, height: height }} />
      </Box>
    );
  };

  if (!editMode) {
    return (
      <React.Fragment>
        {!isLoading ? (
          <Grid container direction="column" alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                {band?.tblGroup?.name} - {labels["TBL_COMMON_LABEL_BANDVIDEOS"]}
              </Typography>
              <hr className={classes.my1} />
            </Grid>
            {userSession.applicationRole.includes(ROLE_PUBLIC) && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button endIcon={<AddCircleIcon />} onClick={handleAddBandVideo}>
                  {labels["TBL_BANDVIDEO_BUTTON_LABEL_ADDBANDVIDEO"]}
                </Button>
              </Box>
            )}
            <div className={classes.mt1}></div>
            <Grid item xs={10}>
              <div className={cClasses.root}>
                <ImageList rowHeight="auto" cols={cols} gap={16}>
                  {paginVideos.map((item, i) => (
                    <GridListTile key={item.seqVideo} className={cClasses.item}>
                      <Video key={i} item={item} />
                    </GridListTile>
                  ))}
                </ImageList>
              </div>
            </Grid>
            <div className={classes.py1}></div>
            {paginVideos.length > 0 && <Pagination count={Math.ceil(bandVideos.length / 4)} page={page} onChange={handleChange} variant="outlined" color="secondary" />}
          </Grid>
        ) : (
          <Grid container direction="column" alignContent="center" alignItems="center">
            <TblCircularProgress />
          </Grid>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid container direction="column" alignContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {band?.tblGroup?.name} - {labels["TBL_COMMON_LABEL_BANDVIDEOS"]}
            </Typography>
            <hr className={classes.my1} />
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} validate={handleValidation} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <TblAddBandVideo data={data} actions={actions} component={UpdateForm} />
              </Grid>
              <div className={classes.pt1}></div>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <Button disabled={isSubmitting} onClick={handleAddBandVideo} className={classes.backButton}>
                    {labels["TBL_COMMON_BUTTON_CANCEL"]}
                  </Button>
                </Grid>
                <div className={classes.pr1}></div>
                <Grid item>
                  <Button type="submit" disabled={isSubmitting} variant="contained" color="secondary">
                    {labels["TBL_COMMON_BUTTON_LABEL_FINISH"]}
                  </Button>
                </Grid>
              </Grid>
              {isSubmitting && <TblLinearProgress />}
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
};

PropTypes.TblBandVideo = {
  topRef: PropTypes.object.isRequired,
  setVideos: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TblBandVideo;

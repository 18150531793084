export class TblErrorMessage {
  constructor(seqErrorMessage, codMessage, dateCre, dateMod, userCre, userMod) {
    this.seqErrorMessage = seqErrorMessage;
    this.codMessage = codMessage;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { Field, useFormikContext } from "formik";

import FormControl from "@material-ui/core/FormControl";
import { KeyboardDateTimePicker } from "formik-material-ui-pickers";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

const TblDateTimePicker = ({ id, name, label, properties }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const { isSubmitting } = useFormikContext();

  properties = {
    ...properties,
    clearLabel: labels["TBL_COMMON_BUTTON_RESET"],
    cancelLabel: labels["TBL_COMMON_BUTTON_CLOSE"],
    okLabel: labels["TBL_COMMON_BUTTON_OK"],
  };

  return (
    <FormControl fullWidth>
      <Field component={KeyboardDateTimePicker} id={id} name={name} label={label} inputVariant="outlined" {...properties} disabled={isSubmitting} />
    </FormControl>
  );
};

TblDateTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  properties: PropTypes.object,
};

export default TblDateTimePicker;

import { VALUE_N, VALUE_S } from "../constants";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblContestant } from "../model/TblContestant";
import { TblUser } from "../model/TblUser";

export const apiSearchContestants = (url, obj) => async () => {
  const { codContest } = obj;
  url = `${url}?codContest=${codContest}`;
  return searchData(url, obj);
};

export const apiGetContestWinners = (url, obj) => async () => {
  const { codContest } = obj;
  url = `${url}?codContest=${codContest}`;
  const { data } = await doGet(url);
  return data;
};

export const apiGenerateContestWinners = (url, obj) => async () => {
  const { codContest, numWinners } = obj;
  url = `${url}?codContest=${codContest}&numWinners=${numWinners}`;
  const { data } = await doGet(url);
  return data;
};

export const apiAddContestant = (url, obj) => async () => {
  const { seqContestant, tblContest, user, codContestant, valid, winner } = obj;

  const request = new TblContestant(seqContestant, tblContest, new TblUser(user.seq, user.value), codContestant, valid ? VALUE_S : VALUE_N, winner ? VALUE_S : VALUE_N);
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateContestant = (url, obj) => async () => {
  const { seqContestant, tblContest, user, codContestant, valid, winner, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqContestant}`;

  const request = new TblContestant(
    seqContestant,
    tblContest,
    new TblUser(user.seq, user.value),
    codContestant,
    valid ? VALUE_S : VALUE_N,
    winner ? VALUE_S : VALUE_N,
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteContestant = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

export const apiParticipateContest = (url, obj) => async () => {
  const { data } = await doPost(url, obj);
  return data;
};

import {
  ADD_ELEMENTS_BAND_VIDEOPAGE,
  API_BAND_VIDEO,
  API_BAND_VIDEO_ADD,
  API_BAND_VIDEO_DELETE,
  API_BAND_VIDEO_EDIT,
  API_BAND_VIDEO_SEARCH,
  CLEAR_BAND_VIDEO_TABLE,
  NO_ACTION,
  SET_SCREEN_BAND_VIDEOPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tbandVideo = {
  bandVideos: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBandVideo = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_VIDEO]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, bandVideos: action.payload || [] };
    },
    [API_BAND_VIDEO_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandVideos: {
          bandVideos: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_BAND_VIDEO_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandVideos.totalElements += 1;

      return { ...state, tbandVideos: { ...state.tbandVideos, bandVideos: [action.payload].concat(state.tbandVideos?.bandVideos || []) } };
    },
    [API_BAND_VIDEO_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const videoPayload = action.payload;
      const { seqVideo } = videoPayload;
      const bandVideos = state.tbandVideos?.bandVideos || [];
      const newBandVideos = bandVideos.map((bandVideo) => {
        if (bandVideo.seqVideo === seqVideo) {
          return videoPayload;
        } else {
          return bandVideo;
        }
      });
      return { ...state, tbandVideos: { ...state.tbandVideos, bandVideos: newBandVideos } };
    },
    [API_BAND_VIDEO_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandVideos.totalElements -= 1;
      return { ...state, tbandVideos: { ...state.tbandVideos, bandVideos: state.tbandVideos.bandVideos.filter((c) => c.seqVideo !== action.payload) } };
    },
    [ADD_ELEMENTS_BAND_VIDEOPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandVideos: {
          bandVideos: [...state.tbandVideos.bandVideos, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbandVideos?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BAND_VIDEOPAGE]: (state, action) => ({ ...state, tbandVideos: { ...state.tbandVideos, screenPage: action.payload } }),
    [CLEAR_BAND_VIDEO_TABLE]: (state) => ({ ...state, tbandVideos: initial_tbandVideo }),
  },
  { tbandVideos: initial_tbandVideo, bandVideos: [] }
);

import {
  ADD_ELEMENTS_BAND_STYLEPAGE,
  API_BAND_STYLE_ADD,
  API_BAND_STYLE_DELETE,
  API_BAND_STYLE_SEARCH,
  CLEAR_BAND_STYLE_TABLE,
  NO_ACTION,
  SET_SCREEN_BAND_STYLEPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tbandStyle = {
  bandStyles: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBandStyle = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_STYLE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandStyles: {
          bandStyles: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_BAND_STYLE_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandStyles.totalElements += 1;

      return { ...state, tbandStyles: { ...state.tbandStyles, bandStyles: [action.payload].concat(state.tbandStyles?.bandStyles || []) } };
    },
    [API_BAND_STYLE_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandStyles.totalElements -= 1;
      return { ...state, tbandStyles: { ...state.tbandStyles, bandStyles: state.tbandStyles.bandStyles.filter((c) => c.seqStyle !== action.payload) } };
    },
    [ADD_ELEMENTS_BAND_STYLEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandStyles: {
          bandStyles: [...state.tbandStyles.bandStyles, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbandStyles?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BAND_STYLEPAGE]: (state, action) => ({ ...state, tbandStyles: { ...state.tbandStyles, screenPage: action.payload } }),
    [CLEAR_BAND_STYLE_TABLE]: (state) => ({ ...state, tbandStyles: initial_tbandStyle }),
  },
  { tbandStyles: initial_tbandStyle }
);

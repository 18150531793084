import { useMemo, useState } from "react";

import ErrorMessageSearchForm from "./ErrorMessageSearchForm";
import ErrorMessageTable from "./ErrorMessageTable";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblErrorMessage from "../../../constants/TblErrorMessage.json";

const extraData = {
  headCells: TblErrorMessage.headCells,
  initialValues: {
    seqErrorMessageId: null,
    codMessage: "",
    text: "",
    codLang: "",
  },
};

const ErrorMessageTab = ({ data, actions }) => {
  const [selected, setSelected] = useState({});
  data = useMemo(() => ({ ...data, ...extraData, selected }), [data, selected]);
  actions = useMemo(() => ({ ...actions, setSelected }), [actions, setSelected]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <ErrorMessageSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <ErrorMessageTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

ErrorMessageTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ErrorMessageTab;

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblDatePicker from "../../../TblForms/TblDatePicker";
import TblSearchForm from "../../TblSearchForm";
import TblSelect from "../../../TblForms/TblSelect";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetDomain } from "../../../../actions/domainActions";
import { actionGetLangs } from "../../../../actions/langActions";
import { actionSearchContests } from "../../../../actions/contestActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const initialValuesContest = {
  codContest: "",
  contestType: "",
  name: "",
  startDate: null,
  endDate: null,
  codLang: "",
  active: "",
};

const ContestSearchForm = ({ data, actions }) => {
  const userSession = useSelector((state) => state.tblUser.userSession);
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const contestTypeOptions = useSelector((state) => state.tblDomain.CONTEST_TYPE);
  const activoOptions = useSelector((state) => state.tblDomain.IND_YES_NO);
  const dispatch = useDispatch();

  const { headCellsContests } = data;
  const { setSelecContest } = actions;

  if (langs.length === 0) {
    callService(dispatch, actionGetLangs);
  }

  if (!contestTypeOptions || contestTypeOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "CONTEST_TYPE", userLang: userSession.userLang });
  }

  if (!activoOptions || activoOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "IND_YES_NO", contestLang: userSession.userLang });
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.orderBy = headCellsContests[1].path;
      callService(dispatch, actionSearchContests, values).finally(() => {
        setSelecContest({});
        setSubmitting(false);
      });
    },
    [dispatch, headCellsContests, setSelecContest]
  );

  return (
    <TblSearchForm initialValues={initialValuesContest} onSubmit={handleSubmit}>
      <Grid item xs={8}>
        <TblTextField id={headCellsContests[1].id} name={headCellsContests[1].column} label={labels[headCellsContests[1].label]} properties={headCellsContests[1].properties} />
      </Grid>
      <Grid item xs={8}>
        <TblTextField id={headCellsContests[2].id} name={headCellsContests[2].column} label={labels[headCellsContests[2].label]} properties={headCellsContests[2].properties} />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCellsContests[3].id}
          name={headCellsContests[3].column}
          label={labels[headCellsContests[3].label]}
          options={contestTypeOptions}
          defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
        />
      </Grid>
      <Grid item xs={4}>
        <TblDatePicker id={headCellsContests[7].id} name={headCellsContests[7].column} label={labels[headCellsContests[7].label]} properties={headCellsContests[7].properties} />
      </Grid>
      <Grid item xs={4}>
        <TblDatePicker id={headCellsContests[8].id} name={headCellsContests[8].column} label={labels[headCellsContests[8].label]} properties={headCellsContests[8].properties} />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCellsContests[9].id}
          name={headCellsContests[9].column}
          label={labels[headCellsContests[9].label]}
          options={activoOptions}
          defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
        />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCellsContests[14].id}
          name={headCellsContests[14].column}
          label={labels[headCellsContests[14].label]}
          options={langs}
          defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
        />
      </Grid>
    </TblSearchForm>
  );
};

ContestSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContestSearchForm;

import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { alpha, makeStyles } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

const customStyles = makeStyles(() => ({
  customTooltip: {
    width: 70,
    margin: 0,
    border: "1px solid #f5f5f5",
    backgroundColor: alpha("#fff", 0.8),
    padding: 10,
  },
  label: {
    margin: 0,
    color: "#666",
    fontWeight: 700,
    height: 15,
  },
}));

const CustomTooltip = ({ active, payload }) => {
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  if (active) {
    return (
      <div className={cClasses.customTooltip}>
        <p className={cClasses.label}>{`${labels["TBL_COMMON_LABEL_VOTES"]}: ${payload[0]?.value}`}</p>
      </div>
    );
  }

  return null;
};

const TblBandChart = ({ data }) => {
  const { dimensions, stats } = data;

  return (
    <BarChart height={130} width={dimensions.width > 400 && isMobile ? 300 : 250} layout="vertical" margin={{ top: 10, bottom: 10, left: 10, right: 10 }} data={stats}>
      <XAxis type="number" hide={true} />
      <YAxis type="category" dataKey="name" interval={0} />
      <Tooltip content={<CustomTooltip />} />
      <Bar dataKey="votes" fill="#ef343b" barSize={15}></Bar>
    </BarChart>
  );
};

TblBandChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TblBandChart;

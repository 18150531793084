import React, { useEffect } from "react";

import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblFrame from "../components/TblFrame";
import TblMyProfile from "../components/TblProfile/TblMyProfile";
import TblTabPanel from "../components/TblTabPanel";
import { useProfile } from "../hooks/TblProfile/useProfile";
import { useSelector } from "react-redux";
import { useStyles } from "../theme/useStyles";

const ProfilePage = ({ setScroll }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { data, actions } = useProfile();
  const { topRef, tab, votes, reviews, contests } = data;
  const { handleChange } = actions;

  return (
    <TblFrame setScroll={setScroll} size={6}>
      <div className={classes.pTopPage} ref={topRef}></div>
      <Paper>
        <Tabs value={tab} onChange={handleChange} indicatorColor="secondary" textColor="secondary" variant="scrollable" scrollButtons="auto" aria-label="profile tabs">
          <Tab label={labels["TBL_PROFILE_APPBAR_TABPANEL_MYPROFILE"]} />

          <Tab
            label={
              <Badge badgeContent={votes} color="secondary">
                {labels["TBL_PROFILE_APPBAR_TABPANEL_MYVOTES"]}
              </Badge>
            }
          />

          <Tab
            label={
              <Badge badgeContent={reviews} color="secondary">
                {labels["TBL_PROFILE_APPBAR_TABPANEL_MYREVIEWS"]}
              </Badge>
            }
          />

          <Tab
            label={
              <Badge badgeContent={contests} color="secondary">
                {labels["TBL_PROFILE_APPBAR_TABPANEL_MYCONTESTS"]}
              </Badge>
            }
          />
        </Tabs>
      </Paper>
      <div className={classes.mt1}></div>
      <TblTabPanel value={tab} index={0}>
        <TblMyProfile data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={tab} index={1}>
        <h1>Próximamente</h1>
      </TblTabPanel>
      <TblTabPanel value={tab} index={2}>
        <h1>Próximamente</h1>
      </TblTabPanel>
      <TblTabPanel value={tab} index={3}>
        <h1>Próximamente</h1>
      </TblTabPanel>
    </TblFrame>
  );
};

ProfilePage.propTypes = {
  setScroll: PropTypes.func.isRequired,
};

export default ProfilePage;

import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";
import ImageList from "@material-ui/core/ImageList";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const customStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    cursor: "pointer",
  },
}));

const TblPartners = ({ data }) => {
  const cClasses = customStyles();
  const imagesPath = useSelector((state) => state.tblImagesPath.BAF) || [];

  const { cols, width } = data;

  const Image = ({ item }) => {
    return <Avatar alt={item.imageName} variant="square" src={item.imagePath} style={{ width: width, height: "auto" }} />;
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={11}>
        <div className={cClasses.root}>
          <ImageList rowHeight="auto" cols={cols} gap={8}>
            {imagesPath.map((item, i) => (
              <GridListTile key={item.seq} className={cClasses.item} onClick={() => window.open(item.imageUrl, "_blank")}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Image key={i} item={item} />
                </Box>
              </GridListTile>
            ))}
          </ImageList>
        </div>
      </Grid>
    </Grid>
  );
};

TblPartners.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TblPartners;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { callService, doPost } from "../../utils/serviceUtils";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TblLoginForm from "./TblLoginForm/TblLoginForm";
import TblLoginOptions from "./TblLoginOptions/TblLoginOptions";
import TblRememberPassword from "./TblRememberPassword";
import TblSignupForm from "./TblSignupForm";
import TblSignupOptions from "./TblSignupOptions/TblSignupOptions";
import TblSignupSocialForm from "./TblSignupSocialForm";
import { VALUE_ESP } from "../../constants";
import { actionGetProvinces } from "../../actions/provinceActions";
import { urlVerifyReCaptcha } from "../../api/urls";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useStyles } from "../../theme/useStyles";

const screens = {
  loginOptions: "loginOptions",
  signupOptions: "signupOptions",
  loginForm: "loginForm",
  signupForm: "signupForm",
  signupSocialForm: "signupSocialForm",
  rememberPassword: "rememberPassword",
};

const TblLogin = () => {
  const classes = useStyles();
  const provinces = useSelector((state) => state.tblProvince.provinces);
  const dispatch = useDispatch();

  const topRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showScreen, setShowScreen] = useState(screens.loginOptions);
  const [initialValues, setInitialValues] = useState({
    id: "",
    user: "",
    email: "",
    password: "",
    confpass: "",
    birthDate: null,
    codProvince: "",
    photoProfile: "",
    socialName: "",
  });

  //reCAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return false;
    }
    const token = await executeRecaptcha();
    const request = {
      token: token,
    };
    const result = await doPost(urlVerifyReCaptcha, request);
    return result?.data?.success;
  }, [executeRecaptcha]);

  useEffect(() => {
    if (provinces?.length === 0) {
      callService(dispatch, actionGetProvinces, VALUE_ESP);
    }
  }, [executeRecaptcha, provinces, dispatch, handleReCaptchaVerify]);

  const data = useMemo(
    () => ({
      isLoading,
      screens,
      initialValues,
      provinces,
      topRef,
    }),
    [initialValues, isLoading, provinces]
  );

  const actions = useMemo(
    () => ({
      setIsLoading,
      setShowScreen,
      setInitialValues,
      handleReCaptchaVerify,
    }),
    [handleReCaptchaVerify]
  );

  return (
    <React.Fragment>
      <div className={classes.pTopPage} ref={topRef}></div>
      <Grid container direction="column" alignContent="center" alignItems="center" className={classes.pt1}>
        <Grid item xs={12}>
          <Avatar src={"/images/application/tblimagelogocentral.jpg"} alt="logo-central" variant="square" style={{ width: 80, height: 100 }} />
        </Grid>
        <div className={classes.mb1}></div>
        <Grid item xs={12} sm={7} md={5} lg={3} container direction="column">
          {showScreen === screens.loginOptions && <TblLoginOptions data={data} actions={actions} />}
          {showScreen === screens.signupOptions && <TblSignupOptions data={data} actions={actions} />}
          {showScreen === screens.loginForm && <TblLoginForm data={data} actions={actions} />}
          {showScreen === screens.signupForm && <TblSignupForm data={data} actions={actions} />}
          {showScreen === screens.signupSocialForm && <TblSignupSocialForm data={data} actions={actions} />}
          {showScreen === screens.rememberPassword && <TblRememberPassword data={data} actions={actions} />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TblLogin;

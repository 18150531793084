import {
  ADD_ELEMENTS_USERPAGE,
  API_USERS_GET,
  API_USER_ADD,
  API_USER_DELETE,
  API_USER_EDIT,
  API_USER_GET,
  API_USER_SEARCH,
  CLEAR_USER_TABLE,
  NO_ACTION,
  SET_SCREEN_USERPAGE,
  USER_LOGIN,
  USER_LOGOUT,
} from "../constants";
import {
  apiActiveUser,
  apiAddUser,
  apiChangePassword,
  apiDeleteUser,
  apiDesVinculateAccount,
  apiGet,
  apiGetUser,
  apiLogin,
  apiLoginSocial,
  apiLogout,
  apiRememberPassEmail,
  apiSearchUsers,
  apiSignUp,
  apiUnsubscribeUser,
  apiUpdatePublicUser,
  apiUpdateUser,
  apiValidUserEmail,
  apiValidUserName,
  apiVinculateAccount,
} from "../api/userAPI";
import {
  urlActiveUser,
  urlAddUser,
  urlChangePassword,
  urlDeleteUser,
  urlDesVinculateFacebook,
  urlDesVinculateGoogle,
  urlGetUser,
  urlGetUserSession,
  urlGetUsers,
  urlLogin,
  urlLoginSocial,
  urlLogout,
  urlRememberPassEmail,
  urlResendActiveEmail,
  urlSearchUsers,
  urlSignup,
  urlUnsubscribeUser,
  urlUpdatePublicUser,
  urlUpdateUser,
  urlValidUserEmail,
  urlValidUserName,
  urlVinculateAccount,
} from "../api/urls";

import { createAction } from "redux-actions";

export const actionLogin = createAction(USER_LOGIN, (user) => apiLogin(urlLogin, user)());
export const actionLoginSocial = createAction(USER_LOGIN, (user) => apiLoginSocial(urlLoginSocial, user)());
export const actionLogout = createAction(USER_LOGOUT, apiLogout(urlLogout));
export const actionSignUp = createAction(USER_LOGIN, (user) => apiSignUp(urlSignup, user)());
export const actionResendActiveEmail = createAction(NO_ACTION, apiGet(urlResendActiveEmail));
export const actionActiveUser = createAction(USER_LOGIN, (activeObject) => apiActiveUser(urlActiveUser, activeObject)());
export const actionRememberPassEmail = createAction(NO_ACTION, (email) => apiRememberPassEmail(urlRememberPassEmail, email)());
export const actionChangePassword = createAction(NO_ACTION, (changePassObject) => apiChangePassword(urlChangePassword, changePassObject)());
export const actionGetUserSession = createAction(USER_LOGIN, apiGet(urlGetUserSession));
export const actionSetCurrentPage = createAction(SET_SCREEN_USERPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_USERPAGE, (user) => apiSearchUsers(urlSearchUsers, user)());
export const actionGetUsers = createAction(API_USERS_GET, apiGet(urlGetUsers));
export const actionSearchUsers = createAction(API_USER_SEARCH, (user) => apiSearchUsers(urlSearchUsers, user)());
export const actionAddUser = createAction(API_USER_ADD, (user) => apiAddUser(urlAddUser, user)());
export const actionUpdateUser = createAction(API_USER_EDIT, (user) => apiUpdateUser(urlUpdateUser, user)());
export const actionDeleteUser = createAction(API_USER_DELETE, ({ seqUser }) => apiDeleteUser(urlDeleteUser, seqUser)());
export const actionClearUserTable = createAction(CLEAR_USER_TABLE);
export const actionValidUserName = createAction(NO_ACTION, (userName) => apiValidUserName(urlValidUserName, userName)());
export const actionValidUserEmail = createAction(NO_ACTION, (userEmail) => apiValidUserEmail(urlValidUserEmail, userEmail)());
export const actionVinculateAccount = createAction(API_USER_GET, (user) => apiVinculateAccount(urlVinculateAccount, user)());
export const actionDesVinculateFacebook = createAction(API_USER_GET, (user) => apiDesVinculateAccount(urlDesVinculateFacebook, user)());
export const actionDesVinculateGoogle = createAction(API_USER_GET, (user) => apiDesVinculateAccount(urlDesVinculateGoogle, user)());
export const actionGetUser = createAction(API_USER_GET, (userName) => apiGetUser(urlGetUser, userName)());
export const actionUpdatePublicUser = createAction(API_USER_GET, (user) => apiUpdatePublicUser(urlUpdatePublicUser, user)());
export const actionUnsubscribeUser = createAction(USER_LOGOUT, (user) => apiUnsubscribeUser(urlUnsubscribeUser, user)());

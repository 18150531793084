import { useMemo, useState } from "react";

import ContestContainer from ".";
import ContestSearchForm from "./ContestSearchForm";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblContest from "../../../constants/TblContest.json";
import TblContestBand from "../../../constants/TblContestBand.json";
import TblContestant from "../../../constants/TblContestant.json";

const extraData = {
  headCellsContests: TblContest.headCells,
  headCellsContestants: TblContestant.headCells,
  headCellsContestBands: TblContestBand.headCells,
  initialValuesContest: {
    seqContest: null,
    codContest: "",
    contestType: "",
    contestCodeRequired: "",
    name: "",
    summary: "",
    description: "",
    voteRange: "",
    contestCode: "",
    startDate: null,
    endDate: null,
    codLang: "",
    active: true,
    responsibleEmail: "",
    urlFacebook: "",
    urlTwitter: "",
    urlInstagram: "",
    file: null,
    bands: [],
  },
  initialValuesContestant: {
    seqContestant: null,
    user: "",
    codContestant: "",
    valid: true,
    winner: false,
    tblContest: "",
  },
};

const ContestTab = ({ data, actions }) => {
  const [selecContest, setSelecContest] = useState({});
  const [selecContestant, setSelecContestant] = useState({});
  const [selecContestBand, setSelecContestBand] = useState({});
  const [openContestantAudit, setOpenContestantAudit] = useState(false);

  data = useMemo(
    () => ({ ...data, ...extraData, selecContest, selecContestant, selecContestBand, openContestantAudit }),
    [data, selecContest, selecContestant, selecContestBand, openContestantAudit]
  );
  actions = useMemo(
    () => ({
      ...actions,
      setSelecContest,
      setSelecContestant,
      setSelecContestBand,
      setOpenContestantAudit,
    }),
    [actions]
  );

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <ContestSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <ContestContainer data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

ContestTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContestTab;

import {
  ADD_ELEMENTS_DOMAINPAGE,
  API_DOMAIN_ADD,
  API_DOMAIN_DELETE,
  API_DOMAIN_EDIT,
  API_DOMAIN_GET,
  API_DOMAIN_SEARCH,
  CLEAR_DOMAIN_TABLE,
  SET_SCREEN_DOMAINPAGE,
} from "../constants";
import { apiAddDomain, apiDeleteDomain, apiGetDomain, apiSearchDomains, apiUpdateDomain } from "../api/domainAPI";
import { urlAddDomain, urlDeleteDomain, urlGetDomains, urlSearchDomains, urlUpdateDomain } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_DOMAINPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_DOMAINPAGE, (domain) => apiSearchDomains(urlSearchDomains, domain)());
export const actionGetDomain = createAction(API_DOMAIN_GET, (domainCode) => apiGetDomain(urlGetDomains, domainCode)());
export const actionSearchDomains = createAction(API_DOMAIN_SEARCH, (domain) => apiSearchDomains(urlSearchDomains, domain)());
export const actionAddDomain = createAction(API_DOMAIN_ADD, (domain) => apiAddDomain(urlAddDomain, domain)());
export const actionUpdateDomain = createAction(API_DOMAIN_EDIT, (domain) => apiUpdateDomain(urlUpdateDomain, domain)());
export const actionDeleteDomain = createAction(API_DOMAIN_DELETE, ({ seqDomainId }) => apiDeleteDomain(urlDeleteDomain, seqDomainId)());
export const actionClearDomainTable = createAction(CLEAR_DOMAIN_TABLE);

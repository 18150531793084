import { Form, Formik } from "formik";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblAutocomplete from "../../TblForms/TblAutocomplete";
import TblSelect from "../../TblForms/TblSelect";
import TblTextField from "../../TblForms/TblTextField";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblBandFilters = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const provinceOptions = useSelector((state) => state.tblProvince.provinces);
  const styleOptions = useSelector((state) => state.tblStyle.styles);
  const sortOptions = useSelector((state) => state.tblDomain.ORDER_BY);

  const { initialValues } = data;
  const { handleSubmitFilter, handleClearFilter } = actions;

  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmitFilter}>
        {({ isSubmitting, resetForm, setSubmitting }) => (
          <Form>
            <Grid container direction={isMobile ? "column" : "row"} justifyContent="center" spacing={3}>
              <Grid item xs={12} lg={3}>
                <TblTextField
                  id="filter-name"
                  name="name"
                  label={labels["TBL_ENTITY_TBL_GROUP_NAME"]}
                  properties={{
                    type: "text",
                    maxLength: "50",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TblAutocomplete
                  id="filter-province"
                  name="provinces"
                  label={labels["TBL_COMMON_LABEL_PROVINCES"]}
                  properties={{ filterSelectedOptions: true, multiple: true }}
                  options={provinceOptions}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TblAutocomplete
                  id="filter-style"
                  name="styles"
                  label={labels["TBL_COMMON_LABEL_STYLES"]}
                  properties={{ filterSelectedOptions: true, multiple: true }}
                  options={styleOptions}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TblSelect id="sort-options" name="sort" label={labels["TBL_COMMON_LABEL_ORDERBY"]} options={sortOptions} />
              </Grid>
              <Grid item xs={12} container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <Grid item>
                  <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                    {labels["TBL_COMMON_BUTTON_LABEL_APPLY"]}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => handleClearFilter(resetForm, setSubmitting)} disabled={isSubmitting}>
                    {labels["TBL_COMMON_BUTTON_RESET"]}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <div className={classes.mb1}></div>
    </React.Fragment>
  );
};

TblBandFilters.propTypes = {
  initialValues: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
  setShowFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default TblBandFilters;

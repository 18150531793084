import {
  ADD_ELEMENTS_BAND_PROVINCEPAGE,
  API_BAND_PROVINCE_ADD,
  API_BAND_PROVINCE_DELETE,
  API_BAND_PROVINCE_SEARCH,
  CLEAR_BAND_PROVINCE_TABLE,
  NO_ACTION,
  SET_SCREEN_BAND_PROVINCEPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tbandProvince = {
  bandProvinces: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBandProvince = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_PROVINCE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandProvinces: {
          bandProvinces: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_BAND_PROVINCE_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandProvinces.totalElements += 1;

      return { ...state, tbandProvinces: { ...state.tbandProvinces, bandProvinces: [action.payload].concat(state.tbandProvinces?.bandProvinces || []) } };
    },
    [API_BAND_PROVINCE_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandProvinces.totalElements -= 1;
      return { ...state, tbandProvinces: { ...state.tbandProvinces, bandProvinces: state.tbandProvinces.bandProvinces.filter((c) => c.seqProvince !== action.payload) } };
    },
    [ADD_ELEMENTS_BAND_PROVINCEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandProvinces: {
          bandProvinces: [...state.tbandProvinces.bandProvinces, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbandProvinces?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BAND_PROVINCEPAGE]: (state, action) => ({ ...state, tbandProvinces: { ...state.tbandProvinces, screenPage: action.payload } }),
    [CLEAR_BAND_PROVINCE_TABLE]: (state) => ({ ...state, tbandProvinces: initial_tbandProvince }),
  },
  { tbandProvinces: initial_tbandProvince }
);

import PropTypes from "prop-types";
import TblBand from "../components/TblBand";
import TblFrame from "../components/TblFrame";
import { useEffect } from "react";

const BandPage = ({ setScroll }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <TblFrame setScroll={setScroll} size={6}>
      <TblBand />
    </TblFrame>
  );
};

BandPage.propTypes = {
  setScroll: PropTypes.func.isRequired,
};

export default BandPage;

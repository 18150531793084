export class TblContestGroupVote {
  constructor(seqContestGroupVotes, tblContestGroup, tblContestant, tblUser, vote, dateCre, dateMod, userCre, userMod) {
    this.seqContestGroupVotes = seqContestGroupVotes;
    this.tblContestGroup = tblContestGroup;
    this.tblContestant = tblContestant;
    this.tblUser = tblUser;
    this.vote = vote;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblErrorMessage } from "../model/TblErrorMessage";
import { TblErrorMessageId } from "../model/TblErrorMessageId";
import { TblLanguage } from "../model/TblLanguage";

export const apiGetErrorMessages = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchErrorMessages = (url, obj) => async () => {
  const { codMessage, text, codLang } = obj;
  url = `${url}?codMessage=${codMessage}&text=${text}&codLang=${codLang?.value ?? ""}`;
  return searchData(url, obj);
};

export const apiAddErrorMessage = (url, obj) => async () => {
  const { seqErrorMessageId, codMessage, text, codLang } = obj;
  const request = new TblErrorMessageId(seqErrorMessageId, new TblErrorMessage(undefined, codMessage), text, new TblLanguage(codLang.seq, codLang.value, codLang.label));
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateErrorMessage = (url, obj) => async () => {
  const { seqErrorMessageId, codMessage, text, codLang, tblErrorMessage, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqErrorMessageId}`;
  const request = new TblErrorMessageId(
    seqErrorMessageId,
    new TblErrorMessage(tblErrorMessage.seqErrorMessage, codMessage),
    text,
    new TblLanguage(codLang.seq, codLang.value, codLang.label),
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteErrorMessage = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import TblDialogTitle from "../TblDialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const customStyles = makeStyles(() => ({
  codContest: {
    backgroundColor: "#FF5733",
    padding: 10,
    fontWeight: "bold",
    marginTop: 18,
    color: "white",
    cursor: "pointer",
  },
}));

const TblParticipateConfirm = ({ open, onClosePopup, codContestant }) => {
  const cClasses = customStyles();
  const templates = useSelector((state) => state.tblTemplate.templates);
  const labels = useSelector((state) => state.tblLabel.labels);

  return (
    <Dialog onClose={onClosePopup} aria-labelledby="tbl-popup-message" open={open} fullWidth>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={onClosePopup} style={{ color: "#fff", backgroundColor: "#17a2b8" }}>
        {labels["TBL_COMMON_DIALOGTITLE_INFO"]}
      </TblDialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">{ReactHtmlParser(templates["TBL_POPUPS_PARTICIPATE_CONFIRM_TEXT"])}</Typography>
        <Grid container direction="column" alignContent="center" alignItems="center">
          <Tooltip title={labels["TBL_COMMON_CLICKTOCOPY_LABEL"]} placement="right">
            <Typography
              variant="h4"
              className={cClasses.codContest}
              onClick={() => {
                navigator.clipboard.writeText(codContestant);
              }}
            >
              {codContestant}
            </Typography>
          </Tooltip>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

TblParticipateConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  codContestant: PropTypes.string.isRequired,
};

export default TblParticipateConfirm;

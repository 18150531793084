import { doDelete, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblGroup } from "../model/TblGroup";
import { TblGroupLink } from "../model/TblGroupLink";

export const apiSearchBandLinks = (url, obj) => async () => {
  const { codGroup } = obj;
  url = `${url}?codGroup=${codGroup}`;
  return searchData(url, obj);
};

export const apiAddBandLink = (url, obj) => async () => {
  const { seqLink, tblGroup, namePage, urlPage } = obj;
  const request = new TblGroupLink(seqLink, new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name), namePage.value, urlPage);
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateBandLink = (url, obj) => async () => {
  const { seqLink, tblGroup, namePage, urlPage, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqLink}`;
  const request = new TblGroupLink(seqLink, new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name), namePage.value, urlPage, dateCre, dateMod, userCre, userMod);
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteBandLink = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

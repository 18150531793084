import { combineReducers } from "redux";
import { tblBand } from "./tblBand";
import { tblBandLink } from "./tblBandLink";
import { tblBandProvince } from "./tblBandProvince";
import { tblBandReview } from "./tblBandReview";
import { tblBandStyle } from "./tblBandStyle";
import { tblBandUserVote } from "./tblBandUserVote";
import { tblBandVideo } from "./tblBandVideo";
import { tblContest } from "./tblContest";
import { tblContestBand } from "./tblContestBand";
import { tblContestant } from "./tblContestant";
import { tblCountry } from "./tblCountry";
import { tblDomain } from "./tblDomain";
import { tblErrorMessage } from "./tblErrorMessage";
import { tblImagesPath } from "./tblImagesPath";
import { tblLabel } from "./tblLabel";
import { tblLang } from "./tblLang";
import { tblNotifier } from "./tblNotifier";
import { tblProvince } from "./tblProvince";
import { tblStyle } from "./tblStyle";
import { tblTemplate } from "./tblTemplate";
import { tblUser } from "./tblUser";

export default combineReducers({
  tblNotifier,
  tblBand,
  tblBandProvince,
  tblBandStyle,
  tblBandLink,
  tblBandVideo,
  tblBandUserVote,
  tblBandReview,
  tblUser,
  tblContest,
  tblContestant,
  tblContestBand,
  tblStyle,
  tblCountry,
  tblProvince,
  tblLabel,
  tblTemplate,
  tblErrorMessage,
  tblLang,
  tblDomain,
  tblImagesPath,
});

import { Field, useFormikContext } from "formik";

import { Autocomplete } from "formik-material-ui-lab";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import PropTypes from "prop-types";
import React from "react";
import TextField from "@material-ui/core/TextField";

const TblAutocomplete = ({ id, name, label, disabled, properties, options, inputRef, isPaginated, onChange, onSelect }) => {
  const { errors, touched, setFieldValue, setTouched, isSubmitting } = useFormikContext();
  const { filterSelectedOptions, multiple } = properties;
  const valid = !touched[name] || !errors[name];

  return (
    <FormControl fullWidth error={!valid}>
      <Field
        id={id}
        name={name}
        disabled={!!disabled || isSubmitting}
        component={Autocomplete}
        multiple={!!multiple}
        filterSelectedOptions={!!filterSelectedOptions}
        options={options}
        onChange={(event, value, reason) => {
          setFieldValue(name, value);
          setTouched({ [name]: true }, false);
          onSelect && onSelect(value, event);
        }}
        getOptionSelected={(option, value) => option.seq === value.seq}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => {
          params.inputProps.autoComplete = "new-password";
          return <TextField {...params} error={!valid} label={label} onChange={(event) => onChange && onChange(event)} inputRef={inputRef} variant="outlined" />;
        }}
      />
      {!valid && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

TblAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  properties: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      seq: PropTypes.number.isRequired,
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

export default TblAutocomplete;

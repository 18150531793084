import { Field, useFormikContext } from "formik";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import SelectField from "./SelectField";

const TblSelect = ({ id, name, disabled, label, defaultOption, options, onSelect }) => {
  const { errors, touched } = useFormikContext();
  const valid = !touched[name] || !errors[name];
  return (
    <FormControl variant="outlined" fullWidth error={!valid}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Field component={SelectField} name={name} disabled={!!disabled} options={options || []} label={label} defaultOption={defaultOption} onSelect={onSelect}></Field>
      {!valid && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

TblSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  defaultOption: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      seq: PropTypes.number.isRequired,
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
};

export default TblSelect;

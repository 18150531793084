import { Form, Formik } from "formik";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import PropTypes from "prop-types";
import { ROLE_PUBLIC } from "../../../../constants";
import React from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import TblBandVote from "../TblBandFile/TblBandVote";
import TblCircularProgress from "../../../TblCircularProgress";
import TblLinearProgress from "../../../TblLinearProgress";
import TblTextField from "../../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import { convert2DateLL } from "../../../../utils/dataUtils";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { makeStyles } from "@material-ui/core";
import { useBandFile } from "../../../../hooks/TblBand/useBandFile";
import { useBandReview } from "../../../../hooks/TblBand/useBandReview";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const customStyles = makeStyles(() => ({
  divider: {
    width: "100%",
    margin: 0,
  },
  contentFooter: {
    padding: "10px!important",
  },
}));

const TblBandReview = ({ topRef, setReviews, isLoading, setIsLoading }) => {
  const classes = useStyles();
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const bandReviews = useSelector((state) => state.tblBandReview.bandReviews);
  const band = useSelector((state) => state.tblBand.band);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const { data, actions } = useBandReview(topRef, setReviews, setIsLoading);
  const { data: dataBand, actions: actionsBand } = useBandFile();

  const { myReview, editMode, initialValues, paginReviews, page } = data;
  const { setEditMode, handleChange, handleValidate, handleSubmit, handleAssessment } = actions;

  const Review = ({ item }) => {
    return (
      <Card>
        <CardHeader
          avatar={<Avatar aria-label="recipe" src={item.photoProfile} />}
          title={item.reviewRevisionTitle || item.reviewTitle}
          subheader={item.user + " - " + convert2DateLL(item.reviewDate)}
          action={
            item.seqReview === myReview?.seqReview ? (
              <IconButton aria-label="settings" onClick={() => setEditMode(true)}>
                <EditIcon />
              </IconButton>
            ) : null
          }
        />

        <Divider variant="middle" className={cClasses.divider} />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {item.reviewRevision || item.review}
          </Typography>
        </CardContent>
        <Divider variant="middle" className={cClasses.divider} />
        <CardContent className={cClasses.contentFooter}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" alignContent="flex-end">
            <Grid item xs={6} container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h5" color="secondary" component="p">
                  {item.vote}
                </Typography>
              </Grid>
              <Grid item>
                <img alt="tbl-handvoted" src="/images/application/tblimagegroupinfohandvote.png" />
              </Grid>
            </Grid>
            {!isLoading || isLoading !== item.seqReview ? (
              <Grid item xs={3} md={2} xl={1} container direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="h6" color="primary" component="p">
                    {item.assessment}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleAssessment(item)}>
                    <FontAwesomeIcon icon={faThumbsUp} size="xs" />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <TblCircularProgress />
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <React.Fragment>
      {!isLoading ? (
        <Grid container direction="column" alignContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              {band?.tblGroup?.name} - {labels["TBL_BANDDETAILS_APPBAR_TABPANEL_TITLE_REVIEWS"]}
            </Typography>
            <hr className={classes.my1} />
          </Grid>
          {!band.myVote && (
            <React.Fragment>
              <div className={classes.mt1}></div>
              <Grid item xs={6}>
                <TblBandVote data={dataBand} actions={actionsBand} />
              </Grid>
            </React.Fragment>
          )}
          <div className={classes.mt1}></div>
          {editMode && (
            <Grid item container direction="column" xs={12} lg={6}>
              <Formik enableReinitialize initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit}>
                {({ isSubmitting, resetForm }) => (
                  <Form>
                    <Grid item xs={12} container direction="column" spacing={3}>
                      <Grid item xs={12}>
                        <TblTextField
                          id="input-title"
                          name="title"
                          label={labels["TBL_COMMON_LABEL_TITLEREVIEW"]}
                          disabled={!userSession.applicationRole.includes(ROLE_PUBLIC)}
                          properties={{ type: "text", maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TblTextField
                          id="input-text"
                          name="review"
                          label={labels["TBL_COMMON_LABEL_REVIEW"]}
                          disabled={!userSession.applicationRole.includes(ROLE_PUBLIC)}
                          properties={{
                            type: "text",
                            multiline: true,
                            rows: 5,
                            maxRows: 10,
                            maxLength: 5000,
                          }}
                        />
                      </Grid>
                      <Grid item container direction="row" justifyContent="flex-end" spacing={3}>
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting || !userSession.applicationRole.includes(ROLE_PUBLIC)}
                          >
                            {labels["TBL_COMMON_BUTTON_SEND"]}
                          </Button>
                        </Grid>
                        {myReview ? (
                          <Grid item>
                            <Button
                              variant="contained"
                              startIcon={<ClearIcon />}
                              onClick={() => setEditMode(false)}
                              disabled={isSubmitting || !userSession.applicationRole.includes(ROLE_PUBLIC)}
                            >
                              {labels["TBL_COMMON_BUTTON_CANCEL"]}
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item>
                            <Button
                              variant="contained"
                              startIcon={<RotateLeftIcon />}
                              onClick={resetForm}
                              disabled={isSubmitting || !userSession.applicationRole.includes(ROLE_PUBLIC)}
                            >
                              {labels["TBL_COMMON_BUTTON_RESET"]}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {isSubmitting && <TblLinearProgress />}
                  </Form>
                )}
              </Formik>
            </Grid>
          )}
          {(!editMode || !myReview) && (
            <Grid item xs={12} lg={6} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              {paginReviews.map((item) => (
                <Grid item key={item.seqReview}>
                  <Review item={item} />
                </Grid>
              ))}
              <Grid item xs={12}>
                {paginReviews.length > 0 && <Pagination count={Math.ceil(bandReviews.length / 5)} page={page} onChange={handleChange} variant="outlined" color="secondary" />}
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container direction="column" alignContent="center" alignItems="center">
          <TblCircularProgress />
        </Grid>
      )}
    </React.Fragment>
  );
};

PropTypes.TblBandReview = {
  topRef: PropTypes.object.isRequired,
  setReviews: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default TblBandReview;

import {
  ADD_ELEMENTS_BAND_REVIEWPAGE,
  API_BAND_REVIEW,
  API_BAND_REVIEW_ADD,
  API_BAND_REVIEW_ASSESS_ADD,
  API_BAND_REVIEW_CREATE,
  API_BAND_REVIEW_DELETE,
  API_BAND_REVIEW_EDIT,
  API_BAND_REVIEW_SEARCH,
  CLEAR_BAND_REVIEW_TABLE,
  SET_SCREEN_BAND_REVIEWPAGE,
} from "../constants";
import {
  apiAddBandReview,
  apiAddBandReviewAssess,
  apiCreateBandReview,
  apiDeleteBandReview,
  apiGetBandReviews,
  apiSearchBandReviews,
  apiSearchUserReviews,
  apiUpdateBandReview,
} from "../api/bandReviewAPI";
import {
  urlAddBandReview,
  urlAddBandReviewAssess,
  urlCreateBandReview,
  urlDeleteBandReview,
  urlGetBandReviews,
  urlSearchBandReviews,
  urlSearchUserReviews,
  urlUpdateBandReview,
} from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BAND_REVIEWPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BAND_REVIEWPAGE, (bandReview) => apiSearchBandReviews(urlSearchBandReviews, bandReview)());
export const actionAddElementsPage2 = createAction(ADD_ELEMENTS_BAND_REVIEWPAGE, (bandUserVote) => apiSearchUserReviews(urlSearchUserReviews, bandUserVote)());
export const actionGetBandReviews = createAction(API_BAND_REVIEW, (codGroup) => apiGetBandReviews(urlGetBandReviews, codGroup)());
export const actionSearchBandReviews = createAction(API_BAND_REVIEW_SEARCH, (bandReview) => apiSearchBandReviews(urlSearchBandReviews, bandReview)());
export const actionAddBandReview = createAction(API_BAND_REVIEW_ADD, (bandReview) => apiAddBandReview(urlAddBandReview, bandReview)());
export const actionUpdateBandReview = createAction(API_BAND_REVIEW_EDIT, (bandReview) => apiUpdateBandReview(urlUpdateBandReview, bandReview)());
export const actionDeleteBandReview = createAction(API_BAND_REVIEW_DELETE, ({ seqReview }) => apiDeleteBandReview(urlDeleteBandReview, seqReview)());
export const actionCreateBandReview = createAction(API_BAND_REVIEW_CREATE, (bandReview) => apiCreateBandReview(urlCreateBandReview, bandReview)());
export const actionAddBandReviewAssess = createAction(API_BAND_REVIEW_ASSESS_ADD, (seqReview) => apiAddBandReviewAssess(urlAddBandReviewAssess, seqReview)());
export const actionSearchUserReviews = createAction(API_BAND_REVIEW_SEARCH, (bandReview) => apiSearchUserReviews(urlSearchUserReviews, bandReview)());
export const actionClearBandReviewTable = createAction(CLEAR_BAND_REVIEW_TABLE);

import { ADD_ELEMENTS_BAND_USER_VOTEPAGE, API_BAND_USER_VOTE_SEARCH, CLEAR_BAND_USER_VOTE_TABLE, SET_SCREEN_BAND_USER_VOTEPAGE } from "../constants";
import { apiSearchBandUserVotes, apiSearchUserVotes } from "../api/bandUserVoteAPI";
import { urlSearchBandUserVotes, urlSearchUserVotes } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BAND_USER_VOTEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BAND_USER_VOTEPAGE, (bandUserVote) => apiSearchBandUserVotes(urlSearchBandUserVotes, bandUserVote)());
export const actionAddElementsPage2 = createAction(ADD_ELEMENTS_BAND_USER_VOTEPAGE, (bandUserVote) => apiSearchUserVotes(urlSearchUserVotes, bandUserVote)());
export const actionSearchBandUserVotes = createAction(API_BAND_USER_VOTE_SEARCH, (bandUserVote) => apiSearchBandUserVotes(urlSearchBandUserVotes, bandUserVote)());
export const actionSearchUserVotes = createAction(API_BAND_USER_VOTE_SEARCH, (bandUserVote) => apiSearchUserVotes(urlSearchUserVotes, bandUserVote)());
export const actionClearBandUserVoteTable = createAction(CLEAR_BAND_USER_VOTE_TABLE);

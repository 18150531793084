import {
  ADD_ELEMENTS_IMAGES_PATHPAGE,
  API_IMAGES_PATH_ADD,
  API_IMAGES_PATH_DELETE,
  API_IMAGES_PATH_EDIT,
  API_IMAGES_PATH_GET,
  API_IMAGES_PATH_SEARCH,
  CLEAR_IMAGES_PATH_TABLE,
  SET_SCREEN_IMAGES_PATHPAGE,
} from "../constants";
import { apiAddImagesPath, apiDeleteImagesPath, apiGetImagesPaths, apiSearchImagesPaths, apiUpdateImagesPath } from "../api/imagesPathAPI";
import { urlAddImagesPath, urlDeleteImagesPath, urlGetImagesPaths, urlSearchImagesPaths, urlUpdateImagesPath } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_IMAGES_PATHPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_IMAGES_PATHPAGE, (imagePath) => apiSearchImagesPaths(urlSearchImagesPaths, imagePath)());
export const actionGetImagesPaths = createAction(API_IMAGES_PATH_GET, (imagePath) => apiGetImagesPaths(urlGetImagesPaths, imagePath)());
export const actionSearchImagesPaths = createAction(API_IMAGES_PATH_SEARCH, (imagePath) => apiSearchImagesPaths(urlSearchImagesPaths, imagePath)());
export const actionAddImagesPath = createAction(API_IMAGES_PATH_ADD, (imagePath) => apiAddImagesPath(urlAddImagesPath, imagePath)());
export const actionUpdateImagesPath = createAction(API_IMAGES_PATH_EDIT, (imagePath) => apiUpdateImagesPath(urlUpdateImagesPath, imagePath)());
export const actionDeleteImagesPath = createAction(API_IMAGES_PATH_DELETE, ({ seqImagesPath }) => apiDeleteImagesPath(urlDeleteImagesPath, seqImagesPath)());
export const actionClearImagesPathTable = createAction(CLEAR_IMAGES_PATH_TABLE);

import { doDelete, doPost, searchData } from "../utils/serviceUtils";

import { TblCountry } from "../model/TblCountry";
import { TblGroup } from "../model/TblGroup";
import { TblGroupProvince } from "../model/TblGroupProvince";
import { TblProvince } from "../model/TblProvince";

export const apiSearchBandProvinces = (url, obj) => async () => {
  const { codGroup, codCountry } = obj;
  url = `${url}?codGroup=${codGroup}&codCountry=${codCountry}`;
  return searchData(url, obj);
};

export const apiAddBandProvince = (url, obj) => async () => {
  const { seqProvince, tblGroup, tblCountry, codProvince } = obj;
  const request = new TblGroupProvince(
    seqProvince,
    new TblGroup(tblGroup.seqGroup, tblGroup.codGroup, tblGroup.name),
    new TblCountry(tblCountry.seqCountry, tblCountry.codCountry),
    new TblProvince(codProvince.seq, new TblCountry(tblCountry.seqCountry, tblCountry.codCountry), codProvince.value, codProvince.label)
  );
  const { data } = await doPost(url, request);
  return data;
};

export const apiDeleteBandProvince = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

import {
  ADD_ELEMENTS_ERROR_MESSAGEPAGE,
  API_ERROR_MESSAGE_ADD,
  API_ERROR_MESSAGE_DELETE,
  API_ERROR_MESSAGE_EDIT,
  API_ERROR_MESSAGE_GET,
  API_ERROR_MESSAGE_SEARCH,
  CLEAR_ERROR_MESSAGE_TABLE,
  NO_ACTION,
  SET_SCREEN_ERROR_MESSAGEPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_terrorMessage = {
  errorMessages: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblErrorMessage = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_ERROR_MESSAGE_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const errorMessagesPayload = action.payload;
      const initialValue = {};
      const errorMessages = errorMessagesPayload.reduce((acc, errorMessage) => {
        return { ...acc, [errorMessage.tblErrorMessage.codMessage]: errorMessage.text };
      }, initialValue);
      return { ...state, errorMessages };
    },
    [API_ERROR_MESSAGE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        terrorMessages: {
          errorMessages: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_ERROR_MESSAGE_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.errorMessages;
      state.terrorMessages.totalElements += 1;

      return { ...state, terrorMessages: { ...state.terrorMessages, errorMessages: [action.payload].concat(state.terrorMessages?.errorMessages || []) } };
    },
    [API_ERROR_MESSAGE_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const errorMessagePayload = action.payload;
      const { seqErrorMessageId } = errorMessagePayload;
      const errorMessages = state.terrorMessages?.errorMessages || [];
      const newErrorMessages = errorMessages.map((errorMessage) => {
        if (errorMessage.seqErrorMessageId === seqErrorMessageId) {
          return errorMessagePayload;
        } else {
          return errorMessage;
        }
      });
      delete state.errorMessages;
      return { ...state, terrorMessages: { ...state.terrorMessages, errorMessages: newErrorMessages } };
    },
    [API_ERROR_MESSAGE_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.errorMessages;
      state.terrorMessages.totalElements -= 1;
      return { ...state, terrorMessages: { ...state.terrorMessages, errorMessages: state.terrorMessages.errorMessages.filter((c) => c.seqErrorMessageId !== action.payload) } };
    },
    [ADD_ELEMENTS_ERROR_MESSAGEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        terrorMessages: {
          errorMessages: [...state.terrorMessages.errorMessages, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.terrorMessages?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_ERROR_MESSAGEPAGE]: (state, action) => ({ ...state, terrorMessages: { ...state.terrorMessages, screenPage: action.payload } }),
    [CLEAR_ERROR_MESSAGE_TABLE]: (state) => ({ ...state, terrorMessages: initial_terrorMessage }),
  },
  { terrorMessages: initial_terrorMessage, errorMessages: [] }
);

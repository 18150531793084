import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import React from "react";

const TblMenu = ({ children, anchorEl, onClose }) => {
  return (
    <Menu
      id="actions-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {children}
    </Menu>
  );
};

TblMenu.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default TblMenu;

export class TblLabel {
  constructor(seqLabel, codLabel, dateCre, dateMod, userCre, userMod) {
    this.seqLabel = seqLabel;
    this.codLabel = codLabel;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

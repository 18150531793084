export class TblDomain {
  constructor(seqDomain, domainCode, valueCode, dateCre, dateMod, userCre, userMod) {
    this.seqDomain = seqDomain;
    this.domainCode = domainCode;
    this.valueCode = valueCode;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblUser from "../../../constants/TblUser.json";
import TblUserReview from "../../../constants/TblUserReview.json";
import TblUserVote from "../../../constants/TblUserVote.json";
import UserContainer from ".";
import UserSearchForm from "./UserSearchForm";

const extraData = {
  headCellsUsers: TblUser.headCells,
  headCellsUserVotes: TblUserVote.headCells,
  headCellsUserReviews: TblUserReview.headCells,
  initialValuesUser: {
    seqUser: null,
    user: "",
    password: "",
    confpass: "",
    email: "",
    name: "",
    lastName: "",
    birthDate: null,
    userLang: "",
    codCountry: "",
    codProvince: "",
    isLinkedFacebook: "",
    isLinkedGoogle: "",
    active: true,
    applicationRole: "",
    notifications: true,
    dateCreFrom: null,
    dateCreTo: null,
    file: null,
  },
};

const UserTab = ({ data, actions }) => {
  const [selecUser, setSelecUser] = useState({});
  const [disabledProv, setDisabledProv] = useState(true);
  const [selecUserVote, setSelecUserVote] = useState({});
  const [selecReview, setSelecReview] = useState({});
  const [openUserVoteAudit, setOpenUserVoteAudit] = useState(false);
  const [openReviewAudit, setOpenReviewAudit] = useState(false);

  data = useMemo(
    () => ({ ...data, ...extraData, disabledProv, selecUser, selecUserVote, selecReview, openUserVoteAudit, openReviewAudit }),
    [data, disabledProv, selecUser, selecUserVote, selecReview, openUserVoteAudit, openReviewAudit]
  );
  actions = useMemo(
    () => ({
      ...actions,
      setDisabledProv,
      setSelecUser,
      setSelecUserVote,
      setSelecReview,
      setOpenUserVoteAudit,
      setOpenReviewAudit,
    }),
    [actions]
  );

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <UserSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <UserContainer data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

UserTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserTab;

import { ROLE_PUBLIC, VALUE_S } from "../../constants";
import React, { useEffect, useRef, useState } from "react";
import { actionGetActiveContests, actionGetFinishedContests, actionGetMyContests, actionGetUpcomingContests } from "../../actions/contestActions";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblActives from "./TblActives/TblActives";
import TblFinisheds from "./TblFinisheds/TblFinisheds";
import TblTabPanel from "../TblTabPanel";
import TblUpcomings from "./TblUpcomings/TblUpcomings";
import { callService } from "../../utils/serviceUtils";
import { useStyles } from "../../theme/useStyles";

const TblContest = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const topRef = useRef(null);
  const dispatch = useDispatch();

  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setLoading(true);
    const services = [callService(dispatch, actionGetActiveContests), callService(dispatch, actionGetFinishedContests), callService(dispatch, actionGetUpcomingContests)];
    if (userSession.applicationRole.includes(ROLE_PUBLIC)) {
      services.push(callService(dispatch, actionGetMyContests, VALUE_S));
    }
    Promise.all(services).finally(() => setLoading(false));
  }, [dispatch, userSession.applicationRole]);

  return (
    <React.Fragment>
      <div className={classes.pTopPage} ref={topRef}></div>
      <Paper>
        <Tabs value={tab} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="band details tabs">
          <Tab label={labels["TBL_CONTEST_TAB_TABPANEL_ACTIVE"]} />
          <Tab label={labels["TBL_CONTEST_TAB_TABPANEL_NEXT"]} />
          <Tab label={labels["TBL_CONTEST_TAB_TABPANEL_FINISHED"]} />
        </Tabs>
      </Paper>
      <div className={classes.mt1}></div>
      <TblTabPanel value={tab} index={0}>
        <TblActives topRef={topRef} loading={loading} setLoading={setLoading} />
      </TblTabPanel>
      <TblTabPanel value={tab} index={1}>
        <TblUpcomings topRef={topRef} loading={loading} />
      </TblTabPanel>
      <TblTabPanel value={tab} index={2}>
        <TblFinisheds topRef={topRef} loading={loading} />
      </TblTabPanel>
    </React.Fragment>
  );
};

export default TblContest;

import React, { useCallback, useMemo, useState } from "react";
import {
  actionAddBandLink,
  actionAddElementsPage,
  actionDeleteBandLink,
  actionSearchBandLinks,
  actionSetCurrentPage,
  actionUpdateBandLink,
} from "../../../../actions/bandLinkActions";

import Grid from "@material-ui/core/Grid";
import HttpIcon from "@material-ui/icons/Http";
import PropTypes from "prop-types";
import RRSSList from "../../../../constants/RRSSList.json";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblCards from "../../TblCards";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupLink } from "../../../../model/TblGroupLink";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const BandLinkTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tbandLinks = useSelector((state) => state.tblBandLink.tbandLinks);

  const [open, setOpen] = useState(false);

  const { search, serverPage, screenPage, totalElements } = tbandLinks;
  const { selecLink, openLinkAudit } = data;
  const { setSelecLink, setOpenLinkAudit } = actions;

  const rows = useMemo(
    () =>
      tbandLinks.bandLinks.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupLink(
            row.seqLink,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            row.namePage,
            row.urlPage,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tbandLinks.bandLinks]
  );

  const rrssOptions = useMemo(
    () =>
      RRSSList.reduce((acc, rrss) => {
        let isValid = false;
        if (selecLink.namePage !== rrss.value) {
          if (rows.filter((row) => row.namePage === rrss.value).length === 0) {
            isValid = true;
          }
        } else {
          isValid = true;
        }
        if (isValid) {
          return [...acc, rrss];
        } else {
          return acc;
        }
      }, []),
    [rows, selecLink.namePage]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_GROUP_LINK"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      if (!values.namePage) {
        errors.namePage = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!values.urlPage) {
        errors.urlPage = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (!values.urlPage.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)) {
        errors.urlPage = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      return errors;
    },
    [labels]
  );

  const handleAfterSubmit = useCallback(
    (response) => {
      setSelecLink(
        new TblGroupLink(response.seqLink, response.tblGroup, response.namePage, response.urlPage, response.dateCre, response.dateMod, response.userCre, response.userMod)
      );
    },
    [setSelecLink]
  );

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BANDLINKS"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecLink,
    }),
    [data, open, headCells, initialValues, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecLink]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandLinks,
      actionAdd: actionAddBandLink,
      actionUpdate: actionUpdateBandLink,
      actionDelete: actionDeleteBandLink,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setOpen,
      onValidation: handleValidation,
      setSelected: setSelecLink,
      afterSubmit: handleAfterSubmit,
    }),
    [actions, handleValidation, setSelecLink, handleAfterSubmit]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenLinkAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={3}>
            <TblAutocomplete id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} options={rrssOptions} />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <HttpIcon />
              </Grid>
              <Grid item xs={11}>
                <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openLinkAudit} setOpen={setOpenLinkAudit} selected={selecLink} />
    </div>
  );
};

BandLinkTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandLinkTable;

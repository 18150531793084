import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const ActionButtons = ({ isSubmitting, activeStep, handleBack, steps }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  return (
    <React.Fragment>
      <div className={classes.pt1}></div>
      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Button disabled={isSubmitting} onClick={handleBack} className={classes.backButton}>
            {activeStep === 0 ? labels["TBL_COMMON_BUTTON_CANCEL"] : labels["TBL_COMMON_BUTTON_LABEL_BACK"]}
          </Button>
        </Grid>
        <div className={classes.pr1}></div>
        <Grid item>
          {activeStep === steps.length - 1 ? (
            <Button type="submit" disabled={isSubmitting} variant="contained" color="secondary">
              {labels["TBL_COMMON_BUTTON_LABEL_FINISH"]}
            </Button>
          ) : (
            <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">
              {labels["TBL_COMMON_BUTTON_LABEL_NEXT"]}
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ActionButtons;

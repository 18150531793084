import { AesUtil } from "../utils/AESUtil";
import { Properties } from "../constants/properties";
import _ from "lodash";
import { actionEnqueueSnackbar } from "../actions/notifierActions";
import latinMap from "../constants/LatinMap.json";
import moment from "moment";

export const convert2DateLL = (date) => {
  return moment(date).format("LL");
};

export const convert2DateFormat = (date, format) => {
  if (date) {
    return moment(date).parseZone().format(format);
  }
  return null;
};

export const convert2Date = (date) => {
  if (date) {
    return moment(date).parseZone();
  }
  return null;
};

export const convert2UtcISOString = (date) => {
  if (date) {
    return moment(date).utcOffset(0, true).toISOString();
  }
  return null;
};

export const showNotifier = (dispatch, message, variant) => {
  dispatch(
    actionEnqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: variant,
      },
    })
  );
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(
      decodeURIComponent(
        atob(token.split(".")[1])
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
  } catch (e) {
    return null;
  }
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""};${expires}; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

export const getByPath = (obj, path) => {
  if (!_.isEmpty(obj) && path) {
    const parts = path.split(".");
    let o = obj;
    if (parts.length > 1 && o instanceof Object) {
      for (let i = 0, l = parts.length; i < l - 1; i++) {
        if (!o[parts[i]]) o[parts[i]] = {};
        o = o[parts[i]];
      }
    }

    return o[parts[parts.length - 1]];
  } else {
    return undefined;
  }
};

export const encryptData = (data, passphrase) => {
  const iterationCount = 1000;
  const keySize = 128;

  const aesUtil = new AesUtil(keySize, iterationCount);
  return aesUtil.encrypt(Properties.SALT, Properties.FOUR, passphrase, data);
};

export const latinise = (data) => {
  let latin = data.replace(/[^A-Za-z0-9!-/:-@[-`{-¿€]/g, (a) => {
    return latinMap[a] || a;
  });
  latin = latin.replace(/[!-/:-@[-^`{-¿€]/g, "");
  return latin;
};

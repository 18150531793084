import { useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import LabelSearchForm from "./LabelSearchForm";
import LabelTable from "./LabelTable";
import PropTypes from "prop-types";
import TblLabel from "../../../constants/TblLabel.json";

const extraData = {
  headCells: TblLabel.headCells,
  initialValues: {
    seqLabelId: null,
    codLabel: "",
    desLabel: "",
    codLang: "",
  },
};

const LabelTab = ({ data, actions }) => {
  const [selected, setSelected] = useState({});
  data = useMemo(() => ({ ...data, ...extraData, selected }), [data, selected]);
  actions = useMemo(() => ({ ...actions, setSelected }), [actions, setSelected]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <LabelSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <LabelTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

LabelTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default LabelTab;

import {
  ADD_ELEMENTS_CONTESTANTPAGE,
  API_CONTESTANT_ADD,
  API_CONTESTANT_DELETE,
  API_CONTESTANT_EDIT,
  API_CONTESTANT_NEW_WINNER,
  API_CONTESTANT_SEARCH,
  API_CONTESTANT_WINNER,
  API_PARTICIPATE,
  CLEAR_CONTESTANT_TABLE,
  SET_SCREEN_CONTESTANTPAGE,
} from "../constants";
import {
  apiAddContestant,
  apiDeleteContestant,
  apiGenerateContestWinners,
  apiGetContestWinners,
  apiParticipateContest,
  apiSearchContestants,
  apiUpdateContestant,
} from "../api/contestantAPI";
import {
  urlAddContestant,
  urlDeleteContestant,
  urlGenerateContestWinners,
  urlGetContestWinners,
  urlParticipateContest,
  urlSearchContestants,
  urlUpdateContestant,
} from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_CONTESTANTPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_CONTESTANTPAGE, (contest) => apiSearchContestants(urlSearchContestants, contest)());
export const actionSearchContestants = createAction(API_CONTESTANT_SEARCH, (contest) => apiSearchContestants(urlSearchContestants, contest)());
export const actionGetContestWinners = createAction(API_CONTESTANT_WINNER, (contest) => apiGetContestWinners(urlGetContestWinners, contest)());
export const actionGenerateContestWinners = createAction(API_CONTESTANT_NEW_WINNER, (contest) => apiGenerateContestWinners(urlGenerateContestWinners, contest)());
export const actionAddContestant = createAction(API_CONTESTANT_ADD, (contestant) => apiAddContestant(urlAddContestant, contestant)());
export const actionUpdateContestant = createAction(API_CONTESTANT_EDIT, (contestant) => apiUpdateContestant(urlUpdateContestant, contestant)());
export const actionDeleteContestant = createAction(API_CONTESTANT_DELETE, ({ seqContestant }) => apiDeleteContestant(urlDeleteContestant, seqContestant)());
export const actionParticipate = createAction(API_PARTICIPATE, (contestant) => apiParticipateContest(urlParticipateContest, contestant)());
export const actionClearContestantTable = createAction(CLEAR_CONTESTANT_TABLE);

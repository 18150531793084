import { searchData } from "../utils/serviceUtils";

export const apiSearchBandUserVotes = (url, obj) => async () => {
  const { codGroup } = obj;
  url = `${url}?codGroup=${codGroup}`;
  return searchData(url, obj);
};

export const apiSearchUserVotes = (url, obj) => async () => {
  const { user } = obj;
  url = `${url}?user=${user}`;
  return searchData(url, obj);
};

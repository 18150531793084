import Badge from "@material-ui/core/Badge";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import { Properties } from "../../../constants/properties";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblBandFile from "./TblBandFile/TblBandFile";
import TblBandReview from "./TblBandReview/TblBandReview";
import TblBandVideo from "./TblBandVideo/TblBandVideo";
import TblTabPanel from "../../TblTabPanel";
import { useBandDetails } from "../../../hooks/TblBand/useBandDetails";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblBandDetails = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const band = useSelector((state) => state.tblBand.band);

  const { data, actions } = useBandDetails();
  const { topRef, tab, videos, reviews, isLoading } = data;
  const { handleChange, setVideos, setReviews, setIsLoading } = actions;

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:site_name" content="TourBandLive" />
        <meta property="og:url" content={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/groups/${band?.tblGroup?.codGroup}`} />
        <meta property="og:title" content={band?.tblGroup?.name} />
        <meta property="og:description" content={band?.description} />
        <meta property="og:image" itemprop="image" content={`${Properties.URL_BASE}${band?.tblGroup?.photoGroup}`} />
      </Helmet>
      <div className={classes.pTopPage} ref={topRef}></div>
      <Paper>
        <Tabs value={tab} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="band details tabs">
          <Tab label={labels["TBL_BANDDETAILS_APPBAR_TABPANEL_FILE"]} />

          <Tab
            label={
              <Badge badgeContent={videos} color="secondary">
                {labels["TBL_COMMON_LABEL_BANDVIDEOS"]}
              </Badge>
            }
          />

          <Tab
            label={
              <Badge badgeContent={reviews} color="secondary">
                {labels["TBL_COMMON_LABEL_BANDREVIEWS"]}
              </Badge>
            }
          />
        </Tabs>
      </Paper>
      <div className={classes.mt1}></div>
      <TblTabPanel value={tab} index={0}>
        <TblBandFile isLoading={isLoading} />
      </TblTabPanel>
      <TblTabPanel value={tab} index={1}>
        <TblBandVideo topRef={topRef} setVideos={setVideos} isLoading={isLoading} />
      </TblTabPanel>
      <TblTabPanel value={tab} index={2}>
        <TblBandReview topRef={topRef} setReviews={setReviews} isLoading={isLoading} setIsLoading={setIsLoading} />
      </TblTabPanel>
    </React.Fragment>
  );
};

export default TblBandDetails;

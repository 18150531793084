import "./App.css";
import "fontsource-roboto";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import ActiveUserPage from "./pages/ActiveUserPage";
import AddBandPage from "./pages/AddBandPage";
import Backdrop from "@material-ui/core/Backdrop";
import BandDetailsPage from "./pages/BandDetailsPage";
import BandPage from "./pages/BandPage";
import ChangePassPage from "./pages/ChangePassPage";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContestPage from "./pages/ContestPage";
import HomePage from "./pages/HomePage";
import LoginAdminPage from "./pages/LoginAdminPage";
import LoginPage from "./pages/LoginPage";
import MaintainPage from "./pages/MaintainPage";
import ProfilePage from "./pages/ProfilePage";
import { Properties } from "./constants/properties";
import { ROLE_ADMIN } from "./constants";
import TblNotFound from "./components/TblNotFound";
import TblNotifier from "./components/TblNotifer/TblNotifier";
import TblPrivateRoute from "./components/TblPrivateRoute/TblPrivateRoute";
import { actionGetErrorMessages } from "./actions/errorMessageActions";
import { actionGetLabels } from "./actions/labelActions";
import { actionGetTemplates } from "./actions/templateActions";
import { actionGetUserSession } from "./actions/userActions";
import { callService } from "./utils/serviceUtils";
import { useDispatch } from "react-redux";
import { useState } from "react";

const App = () => {
  const [loadPage, setLoadPage] = useState(false);
  const [loadErrorPage, setLoadErrorPage] = useState(false);
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState("none");

  if (!loadPage && !loadErrorPage) {
    callService(dispatch, actionGetUserSession).then(
      () => {
        callService(dispatch, actionGetTemplates);
        Promise.all([callService(dispatch, actionGetLabels), callService(dispatch, actionGetErrorMessages)]).then(
          (responses) => {
            const commonError = responses[1].find((error) => error.tblErrorMessage.codMessage === "TBL_0001");
            Properties.setCOMMON_ERROR(commonError.text);
            const initialValue = {};
            const errorMessages = responses[1].reduce((acc, errorMessage) => {
              return { ...acc, [errorMessage.tblErrorMessage.codMessage]: errorMessage.text };
            }, initialValue);
            Properties.setERRORS(errorMessages);
            setLoadPage(true);
          },
          () => {
            setLoadErrorPage(true);
          }
        );
      },
      () => {
        setLoadErrorPage(true);
      }
    );
  }
  return (
    <div>
      {loadPage ? (
        <div>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route exact path="/login" component={() => <LoginPage setScroll={setScroll} />} />
              <Route exact path="/loginadmin" component={() => <LoginAdminPage />} />
              <TblPrivateRoute exact path="/" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <HomePage scroll={scroll} setScroll={setScroll} />} />
              <TblPrivateRoute path="/maintenance" enviroment={process.env.REACT_APP_ENVIROMENT} roles={ROLE_ADMIN} component={() => <MaintainPage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="/groups" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <BandPage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="/groups/add" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <AddBandPage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="/groups/:codGroup" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <BandDetailsPage setScroll={setScroll} />} />
              <TblPrivateRoute path="/contests" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <ContestPage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="/profile" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <ProfilePage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="/profile/active" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <ActiveUserPage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="/profile/changepassword" enviroment={process.env.REACT_APP_ENVIROMENT} component={() => <ChangePassPage setScroll={setScroll} />} />
              <TblPrivateRoute exact path="*" enviroment={process.env.REACT_APP_ENVIROMENT} component={TblNotFound} />
            </Switch>
          </Router>
          <TblNotifier />
        </div>
      ) : loadErrorPage ? (
        <div>Error Page</div>
      ) : (
        <Backdrop open={!loadPage}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default App;

import { useEffect, useRef } from "react";

import PropTypes from "prop-types";
import TblAbout from "./TblAbout";
import TblContactForm from "./TblContact";
import TblDownload from "./TblDownload";
import TblRanking from "./TblRanking";
import TblServices from "./TblServices";
import { useStyles } from "../../theme/useStyles";

const TblHome = ({ scroll, setScroll }) => {
  const classes = useStyles();
  const serviceRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    if (scroll === "services") {
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
      setScroll("none");
    } else if (scroll === "contact") {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
      setScroll("none");
    } else if (scroll === "home") {
      window.scrollTo(0, 0);
    }
  }, [serviceRef, contactRef, scroll, setScroll]);

  return (
    <div>
      <div className={classes.bgTexture}>
        <TblAbout />
      </div>
      <div ref={serviceRef}>
        <TblServices />
        <TblRanking />
      </div>
      <div className={classes.bgDark}>
        <TblDownload />
      </div>
      <div ref={contactRef}>
        <TblContactForm />
      </div>
    </div>
  );
};

TblHome.propTypes = {
  scroll: PropTypes.string,
  setScroll: PropTypes.func.isRequired,
};

export default TblHome;

import React, { useEffect } from "react";

import PropTypes from "prop-types";
import TblFrame from "../components/TblFrame";
import TblLogin from "../components/TblLogin";

const LoginPage = ({ setScroll }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <TblFrame setScroll={setScroll} size={12} hideCarousel={true}>
      <TblLogin />
    </TblFrame>
  );
};

LoginPage.propTypes = {
  setScroll: PropTypes.func.isRequired,
};

export default LoginPage;

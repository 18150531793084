import {
  ADD_ELEMENTS_IMAGES_PATHPAGE,
  API_IMAGES_PATH_ADD,
  API_IMAGES_PATH_DELETE,
  API_IMAGES_PATH_EDIT,
  API_IMAGES_PATH_GET,
  API_IMAGES_PATH_SEARCH,
  CLEAR_IMAGES_PATH_TABLE,
  NO_ACTION,
  SET_SCREEN_IMAGES_PATHPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_timagesPath = {
  imagesPaths: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblImagesPath = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_IMAGES_PATH_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const imagesPathPayload = action.payload;
      const imagesPath = imagesPathPayload.map((image) => ({ seq: image.seqImagesPath, imageName: image.imageName, imagePath: image.imagePath, imageUrl: image.url }));
      return { ...state, [imagesPathPayload[0].imageGroup]: imagesPath };
    },
    [API_IMAGES_PATH_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        timagesPaths: {
          imagesPaths: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_IMAGES_PATH_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.imagesPaths;
      state.timagesPaths.totalElements += 1;

      return { ...state, timagesPaths: { ...state.timagesPaths, imagesPaths: [action.payload].concat(state.timagesPaths?.imagesPaths || []) } };
    },
    [API_IMAGES_PATH_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const imagePathPayload = action.payload;
      const { seqImagesPath } = imagePathPayload;
      const imagePaths = state.timagesPaths?.imagesPaths || [];
      const newImagesPath = imagePaths.map((imagePath) => {
        if (imagePath.seqImagesPath === seqImagesPath) {
          return imagePathPayload;
        } else {
          return imagePath;
        }
      });
      delete state.imagesPaths;
      return { ...state, timagesPaths: { ...state.timagesPaths, imagesPaths: newImagesPath } };
    },
    [API_IMAGES_PATH_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.imagesPaths;
      state.timagesPaths.totalElements -= 1;
      return { ...state, timagesPaths: { ...state.timagesPaths, imagesPaths: state.timagesPaths.imagesPaths.filter((c) => c.seqImagesPath !== action.payload) } };
    },
    [ADD_ELEMENTS_IMAGES_PATHPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        timagesPaths: {
          imagesPaths: [...state.timagesPaths.imagesPaths, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.timagesPaths?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_IMAGES_PATHPAGE]: (state, action) => ({ ...state, timagesPaths: { ...state.timagesPaths, screenPage: action.payload } }),
    [CLEAR_IMAGES_PATH_TABLE]: (state) => ({ ...state, timagesPaths: initial_timagesPath }),
  },
  { timagesPaths: initial_timagesPath }
);

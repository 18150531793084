import { ADD_ELEMENTS_LABELPAGE, API_LABEL_ADD, API_LABEL_DELETE, API_LABEL_EDIT, API_LABEL_GET, API_LABEL_SEARCH, CLEAR_LABEL_TABLE, SET_SCREEN_LABELPAGE } from "../constants";
import { apiAddLabel, apiDeleteLabel, apiGetLabels, apiSearchLabels, apiUpdateLabel } from "../api/labelAPI";
import { urlAddLabel, urlDeleteLabel, urlGetLabels, urlSearchLabels, urlUpdateLabel } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_LABELPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_LABELPAGE, (label) => apiSearchLabels(urlSearchLabels, label)());
export const actionGetLabels = createAction(API_LABEL_GET, apiGetLabels(urlGetLabels));
export const actionSearchLabels = createAction(API_LABEL_SEARCH, (label) => apiSearchLabels(urlSearchLabels, label)());
export const actionAddLabel = createAction(API_LABEL_ADD, (label) => apiAddLabel(urlAddLabel, label)());
export const actionUpdateLabel = createAction(API_LABEL_EDIT, (label) => apiUpdateLabel(urlUpdateLabel, label)());
export const actionDeleteLabel = createAction(API_LABEL_DELETE, ({ seqLabelId }) => apiDeleteLabel(urlDeleteLabel, seqLabelId)());
export const actionClearLabelTable = createAction(CLEAR_LABEL_TABLE);

import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import TblLinearProgress from "../../TblLinearProgress";
import Typography from "@material-ui/core/Typography";
import { actionLoginSocial } from "../../../actions/userActions";
import { callService } from "../../../utils/serviceUtils";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../../../theme/useStyles";

const customStyles = makeStyles(() => ({
  root: {
    display: "flex",
    backgroundColor: "rgb(45,45,45)",
    color: "#fff",
    width: "100%",
  },
  content: {
    paddingBottom: "0px!important",
  },
  cover: {
    backgroundColor: "#fff",
    width: 50,
    height: 50,
  },
}));

const TblSignupOptions = ({ data, actions }) => {
  const classes = useStyles();
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { isLoading, screens, topRef } = data;
  const { setIsLoading, setShowScreen, setInitialValues, handleReCaptchaVerify } = actions;

  const processResponse = useCallback(
    (response) => {
      if (!response?.isNew) {
        history.push(params.get("pathname"));
      } else {
        setShowScreen(screens.signupSocialForm);
        setInitialValues({
          id: response.id,
          user: response.name,
          email: response.email,
          password: "",
          confpass: "",
          birthDate: null,
          codProvince: "",
          photoProfile: response.photoProfile,
          socialName: response.socialName,
        });
      }
      setIsLoading(false);
    },
    [history, params, screens.signupSocialForm, setInitialValues, setIsLoading, setShowScreen]
  );

  const responseFacebook = useCallback(
    async (response) => {
      setIsLoading(true);
      const result = await handleReCaptchaVerify();
      if (result) {
        const request = {
          id: response?.userID,
          name: response?.name,
          email: response?.email,
          photoProfile: response?.picture?.data?.url,
          socialName: "FACEBOOK",
          token: response?.signedRequest,
        };
        const res = await callService(dispatch, actionLoginSocial, request);
        processResponse(res);
      } else {
        setIsLoading(false);
      }
    },
    [dispatch, handleReCaptchaVerify, processResponse, setIsLoading]
  );

  const responseGoogle = useCallback(
    async (response) => {
      setIsLoading(true);
      const result = await handleReCaptchaVerify();
      if (result && !response.error) {
        const request = {
          id: response?.profileObj?.googleId,
          name: response?.profileObj?.givenName,
          email: response?.profileObj?.email,
          photoProfile: response?.profileObj.imageUrl,
          socialName: "GOOGLE",
          token: response?.tokenId,
        };
        const res = await callService(dispatch, actionLoginSocial, request);
        processResponse(res);
      } else {
        setIsLoading(false);
      }
    },
    [dispatch, handleReCaptchaVerify, processResponse, setIsLoading]
  );

  const handleShowSignupForm = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.signupForm);
    setInitialValues({ id: "", user: "", email: "", password: "", confpass: "", birthDate: null, codProvince: "", photoProfile: "", socialName: "" });
  };

  const handleShowLoginOptions = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.loginOptions);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          render={(renderProps) => (
            <Button onClick={renderProps.onClick} disabled={isLoading} fullWidth>
              <Card className={cClasses.root}>
                <CardMedia className={cClasses.cover} image={"/images/application/facebooklogo.svg"} title="facebook-logo" />
                <CardContent className={cClasses.content}>
                  <Typography align="left" variant="caption">
                    {labels["TBL_LOGIN_BUTTON_SIGNUP_FACEBOOK"]}
                  </Typography>
                </CardContent>
              </Card>
            </Button>
          )}
          fields="name,email,picture"
          callback={responseFacebook}
          version={3.3}
          redirectUri={window.location.href.substring(0, window.location.href.indexOf("?"))}
          disableMobileRedirect={true}
          isDisabled={isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          render={(renderProps) => (
            <Button onClick={renderProps.onClick} disabled={renderProps.disabled} fullWidth>
              <Card className={cClasses.root}>
                <CardMedia className={cClasses.cover} image={"/images/application/googlelogo.svg"} title="google-logo" />
                <CardContent className={cClasses.content}>
                  <Typography align="left" variant="caption">
                    {labels["TBL_LOGIN_BUTTON_SIGNUP_GOOGLE"]}
                  </Typography>
                </CardContent>
              </Card>
            </Button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth onClick={handleShowSignupForm} disabled={isLoading}>
          <Card className={cClasses.root}>
            <CardMedia className={cClasses.cover} image={"/images/application/tblimagelogosimple.png"} title="tbl-logo" />
            <CardContent className={cClasses.content}>
              <Typography align="left" variant="caption">
                {labels["TBL_LOGIN_BUTTON_SIGNUP_TOURBANDLIVE"]}
              </Typography>
            </CardContent>
          </Card>
        </Button>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Grid item xs={10}>
          {isLoading && <TblLinearProgress />}
        </Grid>
      </Box>
      <div className={classes.mt1}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">{labels["TBL_LOGIN_MSG_LOGIN"]}</Typography>
        <div className={classes.mrxs}></div>
        <Link onClick={handleShowLoginOptions} component="button" variant="body2">
          {labels["TBL_LOGIN_BUTTON_LOGIN"]}
        </Link>
      </Box>
    </React.Fragment>
  );
};

TblSignupOptions.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblSignupOptions;

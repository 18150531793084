export class TblStyle {
  constructor(seqStyle, codStyle, desStyle, dateCre, dateMod, userCre, userMod) {
    this.seqStyle = seqStyle;
    this.codStyle = codStyle;
    this.desStyle = desStyle;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

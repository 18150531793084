import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblLanguage } from "../model/TblLanguage";

export const apiGetLangs = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchLangs = (url, obj) => async () => {
  const { codLang, desLang } = obj;
  url = `${url}?codLang=${codLang}&desLang=${desLang}`;
  return searchData(url, obj);
};

export const apiAddLang = (url, obj) => async () => {
  const { seqLanguage, codLang, desLang } = obj;
  const request = new TblLanguage(seqLanguage, codLang, desLang);
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateLang = (url, obj) => async () => {
  const { seqLanguage, codLang, desLang, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqLanguage}`;
  const request = new TblLanguage(seqLanguage, codLang, desLang, dateCre, dateMod, userCre, userMod);
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteLang = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

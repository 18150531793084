import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblSearchForm from "../../TblSearchForm";
import TblTextField from "../../../TblForms/TblTextField";
import { actionSearchStyles } from "../../../../actions/styleActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const StyleSearchForm = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const { initialValues, headCells } = data;
  const { setSelected } = actions;

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.orderBy = headCells[1].path;
      callService(dispatch, actionSearchStyles, values).finally(() => {
        setSelected({});
        setSubmitting(false);
      });
    },
    [dispatch, headCells, setSelected]
  );

  return (
    <TblSearchForm initialValues={initialValues} onSubmit={handleSubmit}>
      <Grid item xs={8}>
        <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
      </Grid>
      <Grid item xs={8}>
        <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
      </Grid>
    </TblSearchForm>
  );
};

StyleSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default StyleSearchForm;

import React, { useEffect, useMemo, useState } from "react";
import { actionClearBandLinkTable, actionSearchBandLinks } from "../../../actions/bandLinkActions";
import { actionClearBandProvinceTable, actionSearchBandProvinces } from "../../../actions/bandProvinceActions";
import { actionClearBandReviewTable, actionSearchBandReviews } from "../../../actions/bandReviewActions";
import { actionClearBandStyleTable, actionSearchBandStyles } from "../../../actions/bandStyleActions";
import { actionClearBandUserVoteTable, actionSearchBandUserVotes } from "../../../actions/bandUserVoteActions";
import { actionClearBandVideoTable, actionSearchBandVideos } from "../../../actions/bandVideoActions";
import { useDispatch, useSelector } from "react-redux";

import BandLinkTable from "./BandLinkTable/BandLinkTable";
import BandProvinceTable from "./BandProvinceTable";
import BandReviewTable from "./BandReviewTable/BandReviewTable";
import BandStyleTable from "./BandStyleTable/BandStyleTable";
import BandTable from "./BandTable/BandTable";
import BandUserVoteTable from "./BandUserVoteTable/BandUserVoteTable";
import BandVideoTable from "./BandVideoTable/BandVideoTable";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblTabPanel from "../../TblTabPanel";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";
import { useStyles } from "../../../theme/useStyles";

const BandContainer = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const [value, setValue] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    headCellsBands,
    headCellsBandProvinces,
    headCellsBandStyles,
    headCellsBandLinks,
    headCellsBandVideos,
    headCellsBandUserVotes,
    headCellsBandReviews,
    initialValuesBand,
    initialValuesBandProvince,
    initialValuesBandStyle,
    initialValuesBandLink,
    initialValuesBandVideo,
    initialValuesBandReview,
    selecBand,
    childrens,
  } = data;
  const { setSelecBand, setIsLoading, setChildrens } = actions;

  const dispatch = useDispatch();
  useEffect(() => {
    if ((!_.isEmpty(selecBand) && selecBand?.seqGroupId !== childrens?.seqGroupId) || isUpdated) {
      setIsUpdated(false);
      setIsLoading(true);
      const bandProvincesRQ = {
        codGroup: selecBand.tblGroup.codGroup,
        codCountry: selecBand.tblGroup.tblCountry.codCountry,
        orderBy: headCellsBandProvinces[1].path,
      };
      const bandStyleRQ = {
        codGroup: selecBand.tblGroup.codGroup,
        orderBy: headCellsBandStyles[1].path,
      };
      const bandLinkRQ = {
        codGroup: selecBand.tblGroup.codGroup,
        orderBy: headCellsBandLinks[2].path,
      };
      const bandVideoRQ = {
        codGroup: selecBand.tblGroup.codGroup,
        orderBy: headCellsBandVideos[2].path,
      };
      const bandUserVoteRQ = {
        codGroup: selecBand.tblGroup.codGroup,
        orderBy: headCellsBandUserVotes[2].path,
      };
      const bandReviewRQ = {
        codGroup: selecBand.tblGroup.codGroup,
        orderBy: headCellsBandReviews[2].path,
      };
      Promise.all([
        callService(dispatch, actionSearchBandProvinces, bandProvincesRQ),
        callService(dispatch, actionSearchBandStyles, bandStyleRQ),
        callService(dispatch, actionSearchBandLinks, bandLinkRQ),
        callService(dispatch, actionSearchBandVideos, bandVideoRQ),
        callService(dispatch, actionSearchBandUserVotes, bandUserVoteRQ),
        callService(dispatch, actionSearchBandReviews, bandReviewRQ),
      ]).then((responses) => {
        setChildrens({
          seqGroupId: selecBand.seqGroupId,
          provinces:
            responses[0] && responses[0].length > 0
              ? responses[0].reduce((acc, bandProvince) => {
                  return [
                    ...acc,
                    {
                      seq: bandProvince.seqProvince,
                      value: { seq: bandProvince.tblProvince.seqProvince, value: bandProvince.tblProvince.codProvince, label: bandProvince.tblProvince.desProvince },
                    },
                  ];
                }, [])
              : [],
          styles:
            responses[1] && responses[1].length > 0
              ? responses[1].reduce((acc, bandStyle) => {
                  return [
                    ...acc,
                    {
                      seq: bandStyle.seqStyle,
                      value: { seq: bandStyle.tblStyle.seqStyle, value: bandStyle.tblStyle.codStyle, label: bandStyle.tblStyle.desStyle },
                    },
                  ];
                }, [])
              : [],
          links:
            responses[2] && responses[2].length > 0
              ? responses[2].reduce((acc, bandLink) => {
                  return { ...acc, [bandLink.namePage]: { seq: bandLink.seqLink, value: bandLink.urlPage, label: bandLink.namePage } };
                }, {})
              : [],
          videos:
            responses[3] && responses[3].length > 0
              ? responses[3].reduce((acc, bandVideo) => {
                  return [
                    ...acc,
                    {
                      seq: bandVideo.seqVideo,
                      value: { seqVideo: bandVideo.seqVideo, videoId: bandVideo.videoId, urlVideo: bandVideo.urlVideo, reviewed: bandVideo.reviewed },
                    },
                  ];
                }, [])
              : [],
        });

        setIsLoading(false);
      });
    } else if (_.isEmpty(selecBand) && !_.isEmpty(childrens)) {
      setChildrens({});
      Promise.all([
        dispatch(actionClearBandProvinceTable()),
        dispatch(actionClearBandStyleTable()),
        dispatch(actionClearBandLinkTable()),
        dispatch(actionClearBandVideoTable()),
        dispatch(actionClearBandUserVoteTable()),
        dispatch(actionClearBandReviewTable()),
      ]);
    }
  }, [
    dispatch,
    selecBand,
    headCellsBandProvinces,
    headCellsBandStyles,
    headCellsBandLinks,
    headCellsBandVideos,
    headCellsBandUserVotes,
    headCellsBandReviews,
    setIsLoading,
    childrens,
    setChildrens,
    isUpdated,
  ]);

  actions = useMemo(
    () => ({
      ...actions,
      setIsUpdated,
    }),
    [actions]
  );

  return (
    <div>
      <BandTable headCells={headCellsBands} initialValues={initialValuesBand} selected={selecBand} setSelected={setSelecBand} data={data} actions={actions} />
      <div className={classes.pt2}></div>
      <Paper>
        <Tabs value={value} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="band tabs">
          <Tab label={labels["TBL_COMMON_LABEL_PROVINCES"]} />
          <Tab label={labels["TBL_COMMON_LABEL_STYLES"]} />
          <Tab label={labels["TBL_BANDCONTAINER_APPBAR_TABPANEL_BANDLINKS"]} />
          <Tab label={labels["TBL_COMMON_LABEL_BANDVIDEOS"]} />
          <Tab label={labels["TBL_COMMON_LABEL_VOTES"]} />
          <Tab label={labels["TBL_COMMON_LABEL_BANDREVIEWS"]} />
        </Tabs>
      </Paper>
      <TblTabPanel value={value} index={0}>
        <BandProvinceTable headCells={headCellsBandProvinces} initialValues={initialValuesBandProvince} masterSelected={selecBand} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={1}>
        <BandStyleTable headCells={headCellsBandStyles} initialValues={initialValuesBandStyle} masterSelected={selecBand} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={2}>
        <BandLinkTable headCells={headCellsBandLinks} initialValues={initialValuesBandLink} masterSelected={selecBand} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={3}>
        <BandVideoTable headCells={headCellsBandVideos} initialValues={initialValuesBandVideo} masterSelected={selecBand} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={4}>
        <BandUserVoteTable headCells={headCellsBandUserVotes} masterSelected={selecBand} data={data} actions={actions} />
      </TblTabPanel>
      <TblTabPanel value={value} index={5}>
        <BandReviewTable headCells={headCellsBandReviews} initialValues={initialValuesBandReview} masterSelected={selecBand} data={data} actions={actions} />
      </TblTabPanel>
    </div>
  );
};

BandContainer.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandContainer;

import {
  ADD_ELEMENTS_BAND_REVIEWPAGE,
  API_BAND_REVIEW,
  API_BAND_REVIEW_ADD,
  API_BAND_REVIEW_ASSESS_ADD,
  API_BAND_REVIEW_CREATE,
  API_BAND_REVIEW_DELETE,
  API_BAND_REVIEW_EDIT,
  API_BAND_REVIEW_SEARCH,
  CLEAR_BAND_REVIEW_TABLE,
  NO_ACTION,
  SET_SCREEN_BAND_REVIEWPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tbandReview = {
  bandReviews: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBandReview = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_REVIEW]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, bandReviews: action.payload || [] };
    },
    [API_BAND_REVIEW_CREATE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, bandReviews: [action.payload].concat(state.bandReviews || []) };
    },
    [API_BAND_REVIEW_ASSESS_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const reviewAssessPayload = action.payload;
      const { seqReview } = reviewAssessPayload;
      const bandReviews = state.bandReviews || [];
      const newBandReviews = bandReviews.map((review) => {
        let newAssessment = review.assessment;
        if (review.seqReview === seqReview) {
          if (reviewAssessPayload.assessment === 0) {
            newAssessment -= 1;
          } else {
            newAssessment += 1;
          }
        }
        return {
          ...review,
          assessment: newAssessment,
        };
      });
      return { ...state, bandReviews: newBandReviews };
    },
    [API_BAND_REVIEW_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandReviews: {
          bandReviews: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_BAND_REVIEW_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandReviews.totalElements += 1;
      const reviewPayload = action.payload;

      return { ...state, tbandReviews: { ...state.tbandReviews, bandReviews: [reviewPayload].concat(state.tbandReviews?.bandReviews || []) } };
    },
    [API_BAND_REVIEW_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const reviewPayload = action.payload;
      const { seqReview } = reviewPayload;
      const bandReviews = state.tbandReviews?.bandReviews || [];
      const newBandReviews = bandReviews.map((bandReview) => {
        if (bandReview.seqReview === seqReview) {
          return reviewPayload;
        } else {
          return bandReview;
        }
      });
      return { ...state, tbandReviews: { ...state.tbandReviews, bandReviews: newBandReviews } };
    },
    [API_BAND_REVIEW_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandReviews.totalElements -= 1;
      return { ...state, tbandReviews: { ...state.tbandReviews, bandReviews: state.tbandReviews.bandReviews.filter((c) => c.seqReview !== action.payload) } };
    },
    [ADD_ELEMENTS_BAND_REVIEWPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandReviews: {
          bandReviews: [...state.tbandReviews.bandReviews, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbandReviews?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BAND_REVIEWPAGE]: (state, action) => ({ ...state, tbandReviews: { ...state.tbandReviews, screenPage: action.payload } }),
    [CLEAR_BAND_REVIEW_TABLE]: (state) => ({ ...state, tbandReviews: initial_tbandReview }),
  },
  { tbandReviews: initial_tbandReview, bandReviews: [] }
);

import { Redirect, Route } from "react-router-dom";

import PropTypes from "prop-types";
import { ROLE_ADMIN } from "../../constants";
import React from "react";
import { useSelector } from "react-redux";

const TblPrivateRoute = ({ component: Component, roles, enviroment, ...rest }) => {
  const userSession = useSelector((state) => state.tblUser.userSession);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (enviroment && enviroment === "SIT") {
          if (userSession.applicationRole.includes(ROLE_ADMIN)) {
            return <Component {...props} />;
          } else {
            return <Redirect to={{ pathname: "/loginadmin", state: { from: props.location } }} />;
          }
        } else {
          if (!userSession) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
          }

          // check if route is restricted by role
          if (roles && !userSession.applicationRole.includes(roles)) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: "/" }} />;
          }

          // authorised so return component
          return <Component {...props} />;
        }
      }}
    />
  );
};

TblPrivateRoute.propTypes = {
  Component: PropTypes.node,
  roles: PropTypes.string,
  enviroment: PropTypes.string.isRequired,
};

export default TblPrivateRoute;

import { VALUE_N, VALUE_S } from "../constants";
import { convert2UtcISOString, encryptData } from "../utils/dataUtils";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { Properties } from "../constants/properties";
import { TblCountry } from "../model/TblCountry";
import { TblLanguage } from "../model/TblLanguage";
import { TblProvince } from "../model/TblProvince";
import { TblUser } from "../model/TblUser";

export const apiLogin = (url, obj) => async () => {
  const { user, password, remember } = obj;
  const request = {
    user,
    password: encryptData(password, Properties.PASSPHRASE),
    remember,
    four: Properties.FOUR,
    salt: Properties.SALT,
  };
  const { data } = await doPost(url, request);
  return data;
};

export const apiLoginSocial = (url, obj) => async () => {
  const { data } = await doPost(url, obj);
  return data;
};

export const apiSignUp = (url, obj) => async () => {
  const { id, user, email, password, birthDate, codProvince, photoProfile, socialName } = obj;

  const request = {
    id,
    user,
    email,
    birthdate: convert2UtcISOString(birthDate),
    codProvince: codProvince.value,
    password,
    photoProfile,
    socialName,
  };
  const { data } = await doPost(url, request);
  return data;
};

export const apiGet = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiActiveUser = (url, activeObject) => async () => {
  url = `${url}/${activeObject}`;
  const { data } = await doPut(url);
  return data;
};

export const apiRememberPassEmail = (url, obj) => async () => {
  url = `${url}?email=${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiChangePassword = (url, changePassObject) => async () => {
  url = `${url}/${changePassObject.validObject}`;
  const request = {
    user: undefined,
    password: changePassObject.password,
    remember: undefined,
    four: undefined,
    salt: undefined,
  };
  const { data } = await doPut(url, request);
  return data;
};

export const apiLogout = (url) => async () => {
  const { data } = await doPost(url);
  return data;
};

export const apiValidUserName = (url, obj) => async () => {
  url = `${url}?userName=${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiValidUserEmail = (url, obj) => async () => {
  url = `${url}?userEmail=${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchUsers = (url, obj) => async () => {
  const {
    user,
    name,
    lastName,
    email,
    birthDate,
    userLang,
    codCountry,
    codProvince,
    isLinkedFacebook,
    isLinkedGoogle,
    active,
    applicationRole,
    notifications,
    dateCreFrom,
    dateCreTo,
  } = obj;

  url = `${url}?user=${user}&name=${name}&lastName=${lastName}&email=${email}&birthDate=${convert2UtcISOString(birthDate) || ""}&userLang=${userLang?.value || ""}&codCountry=${
    codCountry?.value || ""
  }&codProvince=${codProvince?.value || ""}&isLinkedFacebook=${isLinkedFacebook?.value || ""}&isLinkedGoogle=${isLinkedGoogle?.value || ""}&active=${
    active?.value || ""
  }&applicationRole=${applicationRole?.value || ""}&notifications=${notifications?.value || ""}&startDate=${convert2UtcISOString(dateCreFrom) || ""}&endDate=${
    convert2UtcISOString(dateCreTo) || ""
  }`;
  return searchData(url, obj);
};

export const apiAddUser = (url, obj) => async () => {
  const { seqUser, user, password, name, lastName, email, birthDate, userLang, codCountry, codProvince, applicationRole, active, notifications, croppedImage } = obj;
  const request = new TblUser(
    seqUser,
    user,
    password,
    undefined,
    undefined,
    undefined,
    undefined,
    name,
    lastName,
    email,
    convert2UtcISOString(birthDate),
    new TblLanguage(userLang?.seq, userLang?.value, userLang?.label),
    new TblCountry(codCountry.seq, codCountry.value),
    new TblProvince(codProvince.seq, new TblCountry(codCountry.seq, codCountry.value), codProvince.value, codProvince.label),
    undefined,
    applicationRole.value,
    active ? VALUE_S : VALUE_N,
    undefined,
    notifications ? VALUE_S : VALUE_N
  );
  request.image = croppedImage;
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateUser = (url, obj) => async () => {
  const {
    seqUser,
    user,
    password,
    name,
    lastName,
    email,
    birthDate,
    userLang,
    codCountry,
    codProvince,
    applicationRole,
    active,
    notifications,
    photoProfile,
    croppedImage,
    dateCre,
    dateMod,
    userCre,
    userMod,
  } = obj;
  url = `${url}/${seqUser}`;
  const request = new TblUser(
    seqUser,
    user,
    password,
    undefined,
    undefined,
    undefined,
    undefined,
    name,
    lastName,
    email,
    convert2UtcISOString(birthDate),
    new TblLanguage(userLang?.seq, userLang?.value, userLang?.label),
    new TblCountry(codCountry.seq, codCountry.value),
    new TblProvince(codProvince.seq, new TblCountry(codCountry.seq, codCountry.value), codProvince.value, codProvince.label),
    photoProfile,
    applicationRole.value,
    active ? VALUE_S : VALUE_N,
    undefined,
    notifications ? VALUE_S : VALUE_N,
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  request.image = croppedImage;
  const { data } = await doPut(url, request);
  return data;
};

export const apiGetUser = (url, userName) => async () => {
  const uri = `${url}/${userName}`;
  const { data } = await doGet(uri);
  return data;
};

export const apiUpdatePublicUser = (url, obj) => async () => {
  const { seqUser, user, password, name, lastName, email, birthDate, codProvince, notifications, croppedImage } = obj;
  url = `${url}/${seqUser}`;
  const request = new TblUser(
    seqUser,
    user,
    password,
    undefined,
    undefined,
    undefined,
    undefined,
    name,
    lastName,
    email,
    convert2UtcISOString(birthDate),
    undefined,
    undefined,
    new TblProvince(codProvince.seq, undefined, codProvince.value, codProvince.label),
    undefined,
    undefined,
    undefined,
    undefined,
    notifications ? VALUE_S : VALUE_N
  );
  request.image = croppedImage;
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteUser = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

export const apiVinculateAccount = (url, obj) => async () => {
  const { seqUser, id, name, email, photoProfile, socialName, token } = obj;
  url = `${url}/${seqUser}`;
  const request = {
    id,
    name,
    email,
    photoProfile,
    socialName,
    token,
  };

  const { data } = await doPut(url, request);
  return data;
};

export const apiDesVinculateAccount = (url, obj) => async () => {
  const { seqUser } = obj;
  url = `${url}/${seqUser}`;
  const { data } = await doPut(url);
  return data;
};

export const apiUnsubscribeUser = (url, obj) => async () => {
  const { seqUser } = obj;
  url = `${url}/${seqUser}`;
  const { data } = await doDelete(url);
  return data;
};

import {
  ADD_ELEMENTS_CONTEST_BANDPAGE,
  API_CONTEST_BANDS_GET,
  API_CONTEST_BAND_SEARCH,
  API_CONTEST_BAND_VOTES_STATS,
  CLEAR_CONTEST_BAND_TABLE,
  NO_ACTION,
  SET_SCREEN_CONTEST_BANDPAGE,
} from "../constants";

import { TblGroup } from "../model/TblGroup";
import { handleActions } from "redux-actions";

export const initial_tcontestBands = {
  contestBands: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const initial_tcontestBandVotesStats = {
  stats: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblContestBand = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_CONTEST_BAND_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontestBands: {
          contestBands: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_CONTEST_BAND_VOTES_STATS]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontestBandVotesStats: {
          stats: [...(action.payload || [])],
          search: {},
          serverPage: 0,
          screenPage: 0,
          totalElements: Number(action.payload.length),
        },
      };
    },
    [API_CONTEST_BANDS_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const contestBandsPayload = action.payload;
      const contestBands = contestBandsPayload.map(
        (contestBand) => new TblGroup(contestBand.tblGroup.seqGroup, contestBand.tblGroup.codGroup, contestBand.tblGroup.name, undefined, contestBand.tblGroup.photoGroup)
      );
      return { ...state, contestBands: contestBands };
    },
    [ADD_ELEMENTS_CONTEST_BANDPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontestBands: {
          contestBands: [...state.tcontestBands.contestBands, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tcontestBands?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_CONTEST_BANDPAGE]: (state, action) => ({ ...state, tcontestBands: { ...state.tcontestBands, screenPage: action.payload } }),
    [CLEAR_CONTEST_BAND_TABLE]: (state) => ({
      ...state,
      tcontestBands: initial_tcontestBands,
      tcontestBandVotesStats: initial_tcontestBandVotesStats,
      contestBands: [],
    }),
  },
  { tcontestBands: initial_tcontestBands, tcontestBandVotesStats: initial_tcontestBandVotesStats, contestBands: [] }
);

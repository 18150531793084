export class TblContest {
  constructor(
    seqContest,
    codContest,
    contestType,
    contestCodeRequired,
    voteRange,
    contestCode,
    name,
    startDate,
    endDate,
    active,
    photoContest,
    responsibleEmail,
    urlFacebook,
    urlTwitter,
    urlInstagram,
    dateCre,
    dateMod,
    userCre,
    userMod
  ) {
    this.seqContest = seqContest;
    this.codContest = codContest;
    this.contestType = contestType;
    this.contestCodeRequired = contestCodeRequired;
    this.voteRange = voteRange;
    this.contestCode = contestCode;
    this.name = name;
    this.startDate = startDate;
    this.endDate = endDate;
    this.active = active;
    this.photoContest = photoContest;
    this.responsibleEmail = responsibleEmail;
    this.urlFacebook = urlFacebook;
    this.urlTwitter = urlTwitter;
    this.urlInstagram = urlInstagram;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import {
  ADD_ELEMENTS_CONTESTPAGE,
  API_ACTIVE_CONTEST,
  API_CONTEST_ADD,
  API_CONTEST_DELETE,
  API_CONTEST_EDIT,
  API_CONTEST_GET,
  API_CONTEST_SEARCH,
  API_FINISHED_CONTEST,
  API_MY_CONTEST,
  API_UPCOMING_CONTEST,
  CLEAR_CONTEST_TABLE,
  NO_ACTION,
  SET_SCREEN_CONTESTPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tcontest = {
  contests: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblContest = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_CONTEST_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const contestsPayload = action.payload;
      const initialValue = {};
      const contests = contestsPayload.reduce((acc, contest) => {
        return { ...acc, [contest.tblContest.codContest]: contest.tblContest.name };
      }, initialValue);
      return { ...state, contests: contests };
    },
    [API_CONTEST_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontests: {
          contests: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_ACTIVE_CONTEST]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, activeContests: action.payload };
    },
    [API_UPCOMING_CONTEST]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, upcomingContests: action.payload };
    },
    [API_FINISHED_CONTEST]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, finishedContests: action.payload };
    },
    [API_MY_CONTEST]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, myContests: action.payload };
    },
    [API_CONTEST_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tcontests.totalElements += 1;

      return { ...state, tcontests: { ...state.tcontests, contests: [action.payload].concat(state.tcontests?.contests || []) } };
    },
    [API_CONTEST_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const contestPayload = action.payload;
      const { seqContestId } = contestPayload;
      const contests = state.tcontests?.contests || [];
      const newContests = contests.map((contest) => {
        if (contest.seqContestId === seqContestId) {
          return contestPayload;
        } else {
          return contest;
        }
      });
      return { ...state, tcontests: { ...state.tcontests, contests: newContests } };
    },
    [API_CONTEST_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tcontests.totalElements -= 1;
      return { ...state, tcontests: { ...state.tcontests, contests: state.tcontests.contests.filter((c) => c.seqContestId !== action.payload) } };
    },
    [ADD_ELEMENTS_CONTESTPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontests: {
          contests: [...state.tcontests.contests, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tcontests?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_CONTESTPAGE]: (state, action) => ({ ...state, tcontests: { ...state.tcontests, screenPage: action.payload } }),
    [CLEAR_CONTEST_TABLE]: (state) => ({ ...state, tcontests: initial_tcontest }),
  },
  { tcontests: initial_tcontest, contests: [], activeContests: [], upcomingContests: [], finishedContests: [], myContests: [] }
);

import React, { useMemo } from "react";
import { actionAddElementsPage2, actionSearchBandReviews, actionSetCurrentPage } from "../../../../actions/bandReviewActions";

import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupReview } from "../../../../model/TblGroupReview";
import TblTable from "../../TblTable";
import { TblUser } from "../../../../model/TblUser";
import { useSelector } from "react-redux";

const UserReviewTable = ({ headCells, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tbandReviews = useSelector((state) => state.tblBandReview.tbandReviews);

  const isReadOnly = true;

  const { search, serverPage, screenPage, totalElements } = tbandReviews;
  const { selecReview, openReviewAudit } = data;
  const { setSelecReview, setOpenReviewAudit } = actions;

  const rows = useMemo(
    () =>
      tbandReviews.bandReviews.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupReview(
            row.seqReview,
            row.codReview,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            new TblUser(row.tblUser.seqUser, row.tblUser.user),
            row.reviewTitle,
            row.review,
            row.reviewed,
            row.reviewedDate,
            row.revision,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tbandReviews.bandReviews]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_GROUP_USER_VOTE"];

  data = useMemo(
    () => ({
      ...data,
      headCells,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BANDUSER_VOTES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecReview,
      isReadOnly,
    }),
    [data, headCells, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecReview, isReadOnly]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandReviews,
      actionAddElementsPage: actionAddElementsPage2,
      setScreenPage: actionSetCurrentPage,
      setSelected: setSelecReview,
    }),
    [actions, setSelecReview]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenReviewAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblAuditPopup open={openReviewAudit} setOpen={setOpenReviewAudit} selected={selecReview} />
    </div>
  );
};

UserReviewTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default UserReviewTable;

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { isMobileOnly } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";

const customStyles = makeStyles(() => ({
  root: {
    width: isMobileOnly ? 300 : 600,
  },
  media: {
    height: isMobileOnly ? 200 : 400,
  },
}));

const TblCardNoContest = ({ image, text, topRef }) => {
  const cClasses = customStyles();
  return (
    <Card className={cClasses.root}>
      <CardMedia className={cClasses.media} image={image} title="tbl-contest-no-actives" />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

TblCardNoContest.propTypes = { image: PropTypes.object.isRequired, text: PropTypes.string.isRequired };

export default TblCardNoContest;

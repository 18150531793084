import { Form, Formik } from "formik";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React from "react";
import TblLinearProgress from "../../TblLinearProgress";
import TblTextField from "../../TblForms/TblTextField";
import TblUser from "../../../constants/TblUser.json";
import Typography from "@material-ui/core/Typography";
import { useChangePass } from "../../../hooks/TblProfile/useChangePass";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const headCells = TblUser.headCells;

const TblChangePass = (props) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { data, actions } = useChangePass();
  const { isLoading, history } = data;
  const { handleValidate, handleSubmit } = actions;

  return (
    <React.Fragment>
      <div className={classes.pt2}></div>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={11}>
          <Typography variant="h5">{labels["TBL_CHANGE_PASSWORD_LABEL_TITLE"]}</Typography>
        </Grid>
        <div className={classes.pt1}></div>
        <Grid item>
          <Formik initialValues={{ password: "", confpass: "" }} validate={handleValidate} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
                  </Grid>
                  <Grid item>
                    <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
                  </Grid>
                  <Grid item container direction="row" justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button type="button" onClick={() => history.push("/login?pathname=/")} variant="contained" color="default" disabled={isSubmitting}>
                        {labels["TBL_COMMON_BUTTON_CANCEL"]}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                        {labels["TBL_COMMON_BUTTON_CONTINUE_LABEL"]}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Grid item xs={10}>
                    {isLoading && <TblLinearProgress />}
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <div className={classes.pb2}></div>
    </React.Fragment>
  );
};

TblChangePass.propTypes = {};

export default TblChangePass;

import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblCountry } from "../model/TblCountry";
import { TblProvince } from "../model/TblProvince";

export const apiGetProvinces = (url, obj) => async () => {
  url = `${url}?codCountry=${obj || ""}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchProvinces = (url, obj) => async () => {
  const { codCountry } = obj;
  url = `${url}?codCountry=${codCountry}`;
  return searchData(url, obj);
};

export const apiAddProvince = (url, obj) => async () => {
  const { seqProvince, tblCountry, codProvince, desProvince } = obj;
  const request = new TblProvince(seqProvince, new TblCountry(tblCountry.seqCountry, tblCountry.codCountry), codProvince, desProvince);
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateProvince = (url, obj) => async () => {
  const { seqProvince, tblCountry, codProvince, desProvince, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqProvince}`;
  const request = new TblProvince(seqProvince, new TblCountry(tblCountry.seqCountry, tblCountry.codCountry), codProvince, desProvince, dateCre, dateMod, userCre, userMod);
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteProvince = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

export class TblErrorMessageId {
  constructor(seqErrorMessageId, tblErrorMessage, text, tblLanguage, dateCre, dateMod, userCre, userMod) {
    this.seqErrorMessageId = seqErrorMessageId;
    this.tblErrorMessage = tblErrorMessage;
    this.text = text;
    this.tblLanguage = tblLanguage;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblSearchForm from "../../TblSearchForm";
import TblSelect from "../../../TblForms/TblSelect";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetLangs } from "../../../../actions/langActions";
import { actionSearchDomains } from "../../../../actions/domainActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const DomainSearchForm = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const dispatch = useDispatch();

  const { headCells, initialValues } = data;
  const { setSelected } = actions;

  if (langs.length === 0) {
    callService(dispatch, actionGetLangs);
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.orderBy = headCells[1].path;
      callService(dispatch, actionSearchDomains, values).finally(() => {
        setSelected({});
        setSubmitting(false);
      });
    },
    [dispatch, headCells, setSelected]
  );

  return (
    <TblSearchForm initialValues={initialValues} onSubmit={handleSubmit}>
      <Grid item xs={8}>
        <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
      </Grid>
      <Grid item xs={8}>
        <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
      </Grid>
      <Grid item xs={8}>
        <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCells[4].id}
          name={headCells[4].column}
          label={labels[headCells[4].label]}
          options={langs}
          defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
        />
      </Grid>
    </TblSearchForm>
  );
};

DomainSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default DomainSearchForm;

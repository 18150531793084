import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import PropTypes from "prop-types";
import { Properties } from "../../../constants/properties";
import React from "react";

const TblContestShare = ({ contest }) => {
  return (
    <div>
      <WhatsappShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/contests`} title={`${contest.name} en @tourbandlive`}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <TelegramShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/contests`} title={`${contest.name} en @tourbandlive`}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
      <LineShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/contests`} title={`${contest.name} en @tourbandlive`}>
        <LineIcon size={32} round={true} />
      </LineShareButton>
      <FacebookShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/contests`} quote={`${contest.name} en @tourbandlive`} hashtag={"#sordoelquenovote"}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={`${Properties.URL_BASE}${process.env.PUBLIC_URL}/contests`} title={`${contest.name} en @tourbandlive`} hashtags={["sordoelquenovote"]}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </div>
  );
};

TblContestShare.propTypes = {
  contest: PropTypes.object.isRequired,
};

export default TblContestShare;

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { convert2DateLL } from "../../../utils/dataUtils";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblAuditPopup = ({ open, setOpen, selected }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog onClose={handleClose} aria-labelledby="popup-dialog-title" open={open} fullWidth>
      <DialogTitle id="tbl-dialog-title">{labels["TBL_COMMON_TITLE_AUDIT"]}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">{labels["TBL_COMMON_TITLE_CREATION"]}</Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" variant="inherit" display="inline">
              {labels["TBL_ENTITY_TBL_USER"]}:
            </Typography>
            <Typography className={classes.pl1} variant="body1" display="inline">
              {selected?.userCre}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" variant="inherit" display="inline">
              {labels["TBL_COMMON_LABEL_DATE"]}:
            </Typography>
            <Typography className={classes.pl1} variant="body1" display="inline">
              {selected?.dateCre && convert2DateLL(selected.dateCre)}
            </Typography>
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="h6">{labels["TBL_COMMON_TITLE_MODIFICATION"]}</Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" variant="inherit" display="inline">
              {labels["TBL_ENTITY_TBL_USER"]}:
            </Typography>
            <Typography className={classes.pl1} variant="body1" display="inline">
              {selected?.userMod}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" variant="inherit" display="inline">
              {labels["TBL_COMMON_LABEL_DATE"]}:{" "}
            </Typography>
            <Typography className={classes.pl1} variant="body1" display="inline">
              {selected?.dateMod && convert2DateLL(selected.dateMod)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className="padding-rigth-15" onClick={handleClose} color="secondary" variant="contained">
          <Typography variant="button">{labels["TBL_COMMON_BUTTON_CLOSE"]}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TblAuditPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selected: PropTypes.object,
};

export default TblAuditPopup;

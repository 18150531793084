import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblSearchForm from "../../TblSearchForm";
import TblSelect from "../../../TblForms/TblSelect";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetLangs } from "../../../../actions/langActions";
import { actionSearchCountries } from "../../../../actions/countryActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const CountrySearchForm = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const dispatch = useDispatch();

  const { initialValuesCountries, headCellsCountries } = data;
  const { setSelecCountry } = actions;

  if (langs.length === 0) {
    callService(dispatch, actionGetLangs);
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.orderBy = headCellsCountries[1].path;
      callService(dispatch, actionSearchCountries, values).finally(() => {
        setSelecCountry({});
        setSubmitting(false);
      });
    },
    [dispatch, headCellsCountries, setSelecCountry]
  );

  return (
    <TblSearchForm initialValues={initialValuesCountries} onSubmit={handleSubmit}>
      <Grid item xs={8}>
        <TblTextField id={headCellsCountries[1].id} name={headCellsCountries[1].column} label={labels[headCellsCountries[1].label]} properties={headCellsCountries[1].properties} />
      </Grid>
      <Grid item xs={8}>
        <TblTextField id={headCellsCountries[2].id} name={headCellsCountries[2].column} label={labels[headCellsCountries[2].label]} properties={headCellsCountries[2].properties} />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCellsCountries[3].id}
          name={headCellsCountries[3].column}
          label={labels[headCellsCountries[3].label]}
          options={langs}
          defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
        />
      </Grid>
    </TblSearchForm>
  );
};

CountrySearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default CountrySearchForm;

import {
  ADD_ELEMENTS_BAND_LINKPAGE,
  API_BAND_LINK_ADD,
  API_BAND_LINK_DELETE,
  API_BAND_LINK_EDIT,
  API_BAND_LINK_SEARCH,
  CLEAR_BAND_LINK_TABLE,
  SET_SCREEN_BAND_LINKPAGE,
} from "../constants";
import { apiAddBandLink, apiDeleteBandLink, apiSearchBandLinks, apiUpdateBandLink } from "../api/bandLinkAPI";
import { urlAddBandLink, urlDeleteBandLink, urlSearchBandLinks, urlUpdateBandLink } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BAND_LINKPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BAND_LINKPAGE, (bandLink) => apiSearchBandLinks(urlSearchBandLinks, bandLink)());
export const actionSearchBandLinks = createAction(API_BAND_LINK_SEARCH, (bandLink) => apiSearchBandLinks(urlSearchBandLinks, bandLink)());
export const actionAddBandLink = createAction(API_BAND_LINK_ADD, (bandLink) => apiAddBandLink(urlAddBandLink, bandLink)());
export const actionUpdateBandLink = createAction(API_BAND_LINK_EDIT, (band) => apiUpdateBandLink(urlUpdateBandLink, band)());
export const actionDeleteBandLink = createAction(API_BAND_LINK_DELETE, ({ seqLink }) => apiDeleteBandLink(urlDeleteBandLink, seqLink)());
export const actionClearBandLinkTable = createAction(CLEAR_BAND_LINK_TABLE);

import { Form, Formik } from "formik";

import ActionButtons from "../ActionButtons";
import Grid from "@material-ui/core/Grid";
import React from "react";
import TblAvatar from "../../../TblForms/TblAvatar";
import TblCropper from "../../../TblCropper/TblCropper";
import TblTextField from "../../../TblForms/TblTextField";
import { useSelector } from "react-redux";
import { useStyles } from "../../../../theme/useStyles";

const Step1 = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { initialValues, imageSrc, headCells, croppedImage, activeStep, steps } = data;
  const { handleValidationStep1, handleSubmit, setImageSrc, setCroppedImage, handleBack } = actions;

  return (
    <Formik initialValues={initialValues} validate={handleValidationStep1} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          {imageSrc ? (
            <TblCropper imageSrc={imageSrc} setImageSrc={setImageSrc} setCroppedImage={setCroppedImage} />
          ) : (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <TblAvatar
                id={headCells[8].id}
                name={headCells[8].column}
                label={labels[headCells[8].label]}
                properties={headCells[8].properties}
                src={croppedImage}
                setImageSrc={setImageSrc}
              />
              <div className={classes.pt1}></div>
              <Grid container item xs={10} md={8} lg={4}>
                <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
              </Grid>
              <div className={classes.pt1}></div>
            </Grid>
          )}
          {!imageSrc && <ActionButtons isSubmitting={isSubmitting} activeStep={activeStep} steps={steps} handleBack={handleBack} />}
        </Form>
      )}
    </Formik>
  );
};

export default Step1;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TblLinearProgress from "../../TblLinearProgress";
import TblTableHead from "./TblTableHead/TblTableHead";
import TblTableRow from "./TblTableRow/TblTableRow";
import _ from "lodash";
import { callService } from "../../../utils/serviceUtils";

const TblTable = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const { rows, headCells, selected, search, serverPage, screenPage, totalElements, detailRow } = data;
  const { setSelected, actionSearch, actionAddElementsPage, setScreenPage } = actions;

  const sequence = headCells[0].column;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].path);
  const [page, setPage] = useState(screenPage);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    const index = rows.findIndex((row) => selected[sequence] === row[sequence]);
    if (index > 0) {
      const pageRound = Math.floor(index / rowsPerPage);
      if (pageRound !== screenPage) {
        setPage(pageRound);
        dispatch(setScreenPage(pageRound));
      }
    } else {
      if (page !== screenPage) {
        setPage(screenPage);
      }
    }
  }, [rows, selected, sequence, rowsPerPage, screenPage, dispatch, setScreenPage, page]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    search.order = newOrder;
    search.orderBy = property;
    callService(dispatch, actionSearch, search).then(() => {
      setOrder(newOrder);
      setOrderBy(property);
    });
  };

  const handleChangePage = (event, newPage) => {
    if (!loadingData) {
      setSelected({});

      if ((newPage + 1) * rowsPerPage >= rows.length + 10) {
        setLoadingData(true);
        search.page = serverPage + 1;
        callService(dispatch, actionAddElementsPage, search)
          .then(() => {
            dispatch(setScreenPage(newPage));
            setPage(newPage);
          })
          .finally(() => {
            setLoadingData(false);
          });
      } else {
        dispatch(setScreenPage(newPage));
        setPage(newPage);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (!loadingData) {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };

  const emptyRows = 5 - Math.min(5, rows.length);

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table aria-label="tbl-table">
            <TblTableHead
              headCells={headCells}
              numSelected={_.isEmpty(selected) ? 0 : 1}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              detailRow={detailRow}
            />
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return <TblTableRow key={row[sequence]} data={data} actions={actions} row={row} index={index} page={page} rowsPerPage={rowsPerPage} />;
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={labels["TBL_COMMON_PAGINATION_TEXT"]}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${labels["TBL_COMMON_PAGINATION_OF"]} ${count}`}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {loadingData && <TblLinearProgress />}
      </Paper>
    </div>
  );
};

TblTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblTable;

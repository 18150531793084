import { Grid, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { actionClearContestBandTable, actionGetContestBandVotesStats } from "../../../actions/contestBandActions";
import { convert2DateFormat, convert2UtcISOString } from "../../../utils/dataUtils";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import { CONTEST_BANDS } from "../../../constants";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import ShareIcon from "@material-ui/icons/Share";
import TblContestGroupsResultPopup from "../../TblPopups/TblContestGroupsResultPopup/TblContestGroupsResultPopup";
import TblContestShare from "./TblContestShare";
import Typography from "@material-ui/core/Typography";
import { callService } from "../../../utils/serviceUtils";
import clsx from "clsx";
import { useStyles } from "../../../theme/useStyles";

const customStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const TblCardActions = ({ topRef, infoRef, onAction, contest, myContests, loading }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const classes = useStyles();
  const cClasses = customStyles();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const [loadingP, setLoadingP] = useState(false);

  useEffect(() => {
    if (open && contest.tblContest.contestType === CONTEST_BANDS) {
      setLoadingP(true);
      callService(dispatch, actionGetContestBandVotesStats, contest.tblContest).finally(() => {
        setLoadingP(false);
      });
    }
    if (expanded) {
      infoRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
    return () => {
      dispatch(actionClearContestBandTable());
    };
  }, [contest.tblContest, dispatch, expanded, infoRef, open, topRef]);

  const myContest = myContests?.filter((myContest) => myContest.codContest === contest.tblContest.codContest) || [];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenResults = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseResults = useCallback(() => {
    setOpen(false);
  }, []);

  const ContestButton = () => {
    if (convert2UtcISOString(contest.tblContest.startDate) > convert2UtcISOString(new Date())) {
      return <Typography variant="body1">{`Comienza el ${convert2DateFormat(contest.tblContest.startDate, "DD/MM/yyyy HH:mm")}`}</Typography>;
    } else if (convert2UtcISOString(contest.tblContest.endDate) <= convert2UtcISOString(new Date())) {
      if (contest.tblContest.contestType === CONTEST_BANDS) {
        return (
          <Button aria-label="show-results" variant="contained" color="primary" onClick={handleOpenResults} disabled={loading}>
            {labels["TBL_COMMON_BUTTON_LABEL_RESULTS_CONTEST"]}
          </Button>
        );
      } else {
        return (
          <Button aria-label="winner" variant="contained" color="primary" onClick={() => onAction(contest.tblContest)} disabled={loading}>
            {labels["TBL_CONTEST_BUTTON_LABEL_WINNER"]}
          </Button>
        );
      }
    } else if (!myContest?.length > 0) {
      return (
        <Button aria-label="participate" variant="contained" color="secondary" onClick={() => onAction(contest.tblContest)} disabled={loading}>
          {labels["TBL_COMMON_BUTTON_LABEL_PARTICIPATE_CONTEST"]}
        </Button>
      );
    } else if (contest.tblContest.contestType === CONTEST_BANDS) {
      return (
        <Button aria-label="show-results" variant="contained" color="primary" onClick={handleOpenResults} disabled={loading}>
          {labels["TBL_COMMON_BUTTON_LABEL_RESULTS_CONTEST"]}
        </Button>
      );
    } else {
      return (
        <Typography variant="body1" style={{ color: "green" }}>
          {labels["TBL_CONTEST_ALREADY_PARTICIPATE_LABEL"]}
        </Typography>
      );
    }
  };

  return (
    <React.Fragment>
      <CardActions disableSpacing>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item container direction="row" alignItems="center">
            <Grid item>
              <ContestButton />
            </Grid>
            <Grid item>
              <IconButton aria-label="share" onClick={() => setShowShare(!showShare)}>
                <ShareIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>{showShare && <TblContestShare contest={contest.tblContest} />}</Grid>
        </Grid>

        <IconButton
          className={clsx(cClasses.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="info"
        >
          <InfoIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h5" paragraph>
            {labels.TBL_COMPONENTS_CONTEST_LABEL_CONTESTRULES}
          </Typography>
          <Typography paragraph>{ReactHtmlParser(contest.description)}</Typography>
        </CardContent>
      </Collapse>
      <TblContestGroupsResultPopup open={open} onClosePopup={handleCloseResults} loading={loadingP} />
    </React.Fragment>
  );
};

TblCardActions.propTypes = {
  topRef: PropTypes.object.isRequired,
  infoRef: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
  contest: PropTypes.object.isRequired,
  myContests: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

export default TblCardActions;

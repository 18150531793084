export class TblGroupProvince {
  constructor(seqProvince, tblGroup, tblCountry, tblProvince, dateCre, userCre) {
    this.seqProvince = seqProvince;
    this.tblGroup = tblGroup;
    this.tblCountry = tblCountry;
    this.tblProvince = tblProvince;
    this.dateCre = dateCre;
    this.userCre = userCre;
  }
}

import React, { useCallback, useMemo } from "react";
import { actionAddElementsPage, actionAddLang, actionDeleteLang, actionSearchLangs, actionSetCurrentPage, actionUpdateLang } from "../../../../actions/langActions";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import { TblLanguage } from "../../../../model/TblLanguage";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const LangTable = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tlangs = useSelector((state) => state.tblLang.tlangs);

  const { search, serverPage, screenPage, totalElements } = tlangs;
  const { headCells, openAudit, selected } = data;
  const { setOpenAudit } = actions;

  const rows = useMemo(
    () =>
      tlangs.langs.reduce((acc, row) => {
        return [...acc, new TblLanguage(row.seqLanguage, row.codLang, row.desLang, row.dateCre, row.dateMod, row.userCre, row.userMod)];
      }, []),
    [tlangs.langs]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_LANGUAGE"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { codLang, desLang } = values;
      if (!codLang) {
        errors.codLang = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (codLang.length > 2) {
        errors.codLang = `${labels["TBL_COMMON_INVALIDINPUT_MAXLENGTH"]} 2`;
      }
      if (!desLang) {
        errors.desLang = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_LANGUAGES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
    }),
    [data, labels, rows, tableLabel, search, serverPage, screenPage, totalElements]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchLangs,
      actionAdd: actionAddLang,
      actionUpdate: actionUpdateLang,
      actionDelete: actionDeleteLang,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
    }),
    [actions, handleValidation]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

LangTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default LangTable;

import { ADD_ELEMENTS_BAND_STYLEPAGE, API_BAND_STYLE_ADD, API_BAND_STYLE_DELETE, API_BAND_STYLE_SEARCH, CLEAR_BAND_STYLE_TABLE, SET_SCREEN_BAND_STYLEPAGE } from "../constants";
import { apiAddBandStyle, apiDeleteBandStyle, apiSearchBandStyles } from "../api/bandStyleAPI";
import { urlAddBandStyle, urlDeleteBandStyle, urlSearchBandStyles } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BAND_STYLEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BAND_STYLEPAGE, (bandStyle) => apiSearchBandStyles(urlSearchBandStyles, bandStyle)());
export const actionSearchBandStyles = createAction(API_BAND_STYLE_SEARCH, (bandStyle) => apiSearchBandStyles(urlSearchBandStyles, bandStyle)());
export const actionAddBandStyle = createAction(API_BAND_STYLE_ADD, (bandStyle) => apiAddBandStyle(urlAddBandStyle, bandStyle)());
export const actionDeleteBandStyle = createAction(API_BAND_STYLE_DELETE, ({ seqStyle }) => apiDeleteBandStyle(urlDeleteBandStyle, seqStyle)());
export const actionClearBandStyleTable = createAction(CLEAR_BAND_STYLE_TABLE);

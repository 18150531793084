import { useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import LangSearchForm from "./LangSearchForm";
import LangTable from "./LangTable";
import PropTypes from "prop-types";
import TblLang from "../../../constants/TblLang.json";

const extraData = {
  headCells: TblLang.headCells,
  initialValues: {
    seqLanguage: null,
    codLang: "",
    desLang: "",
  },
};

const LangTab = ({ data, actions }) => {
  const [selected, setSelected] = useState({});
  data = useMemo(() => ({ ...data, ...extraData, selected }), [data, selected]);
  actions = useMemo(() => ({ ...actions, setSelected }), [actions, setSelected]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <LangSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <LangTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

LangTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default LangTab;

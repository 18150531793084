import { Form, Formik } from "formik";

import Avatar from "@material-ui/core/Avatar";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import React from "react";
import TblAvatar from "../../TblForms/TblAvatar";
import TblCheckBoxLabel from "../../TblForms/TblCheckboxLabel";
import TblConfirmPopup from "../../TblPopups/TblConfirmPopup";
import TblCropper from "../../TblCropper/TblCropper";
import TblDatePicker from "../../TblForms/TblDatePicker";
import TblLinearProgress from "../../TblLinearProgress";
import TblSelect from "../../TblForms/TblSelect";
import TblTextField from "../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const customStyles = makeStyles(() => ({
  root: {
    display: "flex",
    color: "#fff",
    width: "100%",
  },
  vinculate: {
    backgroundColor: "rgb(45,45,45)",
  },
  desVinculate: {
    backgroundColor: "#e0e0e0",
    color: "#000",
  },
  content: {
    paddingBottom: "0px!important",
  },
  cover: {
    backgroundColor: "#fff",
    width: 50,
    height: 50,
  },
}));

const TblMyProfile = ({ data, actions }) => {
  const classes = useStyles();
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const user = useSelector((state) => state.tblUser.user);
  const provinces = useSelector((state) => state.tblProvince.provinces);

  const { isLoading, initialValues, headCells, imageSrc, croppedImage, showChangePass, openConfirm, isLoadingUnsub, editMode } = data;
  const {
    setImageSrc,
    setCroppedImage,
    handleValidate,
    handleSubmit,
    setShowChangePass,
    handleDesVinculateFacebook,
    responseFacebook,
    handleDesVinculateGoogle,
    responseGoogle,
    handleUnsubscribe,
    setOpenConfirm,
    setEditMode,
  } = actions;

  return (
    <React.Fragment>
      {!editMode && (
        <Grid container direction="row" className={classes.mb1}>
          <Grid item xs={12} sm={5}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Button startIcon={<EditIcon />} onClick={() => setEditMode(true)}>
                Modificar
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      <Formik enableReinitialize initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit}>
        {({ resetForm, isSubmitting }) => (
          <Form>
            {imageSrc ? (
              <TblCropper imageSrc={imageSrc} setImageSrc={setImageSrc} setCroppedImage={setCroppedImage} />
            ) : (
              <Grid container direction="column" justifyContent="center">
                <Grid item xs={12} sm={5}>
                  {editMode ? (
                    <TblAvatar
                      id={headCells[18].id}
                      name={headCells[18].column}
                      label={labels[headCells[18].label]}
                      properties={headCells[18].properties}
                      src={croppedImage || user.photoProfile}
                      setImageSrc={setImageSrc}
                    />
                  ) : (
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <Avatar alt="TourBandLive-Avatar" variant="square" src={user.photoProfile} style={{ width: 300, height: 300 }} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Typography variant="h4" className={classes.pt1}>
                  {labels["TBL_MYPROFILE_SUBTITLE_INFOACCOUNT"]}
                </Typography>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={{}} disabled={true} />
                </Grid>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblTextField id={headCells[6].id} name={headCells[6].column} label={labels[headCells[6].label]} properties={{}} disabled={true} />
                </Grid>
                {showChangePass && (
                  <React.Fragment>
                    <Grid item xs={12} sm={5} className={classes.pt1}>
                      <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
                    </Grid>

                    <Grid item xs={12} sm={5} className={classes.pt1}>
                      <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
                    </Grid>
                  </React.Fragment>
                )}
                {editMode && !showChangePass && (
                  <Grid item>
                    <Link onClick={() => setShowChangePass(true)} component="button" variant="body2" className={classes.pt1}>
                      {labels["TBL_MYPROFILE_BUTTON_CHANGE_PASSWORD"]}
                    </Link>
                  </Grid>
                )}

                <Typography variant="h4" className={classes.pt1}>
                  {labels["TBL_MYPROFILE_SUBTITLE_DATAPROFILE"]}
                </Typography>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblTextField id={headCells[4].id} name={headCells[4].column} label={labels[headCells[4].label]} properties={headCells[4].properties} disabled={!editMode} />
                </Grid>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblTextField id={headCells[5].id} name={headCells[5].column} label={labels[headCells[5].label]} properties={headCells[5].properties} disabled={!editMode} />
                </Grid>

                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblDatePicker id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} disabled={!editMode} />
                </Grid>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblSelect
                    id={headCells[10].id}
                    name={headCells[10].column}
                    label={labels[headCells[10].label]}
                    options={provinces}
                    defaultOption={{ seq: 0, value: "", label: labels["TBL_COMMON_SELECT_PROVINCE"] }}
                    disabled={!editMode}
                  />
                </Grid>
                <Typography variant="h4" className={classes.pt1}>
                  {labels["TBL_MYPROFILE_SUBTITLE_CONFIGACCOUNT"]}
                </Typography>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  {user.vinculateFacebook && (
                    <Grid item xs={10} sm={7} className={[classes.pt1, classes.mbxs]}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <TblTextField id={headCells[11].id} name={headCells[11].column} label={labels[headCells[11].label]} properties={{}} disabled={true} />
                        <div className={classes.mr1}></div>
                        <CheckIcon color="primary" />
                      </Box>
                    </Grid>
                  )}
                  {user.vinculateFacebook && editMode && (
                    <Button onClick={handleDesVinculateFacebook} disabled={isLoading} fullWidth>
                      <Card className={[cClasses.desVinculate, cClasses.root]}>
                        <CardMedia className={cClasses.cover} image={"/images/application/facebooklogo.svg"} title="facebook-logo" />
                        <CardContent className={cClasses.content}>
                          <Typography align="left" variant="caption">
                            {labels["TBL_MYPROFILE_BUTTON_DESVINCULATE_FACEBOOK"]}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Button>
                  )}
                  {!user.vinculateFacebook && editMode && (
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      render={(renderProps) => (
                        <Button onClick={renderProps.onClick} disabled={isLoading} fullWidth>
                          <Card className={[cClasses.vinculate, cClasses.root]}>
                            <CardMedia className={cClasses.cover} image={"/images/application/facebooklogo.svg"} title="facebook-logo" />
                            <CardContent className={cClasses.content}>
                              <Typography align="left" variant="caption">
                                {labels["TBL_MYPROFILE_BUTTON_VINCULATE_FACEBOOK"]}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Button>
                      )}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      version={3.3}
                      redirectUri={window.location.href.substring(0, window.location.href.indexOf("?"))}
                      disableMobileRedirect={true}
                      isDisabled={isLoading}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5}>
                  {user.vinculateGoogle && (
                    <Grid item xs={10} sm={7} className={[classes.pt1, classes.mbxs]}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <TblTextField id={headCells[12].id} name={headCells[12].column} label={labels[headCells[12].label]} properties={{}} disabled={true} />
                        <div className={classes.mr1}></div>
                        <CheckIcon color="primary" />
                      </Box>
                    </Grid>
                  )}
                  {user.vinculateGoogle && editMode && (
                    <Button onClick={handleDesVinculateGoogle} disabled={isLoading} fullWidth>
                      <Card className={[cClasses.desVinculate, cClasses.root]}>
                        <CardMedia className={cClasses.cover} image={"/images/application/googlelogo.svg"} title="google-logo" />
                        <CardContent className={cClasses.content}>
                          <Typography align="left" variant="caption">
                            {labels["TBL_MYPROFILE_BUTTON_DESVINCULATE_GOOGLE"]}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Button>
                  )}
                  {!user.vinculateGoogle && editMode && (
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_APP_ID}
                      render={(renderProps) => (
                        <Button onClick={renderProps.onClick} disabled={renderProps.disabled} fullWidth>
                          <Card className={[cClasses.vinculate, cClasses.root]}>
                            <CardMedia className={cClasses.cover} image={"/images/application/googlelogo.svg"} title="google-logo" />
                            <CardContent className={cClasses.content}>
                              <Typography align="left" variant="caption">
                                {labels["TBL_MYPROFILE_BUTTON_VINCULATE_GOOGLE"]}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Button>
                      )}
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      disabled={isLoading}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={5} className={classes.pt1}>
                  <TblCheckBoxLabel
                    id={headCells[15].id}
                    name={headCells[15].column}
                    label={labels[headCells[15].label]}
                    properties={headCells[15].properties}
                    disabled={!editMode}
                  />
                </Grid>
                {isLoading && (
                  <Grid item xs={12} sm={5}>
                    <TblLinearProgress />
                  </Grid>
                )}
                {editMode && (
                  <Grid item xs={12} sm={5} container direction="row" justifyContent="flex-end" className={classes.pt1}>
                    <Button type="submit" variant="contained" color="secondary" className={classes.mr1} disabled={isSubmitting}>
                      {labels["TBL_COMMON_BUTTON_SAVE"]}
                    </Button>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        resetForm();
                        setEditMode(false);
                      }}
                      disabled={isSubmitting}
                    >
                      {labels["TBL_COMMON_BUTTON_CANCEL"]}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      {!imageSrc && (
        <React.Fragment>
          <Grid container direction="column" justifyContent="center" className={classes.pt1}>
            <Grid item xs={12} sm={5}>
              <Button onClick={() => setOpenConfirm(true)} disabled={isLoading} fullWidth>
                <Card className={[cClasses.vinculate, cClasses.root]}>
                  <CardMedia className={cClasses.cover} image={"/images/application/exitlogo.svg"} title="exit-logo" />
                  <CardContent className={cClasses.content}>
                    <Typography align="left" variant="caption">
                      {labels["TBL_MYPROFILE_BUTTON_EXITAPP"]}
                    </Typography>
                  </CardContent>
                </Card>
              </Button>
            </Grid>
          </Grid>
          <TblConfirmPopup open={openConfirm} setOpen={setOpenConfirm} onConfirm={handleUnsubscribe} text="TBL_MYPROFILE_TEXT_DELETEUSERTEXT" isLoading={isLoadingUnsub} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

TblMyProfile.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblMyProfile;

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Rating from "@material-ui/lab/Rating";
import React from "react";

const TblTBPointVote = ({ vote, onVote }) => {
  const IconTbp = (props) => {
    const { value, className, ...other } = props;
    if (className.includes("iconHover") || className.includes("iconFilled")) {
      return (
        <span className={className} {...other}>
          <img variant="square" src="/images/application/tblimagegrouptbpointvoted.png" alt="my-vote" />
        </span>
      );
    } else {
      return (
        <span className={className} {...other}>
          <img variant="square" src="/images/application/tblimagegrouptbpointvote.png" alt="my-vote" />
        </span>
      );
    }
  };

  return (
    <Grid item xs={12}>
        <Box component="fieldset" borderColor="transparent">
          <Rating name="tbl-tbpoint" value={vote} max={1} getLabelText={(value) => `tbp-${value}`} onChange={(event, value) => onVote(value)} IconContainerComponent={IconTbp} />
        </Box>
    </Grid>
  );
};

TblTBPointVote.propTypes = {
  vote: PropTypes.number.isRequired,
  onVote: PropTypes.func.isRequired,
};

export default TblTBPointVote;

import CryptoJS from "crypto-js";

export class Properties {
  static COMMON_ERROR = "Lo sentimos no hemos podido realizar la operacion, vuelva a intentarlo";
  static ERRORS = {};
  static FILE_SIZE = 160 * 1024;
  static SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
  static CALL_QUEUE = [];
  static FOUR = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  static SALT = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
  static PASSPHRASE = process.env.REACT_APP_PASSPHRASE;
  static URL_BASE = "https://www.tourbandlive.com";

  static setCOMMON_ERROR = (text) => {
    if (text) {
      Properties.COMMON_ERROR = text;
    }
  };

  static getCOMMON_ERROR = () => {
    return Properties.COMMON_ERROR;
  };

  static setERRORS = (errors) => {
    if (errors) {
      Properties.ERRORS = errors;
    }
  };

  static getERRORS = () => {
    return Properties.ERRORS;
  };

  static setCALL_QUEUE = (name, status) => {
    if (name && status) {
      const calls = Properties.CALL_QUEUE.filter((call) => call.operation === name);
      if (calls.length > 0) {
        calls[calls.length - 1].status = status;
      } else {
        Properties.CALL_QUEUE.push({ operation: name, status: status });
      }
    }
  };

  static getCALL_QUEUE = () => {
    return Properties.CALL_QUEUE;
  };
}

import { Link, useHistory } from "react-router-dom";
import { ROLE_NOACTIVE, ROLE_PUBLIC } from "../../../constants";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblAbout = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const templates = useSelector((state) => state.tblTemplate.templates);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const history = useHistory();

  const transform = (node) => {
    if (node?.attribs?.id === "redirect") {
      return (
        <Link key={node.attribs.id} onClick={handleRedirectTo}>
          {node.children[0].data}
        </Link>
      );
    }
  };

  const handleRedirectTo = () => {
    history.push("/profile/active");
  };

  return (
    <div>
      <Grid container direction="column" alignContent="center" alignItems="center" className={classes.py2}>
        <Grid item xs={10} lg={4}>
          <Typography variant="h3" className={classes.textWhite} align="center">
            {labels["TBL_HOME_ABOUT_TITLE_TOURBANDLIVE"]}
          </Typography>
          <hr className={`${classes.my1} ${classes.borderWhite}`} />
          <Typography variant="subtitle1" className={classes.textWhite} align="center">
            {labels["TBL_HOME_ABOUT_DESCRIPTION_TOURBANDLIVE"]}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.my1}>
            {!userSession.applicationRole.includes(ROLE_PUBLIC) && !userSession.applicationRole.includes(ROLE_NOACTIVE) && (
              <Link to="/login?pathname=/">
                <Button variant="contained" className={classes.bgWhite} size="large">
                  {labels["TBL_COMMON_BUTTON_LOGIN"]}
                </Button>
              </Link>
            )}
            {userSession.applicationRole.includes(ROLE_PUBLIC) && (
              <Link to="/contests">
                <Button variant="contained" className={classes.bgWhite} size="large">
                  {labels["TBL_COMMON_BUTTON_LABEL_PARTICIPATE"]}
                </Button>
              </Link>
            )}
          </div>
        </Grid>
        {userSession.applicationRole.includes(ROLE_NOACTIVE) && (
          <Grid item xs={10} lg={4}>
            <Typography variant="subtitle2" className={classes.textWhite}>
              {labels["TBL_ACTIVE_USER_LABEL_NO_ACTIVEMSG"]}
            </Typography>
            <Typography variant="subtitle2" className={classes.textWhite}>
              {ReactHtmlParser(templates["TBL_ACTIVE_USER_TEMPLATE_REDIRECT_INSTRUCTIONS"], { transform })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TblAbout;

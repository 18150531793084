import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";
import { TextField } from "formik-material-ui";
import { latinise } from "../../utils/dataUtils";
import { useSelector } from "react-redux";
import { useStyles } from "../../theme/useStyles";

const TblTextField = ({ id, name, label, disabled, setPreviewData, properties, onChange }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const { values, setFieldValue, setTouched, isSubmitting } = useFormikContext();

  const { type, normalize, multiline, scroll, maxLength, minLength, helperText, rows, maxRows, preview, inputRef, isLatinise, autoComplete = "on" } = properties;

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      if (!!normalize && !!isLatinise) {
        setFieldValue(name, latinise(values[name].toUpperCase()));
      } else if (!!normalize) {
        setFieldValue(name, values[name].toUpperCase());
      } else if (!!isLatinise) {
        setFieldValue(name, latinise(values[name]));
      }
    }

    if (isCurrent && setPreviewData) {
      setPreviewData(values[name]);
    }

    return () => {
      isCurrent = false;
    };
  }, [setFieldValue, setPreviewData, name, values, normalize, preview, isLatinise]);

  if (type !== "hidden") {
    return (
      <FormControl fullWidth>
        <Field
          component={TextField}
          id={id}
          name={name}
          label={label}
          type={type}
          disabled={!!disabled || isSubmitting}
          multiline={multiline}
          minRows={rows || 1}
          maxRows={maxRows || 1}
          onKeyUp={(e) => {
            onChange && onChange(e);
            setTouched({ [name]: true }, false);
          }}
          variant="outlined"
          helperText={labels[helperText]}
          inputProps={{ className: scroll && classes.textarea, maxLength: maxLength, minLength: minLength, autoComplete: autoComplete, ref: inputRef }}
        />
      </FormControl>
    );
  }
  return null;
};

TblTextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  setPreviewData: PropTypes.func,
  properties: PropTypes.object,
  onChange: PropTypes.func,
};

export default TblTextField;

import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblCountry } from "../model/TblCountry";
import { TblCountryId } from "../model/TblCountryId";
import { TblLanguage } from "../model/TblLanguage";

export const apiGetCountries = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiSearchCountries = (url, obj) => async () => {
  const { codCountry, desCountry, codLang } = obj;
  url = `${url}?codCountry=${codCountry}&desCountry=${desCountry}&codLang=${codLang?.value ?? ""}`;
  return searchData(url, obj);
};

export const apiAddCountry = (url, obj) => async () => {
  const { seqCountryId, codCountry, desCountry, codLang } = obj;
  const request = new TblCountryId(seqCountryId, new TblCountry(undefined, codCountry), desCountry, new TblLanguage(codLang.seq, codLang.value, codLang.label));
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateCountry = (url, obj) => async () => {
  const { seqCountryId, codCountry, desCountry, codLang, tblCountry, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqCountryId}`;
  const request = new TblCountryId(
    seqCountryId,
    new TblCountry(tblCountry.seqCountry, codCountry),
    desCountry,
    new TblLanguage(codLang.seq, codLang.value, codLang.label),
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteCountry = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

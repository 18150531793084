import { ADD_ELEMENTS_LANGPAGE, API_LANG_ADD, API_LANG_DELETE, API_LANG_EDIT, API_LANG_GET, API_LANG_SEARCH, CLEAR_LANG_TABLE, NO_ACTION, SET_SCREEN_LANGPAGE } from "../constants";

import { handleActions } from "redux-actions";

export const initial_tlang = {
  langs: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblLang = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_LANG_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const langs = action.payload;
      const langsOptions = langs.map((language) => ({ seq: language.seqLanguage, value: language.codLang, label: language.desLang }));
      return { ...state, langs: langsOptions };
    },
    [API_LANG_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tlangs: {
          langs: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_LANG_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.langs;
      state.tlangs.totalElements += 1;

      return { ...state, tlangs: { ...state.tlangs, langs: [action.payload].concat(state.tlangs?.langs || []) } };
    },
    [API_LANG_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const languagePayload = action.payload;
      const { seqLanguage } = languagePayload;
      const languages = state.tlangs?.langs || [];
      const newLanguages = languages.map((language) => {
        if (language.seqLanguage === seqLanguage) {
          return languagePayload;
        } else {
          return language;
        }
      });
      delete state.langs;
      return { ...state, tlangs: { ...state.tlangs, langs: newLanguages } };
    },
    [API_LANG_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.langs;
      state.tlangs.totalElements -= 1;
      return { ...state, tlangs: { ...state.tlangs, langs: state.tlangs.langs.filter((c) => c.seqLanguage !== action.payload) } };
    },
    [ADD_ELEMENTS_LANGPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tlangs: {
          langs: [...state.tlangs.langs, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.tlangs?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_LANGPAGE]: (state, action) => ({ ...state, tlangs: { ...state.tlangs, screenPage: action.payload } }),
    [CLEAR_LANG_TABLE]: (state) => ({ ...state, tlangs: initial_tlang }),
  },
  { tlangs: initial_tlang, langs: [] }
);

// PORTAL URLS
export const urlSendContact = `${process.env.REACT_APP_API_BASE_URL}/portal/contact`;
export const urlVerifyReCaptcha = `${process.env.REACT_APP_API_BASE_URL}/portal/recaptcha`;

// VARIABLES URLS

// BAND URLS
export const urlGetBands = `${process.env.REACT_APP_API_BASE_URL}/bands`;
export const urlSearchBands = `${process.env.REACT_APP_API_BASE_URL}/bands/search`;
export const urlFilterBands = `${process.env.REACT_APP_API_BASE_URL}/bands/filter`;
export const urlAddBand = `${process.env.REACT_APP_API_BASE_URL}/bands/addband`;
export const urlUpdateBand = `${process.env.REACT_APP_API_BASE_URL}/bands/updateband`;
export const urlDeleteBand = `${process.env.REACT_APP_API_BASE_URL}/bands/deleteband`;
export const urlAddPublicBand = `${process.env.REACT_APP_API_BASE_URL}/bands/add`;
export const urlGetRankingBands = `${process.env.REACT_APP_API_BASE_URL}/bands/ranking`;
export const urlVoteBand = `${process.env.REACT_APP_API_BASE_URL}/bands/votes/voteband`;

// BAND PROVINCES URLS
export const urlSearchBandProvinces = `${process.env.REACT_APP_API_BASE_URL}/bands/provinces/search`;
export const urlAddBandProvince = `${process.env.REACT_APP_API_BASE_URL}/bands/provinces/addprovince`;
export const urlDeleteBandProvince = `${process.env.REACT_APP_API_BASE_URL}/bands/provinces/deleteprovince`;

// BAND STYLE URLS
export const urlSearchBandStyles = `${process.env.REACT_APP_API_BASE_URL}/bands/styles/search`;
export const urlAddBandStyle = `${process.env.REACT_APP_API_BASE_URL}/bands/styles/addstyle`;
export const urlDeleteBandStyle = `${process.env.REACT_APP_API_BASE_URL}/bands/styles/deletestyle`;

// BAND LINK URLS
export const urlSearchBandLinks = `${process.env.REACT_APP_API_BASE_URL}/bands/links/search`;
export const urlAddBandLink = `${process.env.REACT_APP_API_BASE_URL}/bands/links/addlink`;
export const urlUpdateBandLink = `${process.env.REACT_APP_API_BASE_URL}/bands/links/updatelink`;
export const urlDeleteBandLink = `${process.env.REACT_APP_API_BASE_URL}/bands/links/deletelink`;

// BAND VIDEO URLS
export const urlGetBandVideos = `${process.env.REACT_APP_API_BASE_URL}/bands/videos`;
export const urlSearchBandVideos = `${process.env.REACT_APP_API_BASE_URL}/bands/videos/search`;
export const urlAddPublicBandVideos = `${process.env.REACT_APP_API_BASE_URL}/bands/videos/add`;
export const urlAddBandVideo = `${process.env.REACT_APP_API_BASE_URL}/bands/videos/addvideo`;
export const urlUpdateBandVideo = `${process.env.REACT_APP_API_BASE_URL}/bands/videos/updatevideo`;
export const urlDeleteBandVideo = `${process.env.REACT_APP_API_BASE_URL}/bands/videos/deletevideo`;

// BAND USER VOTE URLS
export const urlSearchBandUserVotes = `${process.env.REACT_APP_API_BASE_URL}/bands/votes/search`;
export const urlSearchUserVotes = `${process.env.REACT_APP_API_BASE_URL}/users/votes/search`;

// BAND USER REVIEW URLS
export const urlGetBandReviews = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews`;
export const urlSearchBandReviews = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews/search`;
export const urlAddBandReview = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews/addreview`;
export const urlUpdateBandReview = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews/updatereview`;
export const urlDeleteBandReview = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews/deletereview`;
export const urlCreateBandReview = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews/createreview`;
export const urlAddBandReviewAssess = `${process.env.REACT_APP_API_BASE_URL}/bands/reviews/addassessment`;
export const urlSearchUserReviews = `${process.env.REACT_APP_API_BASE_URL}/users/reviews/search`;

// USER URLS
export const urlLogin = `${process.env.REACT_APP_API_BASE_URL}/login`;
export const urlLoginSocial = `${process.env.REACT_APP_API_BASE_URL}/users/loginsocial`;
export const urlLogout = `${process.env.REACT_APP_API_BASE_URL}/users/logout`;
export const urlSignup = `${process.env.REACT_APP_API_BASE_URL}/users/signup`;
export const urlResendActiveEmail = `${process.env.REACT_APP_API_BASE_URL}/users/resend`;
export const urlActiveUser = `${process.env.REACT_APP_API_BASE_URL}/users/active`;
export const urlRememberPassEmail = `${process.env.REACT_APP_API_BASE_URL}/users/rememberpass`;
export const urlChangePassword = `${process.env.REACT_APP_API_BASE_URL}/users/changepassword`;
export const urlGetUserSession = `${process.env.REACT_APP_API_BASE_URL}/users/session`;
export const urlGetUser = `${process.env.REACT_APP_API_BASE_URL}/users/user`;
export const urlGetUsers = `${process.env.REACT_APP_API_BASE_URL}/users/all`;
export const urlSearchUsers = `${process.env.REACT_APP_API_BASE_URL}/users/search`;
export const urlAddUser = `${process.env.REACT_APP_API_BASE_URL}/users/adduser`;
export const urlUpdateUser = `${process.env.REACT_APP_API_BASE_URL}/users/updateuser`;
export const urlDeleteUser = `${process.env.REACT_APP_API_BASE_URL}/users/deleteuser`;
export const urlValidUserName = `${process.env.REACT_APP_API_BASE_URL}/users/validusername`;
export const urlValidUserEmail = `${process.env.REACT_APP_API_BASE_URL}/users/validuseremail`;
export const urlVinculateAccount = `${process.env.REACT_APP_API_BASE_URL}/users/vinculateaccount`;
export const urlDesVinculateFacebook = `${process.env.REACT_APP_API_BASE_URL}/users/desvinculatefacebook`;
export const urlDesVinculateGoogle = `${process.env.REACT_APP_API_BASE_URL}/users/desvinculategoogle`;
export const urlUpdatePublicUser = `${process.env.REACT_APP_API_BASE_URL}/users/update`;
export const urlUnsubscribeUser = `${process.env.REACT_APP_API_BASE_URL}/users/unsubscribe`;

// CONTEST URLS
export const urlGetContests = `${process.env.REACT_APP_API_BASE_URL}/contests`;
export const urlSearchContests = `${process.env.REACT_APP_API_BASE_URL}/contests/search`;
export const urlGetActiveContests = `${process.env.REACT_APP_API_BASE_URL}/contests/activecontest`;
export const urlGetUpcomingContests = `${process.env.REACT_APP_API_BASE_URL}/contests/upcomingcontest`;
export const urlGetFinishedContests = `${process.env.REACT_APP_API_BASE_URL}/contests/finishedcontest`;
export const urlGetMyContests = `${process.env.REACT_APP_API_BASE_URL}/contests/mycontest`;
export const urlValidContestCode = `${process.env.REACT_APP_API_BASE_URL}/contests/validcontestcode`;
export const urlAddContest = `${process.env.REACT_APP_API_BASE_URL}/contests/addcontest`;
export const urlUpdateContest = `${process.env.REACT_APP_API_BASE_URL}/contests/updatecontest`;
export const urlDeleteContest = `${process.env.REACT_APP_API_BASE_URL}/contests/deletecontest`;

// CONTESTANT URLS
export const urlSearchContestants = `${process.env.REACT_APP_API_BASE_URL}/contests/contestants/search`;
export const urlGetContestWinners = `${process.env.REACT_APP_API_BASE_URL}/contests/contestants/winners`;
export const urlGenerateContestWinners = `${process.env.REACT_APP_API_BASE_URL}/contests/contestants/generatewinners`;
export const urlAddContestant = `${process.env.REACT_APP_API_BASE_URL}/contests/contestants/addcontestant`;
export const urlUpdateContestant = `${process.env.REACT_APP_API_BASE_URL}/contests/contestants/updatecontestant`;
export const urlDeleteContestant = `${process.env.REACT_APP_API_BASE_URL}/contests/contestants/deletecontestant`;

// CONTEST BAND URLS
export const urlSearchContestBands = `${process.env.REACT_APP_API_BASE_URL}/contests/bands/search`;
export const urlGetContestBandVotesStats = `${process.env.REACT_APP_API_BASE_URL}/contests/band/votes/stats`;
export const urlParticipateContest = `${process.env.REACT_APP_API_BASE_URL}/contests/band/votes/participate`;
export const urlGetContestBands = `${process.env.REACT_APP_API_BASE_URL}/contests/bands`;

// LANGUAGE URLS
export const urlGetLangs = `${process.env.REACT_APP_API_BASE_URL}/languages`;
export const urlSearchLangs = `${process.env.REACT_APP_API_BASE_URL}/languages/search`;
export const urlAddLang = `${process.env.REACT_APP_API_BASE_URL}/languages/addlanguage`;
export const urlUpdateLang = `${process.env.REACT_APP_API_BASE_URL}/languages/updatelanguage`;
export const urlDeleteLang = `${process.env.REACT_APP_API_BASE_URL}/languages/deletelanguage`;

// LABELS URLS
export const urlGetLabels = `${process.env.REACT_APP_API_BASE_URL}/labels`;
export const urlSearchLabels = `${process.env.REACT_APP_API_BASE_URL}/labels/search`;
export const urlAddLabel = `${process.env.REACT_APP_API_BASE_URL}/labels/addlabel`;
export const urlUpdateLabel = `${process.env.REACT_APP_API_BASE_URL}/labels/updatelabel`;
export const urlDeleteLabel = `${process.env.REACT_APP_API_BASE_URL}/labels/deletelabel`;

// ERROR_MESSAGES URLS
export const urlGetErrorMessages = `${process.env.REACT_APP_API_BASE_URL}/errormessages`;
export const urlSearchErrorMessages = `${process.env.REACT_APP_API_BASE_URL}/errormessages/search`;
export const urlAddErrorMessage = `${process.env.REACT_APP_API_BASE_URL}/errormessages/adderrormessage`;
export const urlUpdateErrorMessage = `${process.env.REACT_APP_API_BASE_URL}/errormessages/updateerrormessage`;
export const urlDeleteErrorMessage = `${process.env.REACT_APP_API_BASE_URL}/errormessages/deleteerrormessage`;

// TEMPLATES URLS
export const urlGetTemplates = `${process.env.REACT_APP_API_BASE_URL}/templates`;
export const urlSearchTemplates = `${process.env.REACT_APP_API_BASE_URL}/templates/search`;
export const urlAddTemplate = `${process.env.REACT_APP_API_BASE_URL}/templates/addtemplate`;
export const urlUpdateTemplate = `${process.env.REACT_APP_API_BASE_URL}/templates/updatetemplate`;
export const urlDeleteTemplate = `${process.env.REACT_APP_API_BASE_URL}/templates/deletetemplate`;

// COUNTRY URLS
export const urlGetCountries = `${process.env.REACT_APP_API_BASE_URL}/countries`;
export const urlSearchCountries = `${process.env.REACT_APP_API_BASE_URL}/countries/search`;
export const urlAddCountry = `${process.env.REACT_APP_API_BASE_URL}/countries/addcountry`;
export const urlUpdateCountry = `${process.env.REACT_APP_API_BASE_URL}/countries/updatecountry`;
export const urlDeleteCountry = `${process.env.REACT_APP_API_BASE_URL}/countries/deletecountry`;

// PROVINCE URLS
export const urlGetProvinces = `${process.env.REACT_APP_API_BASE_URL}/provinces`;
export const urlSearchProvinces = `${process.env.REACT_APP_API_BASE_URL}/provinces/search`;
export const urlAddProvince = `${process.env.REACT_APP_API_BASE_URL}/provinces/addprovince`;
export const urlUpdateProvince = `${process.env.REACT_APP_API_BASE_URL}/provinces/updateprovince`;
export const urlDeleteProvince = `${process.env.REACT_APP_API_BASE_URL}/provinces/deleteprovince`;

// DOMAIN URLS
export const urlGetDomains = `${process.env.REACT_APP_API_BASE_URL}/domains`;
export const urlSearchDomains = `${process.env.REACT_APP_API_BASE_URL}/domains/search`;
export const urlAddDomain = `${process.env.REACT_APP_API_BASE_URL}/domains/adddomain`;
export const urlUpdateDomain = `${process.env.REACT_APP_API_BASE_URL}/domains/updatedomain`;
export const urlDeleteDomain = `${process.env.REACT_APP_API_BASE_URL}/domains/deletedomain`;

// IMAGES_PATH URLS
export const urlGetImagesPaths = `${process.env.REACT_APP_API_BASE_URL}/imagespaths`;
export const urlSearchImagesPaths = `${process.env.REACT_APP_API_BASE_URL}/imagespaths/search`;
export const urlAddImagesPath = `${process.env.REACT_APP_API_BASE_URL}/imagespaths/addimagespath`;
export const urlUpdateImagesPath = `${process.env.REACT_APP_API_BASE_URL}/imagespaths/updateimagespath`;
export const urlDeleteImagesPath = `${process.env.REACT_APP_API_BASE_URL}/imagespaths/deleteimagespath`;

// STYLE URLS
export const urlGetStyles = `${process.env.REACT_APP_API_BASE_URL}/styles`;
export const urlSearchStyles = `${process.env.REACT_APP_API_BASE_URL}/styles/search`;
export const urlAddStyle = `${process.env.REACT_APP_API_BASE_URL}/styles/addstyle`;
export const urlUpdateStyle = `${process.env.REACT_APP_API_BASE_URL}/styles/updatestyle`;
export const urlDeleteStyle = `${process.env.REACT_APP_API_BASE_URL}/styles/deletestyle`;

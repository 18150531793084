import { faEnvelope, faGift, faHeadphones, faHome, faInfoCircle, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const drawerWidth = 280;

const customClasses = makeStyles(() => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    padding: "5px 10px 5px 10px",
    overflow: "auto",
  },
  navHeader: {
    minHeight: "40px !important",
  },
  navMenuHeader: {
    minHeight: "64px !important",
  },
  linkAppBar: {
    textDecoration: "none",
  },
  menuLink: {
    display: "block",
    fontWeight: 600,
  },
  divider: {
    width: "100%",
    margin: 0,
  },
}));

const TblMobileMenu = ({ data, actions, setScroll }) => {
  const cClasses = customClasses();
  const labels = useSelector((state) => state.tblLabel.labels);

  const { openMenu, visibleHeader } = data;
  const { toggleMenu } = actions;

  return (
    <div className={cClasses.root}>
      <Drawer
        className={cClasses.drawer}
        anchor="left"
        ModalProps={{ disableEnforceFocus: true }}
        open={openMenu}
        onClose={toggleMenu(false)}
        classes={{
          paper: cClasses.drawerPaper,
        }}
      >
        {visibleHeader && <Toolbar className={cClasses.navHeader} />}
        <Toolbar className={cClasses.navMenuHeader} />
        <div className={cClasses.drawerContainer}>
          <Link className={cClasses.linkAppBar} to="/">
            <Button onClick={() => setScroll("home")} startIcon={<FontAwesomeIcon icon={faHome} />}>
              <Typography className={cClasses.menuLink} variant="h6" noWrap>
                {labels["TBL_TBLFRAME_APPBAR_LINK_HOME"]}
              </Typography>
            </Button>
          </Link>
          <Link className={cClasses.linkAppBar} to="/">
            <Button onClick={() => setScroll("services")} startIcon={<FontAwesomeIcon icon={faInfoCircle} />}>
              <Typography className={cClasses.menuLink} variant="h6" noWrap>
                {labels["TBL_TBLFRAME_APPBAR_LINK_WHATISTOURBAND"]}
              </Typography>
            </Button>
          </Link>
          <Link className={cClasses.linkAppBar} to="/groups">
            <Button startIcon={<FontAwesomeIcon icon={faHeadphones} />}>
              <Typography className={cClasses.menuLink} variant="h6" noWrap>
                {labels["TBL_COMMON_LABEL_GROUPS"]}
              </Typography>
            </Button>
          </Link>
          <Link className={cClasses.linkAppBar} to="/contests">
            <Button startIcon={<FontAwesomeIcon icon={faGift} />}>
              <Typography className={cClasses.menuLink} variant="h6" noWrap>
                {labels["TBL_COMMON_LABEL_CONTEST"]}
              </Typography>
            </Button>
          </Link>
          <Link className={cClasses.linkAppBar} to="/">
            <Button onClick={() => setScroll("contact")} startIcon={<FontAwesomeIcon icon={faEnvelope} />}>
              <Typography className={cClasses.menuLink} variant="h6" noWrap>
                {labels["TBL_TBLFRAME_APPBAR_LINK_CONTACT"]}
              </Typography>
            </Button>
          </Link>
          <Link className={cClasses.linkAppBar} to="">
            <Button onClick={() => window.open(labels["TBL_TBLFRAME_APPBAR_URL_DOWNLOAD"], "_blank")} startIcon={<FontAwesomeIcon icon={faMobileAlt} />}>
              <Typography className={cClasses.menuLink} variant="h6" noWrap>
                {labels["TBL_TBLFRAME_APPBAR_LINK_DOWNLOAD"]}
              </Typography>
            </Button>
          </Link>
        </div>
        <Divider variant="middle" className={cClasses.divider} />
      </Drawer>
    </div>
  );
};

TblMobileMenu.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  setScroll: PropTypes.func.isRequired,
};

export default TblMobileMenu;

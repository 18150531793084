import {
  ADD_ELEMENTS_BAND_PROVINCEPAGE,
  API_BAND_PROVINCE_ADD,
  API_BAND_PROVINCE_DELETE,
  API_BAND_PROVINCE_SEARCH,
  CLEAR_BAND_PROVINCE_TABLE,
  SET_SCREEN_BAND_PROVINCEPAGE,
} from "../constants";
import { apiAddBandProvince, apiDeleteBandProvince, apiSearchBandProvinces } from "../api/bandProvinceAPI";
import { urlAddBandProvince, urlDeleteBandProvince, urlSearchBandProvinces } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BAND_PROVINCEPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BAND_PROVINCEPAGE, (bandProvince) => apiSearchBandProvinces(urlSearchBandProvinces, bandProvince)());
export const actionSearchBandProvinces = createAction(API_BAND_PROVINCE_SEARCH, (bandProvince) => apiSearchBandProvinces(urlSearchBandProvinces, bandProvince)());
export const actionAddBandProvince = createAction(API_BAND_PROVINCE_ADD, (bandProvince) => apiAddBandProvince(urlAddBandProvince, bandProvince)());
export const actionDeleteBandProvince = createAction(API_BAND_PROVINCE_DELETE, ({ seqProvince }) => apiDeleteBandProvince(urlDeleteBandProvince, seqProvince)());
export const actionClearBandProvinceTable = createAction(CLEAR_BAND_PROVINCE_TABLE);

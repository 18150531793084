import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblDownload = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  return (
    <Grid container direction="column" alignContent="center" alignItems="center" className={classes.py3}>
      <Grid item xs={10}>
        <Typography variant="h3" className={classes.textWhite} align="center">
          {labels["TBL_HOME_DOWNLOAD_TITLE"]}
        </Typography>
        <hr className={`${classes.my1} ${classes.borderWhite}`} />
        <Typography variant="subtitle1" align="center" className={classes.textWhite}>
          {labels["TBL_HOME_DOWNLOAD_DESCRIPTION"]}
        </Typography>
      </Grid>
      <Grid item className={classes.mt1}>
        <Button variant="contained" className={classes.bgWhite} size="large" onClick={() => window.open(labels["TBL_TBLFRAME_APPBAR_URL_DOWNLOAD"], "_blank")}>
          {labels["TBL_COMMON_BUTTON_DOWNLOAD"]}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TblDownload;

import { DONE, IN_PROGRESS } from "../constants";
import { getCookie, showNotifier } from "./dataUtils";

import Axios from "axios";
import { Properties } from "../constants/properties";

const getHeaders = () => {
  const jwtToken = getCookie("JWT_TOKEN");
  return jwtToken
    ? {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    : {};
};

const getUploadFileHeaders = () => {
  const headers = getHeaders();
  headers.headers["content-type"] = "multipart/form-data";
  return headers;
};

const getUrlEncodedHeaders = () => {
  const headers = getHeaders();
  headers.headers["content-type"] = "application/x-www-form-urlencoded";
  return headers;
};

const buildError = (error, dispatch) => {
  const errorMsg = error?.response?.data?.apierror?.message || error?.response?.data?.message;
  if (errorMsg?.includes("Bad credentials")) {
    showNotifier(dispatch, Properties.getERRORS()["TBL_0014"], "error");
  } else if (errorMsg?.includes("TBL_")) {
    showNotifier(dispatch, Properties.getERRORS()[errorMsg], "error");
  } else {
    showNotifier(dispatch, Properties.getCOMMON_ERROR(), "error");
  }
};

export const searchData = async (url, obj) => {
  const { page, order, orderBy } = obj;
  url = `${url}&page=${page || 0}&order=${order || "asc"}&orderBy=${orderBy}`;
  const response = await doGet(url);
  const { data, headers } = response;
  if (data?.stack) {
    return data;
  }
  data.page = page || 0;
  data.totalElements = headers.totalelements;
  data.search = obj;
  return data;
};

export const paginData = async (url, obj) => {
  const { page, order, orderBy } = obj;
  url = `${url}?page=${page || 0}&order=${order || "asc"}&orderBy=${orderBy}`;
  const response = await doGet(url);
  const { data, headers } = response;
  if (data?.stack) {
    return data;
  }
  data.page = page || 0;
  data.totalElements = headers.totalelements;
  data.search = obj;
  return data;
};

export const doGet = async (url) => {
  return Axios.get(url, getHeaders());
};

export const doPost = async (url, object) => {
  return Axios.post(url, object, getHeaders());
};

export const doPut = async (url, object) => {
  return Axios.put(url, object, getHeaders());
};

export const doDelete = async (url, id) => {
  await Axios.delete(url, getHeaders());
  return { data: id };
};

export const doUploadFile = async (url, object, file) => {
  const headers = getUploadFileHeaders();
  const formData = new FormData();
  formData.append("file", file);
  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return Axios.post(url, formData, headers);
};

export const doUrlEncoded = async (url, object) => {
  return Axios.post(url, object, getUrlEncodedHeaders());
};

export const callService = async (dispatch, action, request) => {
  const callId = action.toString().concat(JSON.stringify(request));
  return new Promise((resolve, reject) => {
    if (Properties.getCALL_QUEUE().filter((call) => call.operation === callId && call.status === IN_PROGRESS).length === 0) {
      Properties.setCALL_QUEUE(callId, IN_PROGRESS);
      dispatch(action(request)).then(
        ({ payload }) => {
          Properties.setCALL_QUEUE(callId, DONE);
          if (payload?.stack) {
            if (payload.message.includes("TBL_")) {
              showNotifier(dispatch, Properties.getERRORS()[payload.message], "error");
            } else {
              showNotifier(dispatch, Properties.getCOMMON_ERROR(), "error");
            }
            reject();
          } else {
            resolve(payload);
          }
        },
        (error) => {
          Properties.setCALL_QUEUE(callId, DONE);
          buildError(error, dispatch);
          reject();
        }
      );
    } else {
      resolve();
    }
  });
};

import {
  ADD_ELEMENTS_COUNTRYPAGE,
  API_COUNTRY_ADD,
  API_COUNTRY_DELETE,
  API_COUNTRY_EDIT,
  API_COUNTRY_GET,
  API_COUNTRY_SEARCH,
  CLEAR_COUNTRY_TABLE,
  SET_SCREEN_COUNTRYPAGE,
} from "../constants";
import { apiAddCountry, apiDeleteCountry, apiGetCountries, apiSearchCountries, apiUpdateCountry } from "../api/countryAPI";
import { urlAddCountry, urlDeleteCountry, urlGetCountries, urlSearchCountries, urlUpdateCountry } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_COUNTRYPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_COUNTRYPAGE, (country) => apiSearchCountries(urlSearchCountries, country)());
export const actionGetCountries = createAction(API_COUNTRY_GET, apiGetCountries(urlGetCountries));
export const actionSearchCountries = createAction(API_COUNTRY_SEARCH, (country) => apiSearchCountries(urlSearchCountries, country)());
export const actionAddCountry = createAction(API_COUNTRY_ADD, (country) => apiAddCountry(urlAddCountry, country)());
export const actionUpdateCountry = createAction(API_COUNTRY_EDIT, (country) => apiUpdateCountry(urlUpdateCountry, country)());
export const actionDeleteCountry = createAction(API_COUNTRY_DELETE, ({ seqCountryId }) => apiDeleteCountry(urlDeleteCountry, seqCountryId)());
export const actionClearCountryTable = createAction(CLEAR_COUNTRY_TABLE);

import React, { useCallback, useMemo } from "react";
import {
  actionAddElementsPage,
  actionAddTemplate,
  actionDeleteTemplate,
  actionSearchTemplates,
  actionSetCurrentPage,
  actionUpdateTemplate,
} from "../../../../actions/templateActions";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import TblHtmlView from "../../../TblForms/TblHtmlView";
import { TblLanguage } from "../../../../model/TblLanguage";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import { TblText } from "../../../../model/TblText";
import TblTextField from "../../../TblForms/TblTextField";
import { TblTextId } from "../../../../model/TblTextId";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useSelector } from "react-redux";

const TemplateTable = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const ttemplates = useSelector((state) => state.tblTemplate.ttemplates);

  const { search, serverPage, screenPage, totalElements } = ttemplates;
  const { headCells, openAudit, previewData, showPreviewData, selected } = data;
  const { setOpenAudit, setPreviewData, setShowPreviewData } = actions;

  const rows = useMemo(
    () =>
      ttemplates.templates.reduce((acc, row) => {
        return [
          ...acc,
          new TblTextId(
            row.seqTextId,
            new TblText(row.tblText.seqText, row.tblText.codText, row.tblText.codSearch),
            row.desText,
            new TblLanguage(row.tblLanguage.seqLanguage, row.tblLanguage.codLang, row.tblLanguage.desLang),
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [ttemplates.templates]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_TEXT"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { codText, desText, codLang } = values;
      if (!codText) {
        errors.codText = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!desText) {
        errors.desText = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!codLang?.value) {
        errors.codLang = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_TEMPLATE"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
    }),
    [data, labels, rows, tableLabel, search, serverPage, screenPage, totalElements]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchTemplates,
      actionAdd: actionAddTemplate,
      actionUpdate: actionUpdateTemplate,
      actionDelete: actionDeleteTemplate,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
    }),
    [actions, handleValidation]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={showPreviewData ? 8 : 12}>
                <Grid item xs={12} container direction="column" alignItems="flex-end">
                  {showPreviewData ? (
                    <IconButton onClick={() => setShowPreviewData(false)}>
                      <VisibilityOffIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setShowPreviewData(true)}>
                      <VisibilityIcon />
                    </IconButton>
                  )}

                  <TblTextField
                    id={headCells[3].id}
                    name={headCells[3].column}
                    label={labels[headCells[3].label]}
                    setPreviewData={setPreviewData}
                    properties={headCells[3].properties}
                  />
                </Grid>
              </Grid>
              {showPreviewData && (
                <Grid item xs={4}>
                  <TblHtmlView data={previewData} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
          <Grid container item xs={6}>
            <TblSelect
              id={headCells[4].id}
              name={headCells[4].column}
              label={labels[headCells[4].label]}
              options={langs}
              defaultOption={{ seq: 0, value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
            />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

TemplateTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TemplateTable;

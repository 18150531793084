import PropTypes from "prop-types";
import TblFrame from "../components/TblFrame";
import TblHome from "../components/TblHome";

const HomePage = ({ scroll, setScroll }) => {
  return (
    <TblFrame setScroll={setScroll} size={9}>
      <TblHome scroll={scroll} setScroll={setScroll} />
    </TblFrame>
  );
};

HomePage.propTypes = {
  scroll: PropTypes.string,
  setScroll: PropTypes.func.isRequired,
};

export default HomePage;

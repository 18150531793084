import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Rating from "@material-ui/lab/Rating";
import React from "react";
import { isMobileOnly } from "react-device-detect";

const TblHandsVote = ({ voteRange, codGroup, vote, onVote }) => {
  const IconHands = (props) => {
    const { value, className, ...other } = props;
    if (className.includes("iconHover") || className.includes("iconFilled")) {
      return (
        <span className={className} {...other}>
          {isMobileOnly && voteRange > 5 ? (
            <img variant="square" src="/images/application/tblimagegrouphandvoted.png" alt="my-vote" width={25} />
          ) : (
            <img variant="square" src="/images/application/tblimagegrouphandvoted.png" alt="my-vote" />
          )}
        </span>
      );
    } else {
      return (
        <span className={className} {...other}>
          {isMobileOnly && voteRange > 5 ? (
            <img variant="square" src="/images/application/tblimagegrouphandvote.png" alt="my-vote" width={25} />
          ) : (
            <img variant="square" src="/images/application/tblimagegrouphandvote.png" alt="my-vote" />
          )}
        </span>
      );
    }
  };

  return (
    <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
      <Box component="fieldset" borderColor="transparent">
        <Rating
          name="tbl-voted"
          value={vote[codGroup] || 0}
          getLabelText={(value) => `voto-${value}`}
          onChange={(event, value) => onVote(value, codGroup)}
          IconContainerComponent={IconHands}
          max={voteRange}
        />
      </Box>
    </Grid>
  );
};

TblHandsVote.propTypes = {
  voteRange: PropTypes.number.isRequired,
  codGroup: PropTypes.string.isRequired,
  vote: PropTypes.object.isRequired,
  onVote: PropTypes.func.isRequired,
};

export default TblHandsVote;

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblLinearProgress from "../../TblLinearProgress";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblConfirmPopup = ({ open, setOpen, onConfirm, text, isLoading }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  /**
   * Method to close popup
   */
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={handleClose} aria-labelledby="popup-dialog-title" open={open}>
      <DialogTitle id="tbl-dialog-title" className={classes.confirmHeader}>
        <Typography>
          <WarningIcon className={classes.typographyAlignIcon} /> {labels["TBL_COMMON_POPUP_CONFIRM"]}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>{labels[text]}</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="secondary">
          {labels["TBL_COMMON_BUTTON_YES"]}
        </Button>
        <Button onClick={handleClose}>{labels["TBL_COMMON_BUTTON_NO"]}</Button>
      </DialogActions>
      {isLoading && <TblLinearProgress />}
    </Dialog>
  );
};

TblConfirmPopup.propTypes = {
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default TblConfirmPopup;

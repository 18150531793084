import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React from "react";
import TblLinearProgress from "../TblLinearProgress";
import TblTextField from "../TblForms/TblTextField";
import { actionLogin } from "../../actions/userActions";
import { callService } from "../../utils/serviceUtils";
import { useHistory } from "react-router";
import { useStyles } from "../../theme/useStyles";

const TblLoginAdmin = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleValidate = () => {
    return {};
  };

  const handleSubmit = (values, { setSubmitting }) => {
    callService(dispatch, actionLogin, values)
      .then(() => {
        dispatch({ type: "LOGIN_ACTION" });
        history.push("/");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Grid container direction="column" alignContent="center" alignItems="center" className={classes.py3} spacing={3}>
        <Grid item xs={12}>
          <img src={"/images/application/tblimagelogocentral.jpg"} alt="logo-central" />
        </Grid>
        <Grid item xs={10} sm={6} lg={4} container direction="column">
          <Formik initialValues={{ user: "", password: "", remember: false }} validate={handleValidate} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12}>
                    <TblTextField id="user-input" name="user" label={labels["TBL_ENTITY_TBL_USER"]} properties={{ type: "text" }} />
                  </Grid>
                  <Grid item xs={12}>
                    <TblTextField id="password-input" name="password" label={labels["TBL_ENTITY_TBL_USER_PASSWORD"]} properties={{ type: "password" }} />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                      {labels["TBL_COMMON_BUTTON_LOGIN"]}
                    </Button>
                  </Grid>
                </Grid>
                {isSubmitting && <TblLinearProgress />}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default TblLoginAdmin;

import { VALUE_N, VALUE_S } from "../../constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TblBand from "../../constants/TblBand.json";
import { TblGroupVideo } from "../../model/TblGroupVideo";
import { actionAddPublicBandVideos } from "../../actions/bandVideoActions";
import { callService } from "../../utils/serviceUtils";
import { getYoutubeId } from "../../utils/tblUtils";
import { isMobile } from "react-device-detect";
import { showNotifier } from "../../utils/dataUtils";
import { useFormikContext } from "formik";

const headCells = TblBand.headCells;

const useBandVideo = (topRef) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const band = useSelector((state) => state.tblBand.band);
  const bandVideos = useSelector((state) => state.tblBandVideo.bandVideos);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [paginVideos, setPaginVideos] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [videoId, setVideoId] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [removeId, setRemoveId] = useState("");
  const [videos, setVideos] = useState([]);

  const cols = isMobile ? 1 : paginVideos.length === 1 ? 1 : 2;

  useEffect(() => {
    setPaginVideos(bandVideos.slice((page - 1) * 4, (page - 1) * 4 + 4));
    topRef?.current.scrollIntoView({ behavior: "smooth" });
  }, [bandVideos, page, topRef]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleAddBandVideo = useCallback(() => {
    setEditMode(!editMode);
    setVideoId("");
    topRef?.current.scrollIntoView({ behavior: "smooth" });
  }, [editMode, topRef]);

  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { urlVideo } = values;
      const regex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/g;
      if (urlVideo && !urlVideo.match(regex)) {
        errors.urlVideo = labels["TBL_COMMON_INVALIDINPUT_WRONGYOUTUBEURL"];
      }
      return errors;
    },
    [labels]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      if (values.videos.length > 0) {
        values.tblGroup = band.tblGroup;
        callService(dispatch, actionAddPublicBandVideos, values)
          .then(() => {
            showNotifier(dispatch, labels["TBL_ADDVIDEO_CONFIRMATION_MSG_OK"], "success");
            setEditMode(false);
            topRef?.current.scrollIntoView({ behavior: "smooth" });
          })
          .finally(() => setSubmitting(false));
      } else {
        setSubmitting(false);
      }
    },
    [band.tblGroup, dispatch, labels, topRef]
  );

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setVideoId(event.target.textContent);
    setRemoveId("");
  };

  const handleUploadVideo = () => {
    setIsUpload(true);
  };

  const handleRemoveVideo = (pVideoId) => {
    setRemoveId(pVideoId);
    setVideoId("");
  };

  const UpdateForm = () => {
    const { values, setFieldValue, errors, setErrors, touched, setTouched } = useFormikContext();
    useEffect(() => {
      setVideos(values.videos);
      if (isUpload) {
        const id = getYoutubeId(values.urlVideo);
        if (id) {
          if (!values.videos.find((video) => video.videoId === id)) {
            setFieldValue("videos", [...values.videos, new TblGroupVideo(null, undefined, id, values.urlVideo, values.reviewed ? VALUE_S : VALUE_N)]);
            setFieldValue("urlVideo", "");
          } else {
            setErrors({ ...errors, urlVideo: labels["TBL_COMMON_INVALIDINPUT_URLVIDEO_ALREADYEXIST"] });
          }
        } else {
          setErrors({ ...errors, urlVideo: labels["TBL_COMMON_INVALIDINPUT_WRONGYOUTUBEURL"] });
        }

        setIsUpload(false);
        setVideoId(id);
        setSelectedIndex(id);
      }
      if (removeId) {
        setFieldValue(
          "videos",
          values.videos.filter((video) => video.videoId !== removeId)
        );
        setTouched({ ...touched, videos: true });
        setRemoveId("");
      }
    }, [setFieldValue, values, errors, setErrors, touched, setTouched]);

    return null;
  };

  const data = useMemo(
    () => ({
      headCells,
      videos,
      videoId,
      selectedIndex,
      cols,
      initialValues: {
        urlVideo: "",
        videos: [],
      },
      editMode,
      paginVideos,
      page,
    }),
    [cols, editMode, page, paginVideos, selectedIndex, videoId, videos]
  );
  const actions = useMemo(
    () => ({
      handleListItemClick,
      handleRemoveVideo,
      handleUploadVideo,
      handleAddBandVideo,
      handleChange,
      handleValidation,
      handleSubmit,
    }),
    [handleAddBandVideo, handleSubmit, handleValidation]
  );

  return { data, actions, UpdateForm };
};

export default useBandVideo;

import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import TblLinearProgress from "../../TblLinearProgress";
import TblTextField from "../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import { actionRememberPassEmail } from "../../../actions/userActions";
import { callService } from "../../../utils/serviceUtils";
import { showNotifier } from "../../../utils/dataUtils";
import { useStyles } from "../../../theme/useStyles";

const TblRememberPassword = ({ data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { isLoading, screens, topRef } = data;
  const { setIsLoading, setShowScreen, handleReCaptchaVerify } = actions;

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { email } = values;
      if (!email) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_EMAIL"];
      }
      return errors;
    },
    [labels]
  );

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setIsLoading(true);
      const result = await handleReCaptchaVerify();
      if (result) {
        await callService(dispatch, actionRememberPassEmail, values.email);
        showNotifier(dispatch, labels["TBL_REMEMBER_PASS_MSG_CHANGE_PASSWORD_EMAIL"], "success");
        history.push(params.get("pathname"));
      }
      setSubmitting(false);
      setIsLoading(false);
    },
    [dispatch, handleReCaptchaVerify, history, labels, params, setIsLoading]
  );

  const handleShowLoginOptions = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
    !isLoading && setShowScreen(screens.loginOptions);
  };

  return (
    <Grid item xs={12}>
      <Formik initialValues={{ email: "" }} validate={handleValidate} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                <TblTextField
                  id="email-input"
                  name="email"
                  label={labels["TBL_ENTITY_TBL_USER_EMAIL"]}
                  properties={{ type: "text", helperText: "TBL_REMEMBERPASSWORD_TEXTFIELD_HELPERTEXT" }}
                />
              </Grid>
            </Box>
            <div className={classes.mt1}></div>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
              <Grid item xs={5} md={4}>
                <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                  {labels["TBL_COMMON_BUTTON_CONTINUE_LABEL"]}
                </Button>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid item xs={10}>
                {isLoading && <TblLinearProgress />}
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      <div className={classes.mt2}></div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">{labels["TBL_LOGIN_MSG_LOGINBACK"]}</Typography>
        <div className={classes.mrxs}></div>
        <Link onClick={handleShowLoginOptions} component="button" variant="body2">
          {labels["TBL_LOGIN_BUTTON_LOGINBACK"]}
        </Link>
      </Box>
    </Grid>
  );
};

TblRememberPassword.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblRememberPassword;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  actionAddBandVideo,
  actionAddElementsPage,
  actionDeleteBandVideo,
  actionSearchBandVideos,
  actionSetCurrentPage,
  actionUpdateBandVideo,
} from "../../../../actions/bandVideoActions";

import Grid from "@material-ui/core/Grid";
import { OPERATION_ADD } from "../../../../constants";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupVideo } from "../../../../model/TblGroupVideo";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import YouTube from "react-youtube";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

const BandVideoTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tbandVideos = useSelector((state) => state.tblBandVideo.tbandVideos);

  const [open, setOpen] = useState(false);
  const [urlVideo, setUrlVideo] = useState(false);
  const [videoId, setVideoId] = useState("");

  const { search, serverPage, screenPage, totalElements } = tbandVideos;
  const { selecVideo, openVideoAudit } = data;
  const { setSelecVideo, setOpenVideoAudit } = actions;

  const rows = useMemo(
    () =>
      tbandVideos.bandVideos.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupVideo(
            row.seqVideo,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            row.videoId,
            row.urlVideo,
            row.reviewed,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tbandVideos.bandVideos]
  );

  const UpdateForm = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (urlVideo) {
        const videoID = values.urlVideo.split("/").pop();
        setFieldValue("videoId", videoID);
        setVideoId(videoID);
        setUrlVideo(false);
      }
    }, [setFieldValue, values]);

    return null;
  };

  const tableLabel = labels["TBL_ENTITY_TBL_GROUP_VIDEO"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      if (!values.urlVideo) {
        errors.urlVideo = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  const handleBeforeOpen = useCallback(
    (operation) => {
      if (operation === OPERATION_ADD) {
        setVideoId("");
      } else {
        const videoID = selecVideo.urlVideo.split("/").pop();
        setVideoId(videoID);
      }
    },
    [selecVideo]
  );

  const handleChangeUrlVideo = () => {
    setUrlVideo(true);
  };

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BANDVIDEOS"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecVideo,
    }),
    [data, open, headCells, initialValues, masterSelected, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selecVideo]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandVideos,
      actionAdd: actionAddBandVideo,
      actionUpdate: actionUpdateBandVideo,
      actionDelete: actionDeleteBandVideo,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setOpen,
      onValidation: handleValidation,
      setSelected: setSelecVideo,
      beforeOpen: handleBeforeOpen,
    }),
    [actions, handleValidation, setSelecVideo, handleBeforeOpen]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenVideoAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} onChange={handleChangeUrlVideo} properties={headCells[2].properties} />
            <UpdateForm />
          </Grid>
          <Grid item xs={5}>
            <TblCheckBoxLabel id={headCells[4].id} name={headCells[4].column} label={labels[headCells[4].label]} properties={headCells[4].properties} />
          </Grid>
          {videoId && (
            <Grid item>
              <Typography variant="subtitle1">{labels[headCells[5].label]}:</Typography>
              <YouTube videoId={videoId} opts={{ height: 150 }} />
            </Grid>
          )}
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openVideoAudit} setOpen={setOpenVideoAudit} selected={selecVideo} />
    </div>
  );
};

BandVideoTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandVideoTable;

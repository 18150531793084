import React, { useCallback, useMemo, useState } from "react";
import {
  actionAddElementsPage,
  actionAddImagesPath,
  actionDeleteImagesPath,
  actionSearchImagesPaths,
  actionSetCurrentPage,
  actionUpdateImagesPath,
} from "../../../../actions/imagesPathActions";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Properties } from "../../../../constants/properties";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import { TblImagesPath } from "../../../../model/TblImagesPath";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import TblUploadFile from "../../../TblForms/TblUploadFile";
import Typography from "@material-ui/core/Typography";
import { getByPath } from "../../../../utils/dataUtils";
import { useSelector } from "react-redux";

const LangTable = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const imageGroupsOptions = useSelector((state) => state.tblDomain.IMAGE_GROUP);
  const timagesPaths = useSelector((state) => state.tblImagesPath.timagesPaths);

  const { search, serverPage, screenPage, totalElements } = timagesPaths;
  const { headCells, openAudit, selected } = data;
  const { setOpenAudit } = actions;
  const [imageSrc, setImageSrc] = useState(null);

  const rows = useMemo(
    () =>
      timagesPaths.imagesPaths.reduce((acc, row) => {
        return [
          ...acc,
          new TblImagesPath(row.seqImagesPath, row.imageName, row.imagePath, row.imageGroup, row.url, row.active, row.sort, row.dateCre, row.dateMod, row.userCre, row.userMod),
        ];
      }, []),
    [timagesPaths.imagesPaths]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_IMAGESPATH"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { imageName, imageGroup, file } = values;
      if (!imageName) {
        errors.imageName = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!imageGroup) {
        errors.imageGroup = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (file && !Properties.SUPPORTED_FORMATS.includes(file.type)) {
        errors.file = labels["TBL_COMMON_INVALIDINPUT_FILEFORMAT"];
      }
      return errors;
    },
    [labels]
  );

  const handleBeforeSubmit = useCallback(
    (values) => {
      values.image = imageSrc;
    },
    [imageSrc]
  );

  const handleBeforeOpen = useCallback(() => {
    setImageSrc(null);
  }, []);

  data = useMemo(
    () => ({
      ...data,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_IMAGESPATH"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
    }),
    [data, labels, rows, tableLabel, search, serverPage, screenPage, totalElements]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchImagesPaths,
      actionAdd: actionAddImagesPath,
      actionUpdate: actionUpdateImagesPath,
      actionDelete: actionDeleteImagesPath,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
      beforeSubmit: handleBeforeSubmit,
      beforeOpen: handleBeforeOpen,
    }),
    [actions, handleValidation, handleBeforeOpen, handleBeforeSubmit]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{labels[headCells[7].label]}:</Typography>
            {imageSrc || getByPath(selected, headCells[7].path) ? (
              <img alt="tourbandlive-img" src={imageSrc || getByPath(selected, headCells[7].path)} style={{ maxHeight: 300 }} />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TblUploadFile id={headCells[7].id} name={headCells[7].column} setImageSrc={setImageSrc} properties={headCells[7].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
          </Grid>
          <Grid item xs={5}>
            <TblSelect
              id={headCells[4].id}
              name={headCells[4].column}
              label={labels[headCells[4].label]}
              options={imageGroupsOptions}
              defaultOption={{ seq: 0, value: "", label: labels["TBL_COMMON_NONE"] }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblTextField id={headCells[6].id} name={headCells[6].column} label={labels[headCells[6].label]} properties={headCells[6].properties} />
              </Grid>
              <Grid item xs={5}>
                <TblCheckBoxLabel id={headCells[5].id} name={headCells[5].column} label={labels[headCells[5].label]} properties={headCells[5].properties} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

LangTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default LangTable;

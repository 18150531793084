import { applyMiddleware, compose, createStore } from "redux";
import { initial_tcontestBandVotesStats, initial_tcontestBands } from "../reducers/tblContestBand";

import { initial_tband } from "../reducers/tblBand";
import { initial_tbandLink } from "../reducers/tblBandLink";
import { initial_tbandProvince } from "../reducers/tblBandProvince";
import { initial_tbandReview } from "../reducers/tblBandReview";
import { initial_tbandStyle } from "../reducers/tblBandStyle";
import { initial_tbandUserVotes } from "../reducers/tblBandUserVote";
import { initial_tbandVideo } from "../reducers/tblBandVideo";
import { initial_tcontest } from "../reducers/tblContest";
import { initial_tcontestants } from "../reducers/tblContestant";
import promiseMiddleware from "redux-promise";
import reducers from "../reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = reducers;
const initialValue = {};

const rootReducer = (state, action) => {
  if (action.type === "LOGIN_ACTION") {
    state.tblBand = { tbands: initial_tband, bands: [], band: {}, rankingBands: [] };
    state.tblBandProvince = { tbandProvinces: initial_tbandProvince };
    state.tblBandStyle = { tbandStyles: initial_tbandStyle };
    state.tblBandLink = { tbandLinks: initial_tbandLink };
    state.tblBandVideo = { tbandVideos: initial_tbandVideo, bandVideos: [] };
    state.tblBandUserVote = { tbandUserVotes: initial_tbandUserVotes };
    state.tblBandReview = { tbandReviews: initial_tbandReview, bandReviews: [] };
    state.tblContest = { tcontests: initial_tcontest, contests: [], activeContests: [], upcomingContests: [], finishedContests: [], myContests: [] };
    state.tblContestant = { tcontestants: initial_tcontestants, winners: [] };
    state.tblContestBand = { tcontestBands: initial_tcontestBands, tcontestBandVotesStats: initial_tcontestBandVotesStats, contestBands: [] };
    return appReducer(state, action);
  }

  return appReducer(state, action);
};

export const store = createStore(rootReducer, initialValue, composeEnhancers(applyMiddleware(promiseMiddleware)));

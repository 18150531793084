import {
  ADD_ELEMENTS_USERPAGE,
  API_USERS_GET,
  API_USER_ADD,
  API_USER_DELETE,
  API_USER_EDIT,
  API_USER_GET,
  API_USER_SEARCH,
  CLEAR_USER_TABLE,
  NO_ACTION,
  ROLE_ANONYMOUS,
  SET_SCREEN_USERPAGE,
  USER_LOGIN,
  USER_LOGOUT,
  VALUE_ES,
} from "../constants";

import { TblUser } from "../model/TblUser";
import { createUserSession } from "../utils/tblUtils";
import { handleActions } from "redux-actions";

export const initial_tuser = {
  users: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblUser = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [USER_LOGIN]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }

      return { ...state, userSession: createUserSession() };
    },
    [USER_LOGOUT]: (state, action) => {
      delete state.userSession;
      const userAnonymous = {
        userLang: VALUE_ES,
        applicationRole: ROLE_ANONYMOUS,
      };
      return { ...state, userSession: userAnonymous, user: {} };
    },
    [API_USER_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, user: action.payload };
    },
    [API_USERS_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const users = action.payload;
      const usersOptions = users.map((user) => ({ seq: user.seqUser, value: user.user, label: user.user }));
      return { ...state, users: usersOptions };
    },
    [API_USER_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tusers: {
          users: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_USER_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.users;
      state.tusers.totalElements += 1;

      return { ...state, tusers: { ...state.tusers, users: [action.payload].concat(state.tusers?.users || []) } };
    },
    [API_USER_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const userPayload = action.payload;
      const { seqUser } = userPayload;
      const users = state.tusers?.users || [];
      const newUsers = users.map((user) => {
        if (user.seqUser === seqUser) {
          return userPayload;
        } else {
          return user;
        }
      });
      delete state.users;
      return { ...state, tusers: { ...state.tusers, users: newUsers } };
    },
    [API_USER_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.users;
      state.tusers.totalElements -= 1;
      return { ...state, tusers: { ...state.tusers, users: state.tusers.users.filter((c) => c.seqUser !== action.payload) } };
    },
    [ADD_ELEMENTS_USERPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tusers: {
          users: [...state.tusers.users, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.tusers?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_USERPAGE]: (state, action) => ({ ...state, tusers: { ...state.tusers, screenPage: action.payload } }),
    [CLEAR_USER_TABLE]: (state) => ({ ...state, tusers: initial_tuser }),
  },
  { tusers: initial_tuser, userSession: new TblUser(), users: [], user: {} }
);

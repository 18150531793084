import React, { useCallback, useMemo } from "react";
import { actionAddElementsPage, actionAddStyle, actionDeleteStyle, actionSearchStyles, actionSetCurrentPage, actionUpdateStyle } from "../../../../actions/styleActions";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblCards from "../../TblCards";
import TblMaintainPopup from "../../TblMaintainPopup";
import { TblStyle } from "../../../../model/TblStyle";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const StyleTable = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tstyles = useSelector((state) => state.tblStyle.tstyles);

  const { search, serverPage, screenPage, totalElements } = tstyles;
  const { headCells, openAudit, selected } = data;
  const { setOpenAudit } = actions;

  const rows = useMemo(
    () =>
      tstyles.styles.reduce((acc, row) => {
        return [...acc, new TblStyle(row.seqStyle, row.codStyle, row.desStyle, row.dateCre, row.dateMod, row.userCre, row.userMod)];
      }, []),
    [tstyles.styles]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_STYLE"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { codStyle, desStyle } = values;
      if (!codStyle) {
        errors.codStyle = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (codStyle.length > 2) {
        errors.codStyle = `${labels["TBL_COMMON_INVALIDINPUT_MAXLENGTH"]} 2`;
      }
      if (!desStyle) {
        errors.desStyle = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_STYLES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
    }),
    [data, labels, rows, tableLabel, search, serverPage, screenPage, totalElements]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchStyles,
      actionAdd: actionAddStyle,
      actionUpdate: actionUpdateStyle,
      actionDelete: actionDeleteStyle,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
    }),
    [actions, handleValidation]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

StyleTable.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default StyleTable;

import { VALUE_N, VALUE_S } from "../constants";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblContest } from "../model/TblContest";
import { TblContestId } from "../model/TblContestId";
import { TblGroup } from "../model/TblGroup";
import { TblLanguage } from "../model/TblLanguage";
import { convert2UtcISOString } from "../utils/dataUtils";

export const apiGetContests = (url) => async () => {
  const { data } = await doGet(url);
  return data;
};

export const apiGetMyContests = (url, obj) => async () => {
  url = `${url}?active=${obj}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchContests = (url, obj) => async () => {
  const { codContest, contestType, name, startDate, endDate, codLang, active } = obj;
  url = `${url}?codContest=${codContest}&contestType=${contestType?.value || ""}&name=${name}&startDate=${convert2UtcISOString(startDate) || ""}&endDate=${
    convert2UtcISOString(endDate) || ""
  }&codLang=${codLang?.value || ""}&active=${active?.value || ""}`;
  return searchData(url, obj);
};

export const apiAddContest = (url, obj) => async () => {
  const {
    seqContestId,
    codContest,
    name,
    codLang,
    active,
    bands,
    contestCode,
    contestCodeRequired,
    contestType,
    summary,
    description,
    startDate,
    endDate,
    responsibleEmail,
    voteRange,
    urlFacebook,
    urlTwitter,
    urlInstagram,
    croppedImage,
  } = obj;

  const contest = new TblContest(
    undefined,
    codContest,
    contestType.value,
    contestCodeRequired.value,
    voteRange.value,
    contestCode,
    name,
    convert2UtcISOString(startDate),
    convert2UtcISOString(endDate),
    active ? VALUE_S : VALUE_N,
    undefined,
    responsibleEmail,
    urlFacebook,
    urlTwitter,
    urlInstagram
  );
  const language = new TblLanguage(codLang.seq, codLang.value, codLang.label);
  const tblBands = bands.map((band) => new TblGroup(band.seq, band.value, band.label));

  const request = new TblContestId(seqContestId, contest, language, summary, description, tblBands);
  request.image = croppedImage;
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateContest = (url, obj) => async () => {
  const {
    seqContestId,
    codContest,
    tblContest,
    name,
    codLang,
    active,
    bands,
    contestCode,
    contestCodeRequired,
    contestType,
    summary,
    description,
    startDate,
    endDate,
    responsibleEmail,
    voteRange,
    urlFacebook,
    urlTwitter,
    urlInstagram,
    dateCre,
    dateMod,
    userCre,
    userMod,
    croppedImage,
  } = obj;
  url = `${url}/${seqContestId}`;

  const contest = new TblContest(
    tblContest.seqContest,
    codContest,
    contestType.value,
    contestCodeRequired.value,
    voteRange.value,
    contestCode,
    name,
    convert2UtcISOString(startDate),
    convert2UtcISOString(endDate),
    active ? VALUE_S : VALUE_N,
    tblContest.photoContest,
    responsibleEmail,
    urlFacebook,
    urlTwitter,
    urlInstagram,
    dateCre,
    dateMod,
    userCre,
    userMod
  );
  const language = new TblLanguage(codLang.seq, codLang.value, codLang.label);
  const tblBands = bands.map((band) => new TblGroup(band.seq, band.value, band.label));

  const request = new TblContestId(seqContestId, contest, language, summary, description, tblBands, dateCre, dateMod, userCre, userMod);
  request.image = croppedImage;
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteContest = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { actionChangePassword } from "../../actions/userActions";
import { callService } from "../../utils/serviceUtils";
import { showNotifier } from "../../utils/dataUtils";

export const useChangePass = () => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { password, confpass } = values;
      if (!password) {
        errors.password = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (password !== "" && password.length < 6) {
        errors.password = labels["TBL_COMMON_INVALIDINPUT_MINLENGTH"].replace("<number>", "6");
      } else if (password !== confpass) {
        errors.password = labels["TBL_COMMON_PASSWORD_NOMATCH"];
        errors.confpass = labels["TBL_COMMON_PASSWORD_NOMATCH"];
      }
      return errors;
    },
    [labels]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setIsLoading(true);
      const changePassObject = {
        validObject: params.get("validKey"),
        password: values.password,
      };
      callService(dispatch, actionChangePassword, changePassObject)
        .then(() => {
          showNotifier(dispatch, labels["TBL_CHANGE_PASS_MSG_CONFIRMATION"], "success");
          history.push("/login?pathname=/");
        })
        .finally(() => {
          setIsLoading(false);
          setSubmitting(false);
        });
    },
    [dispatch, history, labels, params]
  );

  const data = useMemo(
    () => ({
      isLoading,
      history,
      location,
      params,
    }),
    [history, isLoading, location, params]
  );

  const actions = useMemo(
    () => ({
      setIsLoading,
      handleValidate,
      handleSubmit,
    }),
    [handleSubmit, handleValidate]
  );

  return { data, actions };
};

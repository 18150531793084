import { Form, Formik } from "formik";

import ActionButtons from "../ActionButtons";
import BandLinks from "../../../TblMaintainTabs/Bands/BandTable/BandLinks";
import Grid from "@material-ui/core/Grid";
import React from "react";
import TblAddBandVideo from "../../TblAddBandVideo";
import { useStyles } from "../../../../theme/useStyles";

const Step3 = ({ data, actions, component: UpdateForm }) => {
  const classes = useStyles();

  const { initialValues, activeStep, steps } = data;
  const { handleValidationStep3, handleSubmit, handleBack } = actions;

  return (
    <Formik initialValues={initialValues} validate={handleValidationStep3} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid container item xs={10} md={8} lg={4}>
              <BandLinks data={data} />
            </Grid>
            <div className={classes.pt1}></div>
            <TblAddBandVideo data={data} actions={actions} component={UpdateForm} />
          </Grid>
          <ActionButtons isSubmitting={isSubmitting} activeStep={activeStep} steps={steps} handleBack={handleBack} />
        </Form>
      )}
    </Formik>
  );
};

export default Step3;

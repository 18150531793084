import { actionAddBandReviewAssess, actionCreateBandReview } from "../../actions/bandReviewActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { callService } from "../../utils/serviceUtils";
import { showNotifier } from "../../utils/dataUtils";

export const useBandReview = (topRef, setReviews, setIsLoading) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const bandReviews = useSelector((state) => state.tblBandReview.bandReviews);
  const band = useSelector((state) => state.tblBand.band);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [paginReviews, setPaginReviews] = useState([]);
  const [myReview, setMyReview] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const initialValues = useMemo(
    () => ({
      seq: myReview?.seqReview || null,
      title: myReview?.reviewRevisionTitle || myReview?.reviewTitle || "",
      review: myReview?.reviewRevision || myReview?.review || "",
    }),
    [myReview]
  );

  useEffect(() => {
    // Set page
    const findMyReview = bandReviews.find((review) => review.user === userSession.user);
    let pageReview = [];
    if (findMyReview) {
      // Set myReview
      pageReview = bandReviews.filter((review) => review.user !== userSession.user).slice((page - 1) * 4, (page - 1) * 4 + 4);
      pageReview.unshift(findMyReview);
      setMyReview(findMyReview);
      setEditMode(false);
    } else {
      pageReview = bandReviews.slice((page - 1) * 5, (page - 1) * 5 + 5);
      setEditMode(true);
      setMyReview(null);
    }
    setPaginReviews(pageReview);
    // scroll to top page
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [page, bandReviews, userSession, topRef]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { title, review } = values;
      if (!title) {
        errors.title = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!review) {
        errors.review = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (review.length < 100) {
        errors.review = labels["TBL_COMMON_INVALIDINPUT_MINLENGTH"].replace("<number>", "100");
      }
      return errors;
    },
    [labels]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.tblGroup = band.tblGroup;
      values.tblUser = { user: userSession.user };
      callService(dispatch, actionCreateBandReview, values)
        .then(() => {
          showNotifier(dispatch, labels["TBL_BAND_REVIEW_SAVEREVIEW_OK"], "success");
          if (!values.seq) {
            setReviews(bandReviews.length + 1);
          }
        })
        .finally(() => setSubmitting(false));
    },
    [band, bandReviews, dispatch, labels, setReviews, userSession]
  );

  const handleAssessment = useCallback(
    (bandReview) => {
      if (bandReview.user !== userSession.user) {
        setIsLoading(true);
        callService(dispatch, actionAddBandReviewAssess, bandReview.seqReview).finally(() => setIsLoading(false));
      }
    },
    [dispatch, setIsLoading, userSession]
  );

  const data = useMemo(
    () => ({
      page,
      paginReviews,
      myReview,
      editMode,
      initialValues,
    }),
    [editMode, initialValues, myReview, page, paginReviews]
  );

  const actions = useMemo(
    () => ({
      setPage,
      setPaginReviews,
      setMyReview,
      setEditMode,
      handleChange,
      handleValidate,
      handleSubmit,
      handleAssessment,
    }),
    [handleAssessment, handleSubmit, handleValidate]
  );

  return { data, actions };
};

import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblCardNoContest from "../TblCardNoContest";
import TblContestWinnersPopup from "../../TblPopups/TblContestWinnersPopup";
import TblGridContests from "../TblGridContests";
import TblGridSkeleton from "../TblGridContests/TblGridSkeleton";
import Typography from "@material-ui/core/Typography";
import { actionGetContestWinners } from "../../../actions/contestantActions";
import { callService } from "../../../utils/serviceUtils";

const TblFinisheds = ({ topRef, loading }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const templates = useSelector((state) => state.tblTemplate.templates);
  const finishedContests = useSelector((state) => state.tblContest.finishedContests);

  const dispatch = useDispatch();
  const [loadingP, setLoadingP] = useState(false);
  const [open, setOpen] = useState(false);

  const showWinners = useCallback(
    (contest) => {
      setLoadingP(true);
      callService(dispatch, actionGetContestWinners, contest).finally(() => setLoadingP(false));
      setOpen(true);
    },
    [dispatch]
  );

  const handleClosePopup = useCallback(() => setOpen(false), []);

  return (
    <React.Fragment>
      <Grid container direction="column" alignContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {labels["TBL_CONTEST_TABPANEL_TITLE_FINISHED"]}
          </Typography>
          <hr />
        </Grid>
        {!loading ? (
          finishedContests.length > 0 ? (
            <TblGridContests topRef={topRef} gridList={finishedContests || []} onAction={showWinners} loading={loading} />
          ) : (
            <TblCardNoContest image={"/images/application/tblimagecontestnofinished.jpg"} text={templates["TBL_CONTEST_NO_FINISHED_CARD"]} topRef={topRef} />
          )
        ) : (
          <TblGridSkeleton />
        )}
      </Grid>
      <TblContestWinnersPopup open={open} onClosePopup={handleClosePopup} loading={loadingP} />
    </React.Fragment>
  );
};

PropTypes.TblBandReview = {
  topRef: PropTypes.object.isRequired,
};

export default TblFinisheds;

import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import Select from "@material-ui/core/Select";

const renderMenuItems = (options) => {
  return options.map((option) => (
    <MenuItem key={option.seq} value={option}>
      {option.label}
    </MenuItem>
  ));
};
const SelectField = ({ options, field, form, label, disabled, defaultOption, onSelect }) => {
  return (
    <Select
      name={field.name}
      value={options && field?.value ? options.find((option) => Object.values(option)[0] === Object.values(field.value)[0]) : ""}
      onChange={(event) => {
        form.setFieldValue(field.name, options ? options.find((option) => Object.values(option)[0] === Object.values(event.target.value)[0]) : "");
        form.setTouched({ [field.name]: true }, false);
        onSelect && onSelect(event);
      }}
      onBlur={field.onBlur}
      label={label}
      disabled={form.isSubmitting || !!disabled}
    >
      {defaultOption ? <MenuItem value={defaultOption.value}>{defaultOption.label}</MenuItem> : null}
      {renderMenuItems(options)}
    </Select>
  );
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      seq: PropTypes.number.isRequired,
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.any,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultOption: PropTypes.object,
  onSelect: PropTypes.func,
};

export default SelectField;

import { actionActiveUser, actionResendActiveEmail } from "../../actions/userActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { ROLE_NOACTIVE } from "../../constants";
import { callService } from "../../utils/serviceUtils";
import { showNotifier } from "../../utils/dataUtils";

export const useActiveUser = () => {
  const userSession = useSelector((state) => state.tblUser.userSession);
  const labels = useSelector((state) => state.tblLabel.labels);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const [activated, setActivated] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    if (params.get("activekey") && !activated) {
      setIsLoading(true);
      callService(dispatch, actionActiveUser, params.get("activekey"))
        .then(() => {
          setActivated(true);
          history.push("/profile/active");
        })
        .finally(() => setIsLoading(false));
    } else if (!userSession.applicationRole.includes(ROLE_NOACTIVE) && !activated) {
      history.push("/");
    }
  }, [activated, dispatch, history, params, userSession.applicationRole]);

  const handleResendActivationEmail = useCallback(() => {
    if (!isLoadingResend) {
      setIsLoadingResend(true);
      callService(dispatch, actionResendActiveEmail)
        .then(() => showNotifier(dispatch, labels["TBL_ACTIVE_USER_MSG_RESEND_ACTIVE_EMAIL"], "success"))
        .finally(() => setIsLoadingResend(false));
    }
  }, [dispatch, isLoadingResend, labels]);

  const data = useMemo(
    () => ({
      isLoading,
      isLoadingResend,
      activated,
      history,
      location,
      params,
    }),
    [activated, history, isLoading, isLoadingResend, location, params]
  );

  const actions = useMemo(
    () => ({
      setIsLoading,
      setIsLoadingResend,
      setActivated,
      handleResendActivationEmail,
    }),
    [handleResendActivationEmail]
  );

  return { data, actions };
};

import React from "react";
import Step from "@material-ui/core/Step";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { useAddBand } from "../../../hooks/TblBand/useAddBand";
import { useStyles } from "../../../theme/useStyles";

const TblAddBand = () => {
  const classes = useStyles();

  const { data, actions, UpdateForm } = useAddBand();
  const { steps, activeStep } = data;

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && <Step1 data={data} actions={actions} />}
      {activeStep === 1 && <Step2 data={data} actions={actions} />}
      {activeStep === 2 && <Step3 data={data} actions={actions} component={UpdateForm} />}
      <div className={classes.pb2}></div>
    </React.Fragment>
  );
};

export default TblAddBand;

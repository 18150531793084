export class TblCountry {
  constructor(seqCountry, codCountry, dateCre, dateMod, userCre, userMod) {
    this.seqCountry = seqCountry;
    this.codCountry = codCountry;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { actionAddBand, actionAddElementsPage, actionDeleteBand, actionSearchBands, actionSetCurrentPage, actionUpdateBand } from "../../../../actions/bandActions";
import { getByPath, latinise } from "../../../../utils/dataUtils";
import { useDispatch, useSelector } from "react-redux";

import BandLinks from "./BandLinks";
import BandVideos from "./BandVideos";
import Grid from "@material-ui/core/Grid";
import { OPERATION_ADD } from "../../../../constants";
import PropTypes from "prop-types";
import { Properties } from "../../../../constants/properties";
import Skeleton from "@material-ui/lab/Skeleton";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblAvatar from "../../../TblForms/TblAvatar";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import TblCropper from "../../../TblCropper/TblCropper";
import { TblGroupId } from "../../../../model/TblGroupId";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblSelect from "../../../TblForms/TblSelect";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetProvinces } from "../../../../actions/provinceActions";
import { actionGetStyles } from "../../../../actions/styleActions";
import { callService } from "../../../../utils/serviceUtils";
import { useFormikContext } from "formik";
import { useStyles } from "../../../../theme/useStyles";

const BandTable = ({ headCells, initialValues, selected, setSelected, data, actions }) => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const countries = useSelector((state) => state.tblCountry.countries);
  const provinces = useSelector((state) => state.tblProvince.provinces);
  const styles = useSelector((state) => state.tblStyle.styles);
  const langs = useSelector((state) => state.tblLang.langs);
  const tbands = useSelector((state) => state.tblBand.tbands);
  const dispatch = useDispatch();

  const { search, serverPage, screenPage, totalElements } = tbands;
  const { openAudit, disabledProv, isLoading, childrens } = data;
  const { setOpenAudit, setDisabledProv, setIsUpdated } = actions;

  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  if (styles.length === 0) {
    callService(dispatch, actionGetStyles);
  }

  const rows = useMemo(
    () =>
      tbands.bands.reduce((acc, row) => {
        if (row.seqGroupId) {
          return [
            ...acc,
            new TblGroupId(
              row.seqGroupId,
              row.tblGroup,
              row.tblLanguage,
              row.description,
              undefined,
              undefined,
              undefined,
              undefined,
              row.dateCre,
              row.dateMod,
              row.userCre,
              row.userMod
            ),
          ];
        } else {
          return [...acc];
        }
      }, []),
    [tbands.bands]
  );

  // Latinise
  const [isLatinise, setIsLatinise] = useState(false);
  const UpdateForm = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (isLatinise) {
        setFieldValue("codGroup", latinise(values.name));
        setIsLatinise(false);
      }
    }, [setFieldValue, values]);

    return null;
  };

  const tableLabel = labels["TBL_ENTITY_TBL_BAND"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { name, description, codCountry, provinces, styles, codLang, web, facebook, instagram, youtube, bandcamp, soundcloud, urlVideo, file } = values;
      if (!name) {
        errors.name = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!description) {
        errors.description = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!codCountry?.value) {
        errors.codCountry = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!codLang?.value) {
        errors.codLang = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (provinces.length === 0) {
        errors.provinces = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (styles.length === 0) {
        errors.styles = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!web && !facebook && !instagram) {
        errors.web = labels["TBL_COMMON_INVALIDINPUT_ATLEASTONE_RRSS"];
        errors.facebook = labels["TBL_COMMON_INVALIDINPUT_ATLEASTONE_RRSS"];
        errors.instagram = labels["TBL_COMMON_INVALIDINPUT_ATLEASTONE_RRSS"];
      }
      const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      if (web && !web.match(regex)) {
        errors.web = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (facebook && !facebook.match(regex)) {
        errors.facebook = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (instagram && !instagram.match(regex)) {
        errors.instagram = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (youtube && !youtube.match(regex)) {
        errors.youtube = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (bandcamp && !bandcamp.match(regex)) {
        errors.bandcamp = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (soundcloud && !soundcloud.match(regex)) {
        errors.soundcloud = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (urlVideo && !urlVideo.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/)) {
        errors.urlVideo = labels["TBL_COMMON_INVALIDINPUT_WRONGYOUTUBEURL"];
      }
      if (file && !Properties.SUPPORTED_FORMATS.includes(file.type)) {
        errors.file = labels["TBL_COMMON_INVALIDINPUT_FILEFORMAT"];
      }
      return errors;
    },
    [labels]
  );

  const handleChangeName = () => {
    setIsLatinise(true);
  };

  const handleSelectCountry = useCallback(
    (e) => {
      const country = e.target;
      callService(dispatch, actionGetProvinces, country?.value?.value).then((response) => {
        if (response.length > 0) {
          setDisabledProv(false);
        } else {
          setDisabledProv(true);
        }
      });
    },
    [dispatch, setDisabledProv]
  );

  const handleBeforeSubmit = useCallback(
    (values) => {
      values.croppedImage = croppedImage;
      values.childrens = childrens;
    },
    [croppedImage, childrens]
  );

  const handleAfterSubmit = useCallback(() => {
    setIsUpdated(true);
  }, [setIsUpdated]);

  const handleBeforeOpen = useCallback(
    (operation) => {
      setCroppedImage(null);
      setImageSrc(null);
      if (selected?.tblGroup?.tblCountry) {
        callService(dispatch, actionGetProvinces, selected.tblGroup.tblCountry.codCountry).then(() => {
          if (operation === OPERATION_ADD) {
            setDisabledProv(true);
          } else {
            setDisabledProv(false);
          }
        });
      }
    },
    [selected, dispatch, setDisabledProv]
  );

  data = useMemo(
    () => ({
      ...data,
      headCells,
      initialValues,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BAND"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected,
      imageSrc,
    }),
    [data, headCells, initialValues, labels, rows, totalElements, tableLabel, search, serverPage, screenPage, selected, imageSrc]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBands,
      actionAdd: actionAddBand,
      actionUpdate: actionUpdateBand,
      actionDelete: actionDeleteBand,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
      setSelected,
      setCroppedImage,
      setImageSrc,
      beforeSubmit: handleBeforeSubmit,
      afterSubmit: handleAfterSubmit,
      beforeOpen: handleBeforeOpen,
    }),
    [actions, handleValidation, setSelected, handleBeforeSubmit, handleAfterSubmit, handleBeforeOpen]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        {imageSrc ? (
          <TblCropper imageSrc={imageSrc} setImageSrc={setImageSrc} setCroppedImage={setCroppedImage} />
        ) : (
          <Grid container direction="column" spacing={3}>
            <TblAvatar
              id={headCells[8].id}
              name={headCells[8].column}
              label={labels[headCells[8].label]}
              properties={headCells[8].properties}
              src={croppedImage || getByPath(selected, headCells[8].path)}
              setImageSrc={setImageSrc}
            />
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={5}>
                <TblTextField
                  id={headCells[1].id}
                  name={headCells[1].column}
                  label={labels[headCells[1].label]}
                  disabled={headCells[1].disabled}
                  properties={headCells[1].properties}
                />
              </Grid>
              <Grid item xs={5}>
                <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} onChange={handleChangeName} properties={headCells[2].properties} />
                <UpdateForm />
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item xs={12}>
                <TblTextField id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" item>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[3].id}
                  name={headCells[3].column}
                  label={labels[headCells[3].label]}
                  options={countries}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_COUNTRY"] }}
                  onSelect={handleSelectCountry}
                />
              </Grid>
              <Grid item xs={5}>
                {!isLoading ? (
                  <TblAutocomplete
                    id={headCells[4].id}
                    name={headCells[4].column}
                    label={labels[headCells[4].label]}
                    disabled={disabledProv}
                    properties={headCells[4].properties}
                    options={provinces}
                  />
                ) : (
                  <Skeleton variant="rect" className={classes.skeletonInput} />
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" item>
              <Grid item xs={5}>
                <TblSelect
                  id={headCells[5].id}
                  name={headCells[5].column}
                  label={labels[headCells[5].label]}
                  options={langs}
                  defaultOption={{ value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
                />
              </Grid>
              <Grid item xs={5}>
                <TblCheckBoxLabel id={headCells[6].id} name={headCells[6].column} label={labels[headCells[6].label]} properties={headCells[6].properties} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {!isLoading ? (
                <TblAutocomplete id={headCells[9].id} name={headCells[9].column} label={labels[headCells[9].label]} properties={headCells[9].properties} options={styles} />
              ) : (
                <Skeleton variant="rect" className={classes.skeletonInput} />
              )}
            </Grid>
            <Grid item container direction="column" spacing={3}>
              <BandLinks data={data} />
              <BandVideos data={data} />
            </Grid>
          </Grid>
        )}
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

BandTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandTable;

import { faFacebook, faInstagram, faSpotify, faTwitter } from "@fortawesome/free-brands-svg-icons";

import AppBar from "@material-ui/core/AppBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const customStyles = makeStyles((theme) => ({
  grow: {
    width: "100%",
    flexGrow: 1,
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: "#212529 !important",
  },
  navHeader: {
    minHeight: "40px !important",
  },
}));

const TblHeader = () => {
  const classes = useStyles();
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  return (
    <AppBar position="sticky" className={cClasses.grow}>
      <Toolbar className={cClasses.navHeader}>
        <Grid item container direction="row" justifyContent="flex-end" alignContent="center">
          <Grid item className={classes.mr1}>
            <IconButton aria-label="rrss-facebook" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_FACEBOOK"], "_blank")}>
              <FontAwesomeIcon icon={faFacebook} color="#dc3545" />
            </IconButton>
          </Grid>
          <Grid item className={classes.mr1}>
            <IconButton aria-label="rrss-twitter" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_TWITTER"], "_blank")}>
              <FontAwesomeIcon icon={faTwitter} color="#dc3545" />
            </IconButton>
          </Grid>
          <Grid item className={classes.mr1}>
            <IconButton aria-label="rrss-instagram" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_INSTAGRAM"], "_blank")}>
              <FontAwesomeIcon icon={faInstagram} color="#dc3545" />
            </IconButton>
          </Grid>
          <Grid item className={classes.mr1}>
            <IconButton aria-label="rrss-spotify" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_SPOTIFY"], "_blank")}>
              <FontAwesomeIcon icon={faSpotify} color="#dc3545" />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TblHeader;

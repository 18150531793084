export class TblUser {
  constructor(
    seqUser,
    user,
    password,
    facebookId,
    vinculateFacebook,
    googleId,
    vinculateGoogle,
    name,
    lastName,
    email,
    birthDate,
    userLang,
    tblCountry,
    tblProvince,
    photoProfile,
    applicationRole,
    active,
    activeKey,
    notifications,
    dateCre,
    dateMod,
    userCre,
    userMod
  ) {
    this.seqUser = seqUser;
    this.user = user;
    this.password = password;
    this.facebookId = facebookId;
    this.vinculateFacebook = vinculateFacebook;
    this.googleId = googleId;
    this.vinculateGoogle = vinculateGoogle;
    this.name = name;
    this.lastName = lastName;
    this.email = email;
    this.birthDate = birthDate;
    this.userLang = userLang;
    this.tblCountry = tblCountry;
    this.tblProvince = tblProvince;
    this.photoProfile = photoProfile;
    this.applicationRole = applicationRole;
    this.active = active;
    this.activeKey = activeKey;
    this.notifications = notifications;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import { useDispatch, useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblSearchForm from "../../TblSearchForm";
import TblSelect from "../../../TblForms/TblSelect";
import TblTextField from "../../../TblForms/TblTextField";
import { actionGetDomain } from "../../../../actions/domainActions";
import { actionSearchImagesPaths } from "../../../../actions/imagesPathActions";
import { callService } from "../../../../utils/serviceUtils";
import { useCallback } from "react";

const initialValues = {
  imageName: "",
  imagePath: "",
  imageGroup: "",
  active: "",
};

const ImagesPathSearchForm = ({ data, actions }) => {
  const userSession = useSelector((state) => state.tblUser.userSession);
  const labels = useSelector((state) => state.tblLabel.labels);
  const imageGroupsOptions = useSelector((state) => state.tblDomain.IMAGE_GROUP);
  const activoOptions = useSelector((state) => state.tblDomain.IND_YES_NO);
  const dispatch = useDispatch();

  const { headCells } = data;
  const { setSelected } = actions;
  initialValues.active = "";

  if (!imageGroupsOptions || imageGroupsOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "IMAGE_GROUP", userLang: userSession.userLang });
  }
  if (!activoOptions || activoOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "IND_YES_NO", userLang: userSession.userLang });
  }

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      values.orderBy = headCells[1].path;
      callService(dispatch, actionSearchImagesPaths, values).finally(() => {
        setSelected({});
        setSubmitting(false);
      });
    },
    [dispatch, headCells, setSelected]
  );

  return (
    <TblSearchForm initialValues={initialValues} onSubmit={handleSubmit}>
      <Grid item xs={8}>
        <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
      </Grid>
      <Grid item xs={8}>
        <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCells[4].id}
          name={headCells[4].column}
          label={labels[headCells[4].label]}
          options={imageGroupsOptions}
          defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
        />
      </Grid>
      <Grid container item xs={4}>
        <TblSelect
          id={headCells[5].id}
          name={headCells[5].column}
          label={labels[headCells[5].label]}
          options={activoOptions}
          defaultOption={{ value: "", label: labels["TBL_COMMON_NONE"] }}
        />
      </Grid>
    </TblSearchForm>
  );
};

ImagesPathSearchForm.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ImagesPathSearchForm;

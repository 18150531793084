import {
  ADD_ELEMENTS_BAND_VIDEOPAGE,
  API_BAND_VIDEO,
  API_BAND_VIDEO_ADD,
  API_BAND_VIDEO_DELETE,
  API_BAND_VIDEO_EDIT,
  API_BAND_VIDEO_SEARCH,
  CLEAR_BAND_VIDEO_TABLE,
  NO_ACTION,
  SET_SCREEN_BAND_VIDEOPAGE,
} from "../constants";
import { apiAddBandVideo, apiAddPublicBandVideos, apiDeleteBandVideo, apiGetBandVideos, apiSearchBandVideos, apiUpdateBandVideo } from "../api/bandVideoAPI";
import { urlAddBandVideo, urlAddPublicBandVideos, urlDeleteBandVideo, urlGetBandVideos, urlSearchBandVideos, urlUpdateBandVideo } from "../api/urls";

import { createAction } from "redux-actions";

export const actionSetCurrentPage = createAction(SET_SCREEN_BAND_VIDEOPAGE, (currentPage) => currentPage);
export const actionAddElementsPage = createAction(ADD_ELEMENTS_BAND_VIDEOPAGE, (bandVideo) => apiSearchBandVideos(urlSearchBandVideos, bandVideo)());
export const actionGetBandVideos = createAction(API_BAND_VIDEO, (codGroup) => apiGetBandVideos(urlGetBandVideos, codGroup)());
export const actionSearchBandVideos = createAction(API_BAND_VIDEO_SEARCH, (bandVideo) => apiSearchBandVideos(urlSearchBandVideos, bandVideo)());
export const actionAddPublicBandVideos = createAction(NO_ACTION, (bandVideos) => apiAddPublicBandVideos(urlAddPublicBandVideos, bandVideos)());
export const actionAddBandVideo = createAction(API_BAND_VIDEO_ADD, (bandVideo) => apiAddBandVideo(urlAddBandVideo, bandVideo)());
export const actionUpdateBandVideo = createAction(API_BAND_VIDEO_EDIT, (bandVideo) => apiUpdateBandVideo(urlUpdateBandVideo, bandVideo)());
export const actionDeleteBandVideo = createAction(API_BAND_VIDEO_DELETE, ({ seqVideo }) => apiDeleteBandVideo(urlDeleteBandVideo, seqVideo)());
export const actionClearBandVideoTable = createAction(CLEAR_BAND_VIDEO_TABLE);

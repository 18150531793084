import {
  ADD_ELEMENTS_CONTESTANTPAGE,
  API_CONTESTANT_ADD,
  API_CONTESTANT_DELETE,
  API_CONTESTANT_EDIT,
  API_CONTESTANT_SEARCH,
  API_CONTESTANT_WINNER,
  API_PARTICIPATE,
  CLEAR_CONTESTANT_TABLE,
  NO_ACTION,
  SET_SCREEN_CONTESTANTPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tcontestants = {
  contestants: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblContestant = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_CONTESTANT_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontestants: {
          contestants: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_CONTESTANT_WINNER]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return { ...state, winners: action.payload };
    },
    [API_CONTESTANT_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tcontestants.totalElements += 1;
      const contestantPayload = action.payload;

      return { ...state, tcontestants: { ...state.tcontestants, contestants: [contestantPayload].concat(state.tcontestants?.contestants || []) } };
    },
    [API_CONTESTANT_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const contestantPayload = action.payload;
      const { seqContestant } = contestantPayload;
      const contestants = state.tcontestants?.contestants || [];
      const newContestants = contestants.map((contestant) => {
        if (contestant.seqContestant === seqContestant) {
          return contestantPayload;
        } else {
          return contestant;
        }
      });
      return { ...state, tcontestants: { ...state.tcontestants, contestants: newContestants } };
    },
    [API_CONTESTANT_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tcontestants.totalElements -= 1;
      return { ...state, tcontestants: { ...state.tcontestants, contestants: state.tcontestants.contestants.filter((c) => c.seqContestant !== action.payload) } };
    },
    [API_PARTICIPATE]: (state, action) => {
      return state;
    },
    [ADD_ELEMENTS_CONTESTANTPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tcontestants: {
          contestants: [...state.tcontestants.contestants, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tcontestants?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_CONTESTANTPAGE]: (state, action) => ({ ...state, tcontestants: { ...state.tcontestants, screenPage: action.payload } }),
    [CLEAR_CONTESTANT_TABLE]: (state) => ({ ...state, tcontestants: initial_tcontestants }),
  },
  { tcontestants: initial_tcontestants, winners: [] }
);

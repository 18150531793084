import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import TblDialogTitle from "../TblDialogTitle";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const TblMsgPopup = ({ open, setOpen, tKey, onClosePopup }) => {
  const templates = useSelector((state) => state.tblTemplate.templates);
  const labels = useSelector((state) => state.tblLabel.labels);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={onClosePopup || handleClose} aria-labelledby="tbl-popup-message" open={open} fullWidth>
      <TblDialogTitle id="tbl-dialog-title" onClosePopup={onClosePopup || handleClose} style={{ color: "#fff", backgroundColor: "#17a2b8" }}>
        {labels["TBL_COMMON_DIALOGTITLE_INFO"]}
      </TblDialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">{ReactHtmlParser(templates[tKey])}</Typography>
      </DialogContent>
    </Dialog>
  );
};

TblMsgPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  tKey: PropTypes.string.isRequired,
  onClosePopup: PropTypes.func,
};

export default TblMsgPopup;

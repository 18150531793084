import { faFacebook, faInstagram, faSpotify, faTwitter } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ReactHtmlParser from "react-html-parser";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblFooter = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const templates = useSelector((state) => state.tblTemplate.templates) || {};

  return (
    <div className={classes.bgDark}>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.py2}>
        <Grid item xs={10} className={classes.pb1}>
          <Typography variant="h5" className={classes.textWhite} align="center">
            {labels["TBL_FOOTER_TITLE_TOURBANDLIVE"]}
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="center" alignItems="center">
          <Grid item className={classes.pr1}>
            <img src={"/images/application/tblimagelogoblanco.png"} alt="logo-blanco" width="100" />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item className={classes.mbxs}>
                <IconButton aria-label="rrss-facebook" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_FACEBOOK"], "_blank")}>
                  <FontAwesomeIcon icon={faFacebook} color="#fff" />
                </IconButton>
              </Grid>
              <Grid item className={classes.mbxs}>
                <IconButton aria-label="rrss-twitter" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_TWITTER"], "_blank")}>
                  <FontAwesomeIcon icon={faTwitter} color="#fff" />
                </IconButton>
              </Grid>
              <Grid item className={classes.mbxs}>
                <IconButton aria-label="rrss-instagram" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_INSTAGRAM"], "_blank")}>
                  <FontAwesomeIcon icon={faInstagram} color="#fff" />
                </IconButton>
              </Grid>
              <Grid item className={classes.mbxs}>
                <IconButton aria-label="rrss-spotify" size="small" onClick={() => window.open(labels["TBL_COMMON_LINK_SPOTIFY"], "_blank")}>
                  <FontAwesomeIcon icon={faSpotify} color="#fff" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.pt1}>
          <Typography variant="subtitle1" className={classes.textWhite} align="center">
            {ReactHtmlParser(templates["TBL_INDEXHTML_FOOTER_TEXT_COPYRIGHT"])}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.textWhite} align="center">
            {ReactHtmlParser(templates["TBL_RECAPTCHA_GOOGLE_PRIVACY_POLICY"])}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default TblFooter;

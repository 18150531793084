import { VALUE_ES, VALUE_ESP } from "../../constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Properties } from "../../constants/properties";
import TblBand from "../../constants/TblBand.json";
import { actionAddPublicBand } from "../../actions/bandActions";
import { actionGetCountries } from "../../actions/countryActions";
import { actionGetLangs } from "../../actions/langActions";
import { actionGetProvinces } from "../../actions/provinceActions";
import { actionGetStyles } from "../../actions/styleActions";
import { callService } from "../../utils/serviceUtils";
import { showNotifier } from "../../utils/dataUtils";
import useBandVideo from "./useBandVideo";
import { useHistory } from "react-router-dom";

const headCells = TblBand.headCells;

export const useAddBand = (topRef) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const countries = useSelector((state) => state.tblCountry.countries);
  const langs = useSelector((state) => state.tblLang.langs);
  const dispatch = useDispatch();
  const history = useHistory();
  let { data, actions, UpdateForm } = useBandVideo(topRef);

  const [initialValues, setInitialValues] = useState({
    name: "",
    provinces: [],
    styles: [],
    description: "",
    web: "",
    facebook: "",
    instagram: "",
    youtube: "",
    bandcamp: "",
    soundcloud: "",
    urlVideo: "",
    videos: [],
    file: null,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const steps = useMemo(() => [labels["TBL_ADDBAND_STEPS_LABEL_STEP1"], labels["TBL_ADDBAND_STEPS_LABEL_STEP2"], labels["TBL_ADDBAND_STEPS_LABEL_STEP3"]], [labels]);

  useEffect(() => {
    callService(dispatch, actionGetProvinces, VALUE_ESP);
    callService(dispatch, actionGetLangs);
    callService(dispatch, actionGetCountries);
    callService(dispatch, actionGetStyles);
  }, [dispatch]);

  const handleValidationStep1 = useCallback(
    (values) => {
      const errors = {};
      const { name, file } = values;
      if (!name) {
        errors.name = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!imageSrc && !croppedImage) {
        errors.file = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (file && !Properties.SUPPORTED_FORMATS.includes(file.type)) {
        errors.file = labels["TBL_COMMON_INVALIDINPUT_FILEFORMAT"];
      }
      return errors;
    },
    [croppedImage, imageSrc, labels]
  );

  const handleValidationStep2 = useCallback(
    (values) => {
      const errors = {};
      const { description, provinces, styles } = values;

      if (!description) {
        errors.description = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (description !== "" && description.length < 100) {
        errors.description = labels["TBL_COMMON_INVALIDINPUT_MINLENGTH"].replace("<number>", "100");
      }
      if (provinces.length === 0) {
        errors.provinces = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (styles.length === 0) {
        errors.styles = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  const handleValidationStep3 = useCallback(
    (values) => {
      const errors = {};
      const { web, facebook, instagram, youtube, bandcamp, soundcloud, urlVideo } = values;
      if (!web && !facebook && !instagram && activeStep === 2) {
        errors.web = labels["TBL_COMMON_INVALIDINPUT_ATLEASTONE_RRSS"];
        errors.facebook = labels["TBL_COMMON_INVALIDINPUT_ATLEASTONE_RRSS"];
        errors.instagram = labels["TBL_COMMON_INVALIDINPUT_ATLEASTONE_RRSS"];
      }
      let regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      if (web && !web.match(regex) && activeStep === 2) {
        errors.web = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (facebook && !facebook.match(regex) && activeStep === 2) {
        errors.facebook = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (instagram && !instagram.match(regex) && activeStep === 2) {
        errors.instagram = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (youtube && !youtube.match(regex) && activeStep === 2) {
        errors.youtube = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (bandcamp && !bandcamp.match(regex) && activeStep === 2) {
        errors.bandcamp = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      if (soundcloud && !soundcloud.match(regex) && activeStep === 2) {
        errors.soundcloud = labels["TBL_COMMON_INVALIDINPUT_WRONGFORMEDURL"];
      }
      regex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/g;
      if (urlVideo && !urlVideo.match(regex) && activeStep === 2) {
        errors.urlVideo = labels["TBL_COMMON_INVALIDINPUT_WRONGYOUTUBEURL"];
      }
      return errors;
    },
    [activeStep, labels]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      if (activeStep === steps.length - 1) {
        setInitialValues(values);
        values.country = countries.find((country) => country.value === VALUE_ESP);
        values.language = langs.find((language) => (language.value = VALUE_ES));
        values.croppedImage = croppedImage;
        callService(dispatch, actionAddPublicBand, values)
          .then(() => {
            showNotifier(dispatch, labels["TBL_ADDBAND_CONFIMATION_MSG_OK"], "success");
            history.push("/groups");
          })
          .finally(() => setSubmitting(false));
      } else {
        setInitialValues(values);
        setActiveStep(activeStep + 1);
        setSubmitting(false);
      }
    },
    [activeStep, countries, croppedImage, dispatch, history, labels, langs, steps]
  );

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      history.push("/groups");
    } else {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep, history]);

  data = useMemo(
    () => ({
      ...data,
      activeStep,
      initialValues,
      steps,
      imageSrc,
      croppedImage,
      headCells,
      isLoading: false,
    }),
    [activeStep, croppedImage, data, imageSrc, initialValues, steps]
  );
  actions = useMemo(
    () => ({
      ...actions,
      setActiveStep,
      handleValidationStep1,
      handleValidationStep2,
      handleValidationStep3,
      handleBack,
      handleSubmit,
      setCroppedImage,
      setImageSrc,
    }),
    [actions, handleBack, handleSubmit, handleValidationStep1, handleValidationStep2, handleValidationStep3]
  );

  return {
    data,
    actions,
    UpdateForm,
  };
};

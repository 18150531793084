import { Avatar, makeStyles } from "@material-ui/core";

import Drawer from "@material-ui/core/Drawer";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { useSelector } from "react-redux";

const customClasses = makeStyles(() => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: "auto",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "auto",
  },
  drawerContainer: {
    padding: "5px 10px 5px 10px",
    overflow: "auto",
  },
  navHeader: {
    minHeight: "40px !important",
  },
  navMenuHeader: {
    minHeight: "64px !important",
  },
}));

const TblSearchBox = ({ data, actions }) => {
  const cClasses = customClasses();
  const bands = useSelector((state) => state.tblBand.bands);

  const { openSearch, visibleHeader } = data;
  const { toggleBox, handleNavigate } = actions;

  return (
    <div className={cClasses.root}>
      <Drawer
        className={cClasses.drawer}
        variant="temporary"
        ModalProps={{ disableEnforceFocus: true }}
        anchor="top"
        open={openSearch}
        onClose={toggleBox(false)}
        classes={{
          paper: cClasses.drawerPaper,
        }}
      >
        {visibleHeader && <Toolbar className={cClasses.navHeader} />}
        <Toolbar className={cClasses.navMenuHeader} />
        <div className={cClasses.drawerContainer}>
          <List>
            {bands.map((band) => (
              <Link key={band.seq} to={`/groups/${band.value}`} onClick={handleNavigate} style={{ textDecoration: "none" }}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src={band.photoGroup} alt={band.label} variant="square" style={{ width: 50, height: 50 }} />
                  </ListItemAvatar>
                  <ListItemText primary={band.label} style={{ color: "black" }} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

TblSearchBox.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default TblSearchBox;

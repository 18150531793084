import {
  ADD_ELEMENTS_LABELPAGE,
  API_LABEL_ADD,
  API_LABEL_DELETE,
  API_LABEL_EDIT,
  API_LABEL_GET,
  API_LABEL_SEARCH,
  CLEAR_LABEL_TABLE,
  NO_ACTION,
  SET_SCREEN_LABELPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tlabel = {
  labels: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblLabel = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_LABEL_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const labelsPayload = action.payload;
      const initialValue = {};
      const labels = labelsPayload.reduce((acc, label) => {
        return { ...acc, [label.tblLabel.codLabel]: label.desLabel };
      }, initialValue);
      return { ...state, labels };
    },
    [API_LABEL_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tlabels: {
          labels: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_LABEL_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tlabels.totalElements += 1;

      return { ...state, tlabels: { ...state.tlabels, labels: [action.payload].concat(state.tlabels?.labels || []) } };
    },
    [API_LABEL_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const labelPayload = action.payload;
      const { seqLabelId } = labelPayload;
      const labels = state.tlabels?.labels || [];
      const newLabels = labels.map((label) => {
        if (label.seqLabelId === seqLabelId) {
          return labelPayload;
        } else {
          return label;
        }
      });
      return { ...state, tlabels: { ...state.tlabels, labels: newLabels } };
    },
    [API_LABEL_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tlabels.totalElements -= 1;
      return { ...state, tlabels: { ...state.tlabels, labels: state.tlabels.labels.filter((c) => c.seqLabelId !== action.payload) } };
    },
    [ADD_ELEMENTS_LABELPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tlabels: {
          labels: [...state.tlabels.labels, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tlabels?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_LABELPAGE]: (state, action) => ({ ...state, tlabels: { ...state.tlabels, screenPage: action.payload } }),
    [CLEAR_LABEL_TABLE]: (state) => ({ ...state, tlabels: initial_tlabel }),
  },
  { tlabels: initial_tlabel, labels: [] }
);

import {
  ADD_ELEMENTS_STYLEPAGE,
  API_STYLE_ADD,
  API_STYLE_DELETE,
  API_STYLE_EDIT,
  API_STYLE_GET,
  API_STYLE_SEARCH,
  CLEAR_STYLE_TABLE,
  NO_ACTION,
  SET_SCREEN_STYLEPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tstyle = {
  styles: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblStyle = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_STYLE_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const styles = action.payload;
      const stylesOptions = styles.map((style) => ({ seq: style.seqStyle, value: style.codStyle, label: style.desStyle }));
      return { ...state, styles: stylesOptions };
    },
    [API_STYLE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tstyles: {
          styles: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_STYLE_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.styles;
      state.tstyles.totalElements += 1;

      return { ...state, tstyles: { ...state.tstyles, styles: [action.payload].concat(state.tstyles?.styles || []) } };
    },
    [API_STYLE_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const stylePayload = action.payload;
      const { seqStyle } = stylePayload;
      const styles = state.tstyles?.styles || [];
      const newStyles = styles.map((style) => {
        if (style.seqStyle === seqStyle) {
          return stylePayload;
        } else {
          return style;
        }
      });
      delete state.styles;
      return { ...state, tstyles: { ...state.tstyles, styles: newStyles } };
    },
    [API_STYLE_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.styles;
      state.tstyles.totalElements -= 1;
      return { ...state, tstyles: { ...state.tstyles, styles: state.tstyles.styles.filter((c) => c.seqStyle !== action.payload) } };
    },
    [ADD_ELEMENTS_STYLEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tstyles: {
          styles: [...state.tstyles.styles, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.tstyles?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_STYLEPAGE]: (state, action) => ({ ...state, tstyles: { ...state.tstyles, screenPage: action.payload } }),
    [CLEAR_STYLE_TABLE]: (state) => ({ ...state, tstyles: initial_tstyle }),
  },
  { tstyles: initial_tstyle, styles: [] }
);

import { actionClearBand, actionFilterBands, actionSetCurrentPage } from "../../actions/bandActions";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { VALUE_ESP } from "../../constants";
import _ from "lodash";
import { actionGetDomain } from "../../actions/domainActions";
import { actionGetProvinces } from "../../actions/provinceActions";
import { actionGetStyles } from "../../actions/styleActions";
import { callService } from "../../utils/serviceUtils";

const sort2Order = (sort) => {
  const order = sort.value === "AVD" || sort.value === "NMD" ? "desc" : "asc";
  const orderBy = sort.value === "AVA" || sort.value === "AVD" ? "total" : "name";
  return {
    order,
    orderBy,
  };
};

const order2Sort = (order, orderBy, sortOptions) => {
  let sortValue = "";
  if (order === "desc") {
    if (orderBy === "total") {
      sortValue = "AVD";
    } else {
      sortValue = "NMD";
    }
  } else {
    if (orderBy === "total") {
      sortValue = "AVA";
    } else {
      sortValue = "NMA";
    }
  }
  return sortOptions?.find((e) => e.value === sortValue) || "";
};

const getPageNeeded = (page) => {
  let pageNeeded = Math.floor((20 * (page - 1)) / 100);
  if (isMobileOnly) {
    pageNeeded = Math.floor((5 * (page - 1)) / 100);
  } else if (isTablet) {
    pageNeeded = Math.floor((10 * (page - 1)) / 100);
  }
  return pageNeeded;
};

export const useBands = () => {
  const tbands = useSelector((state) => state.tblBand.tbands);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const sortOptions = useSelector((state) => state.tblDomain.ORDER_BY);

  const dispatch = useDispatch();

  const [page, setPage] = useState(tbands.screenPage || 1);
  const [paginBands, setPaginBands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(0);
  const [initialValues, setInitialValues] = useState({
    name: tbands.search.name || "",
    provinces: tbands.search.provinces || [],
    styles: tbands.search.styles || [],
    sort: order2Sort(tbands.search.order, tbands.search.orderBy, sortOptions),
  });

  const topRef = useRef(null);

  const bands = useMemo(
    () =>
      tbands.bands.map((band) => ({
        seqGroup: band.tblGroup.seqGroup,
        codGroup: band.tblGroup.codGroup,
        name: band.tblGroup.name,
        photoGroup: band.tblGroup.photoGroup,
        total: band.tblGroup.total,
        votes: band.tblGroup.votes,
      })),
    [tbands]
  );

  useEffect(() => {
    callService(dispatch, actionGetProvinces, VALUE_ESP);
    callService(dispatch, actionGetStyles);
    callService(
      dispatch,
      actionGetDomain,
      {
        domainCode: "ORDER_BY",
        userLang: userSession.userLang,
      },
      true
    );
  }, [dispatch, userSession.userLang]);

  useEffect(() => {
    const { name, provinces, styles } = initialValues;
    const filterBandRQ = {
      name: name,
      provinces: provinces,
      styles: styles,
      page: tbands.serverPage,
      order: "desc",
      orderBy: "total",
    };
    callService(dispatch, actionFilterBands, filterBandRQ).finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tbands.search)) {
      setInitialValues({
        name: tbands.search.name,
        provinces: tbands.search.provinces,
        styles: tbands.search.styles,
        sort: order2Sort(tbands.search.order, tbands.search.orderBy, sortOptions),
      });
      dispatch(actionSetCurrentPage(tbands.screenPage));
    }
  }, [dispatch, sortOptions, tbands.screenPage, tbands.search]);

  useEffect(() => {
    if (isMobileOnly) {
      setPaginBands(bands.slice((page - (20 * tbands.serverPage + 1)) * 5, (page - (20 * tbands.serverPage + 1)) * 5 + 5));
    } else if (isTablet) {
      setPaginBands(bands.slice((page - (10 * tbands.serverPage + 1)) * 10, (page - (10 * tbands.serverPage + 1)) * 10 + 10));
    } else {
      setPaginBands(bands.slice((page - (5 * tbands.serverPage + 1)) * 20, (page - (5 * tbands.serverPage + 1)) * 20 + 20));
    }
    let numFilters = 0;
    if (initialValues.name !== "" || initialValues.provinces.length > 0 || initialValues.styles.length > 0) {
      if (initialValues.name !== "") {
        numFilters++;
      }
      if (initialValues.provinces.length > 0) {
        numFilters += initialValues.provinces.length;
      }
      if (initialValues.styles.length > 0) {
        numFilters += initialValues.styles.length;
      }
      setFilters(numFilters);
    } else {
      setFilters(0);
    }
    // scroll to top page
    topRef.current.scrollIntoView({ behavior: "smooth" });
    return () => {
      dispatch(actionClearBand());
    };
  }, [bands, dispatch, tbands.bands, tbands.serverPage, initialValues, page]);

  const searchBands = useCallback(
    (values) => {
      setIsLoading(true);
      const { sort } = values;
      const { order, orderBy } = sort2Order(sort);
      values.page = 0;
      values.order = order;
      values.orderBy = orderBy;
      return new Promise((resolve) => {
        callService(dispatch, actionFilterBands, values).finally(() => resolve());
      });
    },
    [dispatch]
  );

  const postSearchBands = useCallback(
    (setSubmitting) => {
      setSubmitting(false);
      setPage(1);
      dispatch(actionSetCurrentPage(1));
      setShowFilters(false);
      setIsLoading(false);
    },
    [dispatch]
  );

  const handleClearFilter = useCallback(
    async (resetForm, setSubmitting) => {
      resetForm();
      const sort = sortOptions?.find((e) => e.value === "AVD") || "";
      setFilters(0);
      await searchBands({ name: "", provinces: [], styles: [], sort: sort });
      postSearchBands(setSubmitting);
    },
    [postSearchBands, searchBands, sortOptions]
  );

  const handleSubmitFilter = useCallback(
    async (values, { setSubmitting }) => {
      let numFilters = 0;
      if (values.name !== "" || values.provinces.length > 0 || values.styles.length > 0) {
        if (values.name !== "") {
          numFilters++;
        }
        if (values.provinces.length > 0) {
          numFilters++;
        }
        if (values.styles.length > 0) {
          numFilters++;
        }
        setFilters(numFilters);
      } else {
        setFilters(0);
      }
      await searchBands(values);
      postSearchBands(setSubmitting);
    },
    [postSearchBands, searchBands]
  );

  const handleChangePage = useCallback(
    (event, value) => {
      const pageNeeded = getPageNeeded(value);
      if (pageNeeded !== tbands.serverPage) {
        setIsLoading(true);
        const search = tbands.search;
        search.page = pageNeeded;
        callService(dispatch, actionFilterBands, search).finally(() => setIsLoading(false));
      }
      dispatch(actionSetCurrentPage(value));
      setPage(value);
    },
    [dispatch, tbands.search, tbands.serverPage]
  );

  const bandData = useMemo(
    () => ({
      topRef,
      filters,
      showFilters,
      initialValues,
      paginBands,
      isLoading,
      page,
      bands,
    }),
    [bands, filters, initialValues, isLoading, page, paginBands, showFilters]
  );
  const actions = useMemo(
    () => ({
      setShowFilters,
      setPage,
      setFilters,
      handleClearFilter,
      handleSubmitFilter,
      handleChangePage,
    }),
    [handleChangePage, handleClearFilter, handleSubmitFilter]
  );

  return {
    bandData,
    actions,
  };
};

import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, NO_ACTION, REMOVE_SNACKBAR } from "../constants";

import { handleActions } from "redux-actions";

export const tblNotifier = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [ENQUEUE_SNACKBAR]: (state, action) => {
      return [
        ...state,
        {
          key: action.payload.key,
          ...action.payload.notification,
        },
      ];
    },
    [CLOSE_SNACKBAR]: (state, action) => [
      ...state,
      state.map((notification) => (action.dismissAll || notification.key === action.payload.key ? { ...notification, dismissed: true } : { ...notification })),
    ],
    [REMOVE_SNACKBAR]: (state, action) => state.filter((notification) => notification.key !== action.payload),
  },
  []
);

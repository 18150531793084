import { useMemo, useState } from "react";

import Grid from "@material-ui/core/Grid";
import PlaceSearchForm from "./PlaceSearchForm";
import PlaceTable from "./PlaceTable";
import PropTypes from "prop-types";
import TblCountry from "../../../constants/TblCountry.json";
import TblProvince from "../../../constants/TblProvince.json";

const extraData = {
  headCellsCountries: TblCountry.headCells,
  headCellsProvinces: TblProvince.headCells,
  initialValuesCountries: {
    seqCountryId: null,
    codCountry: "",
    desCountry: "",
    codLang: "",
  },
  initialValuesProvinces: {
    seqProvince: null,
    tblCountry: "",
    codProvince: "",
    desProvince: "",
  },
};

const PlaceTab = ({ data, actions }) => {
  const [selecCountry, setSelecCountry] = useState({});
  const [selecProvince, setSelecProvince] = useState({});
  const [openProvinceAudit, setOpenProvinceAudit] = useState(false);
  data = useMemo(() => ({ ...data, ...extraData, selecCountry, selecProvince, openProvinceAudit }), [data, openProvinceAudit, selecCountry, selecProvince]);
  actions = useMemo(() => ({ ...actions, setSelecCountry, setSelecProvince, setOpenProvinceAudit }), [actions, setSelecCountry, setSelecProvince]);

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <PlaceSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <PlaceTable data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

PlaceTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default PlaceTab;

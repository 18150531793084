import {
  ADD_ELEMENTS_PROVINCEPAGE,
  API_PROVINCE_ADD,
  API_PROVINCE_DELETE,
  API_PROVINCE_EDIT,
  API_PROVINCE_GET,
  API_PROVINCE_SEARCH,
  CLEAR_PROVINCE_TABLE,
  NO_ACTION,
  SET_SCREEN_PROVINCEPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tprovince = {
  provinces: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblProvince = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_PROVINCE_GET]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const provinces = action.payload;
      const provincesOptions = provinces.map((province) => ({ seq: province.seqProvince, value: province.codProvince, label: province.desProvince }));
      return { ...state, provinces: provincesOptions };
    },
    [API_PROVINCE_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tprovinces: {
          provinces: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_PROVINCE_ADD]: (state, action) => {
      if (!action.payload || action.payload.stack) {
        return state;
      }
      delete state.provinces;
      state.tprovinces.totalElements += 1;

      return { ...state, tprovinces: { ...state.tprovinces, provinces: [action.payload].concat(state.tprovinces?.provinces || []) } };
    },
    [API_PROVINCE_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const provincePayload = action.payload;
      const { seqProvince } = provincePayload;
      const provinces = state.tprovinces?.provinces || [];
      const newProvinceuages = provinces.map((province) => {
        if (province.seqProvince === seqProvince) {
          return provincePayload;
        } else {
          return province;
        }
      });
      delete state.provinces;
      return { ...state, tprovinces: { ...state.tprovinces, provinces: newProvinceuages } };
    },
    [API_PROVINCE_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      delete state.provinces;
      state.tprovinces.totalElements -= 1;
      return { ...state, tprovinces: { ...state.tprovinces, provinces: state.tprovinces.provinces.filter((c) => c.seqProvince !== action.payload) } };
    },
    [ADD_ELEMENTS_PROVINCEPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tprovinces: {
          provinces: [...state.tprovinces.provinces, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(state.tprovinces?.screenPage || action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_PROVINCEPAGE]: (state, action) => ({ ...state, tprovinces: { ...state.tprovinces, screenPage: action.payload } }),
    [CLEAR_PROVINCE_TABLE]: (state) => ({ ...state, tprovinces: initial_tprovince }),
  },
  { tprovinces: initial_tprovince, provinces: [] }
);

import "./styles.css";

import { CSSTransition, SwitchTransition } from "react-transition-group";

import PropTypes from "prop-types";

const TblSwitchTransition = ({ children, mode, keyTransition, effectClass }) => {
  return (
    <SwitchTransition mode={mode}>
      <CSSTransition
        key={keyTransition}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames={effectClass}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

TblSwitchTransition.propTypes = {
  children: PropTypes.node.isRequired,
  mode: PropTypes.string.isRequired,
  keyTransition: PropTypes.any.isRequired,
  effectClass: PropTypes.string.isRequired,
};

export default TblSwitchTransition;

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblFrame from "../components/TblFrame";
import TblMaintainTabs from "../components/TblMaintainTabs";
import { actionClearBandTable } from "../actions/bandActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useStyles } from "@material-ui/pickers/views/Calendar/Day";

const MaintainPage = ({ setScroll }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actionClearBandTable());
  }, [dispatch]);

  return (
    <TblFrame setScroll={setScroll} size={6} hideCarousel={true}>
      <Grid container>
        <div className={classes.mt1}></div>
        <Grid item xs={12}>
          <TblMaintainTabs />
        </Grid>
      </Grid>
    </TblFrame>
  );
};

MaintainPage.propTypes = {
  setScroll: PropTypes.func.isRequired,
};

export default MaintainPage;

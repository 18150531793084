export class TblContestId {
  constructor(seqContestId, tblContest, tblLanguage, summary, description, listBands, dateCre, dateMod, userCre, userMod) {
    this.seqContestId = seqContestId;
    this.tblContest = tblContest;
    this.tblLanguage = tblLanguage;
    this.summary = summary;
    this.description = description;
    this.bands = listBands;
    this.dateCre = dateCre;
    this.dateMod = dateMod;
    this.userCre = userCre;
    this.userMod = userMod;
  }
}

import React, { useCallback, useMemo } from "react";
import {
  actionAddCountry,
  actionAddElementsPage,
  actionDeleteCountry,
  actionSearchCountries,
  actionSetCurrentPage,
  actionUpdateCountry,
} from "../../../../../actions/countryActions";

import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../../TblPopups/TblAuditPopup";
import TblCards from "../../../TblCards";
import { TblCountry } from "../../../../../model/TblCountry";
import { TblCountryId } from "../../../../../model/TblCountryId";
import { TblLanguage } from "../../../../../model/TblLanguage";
import TblMaintainPopup from "../../../TblMaintainPopup";
import TblSelect from "../../../../TblForms/TblSelect";
import TblTable from "../../../TblTable";
import TblTextField from "../../../../TblForms/TblTextField";
import { useSelector } from "react-redux";

const CountryTable = ({ headCells, initialValues, selected, setSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const langs = useSelector((state) => state.tblLang.langs);
  const tcountries = useSelector((state) => state.tblCountry.tcountries);

  const { search, serverPage, screenPage, totalElements } = tcountries;
  const { openAudit } = data;
  const { setOpenAudit } = actions;

  const rows = useMemo(
    () =>
      tcountries.countries.reduce((acc, row) => {
        return [
          ...acc,
          new TblCountryId(
            row.seqCountryId,
            new TblCountry(row.tblCountry.seqCountry, row.tblCountry.codCountry),
            row.desCountry,
            new TblLanguage(row.tblLanguage.seqLanguage, row.tblLanguage.codLang, row.tblLanguage.desLang),
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tcountries.countries]
  );

  const tableLabel = labels["TBL_ENTITY_TBL_COUNTRY"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { codCountry, desCountry, codLang } = values;
      if (!codCountry) {
        errors.codCountry = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!desCountry) {
        errors.desCountry = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!codLang?.value) {
        errors.codLang = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      headCells,
      initialValues,
      cardtitle: labels["TBL_MAINTAINTABS_TABPANEL_TITLE_COUNTRIES"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected,
    }),
    [data, headCells, initialValues, labels, rows, tableLabel, search, serverPage, screenPage, totalElements, selected]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchCountries,
      actionAdd: actionAddCountry,
      actionUpdate: actionUpdateCountry,
      actionDelete: actionDeleteCountry,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      onValidation: handleValidation,
      setSelected,
    }),
    [setSelected, actions, handleValidation]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <TblTextField id={headCells[1].id} name={headCells[1].column} label={labels[headCells[1].label]} properties={headCells[1].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} />
          </Grid>
          <Grid container item xs={6}>
            <TblSelect
              id={headCells[3].id}
              name={headCells[3].column}
              label={labels[headCells[3].label]}
              options={langs}
              defaultOption={{ seq: 0, value: "", label: labels["TBL_COMMON_SELECT_LANG"] }}
            />
          </Grid>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openAudit} setOpen={setOpenAudit} selected={selected} />
    </div>
  );
};

CountryTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default CountryTable;

import "moment/locale/es";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import moment from "moment";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

const rootComponent = (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY} language="es">
    <CookiesProvider>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          preventDuplicate
        >
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </Provider>
    </CookiesProvider>
  </GoogleReCaptchaProvider>
);
ReactDOM.render(rootComponent, document.getElementById("root"));

reportWebVitals();

import {
  ADD_ELEMENTS_BAND_LINKPAGE,
  API_BAND_LINK_ADD,
  API_BAND_LINK_DELETE,
  API_BAND_LINK_EDIT,
  API_BAND_LINK_SEARCH,
  CLEAR_BAND_LINK_TABLE,
  NO_ACTION,
  SET_SCREEN_BAND_LINKPAGE,
} from "../constants";

import { handleActions } from "redux-actions";

export const initial_tbandLink = {
  bandLinks: [],
  search: {},
  serverPage: 0,
  screenPage: 0,
  totalElements: 0,
};

export const tblBandLink = handleActions(
  {
    [NO_ACTION]: (state) => {
      return state;
    },
    [API_BAND_LINK_SEARCH]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandLinks: {
          bandLinks: [...(action.payload || [])],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: Number(action.payload.page),
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [API_BAND_LINK_ADD]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandLinks.totalElements += 1;

      return { ...state, tbandLinks: { ...state.tbandLinks, bandLinks: [action.payload].concat(state.tbandLinks?.bandLinks || []) } };
    },
    [API_BAND_LINK_EDIT]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      const linkPayload = action.payload;
      const { seqLink } = linkPayload;
      const bandLinks = state.tbandLinks?.bandLinks || [];
      const newBandLinks = bandLinks.map((bandLink) => {
        if (bandLink.seqLink === seqLink) {
          return linkPayload;
        } else {
          return bandLink;
        }
      });
      return { ...state, tbandLinks: { ...state.tbandLinks, bandLinks: newBandLinks } };
    },
    [API_BAND_LINK_DELETE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      state.tbandLinks.totalElements -= 1;
      return { ...state, tbandLinks: { ...state.tbandLinks, bandLinks: state.tbandLinks.bandLinks.filter((c) => c.seqLink !== action.payload) } };
    },
    [ADD_ELEMENTS_BAND_LINKPAGE]: (state, action) => {
      if (action.payload?.stack) {
        return state;
      }
      return {
        ...state,
        tbandLinks: {
          bandLinks: [...state.tbandLinks.bandLinks, ...action.payload],
          search: action.payload.search,
          serverPage: Number(action.payload.page),
          screenPage: state.tbandLinks?.screenPage || action.payload.page,
          totalElements: Number(action.payload.totalElements),
        },
      };
    },
    [SET_SCREEN_BAND_LINKPAGE]: (state, action) => ({ ...state, tbandLinks: { ...state.tbandLinks, screenPage: action.payload } }),
    [CLEAR_BAND_LINK_TABLE]: (state) => ({ ...state, tbandLinks: initial_tbandLink }),
  },
  { tbandLinks: initial_tbandLink }
);

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Carousel from "react-material-ui-carousel";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import TblFooter from "./TblFooter";
import TblHeader from "./TblHeader";
import TblMenuHeader from "./TblMenuHeader";
import TblPartners from "./TblPartners";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useStyles } from "../../theme/useStyles";
import { useTblFrame } from "../../hooks/TblFrame/useTblFrame";

const customStyles = makeStyles(() => ({
  carousel: {
    border: 1,
    borderColor: "#FFF",
    borderStyle: "solid",
  },
}));

const TblFrame = ({ children, setScroll, size, hideHeader, hideCarousel }) => {
  const classes = useStyles();
  const cClasses = customStyles();
  const imagesPath = useSelector((state) => state.tblImagesPath.BAM) || [];

  const { data, actions } = useTblFrame();

  const Item = ({ item }) => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={size}>
          <Card className={cClasses.carousel}>
            <CardActionArea>
              <CardMedia component="img" alt={item.imageName} image={item.imagePath} title={item.imageName} onClick={() => window.open(item.imageUrl, "_blank")} />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {!hideHeader && <TblHeader />}
      <TblMenuHeader data={data} actions={actions} setScroll={setScroll} />
      {!hideCarousel && (
        <div className={classes.bgTexture}>
          <Carousel interval={8000} timeout={1000} indicators={false}>
            {imagesPath.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </div>
      )}
      {children}
      <Divider variant="middle" className={classes.divider} />
      <div className={classes.py1}></div>
      <TblPartners data={data} />
      <div className={classes.py1}></div>
      <TblFooter />
    </React.Fragment>
  );
};

TblFrame.propTypes = {
  children: PropTypes.node,
  setScroll: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};

export default TblFrame;

import { Form, Formik } from "formik";
import { callService, doPost } from "../../../utils/serviceUtils";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";
import TblLinearProgress from "../../TblLinearProgress";
import TblSelect from "../../TblForms/TblSelect";
import TblTextField from "../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import { actionGetDomain } from "../../../actions/domainActions";
import { showNotifier } from "../../../utils/dataUtils";
import { urlSendContact } from "../../../api/urls";
import { useStyles } from "../../../theme/useStyles";

const TblContactForm = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const userSession = useSelector((state) => state.tblUser.userSession);
  const subjectOptions = useSelector((state) => state.tblDomain.CONTACT_SUBJECT) || [];
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      name: userSession?.user || "",
      email: userSession?.email || "",
      subject: "",
      text: "",
    }),
    [userSession]
  );

  if (subjectOptions.length === 0) {
    callService(dispatch, actionGetDomain, { domainCode: "CONTACT_SUBJECT", userLang: userSession.userLang });
  }

  const handleValidate = useCallback(
    (values) => {
      const errors = {};
      const { name, email, subject, text } = values;
      if (!name) {
        errors.name = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!email) {
        errors.email = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      if (!subject) {
        errors.subject = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!text) {
        errors.text = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting, resetForm }) => {
      const { name, email, subject, text } = values;
      const request = {
        name,
        email,
        subject: subject.label,
        text,
      };
      doPost(urlSendContact, request)
        .then(({ data }) => {
          if (data?.stack) {
            showNotifier(dispatch, data.message, "error");
          } else {
            showNotifier(dispatch, labels["TBL_HOME_SENDCONTACT_OK"], "success");
            resetForm();
          }
        })
        .finally(() => setSubmitting(false));
    },
    [dispatch, labels]
  );

  return (
    <Grid container direction="column" alignContent="center" alignItems="center" className={classes.py3}>
      <Grid item xs={10} lg={4}>
        <Typography variant="h3" align="center">
          {labels["TBL_HOME_CONTACT_TITLE"]}
        </Typography>
        <hr className={classes.my1} />
        <Typography variant="subtitle1" align="center">
          {labels["TBL_HOME_CONTACT_SUBTITLE"]}
        </Typography>
      </Grid>
      <Grid item container direction="column" xs={10} lg={6} className={classes.pt2}>
        <Formik enableReinitialize initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit}>
          {({ isSubmitting, resetForm }) => (
            <Form>
              <Grid item xs={12} container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <TblTextField id="input-name" name="name" label={labels["TBL_ENTITY_TBL_USER_NAME"]} properties={{ type: "text", maxLength: 30 }} />
                </Grid>
                <Grid item xs={12}>
                  <TblTextField id="input-email" name="email" label={labels["TBL_ENTITY_TBL_USER_EMAIL"]} properties={{ type: "text", maxLength: 50 }} />
                </Grid>
                <Grid item xs={12}>
                  <TblSelect id="select-subject" name="subject" label={labels["TBL_COMMON_LABEL_SUBJECT"]} options={subjectOptions} />
                </Grid>
                <Grid item xs={12}>
                  <TblTextField
                    id="input-text"
                    name="text"
                    label={labels["TBL_COMMON_LABEL_MESSAGE"]}
                    properties={{
                      type: "text",
                      multiline: true,
                      rows: 10,
                      maxRows: 15,
                      maxLength: 5000,
                    }}
                  />
                </Grid>
                <Grid item container direction="row" justifyContent="flex-end" spacing={3}>
                  <Grid item>
                    <Button type="submit" variant="contained" color="secondary" startIcon={<SearchIcon />} disabled={isSubmitting}>
                      {labels["TBL_COMMON_BUTTON_SEND"]}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" startIcon={<RotateLeftIcon />} onClick={resetForm} disabled={isSubmitting}>
                      {labels["TBL_COMMON_BUTTON_RESET"]}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {isSubmitting && <TblLinearProgress />}
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default TblContactForm;

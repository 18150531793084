import { useMemo, useState } from "react";

import BandContainer from ".";
import BandSearchForm from "./BandSearchForm";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblBand from "../../../constants/TblBand.json";
import TblBandLink from "../../../constants/TblBandLink.json";
import TblBandProvince from "../../../constants/TblBandProvince.json";
import TblBandReview from "../../../constants/TblBandReview.json";
import TblBandStyle from "../../../constants/TblBandStyle.json";
import TblBandUserVote from "../../../constants/TblBandUserVote.json";
import TblBandVideo from "../../../constants/TblBandVideo.json";

const extraData = {
  headCellsBands: TblBand.headCells,
  headCellsBandProvinces: TblBandProvince.headCells,
  headCellsBandStyles: TblBandStyle.headCells,
  headCellsBandLinks: TblBandLink.headCells,
  headCellsBandVideos: TblBandVideo.headCells,
  headCellsBandUserVotes: TblBandUserVote.headCells,
  headCellsBandReviews: TblBandReview.headCells,
  initialValuesBand: {
    seqGroupId: null,
    codGroup: "",
    name: "",
    codCountry: "",
    provinces: [],
    styles: [],
    codLang: "",
    reviewed: true,
    description: "",
    web: "",
    facebook: "",
    instagram: "",
    youtube: "",
    bandcamp: "",
    soundcloud: "",
    urlVideo: "",
    videos: [],
    file: null,
  },
  initialValuesBandProvince: {
    seqProvince: null,
    tblGroup: "",
    tblCountry: "",
    codProvince: "",
  },
  initialValuesBandStyle: {
    seqStyle: null,
    tblGroup: "",
    codStyle: "",
  },
  initialValuesBandLink: {
    seqLink: null,
    tblGroup: "",
    namePage: "",
    urlPage: "",
  },
  initialValuesBandVideo: {
    seqLink: null,
    tblGroup: "",
    urlVideo: "",
    reviewed: true,
  },
  initialValuesBandReview: {
    seqReview: null,
    tblGroup: "",
    user: "",
    reviewTitle: "",
    review: "",
    reviewed: false,
    revisionReviewTitle: "",
    revisionReview: "",
  },
};

const BandTab = ({ data, actions }) => {
  const [disabledProv, setDisabledProv] = useState(true);
  const [selecBand, setSelecBand] = useState({});
  const [selecProvince, setSelecProvince] = useState({});
  const [selecStyle, setSelecStyle] = useState({});
  const [selecLink, setSelecLink] = useState({});
  const [selecVideo, setSelecVideo] = useState({});
  const [selecUserVote, setSelecUserVote] = useState({});
  const [selecReview, setSelecReview] = useState({});
  const [openProvinceAudit, setOpenProvinceAudit] = useState(false);
  const [openStyleAudit, setOpenStyleAudit] = useState(false);
  const [openLinkAudit, setOpenLinkAudit] = useState(false);
  const [openVideoAudit, setOpenVideoAudit] = useState(false);
  const [openUserVoteAudit, setOpenUserVoteAudit] = useState(false);
  const [openReviewAudit, setOpenReviewAudit] = useState(false);

  data = useMemo(
    () => ({
      ...data,
      ...extraData,
      disabledProv,
      selecBand,
      selecProvince,
      selecStyle,
      selecLink,
      selecVideo,
      selecUserVote,
      selecReview,
      openProvinceAudit,
      openStyleAudit,
      openLinkAudit,
      openVideoAudit,
      openUserVoteAudit,
      openReviewAudit,
    }),
    [
      data,
      disabledProv,
      selecBand,
      selecProvince,
      selecStyle,
      selecLink,
      selecVideo,
      selecUserVote,
      selecReview,
      openProvinceAudit,
      openStyleAudit,
      openLinkAudit,
      openVideoAudit,
      openUserVoteAudit,
      openReviewAudit,
    ]
  );
  actions = useMemo(
    () => ({
      ...actions,
      setDisabledProv,
      setSelecBand,
      setSelecProvince,
      setSelecStyle,
      setSelecLink,
      setSelecVideo,
      setSelecUserVote,
      setSelecReview,
      setOpenProvinceAudit,
      setOpenStyleAudit,
      setOpenLinkAudit,
      setOpenVideoAudit,
      setOpenUserVoteAudit,
      setOpenReviewAudit,
    }),
    [actions]
  );

  return (
    <Grid container direction="column" justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <BandSearchForm data={data} actions={actions} />
      </Grid>
      <Grid item xs={12}>
        <BandContainer data={data} actions={actions} />
      </Grid>
    </Grid>
  );
};

BandTab.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandTab;

import BandTab from "./BandTab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TblTabPanel from "../../TblTabPanel";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { useState } from "react";

const AdminBandTabs = ({ data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Paper>
        <Tabs value={value} indicatorColor="secondary" textColor="secondary" centered onChange={handleChange} aria-label="admin band tabs">
          <Tab label={labels["TBL_ADMINBAND_APPBAR_TABPANEL_BAND"]} />
          <Tab label={labels["TBL_ADMINBAND_APPBAR_TABPANEL_RANKING"]} />
        </Tabs>
      </Paper>
      <TblTabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_BAND"]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <BandTab data={data} actions={actions} />
          </Grid>
        </Grid>
      </TblTabPanel>
      <TblTabPanel value={value} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {labels["TBL_MAINTAINTABS_TABPANEL_TITLE_RANKING"]}
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </TblTabPanel>
    </div>
  );
};

AdminBandTabs.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default AdminBandTabs;

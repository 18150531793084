import { VALUE_N, VALUE_S } from "../constants";
import { doDelete, doGet, doPost, doPut, searchData } from "../utils/serviceUtils";

import { TblImagesPath } from "../model/TblImagesPath";

export const apiGetImagesPaths = (url, obj) => async () => {
  const { imageGroup } = obj;
  url = `${url}?imageGroup=${imageGroup}`;
  const { data } = await doGet(url);
  return data;
};

export const apiSearchImagesPaths = (url, obj) => async () => {
  const { imageName, imagePath, imageGroup, active } = obj;
  url = `${url}?imageName=${imageName}&imagePath=${imagePath}&imageGroup=${imageGroup ? imageGroup.value : ""}&active=${active ? active.value : ""}`;
  return searchData(url, obj);
};

export const apiAddImagesPath = (url, obj) => async () => {
  const { seqImagesPath, imageName, imagePath, imageGroup, imageUrl, active, sort, image } = obj;
  const request = new TblImagesPath(seqImagesPath, imageName, imagePath, imageGroup?.value, imageUrl, active ? VALUE_S : VALUE_N, sort);
  request.image = image;
  const { data } = await doPost(url, request);
  return data;
};

export const apiUpdateImagesPath = (url, obj) => async () => {
  const { seqImagesPath, imageName, imagePath, imageGroup, imageUrl, active, sort, image, dateCre, dateMod, userCre, userMod } = obj;
  url = `${url}/${seqImagesPath}`;
  const request = new TblImagesPath(seqImagesPath, imageName, imagePath, imageGroup?.value, imageUrl, active ? VALUE_S : VALUE_N, sort, dateCre, dateMod, userCre, userMod);
  request.image = image;
  const { data } = await doPut(url, request);
  return data;
};

export const apiDeleteImagesPath = (url, id) => async () => {
  url = `${url}/${id}`;
  const { data } = await doDelete(url, id);
  return data;
};

import React, { useCallback, useState } from "react";

import Button from "@material-ui/core/Button";
import Cropper from "react-easy-crop";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { getCroppedImg } from "../../utils/canvasUtils";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useStyles } from "../../theme/useStyles";

const customStyles = makeStyles(() => ({
  frameCropper: {
    paddingTop: 50,
    paddingBottom: 50,
    width: "100%",
    background: "#333",
  },

  cropContainer: {
    position: "relative",
    width: "100%",
    height: 300,
    background: "#333",
  },
}));

const TblCropper = ({ imageSrc, setCroppedImage, setImageSrc }) => {
  const classes = useStyles();
  const cClasses = customStyles();
  const labels = useSelector((state) => state.tblLabel.labels);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsParam) => {
    setCroppedAreaPixels(croppedAreaPixelsParam);
  }, []);

  const cropImage = async () => {
    setCroppedImage(await getCroppedImg(imageSrc, croppedAreaPixels));
    setImageSrc(null);
  };

  return (
    <div>
      <Grid container direction="column" justifyContent="center" alignContent="center">
        <Grid item xs={11} md={8} lg={4}>
          <Typography variant="h6">{labels["TBL_CROPPER_LABEL_CROP_IMAGE"]}:</Typography>
          <div className={classes.pt1}></div>
          <div className={cClasses.frameCropper}>
            <div className={cClasses.cropContainer}>
              <Cropper image={imageSrc} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
            </div>
          </div>
        </Grid>
        <div className={classes.pt1}></div>
        <Grid item xs={11} md={8} lg={4} container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="secondary" onClick={cropImage}>
              {labels["TBL_COMMON_BUTTON_OK"]}
            </Button>
          </Grid>
          <div className={classes.pr1}></div>
          <Grid item>
            <Button variant="contained" onClick={() => setImageSrc(null)}>
              {labels["TBL_COMMON_BUTTON_CLOSE"]}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

TblCropper.propTypes = {
  imageSrc: PropTypes.object.isRequired,
  setCroppedImage: PropTypes.func.isRequired,
  setImageSrc: PropTypes.func.isRequired,
};

export default TblCropper;

import { BrowserView, MobileOnlyView, TabletView, isMobileOnly } from "react-device-detect";
import React, { useCallback, useEffect, useRef, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import TblCardActions from "./TblCardActions";
import TblLinearProgress from "../../TblLinearProgress";
import TblSwitchTransition from "../../TblSwitchTransition/TblSwitchTransition";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const customStyles = makeStyles(() => ({
  root: {
    width: isMobileOnly ? 300 : 600,
  },
  media: {
    height: isMobileOnly ? 200 : 400,
  },
}));

const TblGridContests = ({ topRef, onAction, gridList, myContests, loading }) => {
  const cClasses = customStyles();
  const [page, setPage] = useState(0);
  const [pageContests, setPageContests] = useState([]);
  const [transition, setTransition] = useState("alpha-right");
  const infoRef = useRef(null);

  useEffect(() => {
    setPageContests(gridList.slice(page, page + 1));
  }, [gridList, page, topRef]);

  const nextPage = useCallback(() => {
    setTransition("alpha-right");
    if (page === gridList.length - 1) {
      setPage(0);
    } else {
      setPage(page + 1);
    }
    // scroll to top page
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [gridList.length, page, topRef]);

  const previousPage = useCallback(() => {
    setTransition("alpha-left");
    if (page === 0) {
      setPage(gridList.length - 1);
    } else {
      setPage(page - 1);
    }
    // scroll to top page
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [gridList.length, page, topRef]);

  const ArrowBack = () => {
    return (
      <Grid item>
        <IconButton color="secondary" aria-label="upload picture" component="span" onClick={previousPage} disabled={loading}>
          <ArrowBackIosIcon />
        </IconButton>
      </Grid>
    );
  };

  const ArrowForward = () => {
    return (
      <Grid item>
        <IconButton color="secondary" aria-label="upload picture" component="span" onClick={nextPage} disabled={loading}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} container direction={isMobileOnly ? "column" : "row"} justifyContent="space-evenly" alignItems="center" spacing={2}>
      <BrowserView>
        <ArrowBack />
      </BrowserView>
      <TabletView>
        <ArrowBack />
      </TabletView>
      <MobileOnlyView>
        <Grid item container direction="row">
          <ArrowBack />
          <ArrowForward />
        </Grid>
      </MobileOnlyView>
      {pageContests.map((contest, i) => (
        <TblSwitchTransition key={i} mode="out-in" keyTransition={page} effectClass={transition}>
          <Grid item>
            <Card className={cClasses.root}>
              <CardMedia className={cClasses.media} image={contest.tblContest.photoContest} title={contest.tblContest.name} />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  {contest.tblContest.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {contest.summary}
                </Typography>
              </CardContent>
              <div ref={infoRef}></div>
              <TblCardActions topRef={topRef} infoRef={infoRef} onAction={onAction} contest={contest} myContests={myContests} loading={loading} />
            </Card>
            {loading && <TblLinearProgress />}
          </Grid>
        </TblSwitchTransition>
      ))}
      <BrowserView>
        <ArrowForward />
      </BrowserView>
      <TabletView>
        <ArrowForward />
      </TabletView>
    </Grid>
  );
};

TblGridContests.propTypes = {
  topRef: PropTypes.object.isRequired,
  onAction: PropTypes.func,
  gridList: PropTypes.array.isRequired,
  myContests: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

export default TblGridContests;

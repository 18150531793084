import React, { useCallback, useMemo, useState } from "react";
import {
  actionAddBandReview,
  actionAddElementsPage,
  actionDeleteBandReview,
  actionSearchBandReviews,
  actionSetCurrentPage,
  actionUpdateBandReview,
} from "../../../../actions/bandReviewActions";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TblAuditPopup from "../../../TblPopups/TblAuditPopup";
import TblAutocomplete from "../../../TblForms/TblAutocomplete";
import TblCards from "../../TblCards";
import TblCheckBoxLabel from "../../../TblForms/TblCheckboxLabel";
import { TblGroup } from "../../../../model/TblGroup";
import { TblGroupReview } from "../../../../model/TblGroupReview";
import TblMaintainPopup from "../../TblMaintainPopup";
import TblTable from "../../TblTable";
import TblTextField from "../../../TblForms/TblTextField";
import Typography from "@material-ui/core/Typography";
import { actionGetUsers } from "../../../../actions/userActions";
import { callService } from "../../../../utils/serviceUtils";

const BandReviewTable = ({ headCells, initialValues, masterSelected, data, actions }) => {
  const labels = useSelector((state) => state.tblLabel.labels);
  const tbandReviews = useSelector((state) => state.tblBandReview.tbandReviews);
  const users = useSelector((state) => state.tblUser.users);

  const dispatch = useDispatch();
  const detailRow = true;

  const [open, setOpen] = useState(false);

  const { search, serverPage, screenPage, totalElements } = tbandReviews;
  const { selecReview, openReviewAudit } = data;
  const { setSelecReview, setOpenReviewAudit } = actions;

  if (users.length === 0) {
    callService(dispatch, actionGetUsers);
  }

  const rows = useMemo(
    () =>
      tbandReviews.bandReviews.reduce((acc, row) => {
        return [
          ...acc,
          new TblGroupReview(
            row.seqReview,
            row.codReview,
            new TblGroup(row.tblGroup.seqGroup, row.tblGroup.codGroup, row.tblGroup.name),
            { seq: row.tblUser.seqUser, value: row.tblUser.user, label: row.tblUser.user },
            row.reviewTitle,
            row.review,
            row.reviewed,
            row.reviewedDate,
            row.tblGroupReviewRevision,
            row.dateCre,
            row.dateMod,
            row.userCre,
            row.userMod
          ),
        ];
      }, []),
    [tbandReviews.bandReviews]
  );

  const tableLabel = labels["TBL_COMMON_LABEL_REVIEW"];

  /**
   * Method to validate form
   * @param {*} values
   */
  const handleValidation = useCallback(
    (values) => {
      const errors = {};
      const { review, reviewTitle, user } = values;
      if (!user) {
        errors.user = labels["TBL_COMMON_INVALIDSELECT_REQUIRED"];
      }
      if (!review) {
        errors.review = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      } else if (review.length < 100) {
        errors.review = labels["TBL_COMMON_INVALIDINPUT_MINLENGTH"].replace("<number>", "100");
      }
      if (!reviewTitle) {
        errors.reviewTitle = labels["TBL_COMMON_INVALIDINPUT_REQUIRED"];
      }
      return errors;
    },
    [labels]
  );

  data = useMemo(
    () => ({
      ...data,
      open,
      headCells,
      detailRow,
      initialValues,
      masterSelected,
      cardtitle: labels["TBL_COMMON_LABEL_REVIEW"],
      rows,
      numRows: totalElements,
      tableLabel,
      search,
      serverPage,
      screenPage,
      totalElements,
      selected: selecReview,
    }),
    [data, open, headCells, detailRow, initialValues, masterSelected, labels, rows, totalElements, tableLabel, search, serverPage, screenPage, selecReview]
  );
  actions = useMemo(
    () => ({
      ...actions,
      actionSearch: actionSearchBandReviews,
      actionAdd: actionAddBandReview,
      actionUpdate: actionUpdateBandReview,
      actionDelete: actionDeleteBandReview,
      actionAddElementsPage,
      setScreenPage: actionSetCurrentPage,
      setSelected: setSelecReview,
      onValidation: handleValidation,
      setOpen,
    }),
    [actions, handleValidation, setSelecReview]
  );

  return (
    <div>
      <TblCards data={data} actions={actions} setOpenAudit={setOpenReviewAudit}>
        <TblTable data={data} actions={actions} />
      </TblCards>
      <TblMaintainPopup data={data} actions={actions}>
        <Grid container direction="column" spacing={3}>
          <Grid container direction="row" justifyContent="space-between" item>
            <Grid item xs={5}>
              <TblAutocomplete id={headCells[2].id} name={headCells[2].column} label={labels[headCells[2].label]} properties={headCells[2].properties} options={users} />
            </Grid>
            <Grid item xs={5}>
              <TblCheckBoxLabel id={headCells[5].id} name={headCells[5].column} label={labels[headCells[5].label]} properties={headCells[5].properties} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[3].id} name={headCells[3].column} label={labels[headCells[3].label]} properties={headCells[3].properties} />
          </Grid>
          <Grid item xs={12}>
            <TblTextField id={headCells[4].id} name={headCells[4].column} label={labels[headCells[4].label]} properties={headCells[4].properties} />
          </Grid>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              {labels["TBL_GROUP_REVIEW_LABEL_REVIEWREVISION"]}
            </Typography>
            <Grid item container direction="column" spacing={3}>
              <Grid item xs={12}>
                <TblTextField id={headCells[6].id} name={headCells[6].column} label={labels[headCells[6].label]} properties={headCells[6].properties} />
              </Grid>
              <Grid item xs={12}>
                <TblTextField id={headCells[7].id} name={headCells[7].column} label={labels[headCells[7].label]} properties={headCells[7].properties} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </TblMaintainPopup>
      <TblAuditPopup open={openReviewAudit} setOpen={setOpenReviewAudit} selected={selecReview} />
    </div>
  );
};

BandReviewTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  masterSelected: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default BandReviewTable;

import { ROLE_NOACTIVE, ROLE_PUBLIC } from "../../../constants";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import TblCircularProgress from "../../TblCircularProgress";
import TblLinearProgress from "../../TblLinearProgress";
import Typography from "@material-ui/core/Typography";
import { useActiveUser } from "../../../hooks/TblProfile/useActiveUser";
import { useSelector } from "react-redux";
import { useStyles } from "../../../theme/useStyles";

const TblActiveUser = () => {
  const classes = useStyles();
  const labels = useSelector((state) => state.tblLabel.labels);
  const templates = useSelector((state) => state.tblTemplate.templates);
  const userSession = useSelector((state) => state.tblUser.userSession);

  const { data, actions } = useActiveUser();
  const { isLoading, isLoadingResend, activated, history } = data;
  const { handleResendActivationEmail } = actions;

  const transform = (node) => {
    if (node?.attribs?.id === "resend") {
      return (
        <Link key={node.attribs.id} onClick={handleResendActivationEmail} underline="always">
          {node.children[0].data}
        </Link>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={classes.pt2}></div>
      {!isLoading && (userSession.applicationRole.includes(ROLE_PUBLIC) || activated) && (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={11}>
            <Typography variant="body1">{labels["TBL_ACTIVE_USER_LABEL_MSG"]}</Typography>
            <div className={classes.mt1}></div>
            {ReactHtmlParser(templates["TBL_ACTIVE_USER_TEMPLATE_SERVICES"])}
          </Grid>
          <div className={classes.mt1}></div>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => history.push("/")}>
              {labels["TBL_COMMON_LABEL_BACK"]}
            </Button>
          </Grid>
        </Grid>
      )}
      {!isLoading && userSession.applicationRole.includes(ROLE_NOACTIVE) && (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={11}>
            <Typography variant="body1">{labels["TBL_ACTIVE_USER_LABEL_NO_ACTIVEMSG"]}</Typography>
            <div className={classes.mt1}></div>
            {ReactHtmlParser(templates["TBL_ACTIVE_USER_TEMPLATE_INSTRUCTIONS"], { transform })}
          </Grid>
          {isLoadingResend && (
            <Box sx={{ width: 300 }}>
              <TblLinearProgress />
            </Box>
          )}
          <div className={classes.mt1}></div>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => history.push("/")}>
              {labels["TBL_COMMON_LABEL_BACK"]}
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <TblCircularProgress />
          </Grid>
        </Grid>
      )}
      <div className={classes.pt2}></div>
    </React.Fragment>
  );
};

export default TblActiveUser;
